/*eslint-disable*/
import React, { useState, useEffect } from "react";
// @material-ui/core components
import {
  makeStyles,
  MenuItem,
  TextField,
  CircularProgress,
  Slide,
  Dialog,
  LinearProgress,
  Select,
  DialogContent,
  IconButton,
  ListSubheader,
  Tooltip,
  FormControl,
  InputLabel,
  Chip,
  Typography,
} from "@material-ui/core";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

import jwt from "jsonwebtoken";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

export default function GenerateAccount({ accounts, title, value ,saveVal, inputName}) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const decoded = jwt.decode(Token);
  const classes = useStyles();
  const sweetClass = sweetAlertStyle();
  const [alert, setAlert] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(true);
  

  const {
    Company,
    Department_CostCenter,
    Account1,
    Subsidiary,
    Spare1,
    Spare2,
  } = accounts;


  const [state, setState] = React.useState({
    values: {
      Company: value[0] || "",
      Department_CostCenter: Department_CostCenter.find(d=>d.Acc_NO == value[1]) || "",
      Account1:  Account1.find(d=>d.Acc_NO == value[2]) || "",
      Subsidiary: value[3] || "",
      Spare1: value[4] || "",
      Spare2: value[5] || "",
    },
  });

  React.useEffect(() => {
    saveVal(inputName, `${state.values.Company}-${state.values.Department_CostCenter.Acc_NO}-${state.values.Account1.Acc_NO}-${state.values.Subsidiary}-${state.values.Spare1}-${state.values.Spare2}`)
  }, [state.values]);

  const handleChange = (event) => {
    event.persist();
    setState((state) => ({
      ...state,
      values: {
        ...state.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  return (
    <GridContainer style={{ marginTop: "15px" }}>
      <GridItem xs={12}>
        <Typography>
          {title}{":"}
          {`${state.values.Company}-${state.values.Department_CostCenter.Acc_NO}-${state.values.Account1.Acc_NO}-${state.values.Subsidiary}-${state.values.Spare1}-${state.values.Spare2}`}
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <GridContainer style={{ textAlign: "center", marginTop: "15px" }}>
          <GridItem xs={12} sm={2} md={2}>
            <TextField
              fullWidth={true}
              label="Company"
              id="Company"
              name="Company"
              value={state.values.Company}
              onChange={(event) => {
                handleChange(event);
              }}
              select
              variant="outlined"
            >
              {Company.map((x, index) => (
                <MenuItem key={index} value={x.Acc_NO}>
                  {x.Acc_NO} - {x.Acc_Description}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <Autocomplete
              id="filter-demo"
              options={Department_CostCenter}
              value={state.values.Department_CostCenter || ""}
              variant="outlined"
              getOptionLabel={(option) =>
                `${option?.Acc_NO || ""} - ${option?.Acc_Description || ""}`
              }
              onChange={(event, newValue) => {
                setState((state) => ({
                  ...state,
                  values: {
                    ...state.values,
                    Department_CostCenter: newValue || "",
                  },
                }));
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Department"
                  value={state.values.Department_CostCenter}
                />
              )}
            />
           
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <Autocomplete
              id="filter-demo"
              options={Account1}
              variant="outlined"
              value={state.values.Account1 || ""}
              getOptionLabel={(option) =>
                `${option?.Acc_NO || ""} - ${option?.Acc_Description || ""}`
              }
              onChange={(event, newValue) => {
                setState((state) => ({
                  ...state,
                  values: {
                    ...state.values,
                    Account1: newValue || "",
                  },
                }));
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Account 1"
                  id="Account1"
                  name="Account1"
                  value={state.values.Account1}
                />
              )}
            />
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <TextField
              fullWidth={true}
              label="Subsidiary"
              id="Subsidiary"
              name="Subsidiary"
              value={state.values.Subsidiary}
              onChange={(event) => {
                handleChange(event);
              }}
              select
              variant="outlined"
            >
              {Subsidiary.map((x, index) => (
                <MenuItem key={index} value={x.Acc_NO}>
                  {x.Acc_NO} - {x.Acc_Description}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <TextField
              fullWidth={true}
              label="Spare 1"
              id="Spare1"
              name="Spare1"
              value={state.values.Spare1}
              onChange={(event) => {
                handleChange(event);
              }}
              select
              variant="outlined"
            >
              {Spare1.map((x, index) => (
                <MenuItem key={index} value={x.Acc_NO}>
                  {x.Acc_NO} - {x.Acc_Description}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={2} md={2}>
            <TextField
              fullWidth={true}
              label="Spare 2"
              id="Spare2"
              name="Spare2"
              value={state.values.Spare2}
              onChange={(event) => {
                handleChange(event);
              }}
              select
              variant="outlined"
            >
              {Spare2.map((x, index) => (
                <MenuItem key={index} value={x.Acc_NO}>
                  {x.Acc_NO} - {x.Acc_Description}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
