import React, { useState } from "react";
// @material-ui/core components
import {
  TextField,
  makeStyles,
  Tooltip,
  IconButton,
  withStyles,
  MenuItem,
  Typography,
  Select,
  Dialog,
  DialogContent,
  Input,
  Divider,
  ListSubheader,
} from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CreateIcon from "@material-ui/icons/Create";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Visibility } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { setIsTokenExpired } from "actions/index";
import { conversionRate } from "views/LDocs/Functions/Functions";
import SelectCOA from "./SelectAccount";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";
import { CircularProgress } from "@mui/material";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
  table: {
    minWidth: "100%",
  },
  itemName: {
    width: 300,
    color:'black'
  },
  itemNumber: {
    width: "55%",
    color:'black'
  },
  asterisk: {
    color: "red"
  }
};
const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    // color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

export default function Items(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const dispatch = useDispatch();

  let {
    formState,
    setFormState,
    items,
    addZeroes,
    handleChange,
    handleEditItem,
    removeItem,
    addInvoiceItem,
    addItem,
    validCoaLoading,
    editIndex,
    editItem,
    addingItem,
    setAddingItem,
    setEditIndex,
    isItemLoading,
    closeItembox,
    removeItems,
    currency,
    isVendor,
    receipts,
    currencyLookups,
    baseCurrency,
    userData,
    edit,
    isAr,
  } = props;
  const classes = useStyles();
  const [baseCurr, setBaseCurr] = useState();
  const [COA, setCOA] = useState({
    showModel: false,
    COA: [],
    type: null,
  });

  const [componentState, setComponentState] = useState({
    expenses: "000-000-000-000--000-000",
  });

  const showCOAModelBox = (type) => {
    setCOA((state) => ({
      ...state,
      showModel: true,
      type: type,
    }));
  };

  const closeCOAModalBox = () => {
    setCOA((state) => ({
      ...state,
      showModel: false,
      type: null,
    }));
  };

  const handleChangeAccountTypes = (name, value) => {
  
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: value,
        },
      }));
  };
  const handleChangeExpense = (event) => {
    event.persist();
    if (event.target.name == 'inlineExpenseType'){
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          expenseType: event.target.value,
        },
      }));
    }
};

  React.useEffect(() => {
    setBaseCurr(
      baseCurrency
        ? baseCurrency
        : !isVendor
          ? userData?.currency?.Currency_Base
          : formState.selectedOrg
            ? formState.selectedOrg.currency
            : ""
    );
  }, [formState]);

  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      {COA.showModel ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"xl"}
          open={COA.showModel}
          // TransitionComponent={Transition}
          keepMounted
          onClose={closeCOAModalBox}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <SelectCOA
              title="GL Account"
              accounts={formState.coa}
              value={formState.values.expenseType?.split("-") || []}
              saveVal={handleChangeAccountTypes}
              inputName={"expenseType"}
              closeModal={closeCOAModalBox}
            />
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}

      <TableContainer
        component={Paper}
        style={{
          borderBottom:
            formState.errors.items === "error" ? "2px red solid" : "none",
        }}
      >
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            {/* Labels */}
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">
                Unit Cost ({currency.Code || "$"})
              </StyledTableCell>
              <StyledTableCell align="right">Quantity</StyledTableCell>
              {/* <StyledTableCell align="right">Discount (%)</StyledTableCell> */}
              <StyledTableCell align="right">VAT (%)</StyledTableCell>
              <StyledTableCell align="right">
                Amount ({currency.Code || "$"})
              </StyledTableCell>
              <StyledTableCell> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) =>
              editIndex == index ? (
                <React.Fragment>
                  <StyledTableRow key="addLineItem">
                    {!isVendor && formState.isReceipt && formState.isPo ? (
                      <StyledTableCell style={{ paddingTop: 30 }} colSpan="3">
                        <TextField
                          className={classes.textField}
                          required
                          fullWidth={true}
                          error={formState.errors.receiptNumber === "error"}
                          helperText={
                            formState.errors.receiptNumber === "error"
                              ? "Valid Receipt Number required"
                              : null
                          }
                          label="Receipt Number"
                          id="receiptNumber"
                          name="receiptNumber"
                          InputLabelProps={{
                            shrink:true,
                            style: { color: 'black' },
                            classes: {
                              asterisk: classes.asterisk
                            }
                          }}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          value={formState.values.receiptNumber || ""}
                          select
                        >
                          <MenuItem disabled={true} key={"disabled"}>
                            Receipt Number
                          </MenuItem>

                          {Object.keys(receipts || {}).map((k) => (
                            <MenuItem key={`receiptId-${k}`} value={k}>
                              {k}
                            </MenuItem>
                          )) || []}
                        </TextField>
                      </StyledTableCell>
                    ) : (
                      ""
                    )}

                    <StyledTableCell style={{ paddingTop: 30 }} colSpan={!isVendor && formState.isReceipt && formState.isPo ? 4 : 7}>
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        required
                        error={formState.errors.taxClassification === "error"}
                        helperText={
                          formState.errors.taxClassification === "error"
                            ? "Valid Tax Classification required"
                            : null
                        }
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        label="Tax Classification"
                       
                        id="taxClassification"
                        name="taxClassification"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        value={formState.values.taxClassification || ""}
                        select
                      >
                        <MenuItem disabled={true} key={"disabled"}>
                          Tax Classifications
                        </MenuItem>
                        {formState.taxClassifications.map((t) => (
                          <MenuItem key={`classification-${t._id}`} value={t.LookupCode}>
                            {t.LookupCode || ""}
                          </MenuItem>
                        )) || []}
                      </TextField>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow key={"AddingItem"}>
                    <StyledTableCell style={{ width: 100 }}>
                      <TextField
                        fullWidth={true}
                        label="ID "
                        type="number"
                        disabled={true}
                        InputLabelProps={{
                          shrink:true,
                          style: { color: 'black' },
                        }}
                        value={index + 1 || ""}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ minWidth: 300 }}
                      component="th"
                      scope="row"
                    >
                      <TextField
                        fullWidth={true}
                        required
                        error={formState.errors.itemName === "error"}
                        helperText={
                          formState.errors.itemName === "error"
                            ? "Valid Item Name is required"
                            : null
                        }
                        label="Item Name "
                        // label={<Typography  style={{ color: 'black' }}>Petty Cash</Typography>}
                        id="item"
                        name="itemName"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        type="text"
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        value={formState.values.itemName || ""}
                        className={classes.itemName}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TextField
                        fullWidth={true}
                        required
                        error={formState.errors.unitCost === "error"}
                        helperText={
                          formState.errors.unitCost === "error"
                            ? "Valid Unit Cost is required"
                            : null
                        }
                        label="Unit Cost"
                        id="unitcost"
                        name="unitCost"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        type="number"
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        value={formState.values.unitCost || ""}
                        className={classes.textField}
                        disabled={!row?.isAmountMatching}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TextField
                      disabled={row?.isAmountMatching}
                        fullWidth={true}
                        required
                        error={formState.errors.quantity === "error"}
                        helperText={
                          formState.errors.quantity === "error"
                            ? "Valid Quantity is required"
                            : null
                        }
                        label="Quantity"
                        id="quantity"
                        name="quantity"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        type="number"
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        value={formState.values.quantity || ""}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">
                      <TextField
                        fullWidth={true}
                        error={formState.errors.discount === "error"}
                        helperText={
                          formState.errors.discount === "error"
                            ? "Valid Discount is required"
                            : null
                        }
                        label="Discount "
                        id="discount"
                        name="discount"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        type="number"
                        value={addZeroes(formState.values.discount) || 0.0}
                        className={classes.textField}
                      />
                    </StyledTableCell> */}
                    <StyledTableCell align="right">
                      <TextField
                        fullWidth={true}
                        required
                        error={formState.errors.vat === "error"}
                        helperText={
                          formState.errors.vat === "error"
                            ? "Valid VAT is required"
                            : null
                        }
                        label="VAT "
                        id="vat"
                        disabled={true}
                        name="vat"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        type="number"
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        value={addZeroes(formState.values.vat) || 0.0}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TextField
                        fullWidth={true}
                        label="Amount"
                        id="item"
                        name="amount"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        disabled={true}
                        type="string"
                        value={
                          addZeroes(
                            parseFloat(formState.values.unitCost) *
                            parseFloat(formState.values.quantity) +
                            parseFloat(
                              (formState.values.unitCost *
                                formState.values.vat) /
                              100
                            ) *
                            formState.values.quantity
                          ) || 0.0
                        }
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 100 }} align="right">
                      <Tooltip title="Save Chnages" aria-label="save">
                        <IconButton onClick={() => editItem(index)}>
                        {validCoaLoading ? <CircularProgress/> :
                        <CheckCircleOutlineIcon
                          style={{ color: "green" }}
                          size="small"
                        /> }
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Close" aria-label="close">
                        <IconButton onClick={() => closeItembox()}>
                          <HighlightOffIcon
                            style={{ color: "red" }}
                            size="small"
                          />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>

                  {!isVendor ? (
                    <StyledTableRow>
                      <>
                        {/* <StyledTableCell style={{ paddingTop: 20 }} colSpan="3">
                          <TextField
                            className={classes.textField}
                            fullWidth={true}
                            label={"Liabilites"}
                            id="liabilityType"
                            disabled={true}
                            name="liabilityType"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            // variant="outlined"
                            InputLabelProps={{
                              style: { color: 'black' },
                            }}
                            value={formState.values.liabilityType || ""}
                          />
                        </StyledTableCell> */}
                        <StyledTableCell style={{ paddingTop: 20 }} colSpan="3">
                          <TextField
                            className={classes.textField}
                            fullWidth={true}
                            required={formState.isPeetyCash}
                            InputLabelProps={{
                              style: { color: 'black' },
                              classes: {
                                asterisk: classes.asterisk
                              }
                            }}
                            error={
                              formState.errors.inlineExpenseType === "error"
                            }
                            helperText={
                              formState.errors.inlineExpenseType === "error"
                                ? "Valid Expense Type required"
                                : null
                            }
                            label={"GL Account"}
                            disabled={!formState.isPeetyCash}
                            id="inlineExpenseType"
                            name="inlineExpenseType"
                            onChange={(event) => {
                              handleChangeExpense(event);
                            }}
                            // variant="outlined"
                            value={
                              formState.values.expenseType ||
                              "000-000-000-000--000-000"
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell style={{ paddingTop: 20 }} colSpan="1">
                          <Button
                            size="small"
                            style={{backgroundColor:"#095392"}}
                            onClick={() => showCOAModelBox("Expense")}
                          >
                            Select GL Account
                          </Button>
                        </StyledTableCell>
                      </>
                    </StyledTableRow>
                  ) : (
                    ""
                  )}
                  {/* <StyledTableRow>
                    <StyledTableCell colSpan={"7"}>
                      <TextField
                        fullWidth={true}
                        error={formState.errors.additionalDetails === "error"}
                        helperText={
                          formState.errors.additionalDetails === "error"
                            ? "Valid Additional Details is required"
                            : null
                        }
                        label="Additonal Details"
                        id="additionaldetails"
                        name="additionalDetails"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        multiline
                        rows={1}
                        type="text"
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        value={formState.values.additionalDetails || ""}
                      />
                    </StyledTableCell>
                  </StyledTableRow> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* Items Display */}
                  <StyledTableRow key={row.itemName}>
                    <StyledTableCell style={{ width: 100 }}>
                      {row.receiptNumber?.length < 1 &&
                        !isVendor &&
                        formState.isReceipt &&
                        formState.isPo ? (
                        <Tooltip
                          title="Receipt Number is Missing.."
                          aria-label="edit"
                        >
                          <IconButton
                            onClick={() => handleEditItem(row, index)}
                          >
                            <ErrorOutlineIcon
                              fontSize="small"
                              style={{ color: "orange" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        index + 1
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ width: 400 }}
                      component="th"
                      scope="row"
                    >
                      {row.itemName}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {`${currency.Code} `}
                      {addZeroes(row.unitCost)}
                      <br />
                      {currency._id != baseCurr &&
                        conversionRate(
                          currency._id,
                          baseCurr,
                          currencyLookups,
                          row.unitCost,
                          false,
                          formState.isPo,
                          formState?.conversionRate || 0
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {addZeroes(row.quantity)}
                    </StyledTableCell>
                    {/* <StyledTableCell align="right">
                      {addZeroes(row.discount)}%
                    </StyledTableCell> */}
                    <StyledTableCell align="right">
                      {addZeroes(row?.vat)}%
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {`${currency.Code} `}
                      {addZeroes(row.amount)}
                      <br />
                      {currency._id != baseCurr &&
                        conversionRate(
                          currency._id,
                          baseCurrency
                            ? baseCurrency
                            : !isVendor
                              ? userData.currency.Currency_Base
                              : formState.selectedOrg
                                ? formState.selectedOrg.currency
                                : "",
                          currencyLookups,
                          row.amount,
                          false,
                          formState.isPo,
                          formState?.conversionRate || 0
                        )}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 100 }} align="right">
                      {isItemLoading ? <CircularProgress/> :
                      <Tooltip title="Edit Item" aria-label="edit">
                        <IconButton  onClick={() => handleEditItem(row, index)}>
                          <CreateIcon
                            style={{ color: "orange" }}
                            size="small"
                          />
                        </IconButton>
                      </Tooltip> }
                      <Tooltip title="Remove Item" aria-label="add">
                        <IconButton onClick={() => removeItem(index)}>
                          <HighlightOffIcon
                            style={{ color: "red" }}
                            size="small"
                          />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              )
            )}

            {/* End Item Display */}

            {addingItem ? (
              <React.Fragment>
                <StyledTableRow key="addLineItem">
                  {!isVendor && formState.isReceipt && formState.isPo ? (
                    <StyledTableCell style={{ paddingTop: 30 }} colSpan="3">
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        required
                        error={formState.errors.receiptNumber === "error"}
                        helperText={
                          formState.errors.receiptNumber === "error"
                            ? "Valid Receipt Number required"
                            : null
                        }
                        label="Receipt Number"
                        id="receiptNumber"
                        name="receiptNumber"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        value={formState.values.receiptNumber || ""}
                        select
                      >
                        <MenuItem disabled={true} key={"disabled"}>
                          Receipt Number
                        </MenuItem>

                        {Object.keys(receipts || {}).map((k) => (
                          <MenuItem key={`receiptId-${k}`} value={k}>
                            {k}
                          </MenuItem>
                        )) || []}
                      </TextField>
                    </StyledTableCell>
                  ) : (
                    ""
                  )}

                  <StyledTableCell style={{ paddingTop: 30 }} colSpan={!isVendor && formState.isReceipt && formState.isPo ? 4 : 7}>
                    <TextField
                      className={classes.textField}
                      fullWidth={true}
                      required
                      error={formState.errors.taxClassification === "error"}
                      helperText={
                        formState.errors.taxClassification === "error"
                          ? "Valid Tax Classification required"
                          : null
                      }
                      label="Tax Classification"
                      id="taxClassification"
                      name="taxClassification"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      value={formState.values.taxClassification || ""}
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      select
                    >
                      <MenuItem disabled={true} key={"disabled"}>
                        Tax Classifications
                      </MenuItem>
                      {formState.taxClassifications.map((t) => (
                        <MenuItem key={`classification-${t._id}`} value={t.LookupCode}>
                          {t.LookupCode || ""}
                        </MenuItem>
                      )) || []}
                    </TextField>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow key={"AddingItem"}>
                  <StyledTableCell style={{ width: 100 }}>
                    <TextField
                      fullWidth={true}
                      label="ID "
                      type="number"
                      disabled={true}
                      value={items?.length + 1 || ""}
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ minWidth: 300 }}
                    component="th"
                    scope="row"
                  >
                    <TextField
                      fullWidth={true}
                      required
                      error={formState.errors.itemName === "error"}
                      helperText={
                        formState.errors.itemName === "error"
                          ? "Valid Item Name is required"
                          : null
                      }
                      label="Item Name "
                      id="item"
                      name="itemName"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      type="text"
                      value={formState.values.itemName || ""}
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      className={classes.itemName}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <TextField
                      fullWidth={true}
                      required
                      error={formState.errors.unitCost === "error"}
                      helperText={
                        formState.errors.unitCost === "error"
                          ? "Valid Unit Cost is required"
                          : null
                      }
                      label="Unit Cost"
                      id="unitcost"
                      name="unitCost"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      type="number"
                      value={formState.values.unitCost || ""}
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TextField
                      fullWidth={true}
                      required
                      error={formState.errors.quantity === "error"}
                      helperText={
                        formState.errors.quantity === "error"
                          ? "Valid Quantity is required"
                          : null
                      }
                      label="Quantity"
                      id="quantity"
                      name="quantity"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      type="number"
                      value={formState.values.quantity || ""}
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell align="right">
                    <TextField
                      fullWidth={true}
                      error={formState.errors.discount === "error"}
                      helperText={
                        formState.errors.discount === "error"
                          ? "Valid Discount is required"
                          : null
                      }
                      label="Discount "
                      id="discount"
                      name="discount"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      type="number"
                      value={formState.values.discount || 0.0}
                      className={classes.textField}
                    />
                  </StyledTableCell> */}
                  <StyledTableCell align="right">
                    <TextField
                      fullWidth={true}
                      error={formState.errors.vat === "error"}
                      helperText={
                        formState.errors.vat === "error"
                          ? "Valid VAT is required"
                          : null
                      }
                      label="VAT "
                      id="VAT"
                      name="vat"
                      disabled={true}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      type="number"
                      value={formState.values.vat || 0.0}
                      InputLabelProps={{
                        style: { color: 'black' }
                      }}
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TextField
                      fullWidth={true}
                      label="Amount"
                      id="item"
                      name="amount"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      disabled={true}
                      type="text"
                      value={
                        addZeroes(
                          parseFloat(formState.values.unitCost) *
                          parseFloat(formState.values.quantity) +
                          parseFloat(
                            (formState.values.unitCost *
                              formState.values.vat) /
                            100
                          ) *
                          formState.values.quantity
                        ) || 0.0
                      }
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 100 }} align="right">
                    <Tooltip title="Save Chnages" aria-label="save">
                      <IconButton onClick={addItem}>
                        {validCoaLoading ? <CircularProgress/> :
                        <CheckCircleOutlineIcon
                          style={{ color: "green" }}
                          size="small"
                        /> }
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Item" aria-label="remove">
                      <IconButton onClick={() => removeItems()}>
                        <HighlightOffIcon
                          style={{ color: "red" }}
                          size="small"
                        />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>

                {!isVendor ? (
                  <StyledTableRow>
                    <>
                      {/* <StyledTableCell style={{ paddingTop: 20 }} colSpan="3">
                        <TextField
                          className={classes.textField}
                          fullWidth={true}
                          label={"Liabilites"}
                          id="liabilityType"
                          disabled={true}
                          name="liabilityType"
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          // variant="outlined"
                          value={formState.values.liabilityType || ""}
                        />
                      </StyledTableCell> */}
                      <StyledTableCell style={{ paddingTop: 20 }} colSpan="3">
                        <TextField
                          className={classes.textField}
                          fullWidth={true}
                          required={formState.isPeetyCash}
                          InputLabelProps={{
                            style: { color: 'black' },
                            classes: {
                              asterisk: classes.asterisk
                            }
                          }}
                          error={formState.errors.inlineExpenseType === "error"}
                          helperText={
                            formState.errors.inlineExpenseType === "error"
                              ? "Valid Expense Type required"
                              : null
                          }
                          disabled={!formState.isPeetyCash}
                          label={"GL Account"}
                          // disabled={true}
                          id="inlineExpenseType"
                          name="inlineExpenseType"
                          onChange={(event) => {
                            handleChangeExpense(event);
                          }}
                          // variant="outlined"
                          value={
                            formState.values.expenseType
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell style={{ paddingTop: 20 }} colSpan="1">
                        <Button
                          size="small"
                          onClick={() => showCOAModelBox("Expense")}
                        >
                          Select GL Account
                        </Button>
                      </StyledTableCell>
                    </>
                  </StyledTableRow>
                ) : (
                  ""
                )}
                {/* <StyledTableRow>
                  <StyledTableCell colSpan={"7"}>
                    <TextField
                      fullWidth={true}
                      error={formState.errors.additionalDetails === "error"}
                      helperText={
                        formState.errors.additionalDetails === "error"
                          ? "Valid Additional Details is required"
                          : null
                      }
                      label="Additonal Details"
                      id="additionaldetails"
                      name="additionalDetails"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      multiline
                      rows={2}
                      type="text"
                      value={formState.values.additionalDetails || ""}
                    />
                  </StyledTableCell>
                </StyledTableRow> */}
              </React.Fragment>
            ) : (
              ""
            )}
            
              <StyledTableRow key={"AddItem"}>
                <StyledTableCell colSpan="8" align="center">
                  <Tooltip title="Add Item" aria-label="add">
                    <IconButton onClick={addInvoiceItem}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GridItem>
  );
}
