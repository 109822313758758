import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from 'react-router-dom';

// @material-ui/core components
import { useDispatch, useSelector } from "react-redux";
import { TextField, MenuItem, IconButton, Modal, Typography, Fade, Box, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import { formatDateTime } from "Functions";
import { addZeroes } from "Functions";
import PurchaseOrderList from "actions/PurchaseOrderListAction";
import PaginationsList from "./POPaginationList";
import SyncPo from "views/Components/PurchaseOrderView/Childs/SyncPo";
import { msgAlert } from "Functions";
import Filters from "views/Components/PurchaseOrderView/Childs/Filters"
import { Autocomplete } from '@mui/material'
import { POFilter } from "actions/PurchaseOrderListAction";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
  textField: {
    width: { sm: 200, md: 210, lg: 210, },
    "& .MuiInputBase-root": {
      height: 53,
      width: 210
    }
  }
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PurchaseOrder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [classicModal, setClassicModal] = React.useState(false);
  const [viewModal, setViewModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [Updating, setUpdating] = React.useState(false);
  const [filterView, setFilterView] = React.useState(false);
  const [PurchaseOrderDetail, setPurchaseOrderDetail] = React.useState();
  const [data, setData] = React.useState();
  const [organizationFilter, setOrganizationFilter] = React.useState("");
  const [compFilter, setCompFilter] = React.useState("");
  const [organizations, setOrganizations] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [PurchaseOrder, setPurchaseOrder] = React.useState([]);
  const [_PurchaseOrderList, setPurchaseOrderList] = React.useState([]);
  const [poLoading, setPoLoading] = React.useState(false)
  const [vendorLoading, setVendorLoading] = React.useState(false)
  const [isPurchaseOrderList, setIsPurchaseOrderList] = React.useState(true);
  const [isModal, setisModal] = React.useState(false);
  const [decoded, setDecoded] = React.useState(null);
  const [view, setView] = React.useState("grid");
  const [open, setOpen] = React.useState(false);
  const [isFilterBox, setIsFilterBox] = React.useState(false);
  const [isFilter, setIsFilter] = React.useState(false);
  const [newdata, setnewdata] = React.useState()
  const [status, setStatus] = React.useState('')
  const [filters, setFilters] = React.useState({
    Supplier: null,
    Status: null,
    OrderNumber: null
  })
  const [formState, setFormState] = React.useState({
    selectedPurchaseOrder: '',
    poInputValue: '',
    supplierInputValue: '',
    vendors: [],
    selectedVendor: '',
    newData: '',
    values: {
      status: '',
      comments: ''
    },
    errors: {
      status: '',
      reviewComments: ''
    }
  })
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let userDetail = jwt.decode(Token);

  React.useEffect(() => {
    userDetail = jwt.decode(Token);
    setDecoded(userDetail);
    getPurchaseOrder();
  }, []);

  React.useEffect(() => {
    // if (formState.poInputValue) {
    //   _getPurchaseOrders()
    // }
    // else{
    //   _getPurchaseOrders()
    // }
    _getPurchaseOrders()
  }, [])

  React.useEffect(() => {
    // if (formState.supplierInputValue) {
    //   _getSuppliers()
    // }
    // else{
    //   _getSuppliers()
    // }
    _getSuppliers()
  }, [])

  const getPurchaseOrder = (compId) => {
    setIsLoading(true);
    setPurchaseOrderList();
    setIsLoading(false);
  }

  const _getSuppliers = async () => {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendorsAll`,
      headers: { cooljwt: Token }
    })
      .then((res) => {
        setFormState(formState => ({
          ...formState,
          vendors: res?.data?.result,
        }))
      })
      .catch(error => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
      })
  }

  const syncPo = async () => {

    await axios.get(`${process.env.REACT_APP_LDOCS_API_URL}/invoice/syncPurchaseOrder`, {
      headers: {
        cooljwt: Token
      }
    })
      .then((res) => {
      });
    msgAlert("Sync is in progress. It may take some time, you can continue using system.")
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 190,
    bgcolor: '#fff',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const refresh = () => {
    setFormState(formState => ({
      ...formState,
      poInputValue: '',
      selectedPurchaseOrder: '',
      selectedVendor: ''
    }))
    setFilters(filters => ({
      ...filters,
      Supplier: null,
      Status: null,
      OrderNumber: null
    }))
    setIsFilterBox(false)
    setIsFilter(false)
    setnewdata()
    setStatus('')
    let dispatch_payload = {
      page: 0,
      rowPerPage: 10,
      Token: Token,
      newdata: newdata
    }
    dispatch(PurchaseOrderList(dispatch_payload))
  }

  const _getPurchaseOrders = async (supplierId, newInputValue) => {
    setPurchaseOrder([])
    setPoLoading(true)
    let formData = {
      isAdmin: userDetail.role.isAdmin,
      displayName: userDetail?.displayName,
      po: newInputValue ? newInputValue : '',
      action: "PO",
      supplierId: supplierId ? supplierId : null,
      forPoOnly: true
    }
    await axios({
      method: 'post', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPoForRecAll`,
      data: formData,
      headers: {
        cooljwt: Token
      }
    })
      .then(res => {
        setPoLoading(false)
        setPurchaseOrderList(res?.data)
      })
      .catch(error => {
        setPoLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
      })
  }

  return (
    <>
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={view == "grid" ? true : false}
      >
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Purchase Order</h4>
                </CardIcon>

                <Button
                  color="danger"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={syncPo}
                >
                  Sync
                </Button>
                <Button
                  color="info"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={() => setIsFilterBox(true)}
                >
                  Filter
                </Button>
                <Tooltip
                  id='tooltip-top'
                  title='Refresh'
                  style={{ float: 'right' }}
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    onClick={refresh}
                    round
                    color='info'
                  >
                    Clear Filters
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px'
                  }}
                >
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    >
                      <GridContainer>
                        <Autocomplete
                          id='filter-demo'
                          options={formState.vendors || []}
                          variant='outlined'
                          inputValue={formState.supplierInputValue || ''}
                          onInputChange={(event, newInputValue) => {
                            setFormState(formState => ({
                              ...formState,
                              supplierInputValue: newInputValue,
                            }))
                          }}
                          clearIcon={null}
                          value={formState.selectedVendor || ''}
                          getOptionLabel={option => option?.level1?.Supplier || ''}
                          onChange={(event, newValue) => {
                            setFormState(formState => ({
                              ...formState,
                              selectedVendor: newValue
                            }))
                            if (newValue?.level1?.SupplierId) {
                              debugger
                              setFilters(filters => ({
                                ...filters,
                                Supplier: newValue?.level1?.Supplier
                              }))
                              if (filters.Supplier == null && filters.OrderNumber == null && filters.Status == null) {
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { Supplier: newValue?.level1?.Supplier } }
                                }
                                dispatch(POFilter(dispatch_payload))
                                _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                              }
                              else if (filters.Supplier !== null && filters.OrderNumber == null && filters.Status == null) {
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { Supplier: newValue?.level1?.Supplier } }
                                }
                                dispatch(POFilter(dispatch_payload))
                                _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                              }
                              else if (filters.Supplier == null && filters.OrderNumber !== null && filters.Status == null) {
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { Supplier: newValue?.level1?.Supplier, OrderNumber: filters.OrderNumber } }
                                }
                                dispatch(POFilter(dispatch_payload))
                                _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                              }
                              else if (filters.Supplier !== null && filters.OrderNumber !== null && filters.Status == null) {
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { Supplier: newValue?.level1?.Supplier, OrderNumber: filters.OrderNumber } }
                                }
                                dispatch(POFilter(dispatch_payload))
                                _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                              }
                              else {
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { Supplier: newValue?.level1?.Supplier, Status: filters.Status, OrderNumber: filters.OrderNumber } }
                                }
                                dispatch(POFilter(dispatch_payload))
                                _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                              }
                            }
                          }}
                          sx={{ width: 400 }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label={'Select Supplier'}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {vendorLoading ? (
                                      <CircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                        <Autocomplete
                          id='filter-demo'
                          options={_PurchaseOrderList || []}
                          style={{ marginLeft: '20px' }}
                          value={formState.selectedPurchaseOrder || ''}
                          variant='outlined'
                          getOptionLabel={option => option?.OrderNumber || ''}
                          onInputChange={(event, newInputValue) => {
                            setFormState(formState => ({
                              ...formState,
                              poInputValue: newInputValue
                            }))
                            if (formState.selectedVendor == '') {
                              _getPurchaseOrders(null, newInputValue)
                            }
                          }}
                          clearIcon={null}
                          onChange={(event, newValue) => {
                            setFormState(formState => ({
                              ...formState,
                              selectedPurchaseOrder: newValue
                            }))
                            if (newValue?.OrderNumber) {
                              setFilters(filters => ({
                                ...filters,
                                OrderNumber: newValue?.OrderNumber
                              }))
                              if (filters.OrderNumber == null && filters.Supplier == null && filters.Status == null) {
                                //Dispatch Payload
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { OrderNumber: newValue?.OrderNumber } }
                                };
                                dispatch(POFilter(dispatch_payload));
                              }
                              else if (filters.Supplier !== null && filters.OrderNumber !== null && filters.Status == null) {
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { Supplier: filters.Supplier, OrderNumber: newValue?.OrderNumber } }
                                }
                                dispatch(POFilter(dispatch_payload))
                              }
                              else {
                                let dispatch_payload = {
                                  page: 0,
                                  rowPerPage: 10,
                                  Token: Token,
                                  newdata: { data: { Supplier: filters.Supplier, Status: filters.Status, OrderNumber: newValue?.OrderNumber } }
                                }
                                dispatch(POFilter(dispatch_payload))
                              }

                            }
                          }}
                          sx={{ width: 200 }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label={'Search By PO'}
                              placeholder={'Search'}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {poLoading ? (
                                      <CircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                        <TextField
                          className={classes.textField}
                          variant='outlined'
                          size="medium"
                          style={{ marginLeft: '20px' }}
                          label='Status'
                          select
                          name='status'
                          type='text'
                          onChange={(event, newValue) => {
                            setFilters(filters => ({
                              ...filters,
                              Status: event.target.value
                            }))
                            if (filters.OrderNumber == null && filters.Supplier == null && filters.Status == null) {
                              //Dispatch Payload
                              setIsFilter(true)
                              setnewdata({ data: { Status: event.target.value } })
                              setStatus(event.target.value)
                              //Dispatch Payload
                              let dispatch_payload = {
                                page: 0,
                                rowPerPage: 10,
                                Token: Token,
                                newdata: { data: { Status: event.target.value } }
                              };
                              dispatch(POFilter(dispatch_payload));
                            }
                            else if (filters.OrderNumber !== null && filters.Supplier == null && filters.Status == null) {
                              //Dispatch Payload
                              setIsFilter(true)
                              setnewdata({ data: { Status: event.target.value } })
                              setStatus(event.target.value)
                              //Dispatch Payload
                              let dispatch_payload = {
                                page: 0,
                                rowPerPage: 10,
                                Token: Token,
                                newdata: { data: { OrderNumber: filters.OrderNumber, Status: event.target.value } }
                              };
                              dispatch(POFilter(dispatch_payload));
                            }
                            else if (filters.Supplier !== null && filters.OrderNumber !== null && filters.Status !== null) {
                              //Dispatch Payload
                              setIsFilter(true)
                              setnewdata({ data: { Status: event.target.value } })
                              setStatus(event.target.value)
                              let dispatch_payload = {
                                page: 0,
                                rowPerPage: 10,
                                Token: Token,
                                newdata: { data: { Supplier: filters.Supplier, OrderNumber: filters.OrderNumber, Status: event.target.value } }
                              }
                              dispatch(POFilter(dispatch_payload))
                              // _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                            }
                            else if (filters.Supplier == null && filters.OrderNumber !== null && filters.Status !== null) {
                              //Dispatch Payload
                              setIsFilter(true)
                              setnewdata({ data: { Status: event.target.value } })
                              setStatus(event.target.value)
                              let dispatch_payload = {
                                page: 0,
                                rowPerPage: 10,
                                Token: Token,
                                newdata: { data: { OrderNumber: filters.OrderNumber, Status: event.target.value } }
                              }
                              dispatch(POFilter(dispatch_payload))
                              // _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                            }
                            else if (filters.Supplier !== null && filters.OrderNumber == null && filters.Status == null) {
                              //Dispatch Payload
                              setIsFilter(true)
                              setnewdata({ data: { Status: event.target.value } })
                              setStatus(event.target.value)
                              let dispatch_payload = {
                                page: 0,
                                rowPerPage: 10,
                                Token: Token,
                                newdata: { data: { Supplier: filters.Supplier, Status: event.target.value } }
                              }
                              dispatch(POFilter(dispatch_payload))
                              // _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                            }
                            else if (filters.Supplier !== null && filters.OrderNumber == null && filters.Status !== null) {
                              //Dispatch Payload
                              setIsFilter(true)
                              setnewdata({ data: { Status: event.target.value } })
                              setStatus(event.target.value)
                              let dispatch_payload = {
                                page: 0,
                                rowPerPage: 10,
                                Token: Token,
                                newdata: { data: { Supplier: filters.Supplier, Status: event.target.value } }
                              }
                              dispatch(POFilter(dispatch_payload))
                              // _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                            }
                            else {
                              let dispatch_payload = {
                                page: 0,
                                rowPerPage: 10,
                                Token: Token,
                                newdata: { data: { Supplier: filters.Supplier, Status: event.target.value, OrderNumber: filters.OrderNumber } }
                              }
                              dispatch(POFilter(dispatch_payload))
                              // _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                            }

                          }}


                          value={status || ''}
                        >
                          <MenuItem value='Closed'>Closed</MenuItem>
                          <MenuItem value='Canceled'>Canceled</MenuItem>
                          <MenuItem value='Incomplete'>Incomplete</MenuItem>
                          <MenuItem value='Closed for Receiving'>Closed for Receiving</MenuItem>
                          <MenuItem value='Rejected'>Rejected</MenuItem>
                          <MenuItem value='Withdrawn'>Withdrawn</MenuItem>
                          <MenuItem value='Open'>Open</MenuItem>
                          <MenuItem value='Closed for Invoicing'>Closed for Invoicing</MenuItem>
                          <MenuItem value='Pending Approval'>Pending Approval</MenuItem>
                        </TextField>
                      </GridContainer>
                    </GridItem>
                    {/* <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={1}
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    ></GridItem> */}
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={2}
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    >
                      <Button
                        color="danger"
                        round
                        style={{ float: "right" }}
                        className={classes.marginRight}
                        onClick={() => window.open('https://hen.fa.em2.oraclecloud.com/fscmUI/faces/deeplink?objType=PURCHASE_REQUISITION_LOBUSER&action=NONE')}
                        href=''
                      >
                        Oracle Purchase Requisition
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <br />
                {
                  isFilterBox ?
                    <Filters isOpen={isFilterBox} SetisOpen={setIsFilterBox} setnewdata={setnewdata} />
                    : ""
                }
                {isLoading ? (
                  <CircularProgress disableShrink />
                ) : (

                  <PaginationsList isFilterEnable={isFilterBox} newdata={newdata} isStatus={isFilter} />
                )}

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer >
      </Animated >
    </>
  );
}
