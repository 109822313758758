import React, { useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import logo2 from "assets/img/login/salic-logo.png";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Fingerprint from "@material-ui/icons/Fingerprint";


import useMediaQuery from '@material-ui/core/useMediaQuery';

// core components
import Button from "components/CustomButtons/Button";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styles from "assets/jss/material-dashboard-pro-react/components/SupauthNavbarStyle.js";

const useStyles = makeStyles(styles);
export default function SupplierNavbar(props) {
  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery('(min-width:900px)');
  const [open, setOpen] = React.useState(false);
  const [decoded, setDecoded] = React.useState(null);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const Token = useSelector((state) => state.userReducer.Token) || localStorage.getItem("cooljwt");
  useEffect(() => {
    let userDetail = jwt.decode(Token);
    setDecoded(userDetail);
  }, []);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };



  const getClassToolbar = () => {
   
    if (window.location.pathname === "/supplier/register"){
      return "";
    }
    else{
      return classes.container;
    }
  }


  
  const getTextColor = () => {
    switch (window.location.pathname) {
      case "/auth/login":
        return { color: '#054b8a' };
        break;
    
        case "/auth/dev":
        return { color: '#054b8a' };
        break;
    
      default:
        return { color: '#fff' };
        break;
    }

  }

  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const goback = () => {
    // if (decoded == null || undefined || "") {
    history.push("/supplier/loginVendor")
    // }
  }
  const Path = window.location.pathname.toString();
  
  var list = (
    <List className={classes.list}>

      {Path !== "/supplier/register" ?
        <>
          <ListItem className={classes.listItem}>
            {/* <NavLink
              to={"/auth/login"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("/auth/login")
              })}
            >
              {Path !== "/supplier/login" ?
              <>
              <Fingerprint className={classes.listItemIcon} />
              <ListItemText
                primary={"Invoice Portal"}
                disableTypography={true}
                className={classes.listItemText}
              />
              </> : ""}
            </NavLink> */}
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/supplier/register"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("supplier/register")
              })}
            >
              <Fingerprint className={classes.listItemIcon} />
              <ListItemText
                primary={"Sign Up"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        </>
        : 
        <ListItem className={classes.listItem}>
            <NavLink
              to={"/supplier/login"}
              // className={cx(classes.navLink, {
              //   [classes.navLinkActive]: activeRoute("supplier/login")
              // })}
            >
              <Fingerprint className={classes.listItemIcon} />
              <ListItemText
                primary={"Back to Login"}
                disableTypography={true}
                className={classes.listItemText}
                style={{float:"right"}}
              />
            </NavLink>
          </ListItem>
        }

    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={getClassToolbar()}>
        <Hidden smDown>

          <div className={classes.flex} >
            {/** <Button href="#" className={classes.title} color="transparent">
              {brandText}
            </Button>*/}
            {matches ?
              <img src={logo2} alt="logo" style={{ width: "25%", marginLeft: "0px" }} />
              : ""}
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <img src={logo2} alt="logo" style={{ width: "25%", marginLeft: "0px" }} />
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

SupplierNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
