/*eslint-disable*/
import React, { useState, useEffect } from "react";
// @material-ui/core components
import {
  MenuItem,
  makeStyles,
  CircularProgress,
  TextField,
  Typography,
  Select,
  Input,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Swal from "sweetalert2";
import {
  successAlert,
  errorAlert,
  msgAlert,
} from "views/LDocs/Functions/Functions";
import axios from "axios";
import jwt from "jsonwebtoken";
//Redux
import { sendNotification, getNotification, sendEventLog } from "actions";
import { useSelector, useDispatch } from "react-redux";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";

//Animation
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { Autocomplete } from "@mui/material";
import { POFilter } from "actions/PurchaseOrderListAction";

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);
const Check = require("is-null-empty-or-undefined").Check;

export default function Filter(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
    let userDetail = jwt.decode(Token)
  const isAr = useSelector((state) => state.userReducer.isAr);
  const filters = isAr
    ? [
      { value: "Draft Invoice", id: 1 },
      { value: "Pending For Review", id: 2 },
      { value: "Reviewed", id: 3 },
      { value: "Pending For Approval", id: 4 },
      { value: "Approved", id: 5 },
      { value: "Sent To Customer", id: 6 },
      { value: "Acknowledged", id: 7 },
      { value: "Correction Required", id: 8 },
      { value: "Rejected ", id: 9 },
    ]
    : [
      { value: "Incomplete", id: 4 },
      { value: "Pending", id: 1 },
      { value: "Approved", id: 6 },
      { value: "Approved and Exported", id: 2 },
      { value: "Canceled", id: 5 },
      { value: "Paid", id: 3 },
      { value: "Correction Required", id: 7 },
      { value: "Rejected ", id: 8 },
    ];

  const [customers, setCustomers] = useState([]);
  const decoded = jwt.decode(Token);
  const isVendor = props.isVendor;
  const classes = useStyles();
  const sweetClass = sweetAlertStyle();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isViewWorkFlow, setIsViewWorkflow] = React.useState(false);
  const [isInitWorkFlow, setIsInitWorkFlow] = React.useState(false);
  const [isFileSame, setIsFileSame] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [poLoading, setPoLoading] = React.useState(false)
  const [vendorLoading, setVendorLoading] = React.useState(false)
  const [_PurchaseOrderList, setPurchaseOrderList] = React.useState([]);
  const [isFormState, setIsFormState] = React.useState({
    selectedPurchaseOrder: '',
    poInputValue: '',
    supplierInputValue: '',
    vendors: [],
    selectedVendor: '',
    newData: '',
    values: {
      status: '',
      comments: ''
    },
    errors: {
      status: '',
      reviewComments: ''
    }
  })
  const dispatch = useDispatch();


  const getValues = () => {
    props.setFilters(formState);
  };

  const clearFilters = () => {
    props.setFilters({
      filters: {
        invoiceId: true,
        status: true,
        date: true,
        amount: true,
        partialPaid: true,
        fullPaid: true,
        notPaid: true,
        invoiceType: true,
      },
      values: {
        invoiceId: "",
        status: [],
        supplier:null,
        po:null,
        submitStart: null,
        submitEnd: null,
        amountTo: null,
        amountfrom: null,
        partialPaid: null,
        fullPaid: null,
        notPaid: null,
        invoiceType: null,
      },
    });

  };

  const [formState, setFormState] = React.useState({
    statusOptions: filters,
    filters: props.filters,
    values: props.values,
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const _getSuppliers = async () => {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendorsAll`,
      headers: { cooljwt: Token }
    })
      .then((res) => {
        setIsFormState(isFormState => ({
          ...isFormState,
          vendors: res?.data?.result,
        }))
      })
      .catch(error => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
      })
  }

  const _getPurchaseOrders = async (supplierId, newInputValue) => {
    setPoLoading(true)
    let formData = {
      isAdmin: userDetail?.role?.isAdmin,
      displayName: userDetail?.displayName,
      po: newInputValue ? newInputValue : '',
      action: "PO",
      supplierId: supplierId ? supplierId : null,
      forPoOnly: false
    }
    await axios({
      method: 'post', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPoForRecAll`,
      data: formData,
      headers: {
        cooljwt: Token
      }
    })
      .then(res => {
        setPoLoading(false)
        setPurchaseOrderList(res?.data)
      })
      .catch(error => {
        setPoLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
      })
  }

  React.useEffect(() => {
    if (isVendor) {
      axios({
        method: "get", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/organizationByVender`,
        headers: {
          cooljwt: Token,
        },
      })
        .then((response) => {
          setCustomers(response.data.organizations);
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          console.log(error);
        });
    }
    _getSuppliers();
    _getPurchaseOrders();
  }, []);

  return (
    <Card style={{ maxWidth: 400, boxShadow: "none" }}>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <h4 className={classes.cardTitleText}>Filters</h4>
        </CardIcon>
        <span style={{ float: "right" }}>
          <Button color="danger" onClick={() => clearFilters()} round>
            Clear All
          </Button>
        </span>
      </CardHeader>
      <CardBody>
        <div>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <TextField
                className={classes.textField}
                fullWidth={true}
                label="Invoice Number"
                name="invoiceId"
                type="text"
                id="invoiceID"
                onChange={(event) => {
                  handleChange(event);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formState.values.invoiceId || ""}
              />
            </GridItem>
            <GridItem
              xs={10}
              sm={10}
              md={12}
              lg={12}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Typography varient="h6" component="h2">
                {/* {isVendor ? "Customers" : "Invoice Status"} */}
                Invoice Status
              </Typography>
              <Select
                className={classes.textField}
                fullWidth={true}
                // label={isVendor ? "Customers" : "Invoice Status"}
                label={"Invoice Status"}
                multiple
                name="status"
                onChange={(event) => {
                  handleChange(event);
                }}
                input={<Input />}
                // MenuProps={MenuProps}
                select
                value={formState.values.status || []}
              >
                {formState.statusOptions.map((o, index) => {
                  return (
                    <MenuItem key={index} value={o.id}>
                      {o.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Autocomplete
                id='filter-demo'
                options={isFormState.vendors || []}
                variant='outlined'
                inputValue={isFormState.supplierInputValue || ''}
                onInputChange={(event, newInputValue) => {
                  setIsFormState(isFormState => ({
                    ...isFormState,
                    supplierInputValue: newInputValue,
                  }))
                }}
                value={isFormState.selectedVendor || ''}
                getOptionLabel={option => option?.level1?.Supplier || ''}
                onChange={(event, newValue) => {
                  setIsFormState(isFormState => ({
                    ...isFormState,
                    selectedVendor: newValue
                  }))
                  setFormState((formState) => ({
                    ...formState,
                    values: {
                      ...formState.values,
                      ["supplier"]:  newValue?.level1?.Supplier,
                    },
                  }));
                  if (newValue?.level1?.SupplierId) {
                    let dispatch_payload = {
                      page: 0,
                      rowPerPage: 10,
                      Token: Token,
                      newdata: { data: { Supplier: newValue?.level1?.Supplier } }
                    }
                    dispatch(POFilter(dispatch_payload))
                    _getPurchaseOrders(newValue?.level1?.SupplierId, '')
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Select Supplier'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {vendorLoading ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Autocomplete
                id='filter-demo'
                options={_PurchaseOrderList || []}
                value={isFormState.selectedPurchaseOrder || ''}
                getOptionLabel={option => option?.OrderNumber || ''}
                onInputChange={(event, newInputValue) => {
                  setIsFormState(isFormState => ({
                    ...isFormState,
                    poInputValue: newInputValue
                  }))
                  _getPurchaseOrders(null, newInputValue)
                }}
                onChange={(event, newValue) => {
                  setIsFormState(isFormState => ({
                    ...isFormState,
                    selectedPurchaseOrder: newValue
                  }));
                  setFormState((formState) => ({
                    ...formState,
                    values: {
                      ...formState.values,
                      ["po"]:  newValue?.OrderNumber,
                    },
                  }));
                  if (newValue?.OrderNumber) {
                    //Dispatch Payload
                    let dispatch_payload = {
                      page: 0,
                      rowPerPage: 10,
                      Token: Token,
                      newdata: { data: { OrderNumber: newValue?.OrderNumber } }
                    };
                    dispatch(POFilter(dispatch_payload));
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Search By PO'}
                    placeholder={'Search'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {poLoading ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Typography varient="h6" component="h2">
                Invoice Submission Time
              </Typography>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <TextField
                className={classes.textField}
                fullWidth={true}
                label="Submit From"
                name="submitStart"
                type="date"
                id="datetime-local"
                defaultValue={Date.now()}
                onChange={(event) => {
                  handleChange(event);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formState.values.submitStart || ""}
              />
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={6}
              lg={6}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <TextField
                className={classes.textField}
                fullWidth={true}
                type="date"
                id="datetime-local"
                defaultValue={Date.now()}
                label="Submit End"
                name="submitEnd"
                onChange={(event) => {
                  handleChange(event);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formState.values.submitEnd || ""}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "10px" }}
            >
              <Typography varient="h6" component="h2">
                Invoice Amount
              </Typography>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <TextField
                className={classes.textField}
                fullWidth={true}
                label="Amount From"
                name="amountfrom"
                type="number"
                defaultValue={0}
                onChange={(event) => {
                  handleChange(event);
                }}
                value={formState.values.amountfrom || ""}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <TextField
                className={classes.textField}
                fullWidth={true}
                type="number"
                label="Amount To"
                name="amountTo"
                defaultValue={0}
                onChange={(event) => {
                  handleChange(event);
                }}
                value={formState.values.amountTo || ""}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "10px" }}
            >
              <Typography varient="h6" component="h2">
                Invoice Type
              </Typography>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.invoiceType == 1 ? true : false}
                      onChange={handleChange}
                      value={1}
                      color="primary"
                      name="invoiceType"
                    />
                  }
                  label="Pre-Payment"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.invoiceType == 2 ? true : false}
                      onChange={handleChange}
                      value={2}
                      color="primary"
                      name="invoiceType"
                    />
                  }
                  label="With Receipt"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.invoiceType == 3 ? true : false}
                      onChange={handleChange}
                      value={3}
                      color="primary"
                      name="invoiceType"
                    />
                  }
                  label="Petty Cash"
                />
              </FormGroup>
            </GridItem>
          </GridContainer>
          <span style={{ float: "right", marginTop: 30 }}>
            <React.Fragment>
              <Button
                color="info"
                className={classes.registerButton}
                round
                size="small"
                type="button"
                onClick={() => getValues()}
              >
                {"Apply Filters"}
              </Button>
            </React.Fragment>
            <Button
              color="danger"
              size="small"
              className={classes.registerButton}
              onClick={() => props.closeModal()}
              round
            >
              Close
            </Button>
          </span>
        </div>
      </CardBody>
    </Card>
  );
}


// const setFilters = () => {
//   setFormState((formState) => ({
//     ...formState,
//     values: data.values,
//     filters: data.filters,
//   }));
//   let files = filesData;
//   if (
//     data.filters.status &&
//     data.values.status.length != 0 &&
//     !data.values.status.includes(0)
//   ) {
//     const statusFilter = filters.filter((f) =>
//       data.values.status.includes(f.id)
//     );
//     var filteredFiles = [];
//     files.map((f) => {
//       //If !Vendor Use These Statuses & f = Invoice
//       if (!isVendor) {
//         statusFilter.map((s) => {
//           //s = Filter Status
//           const currentStatus = currentTracking(f.trackingStatus);
//           //Waiting for Acceptance
//           if (
//             s.id == 1 &&
//             currentStatus.val == 1 &&
//             currentStatus.status == "inProgress"
//           ) {
//             if (filteredFiles.find((fi) => fi._id == f._id) == undefined) {
//               //fi == If !exit in Filtered Files Push else !
//               filteredFiles.push(f);
//             }
//           }
//           //Accepted Intial Review
//           if (
//             s.id == 2 &&
//             currentStatus.val == 2 &&
//             currentStatus.status == "inProgress"
//           ) {
//             if (filteredFiles.find((fi) => fi._id == f._id) == undefined) {
//               //fi == If !exit in Filtered Files Push else !
//               filteredFiles.push(f);
//             }
//           }
//           //Pending Review
//           if (
//             s.id == 3 &&
//             currentStatus.val == 2 &&
//             currentStatus.status == "inProgress"
//           ) {
//             if (filteredFiles.find((fi) => fi._id == f._id) == undefined) {
//               //fi == If !exit in Filtered Files Push else !
//               filteredFiles.push(f);
//             }
//           }
//           //Reviewed
//           if (
//             s.id == 4 &&
//             currentStatus.val == 3 &&
//             currentStatus.status == "inProgress"
//           ) {
//             if (filteredFiles.find((fi) => fi._id == f._id) == undefined) {
//               //fi == If !exit in Filtered Files Push else !
//               filteredFiles.push(f);
//             }
//           }
//           //Pending Approval
//           if (
//             s.id == 5 &&
//             currentStatus.val == 3 &&
//             currentStatus.status == "inProgress"
//           ) {
//             if (filteredFiles.find((fi) => fi._id == f._id) == undefined) {
//               //fi == If !exit in Filtered Files Push else !
//               filteredFiles.push(f);
//             }
//           }
//           //Approved
//           if (
//             s.id == 6 &&
//             currentStatus.val == 3 &&
//             currentStatus.status == "completed"
//           ) {
//             if (filteredFiles.find((fi) => fi._id == f._id) == undefined) {
//               //fi == If !exit in Filtered Files Push else !
//               filteredFiles.push(f);
//             }
//           }
//           //Correction Required
//           if (
//             (s.id == 7 && currentStatus.status == "correctionRequired") ||
//             f.workFlowStatus == "correctionRequired"
//           ) {
//             if (filteredFiles.find((ff) => ff._id == f._id) == undefined) {
//               filteredFiles.push(f);
//             }
//           }
//           //Rejected
//           if (s.id == 8 && currentStatus.status == "rejected") {
//             if (filteredFiles.find((ff) => ff._id == f._id) == undefined) {
//               filteredFiles.push(f);
//             }
//           }
//         });
//       } else {
//         data.values.status.map((s) => {
//           if (f.organizationId == s) {
//             filteredFiles.push(f);
//           }
//         });
//       }
//     });
//     files = filteredFiles;
//   }
//   //Amount
//   if (
//     data.filters.amount &&
//     data.values.amountTo >= 1 &&
//     data.values.amountfrom >= 1
//   ) {
//     files = files.filter(
//       (file) =>
//         file.netAmt <= parseInt(data.values.amountTo) &&
//         file.netAmt >= parseInt(data.values.amountfrom)
//     );
//   }
//   //Date
//   if (
//     (data.filters.date && data.values.submitEnd !== null) ||
//     (undefined && data.values.submitStart !== null) ||
//     undefined
//   ) {
//     var endDate = new Date(data.values.submitEnd);
//     endDate.setDate(endDate.getDate() + 1);
//     files = files.filter(
//       (file) =>
//         new Date(file.createdDate) >= new Date(data.values.submitStart) &&
//         new Date(file.createdDate) <= new Date(endDate)
//     );
//   }

//   if (data.values.invoiceType == 1) {
//     files = files.filter((f) => f.isPrePayment != false);
//   }
//   if (data.values.invoiceType == 2) {
//     files = files.filter((f) => f.isReceipt != false);
//     console.log(files);
//   }
//   if (data.values.invoiceType == 3) {
//     files = files.filter((f) => f.isPettyCash != false);
//   }
// }