import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import GetTaxClassList from "actions/GetTaxAction";
import SyncTaxClassification from "views/Components/TaxClassification/SyncTaxClassification";

// @material-ui/core components
import { useDispatch, useSelector } from "react-redux";
import { TextField, MenuItem, IconButton, Table, TableContainer, TableCell, TableHead, TableRow, TableBody } from "@material-ui/core";
import { Backdrop } from "@material-ui/core";
import { Typography, Fade, Modal, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import { formatDateTime } from "Functions";
import { msgAlert } from "Functions";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    cardTitleText: {
        color: "white",
    },
    buttonRight: {},
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function TaxClassificationlist() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [classicModal, setClassicModal] = React.useState(false);
    const [viewModal, setViewModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [Updating, setUpdating] = React.useState(false);
    const [filterView, setFilterView] = React.useState(false);
    const [PurchaseOrderDetail, setPurchaseOrderDetail] = React.useState();
    const [data, setData] = React.useState();
    const [organizationFilter, setOrganizationFilter] = React.useState("");
    const [compFilter, setCompFilter] = React.useState("");
    const [organizations, setOrganizations] = React.useState([]);
    const [companies, setCompanies] = React.useState([]);
    const [PurchaseOrder, setPurchaseOrder] = React.useState([]);
    const [decoded, setDecoded] = React.useState(null);
    const [view, setView] = React.useState("grid");
    const [open, setOpen] = React.useState(false);
    const Token = useSelector((state) => state.userReducer.Token) ||
        localStorage.getItem("cooljwt");
    let userDetail = jwt.decode(Token);

    React.useEffect(() => {
        userDetail = jwt.decode(Token);
        setDecoded(userDetail);
        dispatch(GetTaxClassList(Token))
    }, []);

    const taxClassificationState = useSelector(state => state.taxClassification);
    const { taxClassification, loading } = taxClassificationState;

    // setTimeout(() => {
    //     setOpen(false)
    //   }, 1000);

    //   const handleOpen = () => {
    //     setOpen(true)
    //   };
    //   const handleClose = () => setOpen(false);

    const syncTaxClassification = async () => {
        await axios.get(`${process.env.REACT_APP_LDOCS_API_URL}/invoice/syncTaxClassification`, {
            headers: {
                cooljwt: Token
            }
        }).then((res) => {
           
        })
        msgAlert("Sync is in progress. It may take some time, you can continue using system.");
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 190,
        bgcolor: '#fff',
        boxShadow: 24,
        p: 4,
    };


    return (
        <div>
            <Animated
                animationIn="bounceInRight"
                animationOut="bounceOutLeft"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={view == "grid" ? true : false}
            >
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="info" icon>
                                <CardIcon color="info">
                                    <h4 className={classes.cardTitleText}>Tax Classification</h4>
                                </CardIcon>
                                <Button
                                    color="danger"
                                    round
                                    style={{ float: "right" }}
                                    className={classes.marginRight}
                                    onClick={syncTaxClassification}
                                >
                                    Sync
                                </Button>
                            </CardHeader>
                            <CardBody>
                                {loading ? (
                                    <CircularProgress disableShrink />
                                ) : (
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Lookup Type</TableCell>
                                                    <TableCell>Lookup Code</TableCell>
                                                    <TableCell>Meaning</TableCell>
                                                    <TableCell>Start Date Active</TableCell>
                                                    <TableCell>End Date Active</TableCell>
                                                    <TableCell>Last Synched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {taxClassification?.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.LookupType}</TableCell>
                                                        <TableCell>{row.LookupCode}</TableCell>
                                                        <TableCell>{row.Meaning}</TableCell>
                                                        <TableCell>{row.StartDateActive}</TableCell>
                                                        <TableCell>{row.EndDateActive}</TableCell>
                                                        <TableCell>{formatDateTime(row.LastSyncDate)}</TableCell>
                                                    </TableRow>
                                                )) || []}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Animated>
        </div>
    );
}
