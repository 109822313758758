import axios from "axios";
import { TAX_CLASSIFICATION_FAIL, TAX_CLASSIFICATION_REQUEST, TAX_CLASSIFICATION_SUCCESS } from "./Constant";

const GetTaxClassList =  (Token) => async (dispatch) => {

    try {
        dispatch({ type: TAX_CLASSIFICATION_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_LDOCS_API_URL}/invoice/getTaxClassification`,
        {
            headers: { cooljwt:Token }

        });

        dispatch({ type: TAX_CLASSIFICATION_SUCCESS, payload: data })
    }

    catch (error) {

        dispatch({ type: TAX_CLASSIFICATION_FAIL, payload: error.message })
    }
}
export default GetTaxClassList