import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserDataAction } from "../../../actions";
// @material-ui/core components
import {
  makeStyles,
  CircularProgress,
  Backdrop,
  MenuItem,
  Dialog,
  DialogContent,
  TextField,
} from "@material-ui/core";
// @material-ui/icons
import Swal from "sweetalert2";
import {
  successAlert,
  errorAlert,
  msgAlert,
} from "views/LDocs/Functions/Functions";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import ImageUpload from "./ImageUpload.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { Animated } from "react-animated-css";
import Level1Profile from "./Level1Profile.js";
import jwt from "jsonwebtoken";
import BoardingSteps from "./BoardingSteps";
import Typography from "views/Components/Typography";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { clearAllState } from "actions/VendorProfile";
import { GetDetails } from "actions/VendorProfile";
import axios from "axios";
import Success from "assets/img/statuses/Success.png";
import Rejected from "assets/img/statuses/Rejected.png";
import Pending from "assets/img/statuses/Pending.png";

const sweetAlertStyle = makeStyles(styles2);
const useStyleBackDrop = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function VendorProfile() {
  const backdropCss = useStyleBackDrop();
  const history = useHistory();
  const styles = makeStyles((theme) => ({}));
  const classes = styles();
  const [userData, setUserData] = useState();
  const [decoded, setDecoded] = React.useState(null);
  const [avatar, setAvatar] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [ProfileImage, setProfileImage] = useState(null);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [isloading, setisloading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [supplierWorkFlow, setSupplierWorkFlow] = useState(null);
  const [reload, setReLoad] = React.useState(false);
  const [required, setRequired] = useState(false);
  const [formState, setFormState] = React.useState({
    values: {
      status: "",
      comments: "",
    },
    errors: {
      status: "",
      reviewComments: "",
    },
  });
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let userDetail = jwt.decode(Token);
  const { supplierStatus } = useSelector((state) => state.VendorRegister) || {};
  const [error, setError] = useState({
    vendorName: "",
    contactNumber: "",
    vendorEmail: "",
    taxPayerIdcrNum: "",
    taxRegistrationNum: "",
    error: false,
  });
  const [stepstate, setStepstate] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, isedit, ismail } = useParams();

  let vendorId = id || decoded?.id || "";

  //Declaring Reference to Main Wrapper
  const mainDiv = React.useRef();

  useEffect(() => {
    if (location.pathname == "/supplier/register") {
      setIsLoaded(true);
    }
    if (
      decoded?.isVendor == true &&
      location.pathname.includes("/vendor/vendor_profile")
    ) {
      const { id } = decoded;
      dispatch(GetDetails(id, Token));
      setIsLoaded(true);
    } else if (!decoded?.isVendor) {
      dispatch(clearAllState());
    }
  }, [decoded]);

  useEffect(() => {
    if (userDetail?.role?.isAdmin == false && ismail == "false") {
      if (id) {
        dispatch(GetDetails(id, Token));
        setIsLoaded(true);
        _getDetails(id);
      }
    } else {
      if (!location.pathname.includes("/supplier/register") && Token == null) {
        history.push("/auth/login");
      }
    }
    if (userDetail && userDetail?.role?.isAdmin == true) {
      if (id) {
        dispatch(GetDetails(id, Token));
        setIsLoaded(true);
        _getDetails(id);
      }
    }
    if (Token && ismail == "true") {
      if (id) {
        dispatch(GetDetails(id, Token));
        setIsLoaded(true);
        _getDetails(id);
      }
    }
    setDecoded(userDetail);
  }, []);

  useEffect(() => {
    if (id) {
      _getDetails(id);
    }
  }, [reload]);

  setTimeout(() => {
    setIsLoaded(false);
  }, 2000);

  const updateUserData = (userObject) => {
    dispatch(setUserDataAction(userObject));
  };

  const user = useSelector((state) => state.userReducer.userListData);
  const { generalinfo } = useSelector((state) => state.VendorRegister);
  const { workflow } = useSelector((state) => state.VendorRegister) || {};
  let profileImage = generalinfo?.profileImage || "";

  const handleImageChange = (file, status, imageName) => {
    if (status == 1) {
      setProfileImage(file);
    } else {
      setProfileImage(null);
    }
  };

  const _getDetails = async (id) => {
    setDetailsLoading(true);
    await axios({
      method: "get",
      url: `${
        process.env.REACT_APP_LDOCS_API_URL
      }/vendor/getSupplierById/${id}/${false}`,
      headers: { cooljwt: Token },
    }).then((res) => {
      setDetailsLoading(false);
      setSupplierWorkFlow(res?.data?.workFlowStatus);
    });
  };
  const handlerChange = (event) => {
    event.persist();
    if (event.target.name == "status") {
      if (
        event.target.value == "correctionRequired" ||
        event.target.value == "rejected"
      ) {
        setRequired(true);
      } else {
        setRequired(false);
      }
    }
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  /*getting/destructure workflow */
  let workFlowStatus = workflow?.workFlowStatus;

  const reviewSupplier = async () => {
    setReviewModal(true);
  };

  const reviewSupplierNow = async () => {
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;
    let status;
    let comments;

    if (!Check(formState.values.status)) {
      status = "success";
    } else {
      status = "error";
      error = true;
    }

    if (required ? !Check(formState.values.comments) : (comments = "success")) {
      comments = "success";
    } else {
      comments = "error";
      error = true;
    }
    if (error) {
      setFormState((formState) => ({
        ...formState,
        errors: {
          status: status,
          reviewComments: comments,
        },
      }));
    } else {
      setisloading(true);
      setFormState((formState) => ({
        ...formState,
        errors: {
          status: "",
          comment: "",
        },
      }));
      let postData = {
        vendorId: vendorId,
        action:
          workFlowStatus?.status == "initialReviewStep"
            ? "initialReview"
            : workFlowStatus?.status == "reviewStep"
            ? "review"
            : workFlowStatus?.status == "approveStep"
            ? "approve"
            : "",
        user: decoded.email,
        comment: formState.values.comments,
        status: formState.values.status,
      };
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/updateFlowStatus`,
        data: postData,
        headers: { cooljwt: Token },
      })
        .then((res) => {
          setisloading(false);
          setReviewModal(false);
          _getDetails(id);
          dispatch(GetDetails(id));
          if (workFlowStatus?.status == "initialReviewStep" || "ReviewStep") {
            successAlert("Reviewed Successfully");
          }
          if (workFlowStatus?.status == "approveStep") {
            successAlert("Approved Successfully");
          }
          if (formState?.values?.status === "correctionRequired") {
            successAlert("Sent For Correction Required");
          }
        })
        .catch((err) => {
          let error = err?.response?.data;
          errorAlert(error);
          setisloading(false);
          setReviewModal(false);
        });
    }
  };

  const handleSubmit = () => {
    if (userData !== undefined) {
      var bodyFormData = new FormData();
      bodyFormData.append("profileImg", userData);
      bodyFormData.append("email", generalinfo.email);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let msg = "";
      axios({
        method: "post",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/vendorProfileImg`,
        data: bodyFormData,
        config: config,
      })
        .then((response) => {})
        .catch((error) => {
          msg =
            typeof error.response != "undefined"
              ? error.response.data
              : error.message;
          errorAlert(msg);
        });
    }
  };
  const sweetClass = sweetAlertStyle();

  const scrollHandler = () => {
    var myElement = document.getElementsByClassName("ps");
    myElement.scrollTop = 0;
    window.scrollTo(0, 0);
  };

  return isLoaded ? (
    <Backdrop className={backdropCss.backdrop} open={isLoaded}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <>
      {location.pathname == "/vendor/vendor_profile" &&
      supplierStatus?.status == "CORRECTION REQUIRED" ? (
        <GridContainer>
          <Card
            style={{ width: "98%", display: "flex", flexDirection: "inherit" }}
          >
            <div
              style={{
                background: "rgb(255 0 0)",
                fontsize: "large",
                border: "9px solid #fff",
                borderRadius: "15px",
                width: "361px",
              }}
            >
              <h4 style={{ color: "white", padding: "10px" }}>
                {supplierStatus?.status}
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "100%",
                padding: "20px",
                margin: "5px 0 0 20px",
              }}
            >
              <p
                style={{
                  fontSize: " 1.0rem",
                  fontWeight: "bold",
                  textAlign: "justify",
                  fontFamily: "arial",
                  zoom: "100%",
                }}
              >
                Your application required some corrections, please check your
                email and update your profile with the corrections mentioned in
                the email.
              </p>
            </div>
          </Card>
        </GridContainer>
      ) : (
        ""
      )}
      {location.pathname == "/vendor/vendor_profile" &&
      supplierStatus?.status == "PENDING" ? (
        <GridContainer>
          <Card
            style={{ width: "98%", display: "flex", flexDirection: "inherit" }}
          >
            <div
              style={{
                background: "#ffa500",
                fontsize: "large",
                border: "9px solid #fff",
                borderRadius: "15px",
              }}
            >
              <h4 style={{ color: "white", padding: "10px" }}>
                {supplierStatus?.status}
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "100%",
                padding: "20px",
                margin: "5px 0 0 20px",
              }}
            >
              <p
                style={{
                  fontSize: " 1.0rem",
                  fontWeight: "bold",
                  textAlign: "justify",
                  fontFamily: "arial",
                  zoom: "100%",
                }}
              >
                Thank you for your interest to work with SALIC. Your application
                is in approval process, you will be notified via email once your
                application is approved.
              </p>
            </div>
          </Card>
        </GridContainer>
      ) : (
        ""
      )}
      <GridContainer
        ref={mainDiv}
        style={{ overflowY: "hidden", margin: 0, width: "100%" }}
      >
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {location.pathname !== "/admin/vendor_Register" &&
          location.pathname !== "/supplier/register" &&
          userDetail &&
          !userDetail?.isVendor &&
          supplierWorkFlow?.status == "initialReviewStep" &&
          supplierWorkFlow?.initialReviewStep.reviewedBy == decoded?.email ? (
            <Button
              color="danger"
              round
              style={{ float: "right" }}
              onClick={() => reviewSupplier()}
            >
              {isloading ? (
                <CircularProgress />
              ) : supplierWorkFlow?.status == "initialReviewStep" ? (
                "Initial Review"
              ) : supplierWorkFlow?.status == "reviewStep" ? (
                "Review"
              ) : supplierWorkFlow?.status == "approveStep" ? (
                "Approve"
              ) : (
                ""
              )}
            </Button>
          ) : supplierWorkFlow?.status == "reviewStep" &&
            supplierWorkFlow?.reviewStep?.some(
              (step) => step?.reviewedBy == decoded?.email
            ) ? (
            // <GridItem xs={12} sm={12} md={12} lg={6}>
            <Button
              color="danger"
              round
              style={{ float: "right" }}
              onClick={() => reviewSupplier()}
            >
              {isloading ? (
                <CircularProgress />
              ) : supplierWorkFlow?.status == "initialReviewStep" ? (
                "Initial Review"
              ) : supplierWorkFlow?.status == "reviewStep" ? (
                "Review"
              ) : supplierWorkFlow?.status == "approveStep" ? (
                "Approve"
              ) : (
                ""
              )}
            </Button>
          ) : // </GridItem>

          supplierWorkFlow?.approveStep?.some(
              (step) => step?.status !== "APPROVED"
            ) ? (
            supplierWorkFlow?.status == "approveStep" ? (
              supplierWorkFlow?.approveStep?.some(
                (step) => step?.approvedBy == decoded?.email
              ) ? (
                // <GridItem xs={12} sm={12} md={12} lg={6}  style={{ float: 'right' }}>
                <Button
                  color="danger"
                  round
                  style={{ float: "right" }}
                  onClick={() => reviewSupplier()}
                >
                  {isloading ? (
                    <CircularProgress />
                  ) : supplierWorkFlow?.status == "initialReviewStep" ? (
                    "Initial Review"
                  ) : supplierWorkFlow?.status == "reviewStep" ? (
                    "Review"
                  ) : supplierWorkFlow?.status == "approveStep" ? (
                    "Approve"
                  ) : (
                    ""
                  )}
                </Button>
              ) : (
                // </GridItem>
                ""
              )
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {/* {/ if admin || requester show back button to grid /} */}
          {userDetail && userDetail?.role?.isAdmin == false ? (
            // <GridItem xs={12} sm={12} md={12} lg={6}>
            <Button
              color="danger"
              round
              style={{ float: "right" }}
              onClick={() => history.goBack()}
            >
              Go Back
            </Button>
          ) : // </GridItem>
          userDetail && userDetail?.role?.isAdmin == true ? (
            // <GridItem xs={12} sm={12} md={12} lg={6}>
            <Button
              color="danger"
              round
              style={{ float: "right" }}
              onClick={() => history.goBack()}
            >
              Go Back
            </Button>
          ) : (
            // </GridItem>
            ""
          )}
        </GridItem>
        {reviewModal ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                fullWidth={true}
                maxWidth={"sm"}
                open={reviewModal}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => setReviewModal(false)}
                aria-labelledby="tag-modal-slide-title"
                aria-describedby="tag-modal-slide-description"
              >
                <DialogContent
                  id="tag-modal-slide-description"
                  className={classes.modalBody}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card>
                        <CardHeader color="info" icon>
                          <CardIcon color="info">
                            {/* <h4 className={classes.cardTitle}>Approve/Reject</h4> */}
                            <h4>
                              {workFlowStatus?.status == "initialReviewStep"
                                ? "INITIAL REVIEW"
                                : workFlowStatus?.status == "reviewStep"
                                ? "REVIEW"
                                : workFlowStatus?.status == "approveStep"
                                ? "APPROVE"
                                : "Action"}
                            </h4>
                          </CardIcon>
                        </CardHeader>
                        <CardBody>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <TextField
                              className={classes.textField}
                              fullWidth={true}
                              error={formState.errors.status === "error"}
                              helperText={
                                formState.errors.status === "error"
                                  ? "Status are required"
                                  : null
                              }
                              select
                              label="Status"
                              name="status"
                              onChange={(event) => {
                                handlerChange(event);
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Choose Status
                              </MenuItem>
                              {workFlowStatus?.status === "approveStep" ? (
                                <MenuItem value="APPROVED">
                                  <div className="fileinput text-center">
                                    <div className="thumbnail img-circle3">
                                      <img
                                        src={Success}
                                        alt={"MARK AS APPROVED"}
                                      />
                                    </div>
                                    MARK AS APPROVE
                                  </div>
                                </MenuItem>
                              ) : (
                                <MenuItem value="REVIEWED">
                                  <div className="fileinput text-center">
                                    <div className="thumbnail img-circle3">
                                      <img
                                        src={Success}
                                        alt={"MARK AS REVIEWED"}
                                      />
                                    </div>
                                    MARK AS REVIEW
                                  </div>
                                </MenuItem>
                              )}
                              <MenuItem value="correctionRequired">
                                <div className="fileinput text-center">
                                  <div className="thumbnail img-circle3">
                                    <img
                                      src={Pending}
                                      alt={"correction Required"}
                                    />
                                  </div>
                                  CORRECTION REQUIRED
                                </div>
                              </MenuItem>
                              <MenuItem value="rejected">
                                <div className="fileinput text-center">
                                  <div className="thumbnail img-circle3">
                                    <img
                                      src={Rejected}
                                      alt={"MARK AS REJECTED"}
                                    />
                                  </div>
                                  MARK AS REJECTED
                                </div>
                              </MenuItem>
                            </TextField>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <TextField
                              className={classes.textField}
                              fullWidth={true}
                              required={required}
                              error={
                                formState.errors.reviewComments === "error"
                              }
                              helperText={
                                formState.errors.reviewComments === "error"
                                  ? "Comments are required"
                                  : null
                              }
                              label="Comments"
                              name="comments"
                              onChange={(event) => {
                                handlerChange(event);
                              }}
                              // value={formState.values.reviewComments || ""}
                            ></TextField>
                          </GridItem>
                          <span style={{ float: "right" }}>
                            <Button
                              color="info"
                              className={classes.registerButton}
                              round
                              type="button"
                              onClick={() => reviewSupplierNow()}
                            >
                              {isloading ? (
                                <CircularProgress style={{ color: "#fff" }} />
                              ) : (
                                "Submit"
                              )}
                            </Button>
                            <Button
                              color="danger"
                              className={classes.registerButton}
                              onClick={() => setReviewModal(false)}
                              round
                            >
                              CLOSE
                            </Button>
                          </span>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </DialogContent>
              </Dialog>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}

        <GridItem xs={12} sm={12} md={6} lg={6} className="Top">
          <Card profile>
            <CardAvatar profile></CardAvatar>
            <CardBody
              profile
              style={{ minHeight: "310px", height: "fit-content" }}
            >
              {!userDetail ? (
                <div
                  className="fileinput"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "80px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      fontSize: " 1.4rem",
                      fontWeight: "bold",
                      textAlign: "justify",
                      fontFamily: "arial",
                      lineHeight: "1.5em",
                      zoom: "100%",
                    }}
                  >
                    We welcome any companies who are interested to work with
                    SALIC to complete our form to Register. If we have relevant
                    opportunities which may match your capabilities we will
                    reach out to you.
                  </p>
                </div>
              ) : (
                <ImageUpload
                  addButtonProps={{
                    color: "info",
                    round: true,
                  }}
                  changeButtonProps={{
                    color: "warning",
                    round: true,
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: true,
                  }}
                  uploadButtonProps={{
                    color: "success",
                    round: true,
                  }}
                  name="profileImage"
                  handleImageChange={handleImageChange}
                  avatar
                  successAlert={successAlert}
                  errorAlert={errorAlert}
                  userData={userData}
                  setUserData={setUserData}
                  updateUserData={updateUserData}
                  stepstate={stepstate}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">Profile Information</CardIcon>
            </CardHeader>
            <CardBody profile>
              <Level1Profile
                setError={setError}
                error={error}
                stepstate={stepstate}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <BoardingSteps
            scrollHandler={scrollHandler}
            setError={setError}
            validationError={error}
            handleSubmit={handleSubmit}
            setStepstate={setStepstate}
            setReLoad={setReLoad}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}></GridItem>
      </GridContainer>
    </>
  );
}
