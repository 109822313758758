import userReducer from "./user";
import { productListReducer } from "./PurchaseOrderList";
import {supplierListReducer} from "./SupplierList"

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from "redux";
import { logsListReducer } from "./LogsList";
import { taxClassificationReducer } from "./TaxClassification";
import { organizationListReducer } from "./OrganizationList";
import { VendorRegister } from "./VendorProfile";
import { SubmitVendorRegister } from "./VendorProfile";
import InvoiceFilter from './InvoiceFilter';

// const allReducers = combineReducers({
//   userReducer
// });

// export default allReducers;

 
const rootPersistConfig = {
  key: 'root',
  storage: storage,
}
 
const authPersistConfig = {
  key: 'user_reducer',
  storage: storage,
}
 
const rootReducer = combineReducers({
  userReducer: persistReducer(authPersistConfig, userReducer),
  purchaseOrder:productListReducer,
  suppliers:supplierListReducer,
  logs:logsListReducer,
  taxClassification:taxClassificationReducer,
  organizations:organizationListReducer,
  VendorRegister:VendorRegister,
  SubmitVendor:SubmitVendorRegister,
  filter:InvoiceFilter
})
 
export default persistReducer(rootPersistConfig, rootReducer)