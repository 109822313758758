import axios from "axios";
import dateFormat from "dateformat";
import jwt, { TokenExpiredError } from "jsonwebtoken";
import Swal from "sweetalert2";
import moment from "moment";

export function addZeroes(num) {
  if (typeof num == "string" || typeof num == "number") {
    num = typeof num == "string" ? num : num.toString();
    var value = Number(num);
    var res = num.toString().split(".");
    if (res.length == 1 || res[1].length < 1) {
      value = value.toFixed(2);
    }
    return value;
  } else {
    return 0;
  }
}

export const formatDateTime = (date) => {
  let Token = process.env.token;
  let user = jwt.decode(Token);
  var offset;
  offset = moment().utcOffset();
  var now = new Date(date);
  const someday = moment(now)
    .utcOffset(offset)
    .format("DD-MM-YYYY hh:mm A");
  return someday;
};

export const formatDate = (date) => {
  let Token = process.env.token;
  let user = jwt.decode(Token);
  var offset;

  offset = moment().utcOffset();
  var now = new Date(date);
  const someday = moment(now)
    .utcOffset(offset)
    .format("DD-MM-YYYY");
  return someday;
};


export const verifyEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
  ;
}


export const currentTracking = (trackingStatus) => {
  let currentStatus;
  let activeStep;
  switch (trackingStatus.current_status) {
    case "received":
      currentStatus = trackingStatus.received.status;
      activeStep = { val: 0, status: currentStatus };
      break;
    case "initialReview":
      currentStatus = trackingStatus.initialReview.status;
      if (currentStatus) {
        activeStep = { val: 1, status: currentStatus };
      } else {
        activeStep = { val: 0, status: currentStatus };
      }
      break;
    case "underReview":
      currentStatus = trackingStatus.underReview.status;
      if (currentStatus) {
        activeStep = { val: 2, status: currentStatus };
      } else {
        activeStep = { val: 1, status: currentStatus };
      }
      break;
    case "underApprove":
      currentStatus = trackingStatus.underApprove.status;
      if (currentStatus) {
        activeStep = { val: 3, status: currentStatus };
      } else {
        activeStep = { val: 2, status: currentStatus };
      }
      break;
    case "paymentInProcess":
      currentStatus = trackingStatus.paymentInProcess.status;
      if (currentStatus) {
        activeStep = { val: 4, status: currentStatus };
      } else {
        activeStep = { val: 3, status: currentStatus };
      }
      break;
    case "paid":
      currentStatus = trackingStatus.paid.status;
      if (currentStatus) {
        activeStep = { val: 4, status: currentStatus };
      } else {
        activeStep = { val: 3, status: currentStatus };
      }
      break;
  }
  return activeStep;
};

export const currentTrackingAr = (trackingStatus) => {
  let currentStatus;
  let activeStep;
  switch (trackingStatus.current_status) {
    case "invoiceDraft":
      currentStatus = trackingStatus.invoiceDraft.status;
      activeStep = { val: 0, status: currentStatus };
      break;
    case "underReview":
      currentStatus = trackingStatus.underReview.status;
      if (currentStatus) {
        activeStep = { val: 1, status: currentStatus };
      } else {
        activeStep = { val: 0, status: currentStatus };
      }
      break;
    case "underApprove":
      currentStatus = trackingStatus.underApprove.status;
      if (currentStatus) {
        activeStep = { val: 2, status: currentStatus };
      } else {
        activeStep = { val: 1, status: currentStatus };
      }
      break;
    case "sentToClient":
      currentStatus = trackingStatus.sentToClient.status;
      if (currentStatus) {
        activeStep = { val: 3, status: currentStatus };
      } else {
        activeStep = { val: 2, status: currentStatus };
      }
      break;
    case "paid":
      currentStatus = trackingStatus.paid.status;
      if (currentStatus) {
        activeStep = { val: 4, status: currentStatus };
      } else {
        activeStep = { val: 3, status: currentStatus };
      }
      break;
  }
  return activeStep;
};

export const successAlert = (msg) => {
  Swal.fire({
    title: "Success",
    text: msg,
    icon: "success",
    showCancelButton: false,
    confirmButtonColor: "#49b34e",
    cancelButtonColor: "#d33",
    confirmButtonText: "Ok",
  });
};

export const errorAlert = (msg) => {
  Swal.fire({
    title: "Error",
    text: msg,
    icon: "error",
    showCancelButton: false,
    confirmButtonColor: "#49b34e",
    cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  });
};

export const msgAlert = (msg) => {
  Swal.fire({
    title: "INFO",
    text: msg,
    icon: "warning",
    showCancelButton: false,
    confirmButtonColor: "#49b34e",
    cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  });
};

export const tokenExpiredAlert = (onButtonPress, msg) => {
  Swal.fire({
    title: "INFO",
    text: msg,
    icon: "warning",
    showCancelButton: false,
    confirmButtonColor: "#49b34e",
    cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  }).then((result) => {
    if (result.isConfirmed) {
      onButtonPress();
    }
  });
}

export const sessionTimeout = (onButtonPress, msg) => {
  Swal.fire({
    title: "INFO",
    text: msg,
    icon: "warning",
    showCancelButton: false,
    confirmButtonColor: "#49b34e",
    cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  }).then(async (result) => {
    if (result.isConfirmed) {
      onButtonPress();
    }
  })
}

export const getOCRData = async (file) => {
  return new Promise((res, rej) => {
    let formData = new FormData();
    formData.append("file", file);
    axios({
      method: "post", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_OCR_URL}/uploadfile`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(async ({ data }) => {
        let invoice_payload = {};
        let test_payload = {
          TO: "",
          Tables: [
            {
              discount: "0",
              discount_type: "percentage",
              product_description: "",
              product_item: "Oil",
              quantity: "50",
              total_amount: "5000",
              unit_price: "100",
            },
          ],
          built_no: "",
          company_details: "SALIC VENDOR",
          currency: "",
          current_bill: "",
          discount_type: "percentage",
          due_date: "17/09/2021",
          file_name: "testocr.jpg",
          forward_ref: "",
          freight: "",
          gross_amount: "5000",
          invoice_date: "17/09/2021",
          invoice_number: "INV-3385",
          msr: "",
          new_balance: "",
          payment_terms: "NET-45",
          po: "103",
          previous_balance: "",
          tax: "0",
          tax_type: "vat",
          total: "5000",
          total_discount: "0",
          vehicle_no: "",
          vendor_address: "",
          vendor_email: "",
          vendor_name: "",
          vendor_phone_number: "",
        };

        invoice_payload.inv_items = data?.Tables || [];
        invoice_payload.discount_type =
          data?.discount_type == "vat" ? 0 : 1 || 0;
        invoice_payload.tax_type = data?.tax_type == "vat" ? 0 : 1 || 0;
        invoice_payload.tax = data?.tax || 0;
        invoice_payload.discount = data?.total_discount || 0;
        invoice_payload.invoice_number = data?.invoice_number || "";
        invoice_payload.due_date = new Date(data?.due_date) || new Date();
        invoice_payload.invoice_date =
          new Date(data?.invoice_date) || new Date();

        res(invoice_payload);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

export const checkSelectAll = (permissions, key1, key2) => {
  let AllSelectedModule = [];
  if (typeof permissions == "object") {
    Object.keys(permissions).map((key) => {
      if (key1) {
        key = key1;
      }
      Object.keys(permissions[key]).map((sub_key) => {
        if (key2) {
          sub_key = key2;
        }
        Object.keys(permissions[key][sub_key]).map((item) => {
          if (
            item != "name" &&
            item != "enable" &&
            typeof permissions[key][sub_key][item] == "object"
          ) {
            AllSelectedModule.push(
              permissions[key][sub_key][item]?.enable ? true : false
            );
          }
        });
      });
    });
    let isAllSelectedModule = AllSelectedModule.some((item) => item === true);
    return isAllSelectedModule;
  } else {
    return false;
  }
};

export const validateInvoice = async (row, Token, isAr) => {
  return new Promise((res, rej) => {
    axios({
      method: "post", //you can set what request you want to be
      url: isAr
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ar`
        : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ap`,
      data: {
        invoiceId: row.invoiceId,
        version: row.version,
        vendorId: isAr ? null : row.vendorId,
        clientId: isAr ? row.clientId : null,
      },
      headers: {
        cooljwt: Token,
      },
    })
      .then(async (invoiceRes) => {
        const invoice = invoiceRes.data;
        const isAR = invoice.isAR;
        axios({
          method: "get",
          url: isAR
            ? `${process.env.REACT_APP_LDOCS_API_BOOKCHAIN_URL}/api/invoice/validate-invoice/${invoice.clientId}-${row.invoiceId}-${row.version}`
            : `${process.env.REACT_APP_LDOCS_API_BOOKCHAIN_URL}/api/invoice/validate-invoice/${invoice.vendorId}-${row.invoiceId}-${row.version}`,
        })
          .then(async (blockchainRes) => {
            const blockchain = blockchainRes.data.InvoiceData;
            if (invoice !== null || undefined) {
              let isInvoiceDateSame;
              let isVendorIDSame;
              let isItemCountSame;
              let isGrossAmtSame;
              let isOrganizationIDSame;
              let isDiscountPercentageSame;
              let isTaxAmtSame;
              let isNetAmtSame;
              let isTenantIDSame;
              let isCreatedDateSame;
              let isCreatedBySame;
              let isValidate;
              let BlockchainNames;
              let InvoiceNames;
              if (
                new Date(invoice.invoiceDate).getTime() ==
                new Date(blockchain.invoiceDate).getTime()
              ) {
                isInvoiceDateSame = true;
              } else {
                isInvoiceDateSame = false;
              }
              if (invoice.vendorId == blockchain.vendorID) {
                isVendorIDSame = true;
              } else {
                isVendorIDSame = false;
              }
              if (invoice.items.length == blockchain.itemCount) {
                isItemCountSame = true;
              } else {
                isItemCountSame = false;
              }
              if (invoice.grossAmt == blockchain.grossAmt) {
                isGrossAmtSame = true;
              } else {
                isGrossAmtSame = false;
              }
              if (invoice.organizationId == blockchain.organizationID) {
                isOrganizationIDSame = true;
              } else {
                isOrganizationIDSame = false;
              }
              if (invoice.taxAmt == blockchain.taxAmt) {
                isTaxAmtSame = true;
              } else {
                isTaxAmtSame = false;
              }
              if (invoice.discountPercent == blockchain.discountPercent) {
                isDiscountPercentageSame = true;
              } else {
                isDiscountPercentageSame = false;
              }
              if (invoice.netAmt == blockchain.netAmt) {
                isNetAmtSame = true;
              } else {
                isNetAmtSame = false;
              }
              if (invoice.tenantId == blockchain.tenantID) {
                isTenantIDSame = true;
              } else {
                isTenantIDSame = false;
              }
              if (
                new Date(invoice.createdDate).getTime() ==
                new Date(blockchain.createdDate).getTime()
              ) {
                isCreatedDateSame = true;
              } else {
                isCreatedDateSame = false;
              }
              if (invoice.createdBy == blockchain.createdBy) {
                isCreatedBySame = true;
              } else {
                isCreatedBySame = false;
              }
              if (
                !isInvoiceDateSame ||
                !isVendorIDSame ||
                !isItemCountSame ||
                !isGrossAmtSame ||
                !isOrganizationIDSame ||
                !isDiscountPercentageSame ||
                !isTaxAmtSame ||
                !isNetAmtSame ||
                !isTenantIDSame ||
                !isCreatedDateSame ||
                !isCreatedBySame
              ) {
                isValidate = false;
              } else {
                isValidate = true;
              }
              //Gettting Names
              await axios({
                method: "post", //you can set what request you want to be
                url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getNameById`,
                data: {
                  organizationId: invoice?.organizationId || "",
                  tenantId: invoice?.tenantId || "",
                  vendorId: invoice?.vendorId || "",
                  clientId: invoice?.clientId || "",
                  userId: invoice?.createdBy || "",
                  isVendor: invoice?.createdByVendor || "",
                },
                headers: {
                  cooljwt: Token,
                },
              })
                .then((res) => {
                  InvoiceNames = res.data;
                })
                .catch((err) => {
                  console.log(err);
                });
              //Gettting Names BlockChain
              await axios({
                method: "post", //you can set what request you want to be
                url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getNameById`,
                data: {
                  organizationId: blockchain?.organizationID || "",
                  tenantId: blockchain?.tenantID,
                  vendorId: blockchain?.vendorID || "",
                  clientId: blockchain?.clientID || "",
                  userId: blockchain?.createdBy || "",
                  isVendor: invoice?.createdByVendor || "",
                },
                headers: {
                  cooljwt: Token,
                },
              })
                .then((res) => {
                  BlockchainNames = res.data;
                })
                .catch((err) => {
                  console.log(err);
                });
              const ValidationData = {
                "Submit Date": {
                  onChain: dateFormat(blockchain.invoiceDate, "dd-mm-yyyy"),
                  offChain: dateFormat(invoice.invoiceDate, "dd-mm-yyyy"),
                  isSame: isInvoiceDateSame,
                },
                "Vendor ID": {
                  onChain: isAr ? blockchain.clientID : blockchain.VendorID,
                  offChain: isAr ? invoice.clientId : invoice.vendorId,
                  isSame: isVendorIDSame,
                  onChainName: BlockchainNames.vendorName || null,
                  offChainName: InvoiceNames.vendorName || null,
                },
                "Item Count": {
                  onChain: blockchain.itemCount,
                  offChain: invoice.items.length,
                  isSame: isItemCountSame,
                },
                "Gross Amount": {
                  onChain:
                    invoice.FC_currency.Code + addZeroes(blockchain.grossAmt),
                  offChain:
                    invoice.FC_currency.Code + addZeroes(invoice.grossAmt),
                  isSame: isGrossAmtSame,
                },
                "Organization ID": {
                  onChain: blockchain.organizationID,
                  offChain: invoice.organizationId,
                  isSame: isOrganizationIDSame,
                  onChainName: BlockchainNames.organizationName || null,
                  offChainName: InvoiceNames.organizationName || null,
                },
                "Discount Percentage": {
                  onChain: `${isAr
                    ? blockchain.discountPercent
                    : blockchain.discountPercentage
                    }%`,
                  offChain: `${invoice.discountPercent}%`,
                  isSame: isDiscountPercentageSame,
                },
                "Tax Amount": {
                  onChain:
                    invoice.FC_currency.Code + addZeroes(blockchain.taxAmt),
                  offChain:
                    invoice.FC_currency.Code + addZeroes(invoice.taxAmt),
                  isSame: isTaxAmtSame,
                },
                "Net Amount": {
                  onChain:
                    invoice.FC_currency.Code + addZeroes(blockchain.netAmt),
                  offChain:
                    invoice.FC_currency.Code + addZeroes(invoice.netAmt),
                  isSame: isNetAmtSame,
                },
                "Tenant ID": {
                  onChain: blockchain.tenantID,
                  offChain: invoice.tenantId,
                  isSame: isTenantIDSame,
                  onChainName: BlockchainNames.tenantName || null,
                  offChainName: InvoiceNames.tenantName || null,
                },
                "Created Date": {
                  onChain: formatDateTime(blockchain.createdDate),
                  offChain: formatDateTime(invoice.createdDate),
                  isSame: isCreatedDateSame,
                },
                "Created By": {
                  onChain: blockchain.createdBy,
                  offChain: invoice.createdBy,
                  isSame: isCreatedBySame,
                  onChainName: BlockchainNames.userName || null,
                  offChainName: InvoiceNames.userName || null,
                },
                Validate: {
                  isSame: isValidate,
                },
              };
              res(ValidationData);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const _IsAr = () => {
  let url = window.location.href;
  let is_Ar =
    url.substring(url.lastIndexOf("/") + 1) == "ar"
      ? true
      : url.substring(url.lastIndexOf("/") + 1) == "ar"
        ? false
        : null;
  return is_Ar;
};

export const conversionRate = (
  fc,
  bc,
  currencies,
  amount,
  isNotSymbol,
  isEdit,
  rate
) => {
  const bC = currencies.find((c) => c._id == bc);
  const fC = currencies.find((c) => c._id == fc);
  const bcSymbol = bC ? bC.Code : "";
  const fcRate = isEdit ? rate : fC ? fC.conversionRate : "";
  if (isNotSymbol) {
    return parseFloat(fcRate * amount).toFixed(4);
  } else {
    return fc !== bc
      ? `(${bcSymbol} ${parseFloat(fcRate * amount).toFixed(4)})`
      : "";
  }
};
