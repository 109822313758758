import React, { useState, useEffect } from "react";
// @material-ui/icons
import {
  makeStyles,
  CircularProgress,
  TextField,
  MenuItem,
  useForkRef,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Dialog,
  DialogContent,
  TableCell,
  TableHead,
  TableBody,
  Box,
  TableRow,
  Table,
  Tooltip
} from "@material-ui/core";
// @material-ui/core components

// core components
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Animated } from "react-animated-css";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Swal from 'sweetalert2'
import { successAlert, errorAlert, msgAlert } from "views/LDocs/Functions/Functions";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";
import VendorProfile from "actions/VendorProfile";
import { submitVendorProfile } from "actions/VendorProfile";
import { getBankDetails } from "actions/VendorProfile"
import { getCountry } from "actions/VendorProfile";
import { VendorProfileContacts } from "actions/VendorProfile";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { VendorContactsSubmit } from "actions/VendorProfile";
import { VendorContactDelete } from "actions/VendorProfile";
import { VendorProfileAddress } from "actions/VendorProfile";
import { VendorAddressSubmit } from "actions/VendorProfile";
import { VendorAddressDelete } from "actions/VendorProfile";
import { VendorProfileSites } from "actions/VendorProfile";
import { VendorSitesSubmit } from "actions/VendorProfile";
import { VendorSitesDelete } from "actions/VendorProfile";
import { GetOrganizationType } from "actions/VendorProfile";
import { GetSupplierType } from "actions/VendorProfile";
import { GetAttachmentType } from "actions/VendorProfile";
import { getCity } from "actions/VendorProfile";
import { EditAttributes } from "@material-ui/icons";
import { VendorProfileAddressEdit, VendorProfileAddressClear } from "actions/VendorProfile";
import { VendorProfileAddressChange } from "actions/VendorProfile";
import Edit from '@material-ui/icons/Edit'
import { VendorProfileContactEdit } from "actions/VendorProfile";


const style = theme => ({
  asterisk: {
    color: "red"
  }
});

const useStyles = makeStyles(style)
const sweetAlertStyle = makeStyles(styles2)

export default function General(props) {

  const { validationError } = props

  const { id, isedit } = useParams();
  const [edit, setEdit] = useState(false)
  useEffect(() => {
    if (isedit === "true") {
      setEdit(true)
    }
    if (isedit === "false") {
      setEdit(false)
    }
  }, [isedit])
  const classes = useStyles();
  const [animateStep, setAnimateStep] = useState(true);
  const [selectedFileModel, setSelectedFileModel] = useState(false);
  const [selectedAddresseModel, setSelectedAddresseModel] = useState(false);
  const [selectedSiteModel, setSelectedSiteModel] = useState(false);
  const [editAddressDeatils, setEditAddressDetails] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [addressError, setAddressError] = useState({
    addressLine1: "",
    addressName: "",
    addressName: "",
    city: "",
    country: "",
    countryCode: "",
  });
  const [contactError, setContactError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    error: false
  });
  const [siteError, setSiteError] = useState({
    SupplierSite: "",
    SupplierAddressName: "",
    error: false
  });
  const [formState, setFormState] = useState({
    contact_values: {},
    contact_details: [],
    Address_values: {},
    Address_details: [],
    Site_values: {},
    Site_details: []
  })
  const vendordata = useSelector(state => state.VendorRegister.generalinfo);
  const supplierTypes = useSelector(state => state.VendorRegister.supplierList);
  const organizationTypes = useSelector(state => state.VendorRegister.organizationList);
  const saved_contacts = useSelector(state => state.VendorRegister.contacts?.saved_contacts) || [];
  const saved_address = useSelector(state => state.VendorRegister.address?.saved_address) || [];
  const contact = useSelector(state => state.VendorRegister.contacts?.values) || {};
  const address = useSelector(state => state.VendorRegister.address?.values) || {};
  const saved_site = useSelector(state => state.VendorRegister.site?.saved_site) || [];
  const site = useSelector(state => state.VendorRegister.site?.values) || {};
  const countryList = useSelector(state => state.VendorRegister.countryList);
  const getCities = useSelector(state => state.VendorRegister.city);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountry())
    dispatch(GetSupplierType())
    dispatch(GetOrganizationType())
    dispatch(GetAttachmentType())
  }, [])

  useEffect(() => {
    if (address?.country === "Saudi Arabia") {
      dispatch(getCity("SA"))
      // let countryCode = countryList.find((list) => list.TerritoryShortName == address?.country).TerritoryCode;
      // let name = "countryCode"
      // dispatch(VendorProfileAddress(name, countryCode))
    }
  }, [address?.country]);

  function checkInput(value) {
    if (value.includes('<script>')) {
      return false;
    }
    return true;
  }

  const handleChange = (event) => {
    event.persist();
    const { name, value } = event.target
    if (checkInput(value)) {

      if (name == 'SupplierTypeCode') {
        let values = supplierTypes.find((list) => list.SupplierTypeCode == value).SupplierType;
        dispatch(VendorProfile('SupplierType', values))
      }
      if (name == 'taxRegistratioCountryCode') {
        let country = countryList.find((list) => list.TerritoryCode == value).TerritoryShortName;
        dispatch(VendorProfile('taxRegistratioCountry', country))
      }
      if (name == 'type_of_organization_code') {
        let values = organizationTypes.find((list) => list.organizationTypeCode == value).organizationType;
        dispatch(VendorProfile('type_of_organization', values))
      }
      dispatch(VendorProfile(name, value))
    } else {
      return;
    }
  };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const contacthandleChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (checkInput(value)) {
      dispatch(VendorProfileContacts(name, value))
    }
    else {
      return;
    }
  };

  const addresshandleChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (checkInput(value)) {
      dispatch(VendorProfileContacts(name, value))

      if (event.target.name == "countryCode") {
        let selectedCountry = countryList.find((list) => list.TerritoryCode == event.target.value);
        let name = "country"
        let value = selectedCountry.TerritoryShortName
        dispatch(VendorProfileAddress(name, value))
        dispatch(getCity(event.target.value))
      }
      if (event.target.name == "addressName") {
        let name = "SupplierAddressName"
        dispatch(VendorProfileSites(name, value))
      }
      if (name == "cityCode") {
        let values = getCities.find((list) => list.cityCode == value).name;
        dispatch(VendorProfileAddress('city', values))
      }
      dispatch(VendorProfileAddress(name, value))
    }
    else {
      return;
    }
  };

  const sitehandleChange = (event) => {
    event.persist();
    const { name, value } = event.target
    dispatch(VendorProfileSites(name, value))
  };

  const handleOnclick = () => {
    setEditIndex(null)
    setSelectedFileModel(true)

  };

  const handleAddressclick = () => {
    if (address?.country === "Saudi Arabia") {
      dispatch(getCity("SA"))
    }
    setEditIndex(null)
    setSelectedAddresseModel(true)
  };

  const handleSiteclick = () => {
    if (saved_address.length == 0) {
      msgAlert("Please add address details first")
    }
    else {
      setSelectedSiteModel(true)
    }
  };
  const SubmitContactDetails = () => {
    let { firstName, lastName, email } = contact;

    const Check = require("is-null-empty-or-undefined").Check;
    var contact_error = false;
    let v_firstname;
    let v_lastname;
    let v_email;


    if (!Check(email)) {
      if (verifyEmail(email)) {
        v_email = "success"
      } else {
        v_email = "error";
        contact_error = true;
      }
    } else {
      v_email = "error";
      contact_error = true;
    }

    if (!Check(firstName)) {
      v_firstname = "success";
    } else {
      v_firstname = "error";
      contact_error = true;
    }

    if (!Check(lastName)) {
      v_lastname = "success";
    } else {
      v_lastname = "error";
      contact_error = true;
    }

    if (contact_error) {
      setContactError({
        firstName: v_firstname,
        lastName: v_lastname,
        email: v_email,
        error: true
      })
      setSelectedFileModel(true);
    }
    else {
      dispatch(VendorContactsSubmit(editIndex))
      setContactError({
        firstName: "",
        lastName: "",
        email: "",
        error: false
      })
      setSelectedFileModel(false);
      setEditIndex(null)
    }
  };

  const SubmitAddressDetails = () => {
    let { addressLine1,
      addressName,
      city,
      country,
      countryCode } = address;

    const Check = require("is-null-empty-or-undefined").Check;
    var address_error = false;
    let v_addressLine1;
    let v_addressName;
    let v_city;
    let v_country;
    let v_countryCode;


    if (!Check(addressLine1)) {
      v_addressLine1 = "success";
    } else {
      v_addressLine1 = "error";
      address_error = true;
    }

    if (!Check(addressName)) {
      v_addressName = "success";
    } else {
      v_addressName = "error";
      address_error = true;
    }

    if (!Check(city)) {
      v_city = "success";
    } else {
      v_city = "error";
      address_error = true;
    }

    if (!Check(country)) {
      v_country = "success";
    } else {
      v_country = "error";
      address_error = true;
    }

    if (address_error) {
      setAddressError({
        addressLine1: v_addressLine1,
        addressName: v_addressName,
        addressName: v_addressName,
        city: v_city,
        country: v_country,
        countryCode: v_countryCode,
      })
      setSelectedAddresseModel(true);
    }
    else {
      dispatch(VendorAddressSubmit(editIndex))
      dispatch(VendorSitesSubmit(editIndex))
      setAddressError({
        addressLine1: "",
        addressName: "",
        addressName: "",
        city: "",
        country: "",
        countryCode: "",
      })
      setSelectedAddresseModel(false);
      setEditIndex(null)
    }
  };

  const editAddressDetails = async (row, index) => {

    await dispatch(getCity(row?.countryCode))
    setEditIndex(index)
    dispatch(VendorProfileAddressEdit(row, index))
    setSelectedAddresseModel(true);
  };

  const editContactDetails = (row, index) => {

    setEditIndex(index)
    dispatch(VendorProfileContactEdit(row, index))
    setSelectedFileModel(true);
  };

  const SubmitSiteDetails = () => {

    let { SupplierSite, SupplierAddressName } = site;

    const Check = require("is-null-empty-or-undefined").Check;
    var site_error = false;
    let v_SupplierSite;
    let v_SupplierAddressName;

    if (!Check(SupplierSite)) {
      v_SupplierSite = "success";
    } else {
      v_SupplierSite = "error";
      site_error = true;
    }

    if (!Check(SupplierAddressName)) {
      v_SupplierAddressName = "success";
    } else {
      v_SupplierAddressName = "error";
      site_error = true;
    }

    if (site_error) {
      setSiteError({
        SupplierSite: v_SupplierSite,
        SupplierAddressName: v_SupplierAddressName,
        error: false
      })
      setSelectedSiteModel(true);
    }
    else if (saved_site?.find((list) => list.SupplierAddressName == site.SupplierAddressName)) {
      setSelectedSiteModel(false);
      msgAlert("This address Name already added")
    }
    else {
      dispatch(VendorSitesSubmit())
      setSiteError({
        SupplierSite: "",
        SupplierAddressName: "",
      })
      setSelectedSiteModel(false);
    }
  };

  const delDetails = (fileIndex) => {
    dispatch(VendorContactDelete(fileIndex))
  };

  const delAddressDetails = (fileIndex) => {
    dispatch(VendorAddressDelete(fileIndex))
    dispatch(VendorSitesDelete(fileIndex))

  };
  const delSiteDetails = (fileIndex) => {
    dispatch(VendorSitesDelete(fileIndex))
  };

  return (
    <Animated
      animationIn="bounceInRight"
      animationOut="bounceOutLeft"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >

      <GridContainer justify="center" md={12} xs={12} sm={12}>
        {/* <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            label="Business Relationship"
            id="BusinessRelationshipCode"
            name="BusinessRelationshipCode"
            variant="outlined"
            select
            disabled
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.BusinessRelationshipCode || ""}
          >
            <MenuItem selected disabled value="">Select Business Relationship </MenuItem>
            <MenuItem selected value="Spend Authorized">Spend Authorized</MenuItem>

          </TextField>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            label="Tax Organization Type"
            id="TaxOrganizationTypeCode"
            name="TaxOrganizationTypeCode"
            variant="outlined"
            placeholder="Tax Organization Type ..."
            disabled
            type="text"
            onChange={(event) => {
              handleChange(event);
            }}
            value={"Corporation"}
          />
        </GridItem> */}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            MenuProps={{
              style: {
                maxHeight: 300,
              },
            }}
            label="Supplier Type"
            id="SupplierTypeCode"
            name="SupplierTypeCode"
            variant="outlined"
            select
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.SupplierTypeCode || ""}
          >
            <MenuItem disabled value="">Select Supplier Type</MenuItem>
            {supplierTypes?.map((list) => (
              <MenuItem value={list.SupplierTypeCode}>{list.SupplierType}</MenuItem>
            )) || []}
          </TextField>
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            required
            InputLabelProps={{
              style: { color: 'black' },
              classes: {
                asterisk: classes.asterisk
              }
            }}
            error={validationError.taxPayerIdcrNum === "error"}
            helperText={
              validationError.taxPayerIdcrNum === "error"
                ? "Valid CR Number is required"
                : null
            }
            label="CR Number"
            id="taxPayerIdcrNum"
            name="taxPayerIdcrNum"
            variant="outlined"
            placeholder="CR Number"
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.taxPayerIdcrNum || ""}

          />
        </GridItem>
        {vendordata?.SupplierTypeCode === "Others" &&
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginBottom: "10px" }}
          >
            <TextField
              fullWidth={true}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              label="Specify Supplier Type"
              id="SupplierTypeCode"
              name="other_SupplierType"
              variant="outlined"
              disabled={edit}
              onChange={(event) => {
                handleChange(event);
              }}
              value={vendordata?.other_SupplierType || ""}
            />
          </GridItem>
        }
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            required
            InputLabelProps={{
              style: { color: 'black' },
              classes: {
                asterisk: classes.asterisk
              }
            }}
            error={validationError.taxRegistrationNum === "error"}
            helperText={
              validationError.taxRegistrationNum === "error"
                ? "Valid Tax Registration Number is required"
                : null
            }
            label="Tax Registration Number"
            id="taxRegistrationNum"
            name="taxRegistrationNum"
            variant="outlined"
            placeholder="Tax Registration Number"
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.taxRegistrationNum || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={6}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="Tax Registration Country"
            id="taxRegistratioCountryCode"
            name="taxRegistratioCountryCode"
            variant="outlined"
            placeholder="Tax Registration Country"
            select
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.taxRegistratioCountryCode || ""}
          >
            {countryList?.map((list) => (
              <MenuItem value={list.TerritoryCode}>{list.TerritoryShortName}</MenuItem>
            )) || []}
          </TextField>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="General Information"
            id="general_information"
            name="generalInformation"
            variant="outlined"
            multiline
            placeholder="General Organization Information"
            minRows={5}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.generalInformation || ""}

          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            required
            InputLabelProps={{
              style: { color: 'black' },
              classes: {
                asterisk: classes.asterisk
              }
            }}
            error={validationError.type_of_organization === "error"}
            helperText={
              validationError.type_of_organization === "error"
                ? "Nature of organization is required"
                : null
            }
            label="Nature of Organization"
            id="type_of_organization_code"
            name="type_of_organization_code"
            variant="outlined"
            select
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.type_of_organization_code || ""}

          >
            <MenuItem disabled selected>Select Nature of Your Organization</MenuItem>
            {organizationTypes?.map((list) => (
              <MenuItem selected value={list.organizationTypeCode}>{list.organizationType}</MenuItem>
            )) || []}
          </TextField>
        </GridItem>
        {vendordata?.type_of_organization == "Others" ?
          <GridItem
            xs={12}
            sm={12}
            md={6}
            lg={12}
            style={{ marginBottom: "10px" }}
          >
            <TextField
              fullWidth={true}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              label="Specify Nature of Organization"
              id="other_type"
              name="other_type"
              variant="outlined"
              type="text"
              disabled={edit}
              onChange={(event) => {
                handleChange(event);
              }}
              value={vendordata?.other_type || ""}

            />
          </GridItem> : ""}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="Products and Services"
            id="product_and_services"
            name="productAndServices"
            variant="outlined"
            multiline
            placeholder="Products and Services Providing"
            minRows={5}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.productAndServices || ""}

          />
        </GridItem>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <h4 className={classes.cardTitleText}>Contact(s)</h4>
            </CardIcon>
            <Typography variant="body2" component="h2" style={{ float: "right", color: "red" }}>Minimum one contact required * </Typography>
          </CardHeader>
          <CardBody>
            {!saved_contacts?.length == 0 ?
              <GridItem xs={12} sm={3} md={2} lg={12}>
                <Box boxShadow={3} style={{ padding: 5, background: 'rgb(245 245 245)' }}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell align="left">Last Name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {saved_contacts?.map((row, index) => (
                        <TableRow>
                          <TableCell>{row.firstName}</TableCell>
                          <TableCell>{row.lastName}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>
                            {!edit ?
                              <>
                                <Tooltip title="Edit Address" aria-label="Edit">
                                  <Edit style={{ cursor: "pointer", marginRight: '10px' }} fontSize="small" onClick={() => editContactDetails(row, index)} />
                                </Tooltip>
                                <Tooltip title="Delete Contact" aria-label="delete">
                                  <DeleteIcon fontSize="small" onClick={() => delDetails(index)} />
                                </Tooltip>
                              </> : ""}
                          </TableCell>
                        </TableRow>
                      )) || []}
                    </TableBody>
                  </Table>
                </Box>
              </GridItem>
              : ""}
            <GridItem key={"addAttachment"} xs={12} sm={3} md={2} lg={12}>
              {!edit ?
                <Card
                  onClick={handleOnclick}
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                    background: "#f5f5f5",
                    height: "85px"
                  }}
                >
                  <AddCircleOutlineIcon
                    style={{
                      alignSelf: "center",
                      width: 50,
                      height: 65,
                      marginBottom: 10,
                    }}
                    fontSize="large"
                  />
                  {/* <Typography variant="body1" component="h6">
                  Add Contacts
                </Typography> */}
                </Card> : ""}
            </GridItem>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <h4 className={classes.cardTitleText}>Addresse(s)</h4>
            </CardIcon>
            <Typography variant="body2" component="h2" style={{ float: "right", color: "red" }}>Minimum one address required * </Typography>
          </CardHeader>
          <CardBody >
            {!saved_address?.length == 0 ?
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Box boxShadow={3} style={{ padding: 5, background: 'rgb(245 245 245)' }}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Address Title</TableCell>
                        <TableCell align="left">Country</TableCell>
                        <TableCell align="left">City</TableCell>
                        <TableCell align="left">Address Purpose</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {saved_address?.map((row, index) => (
                        <>
                          <TableRow>
                            <TableCell>{row.addressName}</TableCell>
                            <TableCell>{row.country}</TableCell>
                            <TableCell>{row.city}</TableCell>
                            <TableCell>{row.addressPurpose}</TableCell>
                            <TableCell>
                              {!edit ?
                                <>
                                  <Tooltip title="Edit Address" aria-label="Edit">
                                    <Edit style={{ cursor: "pointer", marginRight: '10px' }} fontSize="small" onClick={() => editAddressDetails(row, index)} />
                                  </Tooltip>
                                  <Tooltip title="Delete Address" aria-label="delete">
                                    <DeleteIcon style={{ cursor: "pointer" }} fontSize="small" onClick={() => delAddressDetails(index)} />
                                  </Tooltip>
                                </>
                                : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Typography component="h2" varient="h4" >Address</Typography>
                              {row.addressLine1}
                            </TableCell>
                          </TableRow>
                        </>
                      )) || []}
                    </TableBody>
                  </Table>
                </Box>
              </GridItem>
              : ""}
            <GridItem key={"addAttachment"} xs={12} sm={3} md={2} lg={12}>
              {!edit ?
                <Card
                  onClick={handleAddressclick}
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                    background: "#f5f5f5",
                    height: "85px"
                  }}
                >
                  <AddCircleOutlineIcon
                    style={{
                      alignSelf: "center",
                      width: 50,
                      height: 65,
                      marginBottom: 10,
                    }}
                    fontSize="large"
                  />
                </Card> : ""}
            </GridItem>
          </CardBody>
        </Card>
        {/* <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <h4 className={classes.cardTitleText}>Site(s)</h4>
            </CardIcon>
            <Typography variant="body2" component="h2" style={{ float: "right", color: "red" }}>Minimum one site required * </Typography>
          </CardHeader>
          <CardBody >
            {!saved_site?.length == 0 ?
              <GridItem xs={12} sm={3} md={2} lg={12}>
                <Box boxShadow={3} style={{ padding: 5, background: 'rgb(245 245 245)' }}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>

                        <TableCell align="left">Address Title</TableCell>
                        <TableCell align="left">Site Name</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {saved_site?.map((row, index) => (
                        <TableRow>
                          <TableCell>{row.SupplierAddressName}</TableCell>
                          <TableCell>{row.SupplierSite}</TableCell>
                          <TableCell>
                            {!edit ?
                              <Tooltip title="Delete Site" aria-label="delete">
                                <DeleteIcon fontSize="small" onClick={() => delSiteDetails(index)} />
                              </Tooltip> : ""}
                          </TableCell>
                        </TableRow>
                      )) || []}
                    </TableBody>
                  </Table>
                </Box>
              </GridItem>
              : ""}
            <GridItem key={"addAttachment"} xs={12} sm={3} md={2} lg={12}>
              {!edit ?
                <Card
                  onClick={handleSiteclick}
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                    background: "#f5f5f5",
                    height: "85px"

                  }}
                >
                  <AddCircleOutlineIcon
                    style={{
                      alignSelf: "center",
                      width: 50,
                      height: 65,
                      marginBottom: 10,
                    }}
                    fontSize="large"
                  />
                </Card> : ""}
            </GridItem>
          </CardBody>
        </Card> */}
        {selectedFileModel ? (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={selectedFileModel}
            keepMounted
            onClose={() => setSelectedFileModel(false)}
            aria-labelledby="selected-modal-slide-title"
            aria-describedby="selected-modal-slide-description"
          >
            <DialogContent
              id="selected-Select"
              className={classes.modalBody}
            >
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info" style={{ marginBottom: 15 }}>
                    <h4 className={classes.cardTitleText}>Contact Details</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center" md={12} xs={12} sm={12}>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }

                        }}
                        error={contactError.firstName === "error"}
                        helperText={
                          contactError.firstName === "error"
                            ? "Valid Name is required"
                            : null
                        }
                        label="First Name"
                        id="firstName"
                        name="firstName"
                        variant="outlined"
                        placeholder="First Name"
                        type="text"
                        disabled={edit}
                        onChange={(event) => {
                          contacthandleChange(event);
                        }}
                        value={contact.firstName || ""}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={contactError.lastName === "error"}
                        helperText={
                          contactError.lastName === "error"
                            ? "Valid Name is required"
                            : null
                        }
                        label="Last Name"
                        id="lastName"
                        name="lastName"
                        variant="outlined"
                        placeholder="Last Name"
                        type="text"
                        disabled={edit}
                        onChange={(event) => {
                          contacthandleChange(event);
                        }}
                        value={contact.lastName || ""}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={contactError.email === "error"}
                        helperText={
                          contactError.email === "error"
                            ? "Valid Email is required"
                            : null
                        }
                        label="Email"
                        id="email"
                        name="email"
                        variant="outlined"
                        placeholder="Email"
                        type="text"
                        disabled={edit}
                        onChange={(event) => {
                          contacthandleChange(event);
                        }}
                        value={contact.email || ""}
                      />
                    </GridItem>
                    <GridItem
                      style={{
                        display: "flex",
                        alignItems: "right",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Button
                        color="danger"
                        size="small"
                        onClick={() => { setSelectedFileModel(false); setEditIndex(null); }}
                        round
                      >
                        Close
                      </Button>
                      <Button
                        color="info"
                        size="small"
                        onClick={() => SubmitContactDetails()}
                        round
                      >
                        Save
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}
        {selectedAddresseModel ? (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={selectedAddresseModel}
            keepMounted
            onClose={() => setSelectedAddresseModel(false)}
            aria-labelledby="selected-modal-slide-title"
            aria-describedby="selected-modal-slide-description"
          >
            <DialogContent
              id="selected-Select"
              className={classes.modalBody}
            >
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info" style={{ marginBottom: 15 }}>
                    <h4 className={classes.cardTitleText}>Address Details</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center" md={12} xs={12} sm={12}>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={addressError.addressName === "error"}
                        helperText={
                          addressError.addressName === "error"
                            ? "Valid Address Title is required"
                            : null
                        }
                        label="Address Title"
                        id="addressName"
                        name="addressName"
                        variant="outlined"
                        placeholder="Address Title"
                        type="text"
                        disabled={edit}
                        onChange={(event) => {
                          addresshandleChange(event);
                        }}
                        value={address?.addressName || ""}

                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={addressError.country === "error"}
                        helperText={
                          addressError.country === "error"
                            ? "Country is required"
                            : null
                        }
                        label="Country"
                        id="countryCode"
                        name="countryCode"
                        variant="outlined"
                        select
                        onChange={(event, TerritoryCode) => {
                          addresshandleChange(event);
                        }}
                        value={address?.countryCode}
                      >
                        <MenuItem selected disabled value="">Select Country</MenuItem>
                        {countryList?.map((list) => (
                          <MenuItem selected value={list.TerritoryCode}>{list.TerritoryShortName}</MenuItem>
                        )) || []}

                      </TextField>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={addressError.city === "error"}
                        helperText={
                          addressError.city === "error"
                            ? "Valid city is required"
                            : null
                        }
                        label="City"
                        id="cityCode"
                        name="cityCode"
                        variant="outlined"
                        placeholder="City"
                        select
                        disabled={edit}
                        onChange={(event) => {
                          addresshandleChange(event);
                        }}

                        value={address?.cityCode || ""}
                      >
                        <MenuItem selected disabled value="">Select City</MenuItem>
                        {getCities?.map((list) => (
                          <MenuItem selected value={list.cityCode}>{list.name}</MenuItem>
                        )) || []}
                      </TextField>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={8}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={addressError.addressLine1 === "error"}
                        helperText={
                          addressError.addressLine1 === "error"
                            ? "Valid Address is required"
                            : null
                        }
                        label="Address"
                        id="addressLine1"
                        name="addressLine1"
                        variant="outlined"
                        placeholder="Address"
                        type="text"
                        disabled={edit}
                        onChange={(event) => {
                          addresshandleChange(event);
                        }}
                        value={address?.addressLine1 || ""}

                      />



                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        error={addressError.addressPurpose === "error"}
                        helperText={
                          addressError.addressPurpose === "error"
                            ? "Valid Purpose of Address is required"
                            : null
                        }
                        label="Purpose of Address"
                        id="addressPurpose"
                        name="addressPurpose"
                        variant="outlined"
                        placeholder="Purpose of Address"
                        type="text"
                        disabled={edit}
                        onChange={(event) => {
                          addresshandleChange(event);
                        }}
                        value={address?.addressPurpose || ""}

                      />
                    </GridItem>
                    {/* <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <FormControlLabel
                        control={<Checkbox
                          name="ordering" color="info" />}
                        label="Ordering"
                        name="ordering"
                        disabled
                        checked={true}
                      // value={vendordata?.ordering || ""}

                      />

                    </GridItem> */}
                    {/* <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={2}
                      style={{ marginBottom: "10px" }}
                    > */}
                    {/* <FormControlLabel
                        control={<Checkbox name="remitTo" color="info" />}
                        label="Remit to"
                        name="remitTo"
                        checked={true}
                        disabled
                       value={vendordata?.remitTo || ""}

                      /> */}
                    <GridItem
                      style={{
                        display: "flex",
                        alignItems: "right",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Button
                        color="danger"
                        size="small"
                        onClick={() => { setSelectedAddresseModel(false); setEditIndex(null); dispatch(VendorProfileAddressClear()) }}
                        round
                      >
                        Close
                      </Button>
                      <Button
                        color="info"
                        size="small"
                        onClick={() => SubmitAddressDetails()}
                        round
                      >
                        Save
                      </Button>
                    </GridItem>
                    {/* </GridItem> */}
                  </GridContainer>
                </CardBody>
              </Card>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}
        {/* {selectedSiteModel ? (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={selectedSiteModel}
            keepMounted
            onClose={() => setSelectedSiteModel(false)}
            aria-labelledby="selected-modal-slide-title"
            aria-describedby="selected-modal-slide-description"
          >
            <DialogContent
              id="selected-Select"
              className={classes.modalBody}
            >
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info" style={{ marginBottom: 15 }}>
                    <h4 className={classes.cardTitleText}>Site Details</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center" md={12} md={12} xs={12} sm={12}>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        error={siteError.SupplierSite === "error"}
                        helperText={
                          siteError.SupplierSite === "error"
                            ? "Valid Site Name is required"
                            : null
                        }
                        label="Site Name *"
                        id="SupplierSite"
                        name="SupplierSite"
                        variant="outlined"
                        placeholder="Site Name"
                        type="text"
                        disabled={edit}
                        onChange={(event) => {
                          sitehandleChange(event);
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        error={siteError.SupplierAddressName === "error"}
                        helperText={
                          siteError.SupplierAddressName === "error"
                            ? "Address Name is required"
                            : null
                        }
                        label="Address (Linked to Site) *"
                        id="SupplierAddressName"
                        name="SupplierAddressName"
                        variant="outlined"
                        select
                        onChange={(event) => {
                          sitehandleChange(event);
                        }}
                      // value={vendordata?.country || ""}
                      >
                        <MenuItem selected disabled value="">Select Linked Address</MenuItem>

                        {saved_address?.map((list) => (
                          <MenuItem selected value={list.addressName}>{list.addressName}</MenuItem>
                        )) || []}

                      </TextField>
                    </GridItem> */}


        {/* <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      style={{ marginBottom: "10px" }}
                    >
                      <TextField
                        fullWidth={true}
                        label="Procurement BU"
                        id="procurementBU"
                        name="procurementBU"
                        variant="outlined"
                        placeholder="Procurement BU ..."
                        type="text"
                        disabled
                        onChange={(event) => {
                          sitehandleChange(event);
                        }}
                        value={"SALIC BU"}
                      />
                    </GridItem> */}
        {/* <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <FormControlLabel
                        control={<Checkbox
                          name="purchasing" color="info" />}
                        label="Purchasing"
                        name="purchasing"
                        checked={true}
                        disabled
                      // value={vendordata?.purchasing || ""}

                      />
                    </GridItem> */}
        {/* <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <FormControlLabel
                        control={<Checkbox name="pay" color="info" />}
                        label="Pay"
                        name="pay"
                        checked={true}
                        disabled
                        value={vendordata?.pay || ""}

                      />

                    </GridItem> */}
        {/* <GridItem
                      style={{
                        display: "flex",
                        alignItems: "right",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Button
                        color="danger"
                        size="small"
                        onClick={() => setSelectedSiteModel(false)}
                        round
                      >
                        Close
                      </Button>
                      <Button
                        color="info"
                        size="small"
                        onClick={() => SubmitSiteDetails()}
                        round
                      >
                        Save
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )} */}
      </GridContainer>
    </Animated>
  );
}


