import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import { Route, useParams, useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import { errorAlert, successAlert } from "Functions";
import login from "assets/img/login_salic1.jpg";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(styles);

export default function NewVendorPassword(props) {
  const history = useHistory();
  const [passwordError, setPasswordError] = useState({
    password: "",
    confirmpassword: "",
  });
  const [password, setPassword] = useState({
    password: "",
    confirmpassword: "",
  });
  const [apiErrorMessage, setAPIErrorMessage] = useState("");
  const [logging, setlogging] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [mongoId, setmongoId] = useState("");
  const [loginName, setloginname] = useState("");
  const [decoded, setDecoded] = useState(null);
  const [strength, setStrength] = useState("");
  const [repatchToken, setRepatchToken] = useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  useEffect(() => {
    setmongoId(getParameterByName("id"));
    // if (userToken) {
    //   const userDetails = jwt.decode(userToken)
    //   setDecoded(userDetails)
    // }
  }, [mongoId]);

  const handlePassword = (event) => {
    event.persist();
    setPassword((password) => ({
      ...password,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name == "password") {
      if (event.target.value.length < 4) {
        setStrength("Weak");
        setAPIErrorMessage();
      } else if (
        event.target.value.length > 5 &&
        event.target.value.length < 8
      ) {
        setStrength("Moderate");
      } else if (
        event.target.value.length > 8 &&
        event.target.value.length < 12
      ) {
        setStrength("Strong");
      }
    }
  };
  const verifyPassword = (value) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(value)) {
      return true;
    }
    return false;
  };
  // if (req.body.confirmPassword != req.body.password) {
  //   return res.status(400).send("Password not matched !");
  // }
  // if (req.body.password.length < 8) {
  //   return res.status(400).send("Password at least 8 characters");
  // }
  // var strongRegex = new RegExp(
  //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  // );
  // if (strongRegex.test(req.body.password)) {
  //   console.log("ok valid password");
  // } else {
  //   return res
  //     .status(400)
  //     .send(
  //       "Password must contain Upper case ,Lower case ,Numeric value and Special Character"
  //     );
  // }
  const resetPassword = async () => {
    const Check = require("is-null-empty-or-undefined").Check;
    var password_error = false;
    let v_password;
    let v_confirmpassword;

    if (!Check(password.password)) {
      if (verifyPassword(password.password)) {
        v_password = "success";
      } else {
        setAPIErrorMessage(
          "Note: Password must contain atleast one upper case ,lower case ,numeric value and special character"
        );
        v_password = "success";
        password_error = true;
      }
    } else {
      v_password = "error";
      password_error = true;
    }

    if (!Check(password.confirmpassword)) {
      v_confirmpassword = "success";
    } else {
      v_confirmpassword = "error";
      password_error = true;
    }

    if (password.confirmpassword != password.password) {
      setAPIErrorMessage("Password not matched !");
    }
    if (password.password.length < 8) {
      setAPIErrorMessage("Atleast 8 characters");
    }

    if (password_error) {
      setStrength("");
      setPasswordError({
        password: v_password,
        confirmpassword: v_confirmpassword,
      });
    } else {
      setPasswordError({
        password: "",
        confirmpassword: "",
      });
      if (!repatchToken) {
        setAPIErrorMessage("RECAPTCHA REQUIRED!");
        return;
      }
      setAPIErrorMessage("");
      setlogging(true);
      await axios({
        method: "post", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/resetPassword`,
        data: {
          id: mongoId,
          password: password.password,
          confirmPassword: password.confirmpassword,
          recaptchaToken: repatchToken,
        },
      })
        .then((res) => {
          setlogging(false);
          setAPIErrorMessage("");
          successAlert("Password updated Successfully");
          history.push("/supplier/login");
        })
        .catch((err) => {
          let error = err?.response?.data;
          console.log(err);
          setlogging(false);
          //   errorAlert(error)
          setAPIErrorMessage(error);
        });
    }
  };
  return (
    <div
      style={{
        padding: "120px 0",
        position: "relative",
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        display: "flex!important",
        margin: "0",
        border: "0",
        backgroundImage: ` url(${login})`,
        // color: whiteColor,
        alignItems: "center",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
      }}
    >
      <div
        // style={{
        //   position: 'fixed',
        //   left: '15%',
        //   right: '0%',
        //   top: '30%',
        //   margin: '0px',
        //   padding: '0px'
        // }}
        className={classes.container}
      >
        <Animated
          animationIn="bounceInUp"
          animationOut="bounceOutUp"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={true}
        >
          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                    >
                      <h4 className={classes.cardTitle}>New Password</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        error={passwordError.password === "error"}
                        helperText={
                          passwordError.password === "error"
                            ? "Password is required"
                            : null
                        }
                        labelText="Password"
                        id="password"
                        name="password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "password",
                          placeholder: "Password must be eight characters",
                          onChange: (event) => {
                            handlePassword(event);
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          type: "password",
                          autoComplete: "off",
                        }}
                      />
                      <CustomInput
                        error={passwordError.confirmpassword === "error"}
                        helperText={
                          passwordError.confirmpassword === "error"
                            ? "Confirm password is required"
                            : null
                        }
                        labelText="Confirm Password"
                        id="confirmpassword"
                        name="confirmpassword"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "confirmpassword",
                          onChange: (event) => {
                            handlePassword(event);
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          type: "password",
                          autoComplete: "off",
                        }}
                      />
                      <h6
                        style={
                          strength == "Weak"
                            ? { color: "red" }
                            : strength == "Moderate"
                            ? { color: "blue" }
                            : strength == "Strong"
                            ? { color: "green" }
                            : {}
                        }
                      >
                        {strength}
                      </h6>
                      <div
                        style={
                          strength == "Weak"
                            ? {
                                width: "33.3%",
                                height: "2px",
                                background: "red",
                              }
                            : strength == "Moderate"
                            ? {
                                width: "66.6%",
                                height: "2px",
                                background: "blue",
                              }
                            : strength == "Strong"
                            ? {
                                width: "99.9%",
                                height: "2px",
                                background: "green",
                              }
                            : {}
                        }
                      ></div>
                      {/* <div>
                        <ul>
                        <li style={strength == "Weak" ? {color:"red"}:{color:"green"}}>Password at least 8 characters</li>
                        <li>Password at least 8 characters</li>
                        <li>Password at least 8 characters</li>
                        </ul>
                      </div> */}
                    </CardBody>
                    <CardFooter
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_KEY}
                        onChange={(value) => setRepatchToken(value)}
                      />
                      <span style={{ color: "red" }}>{apiErrorMessage}</span>
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={resetPassword}
                      >
                        {logging ? (
                          <CircularProgress style={{ color: "#fff" }} />
                        ) : (
                          "Reset Password"
                        )}
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        <Link to={"/supplier/login"}>login</Link>
                      </div>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </Animated>
      </div>
    </div>
  );
}
