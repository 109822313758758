import React from 'react'
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  TablePagination,
  Backdrop,
  makeStyles,
  LinearProgress
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Refresh from '@material-ui/icons/Refresh'
import axios from 'axios'
import { Link } from 'react-router-dom'
import PurchaseOrderList from 'actions/PurchaseOrderListAction'
import { useDispatch, useSelector } from 'react-redux'
import jwt from 'jsonwebtoken'
import { formatDateTime } from 'Functions'
import { POFilter } from 'actions/PurchaseOrderListAction'
import { successAlert } from 'Functions'
import { SettingsPowerOutlined } from '@material-ui/icons'
import { addZeroes } from 'views/LDocs/Functions/Functions'

const useStyles = makeStyles(theme => ({
  root: {
    // textAlign: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))
function PaginationsList ({ isFilterEnable, newdata,isStatus }) {
  const classes = useStyles()
  const [posts, setPosts] = useState([])
  const [page, setPage] = useState(0)
  const [RowsPerPage, setRowsPerPage] = useState(10)
  const [decoded, setDecoded] = React.useState(null)
  const [isloading, setIsloading] = useState(false)
  const [pOId, setPOId] = useState()
  const dispatch = useDispatch()
  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  let userDetail = jwt.decode(Token)

  React.useEffect(() => {
    let dispatch_payload = {
      page,
      rowPerPage: RowsPerPage,
      Token: Token,
      newdata: newdata
    }
    dispatch(PurchaseOrderList(dispatch_payload))
    userDetail = jwt.decode(Token)
    setDecoded(userDetail)
  }, [])

  useEffect(() => {
    if (isFilterEnable == true || isStatus) {
      let dispatch_payload = {
        page,
        rowPerPage: RowsPerPage,
        Token: Token,
        newdata: newdata
      }
      dispatch(POFilter(dispatch_payload))
    } else {
      let dispatch_payload = {
        page,
        rowPerPage: RowsPerPage,
        Token: Token,
        newdata: newdata
      }
      dispatch(PurchaseOrderList(dispatch_payload))
    }
  }, [page])
  const productdata = useSelector(state => state.purchaseOrder.PoList)
  const loading = useSelector(state => state.purchaseOrder)
  const { loading: loadingSave } = loading

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = async event => {
    setRowsPerPage(parseInt(event.target.value), 10)
    let dispatch_payload = {
      page,
      rowPerPage: RowsPerPage,
      Token: Token,
      newdata: newdata
    }
    dispatch(PurchaseOrderList(dispatch_payload))
  }
  const handleFilterChangePage = (e, newPage) => {
    setPage(newPage)
  }
  const handleFilterChangeRowsPerPage = async event => {
    setRowsPerPage(parseInt(event.target.value), 10)
    let dispatch_payload = {
      page,
      rowPerPage: event.target.value,
      Token: Token,
      newdata: newdata
    }
    dispatch(POFilter(dispatch_payload))
  }
  const syncSinglePo = async POHeaderId => {
    setPOId(POHeaderId)
    setIsloading(true)
    await axios
      .get(
        `${process.env.REACT_APP_LDOCS_API_URL}/invoice/syncSinglePO/${POHeaderId}`,
        {
          headers: {
            cooljwt: Token
          }
        }
      )
      .then(res => {
        setIsloading(false)
        successAlert('PO synced successfully')

        if (isFilterEnable == false || isStatus) {
          let dispatch_payload = {
            page,
            rowPerPage: RowsPerPage,
            Token: Token,
            newdata: null
          }
          dispatch(POFilter(dispatch_payload))
        } else {
          let dispatch_payload = {
            page,
            rowPerPage: RowsPerPage,
            Token: Token,
            newdata: newdata
          }
          dispatch(POFilter(dispatch_payload))
        }
      })
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>PO Number</TableCell>
              <TableCell>Procurement BU</TableCell>
              <TableCell>Supplier </TableCell>
              <TableCell>Payment Terms</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Last Synched</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingSave ? (
              <CircularProgress
                style={{
                  marginTop: '50px',
                  marginLeft: '400px',
                  width: '100px',
                  height: '100px'
                }}
              />
            ) : (
              productdata?.PurchaseOrders?.map((row, index) => (
                <>
                  {isloading && pOId == row.POHeaderId ? (
                    <TableRow>
                      <TableCell colSpan={8}>
                        {' '}
                        <LinearProgress style={{ width: '100%' }} />
                      </TableCell>{' '}
                    </TableRow>
                  ) : (
                    ''
                  )}
                  <TableRow key={index}>
                    <TableCell> {row.OrderNumber}</TableCell>
                    <TableCell>{row.ProcurementBU}</TableCell>
                    <TableCell>{row.Supplier}</TableCell>
                    <TableCell>{row.PaymentTerms}</TableCell>
                    <TableCell>{row.Status}</TableCell>
                    <TableCell>{addZeroes(row.Total)}</TableCell>
                    <TableCell>{formatDateTime(row?.LastSyncDate)}</TableCell>
                    <TableCell>
                      {userDetail.role.isAdmin ? (
                        <>
                          <Tooltip
                            title='View PO Details'
                            aria-label='viewDetails'
                          >
                            <Link
                              to={`/admin/purchase_order/${row.POHeaderId}`}
                            >
                              <VisibilityIcon fontSize='small' />
                            </Link>
                          </Tooltip>
                          <Tooltip title='Sync Single PO' aria-label='syncPo'>
                            <Refresh
                              fontSize='small'
                              color='primary'
                              onClick={() => syncSinglePo(row.POHeaderId)}
                              style={{ cursor: 'pointer', marginLeft: 10 }}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            title='View PO Details'
                            aria-label='viewDetails'
                          >
                            <Link
                              to={`/default/purchase_order/${row.POHeaderId}`}
                            >
                              <VisibilityIcon fontSize='small' />
                            </Link>
                          </Tooltip>
                          <Tooltip title='Sync Single PO' aria-label='syncPo'>
                            <Refresh
                              fontSize='small'
                              color='primary'
                              onClick={() => syncSinglePo(row.POHeaderId)}
                              style={{ cursor: 'pointer', marginLeft: 10 }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              )) || []
            )}
          </TableBody>
        </Table>
        {!isFilterEnable && !isStatus ? (
          <TablePagination
            component='div'
            count={productdata?.totalNumOfItems}
            rowsPerPageOptions={[10, 25, 40, 50]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={RowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ''
        )}
        {isFilterEnable || isStatus? (
          <TablePagination
            component='div'
            count={productdata?.totalNumOfItems}
            rowsPerPageOptions={[10, 20, 30]}
            page={page}
            onPageChange={handleFilterChangePage}
            rowsPerPage={RowsPerPage}
            onRowsPerPageChange={handleFilterChangeRowsPerPage}
          />
        ) : (
          ''
        )}
      </TableContainer>
    </>
  )
}
export default PaginationsList
