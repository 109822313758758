import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
// @material-ui/core components
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import defaultAvatar from "assets/img/placeholder.jpg";
import { isArray } from "lodash";
import { formatDateTime } from "Functions";

const sweetAlertStyle = makeStyles(styles2);
let Token = localStorage.getItem("cooljwt");
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};
const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: "relative",
    maxWidth: 360,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {
    minWidth: "10%",
  },
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "3%",
  },
  TableHead: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
}));

export default function Distribution({ distributions }) {
  const useStyles = makeStyles(styles);
  const classesList = useStyle();
  const [animateStep, setAnimateStep] = useState(true);

  let filtered_distrubtions = distributions?.map((obj) => {
    //Clearing Extra
    let new_line = obj;
    delete new_line.links;
    return new_line;
  }) || []

  React.useEffect(() => {}, []);
  return (
    <Animated
      animationIn="bounceInRight"
      animationOut="bounceOutLeft"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >
      {filtered_distrubtions.length > 0 ? (
        <GridContainer style={{ overflowX: "scroll", height: 400 }}>
          <Table className={classesList.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classesList.TableRow}>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Line</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Schedule</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Description</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Distribution</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Status</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Destination Type</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Deliver To Location</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Quantity</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>UOM Name</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Ordered</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Charge Account</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Variance Account</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Accrual Account</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Budget Date</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Funds Status</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Details</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Conversion Date</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Requester</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Requisition</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Requisition Line</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Total Tax</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Total</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ paddingBottom: 5 }}>
            {filtered_distrubtions?.map((line) => (
                <TableRow>
                  <TableCell className={classesList.TableID}>
                    {line?.LineNumber || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.ScheduleNumber || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Description || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.DistributionNumber || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Status || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.DestinationType || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.DeliverToLocationCode || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Quantity || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.UOMName || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Ordered || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.POChargeAccount || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.POVarianceAccount || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.POAccrualAccount || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.BudgetDate ? formatDateTime(line?.BudgetDate) : ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.FundsStatus || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Details || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.ConversionDate ? formatDateTime(line?.ConversionDate) : ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Requester || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Requisition || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.RequisitionLine || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.TotalTax || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Total || ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GridContainer>
       ) : (
        "No record Found."
      )} 
    </Animated>
  );
}
