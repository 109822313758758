import React, { useState, useEffect } from 'react'
// @material-ui/icons
import {
  makeStyles,
  CircularProgress,
  TextField,
  MenuItem,
  useForkRef,
  FormControlLabel,
  Checkbox,
  DialogContent,
  Typography,
  Dialog,
  IconButton,
  TableCell,
  TableHead,
  TableBody,
  Box,
  TableRow,
  Table,
  Tooltip
} from '@material-ui/core'
// @material-ui/core components

// core components
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import CardIcon from 'components/Card/CardIcon.js'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Animated } from 'react-animated-css'
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import Swal from 'sweetalert2'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  successAlert,
  errorAlert,
  msgAlert
} from 'views/LDocs/Functions/Functions'
import axios from 'axios'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import defaultAvatar from 'assets/img/placeholder.jpg'
import { useDispatch, useSelector } from 'react-redux'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { setIsTokenExpired } from 'actions'
import VendorProfile from 'actions/VendorProfile'
import { AttachmentRounded } from '@material-ui/icons'
import CardFooter from 'components/Card/CardFooter'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { submitBankDetails } from 'actions/VendorProfile'
import { getBankDetails } from 'actions/VendorProfile'
import { getBankBranches } from 'actions/VendorProfile'
import { getCurrency } from 'actions/VendorProfile'
import { VendorProfileBankDetails } from 'actions/VendorProfile'
import { VendorBankDetailsSubmit } from 'actions/VendorProfile'
import { VendorBankDetailsDelete } from 'actions/VendorProfile'
import { useParams } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit'
import { VendorProfileBankDetailsEdit } from 'actions/VendorProfile'
import { VendorProfileBankDetailsClear } from 'actions/VendorProfile'


const style = theme => ({
  asterisk: {
    color: 'red'
  }
})

const useStyles = makeStyles(style)
const sweetAlertStyle = makeStyles(styles2)

export default function Bankdetails (props) {
  const classes = useStyles()
  const [animateStep, setAnimateStep] = useState(true)
  const [selectedFileModel, setSelectedFileModel] = useState(false)
  const [show, setshow] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [chkothers, setOthers] = useState(false)
  const [branchOthers, setBranchOthers] = useState(false)
  const [country, setCountry] = useState()
  const [formState, setFormState] = useState({
    values: {},
    bank_details: []
  })
  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  const userCheck = useSelector(state => state.VendorRegister.generalinfo)
  const bankList = useSelector(state => state.VendorRegister.bankList)
  const countryList = useSelector(state => state.VendorRegister.countryList)
  const branchlist = useSelector(state => state.VendorRegister.BranchList)
  const GetCurrency = useSelector(state => state.VendorRegister.currencyList)
  const saved_bankdetails =
    useSelector(state => state.VendorRegister.bankdetails?.saved_bankdetails) ||
    []
  const bankdetails =
    useSelector(state => state.VendorRegister.bankdetails?.values) || {}
  const dispatch = useDispatch()
  const { id, isedit } = useParams()
  const [edit, setEdit] = useState(false)
  const [ibanRequire, setIbanRequire] = useState(false)
  const [editIndex, setEditIndex] = useState(null);
  const [error, setError] = useState({
    country: '',
    countryCode: '',
    bankName: '',
    branchName: '',
    BranchPartyId: '',
    BankPartyId: '',
    accountNumber: '',
    iBAN: '',
    currency: '',
    currencyCode: '',
    others: '',
    branchothers: ''
  })

  React.useEffect(() => {
    if (isedit === 'true') {
      setEdit(true)
    }
    if (isedit === 'false') {
      setEdit(false)
    }
  }, [isedit])

  React.useEffect(() => {
    if (bankdetails?.BankPartyId.toString() == '0') {
      setOthers(true)
    }
    if (bankdetails?.BranchPartyId.toString() == '0') {
      setBranchOthers(true)
    }
    if (bankdetails?.BankPartyId.toString() !== '0') {
      setOthers(false)
    }
    if (bankdetails?.BranchPartyId.toString() !== '0') {
      setBranchOthers(false)
    }
  }, [bankdetails])
  
  useEffect(() => {

    if (bankdetails?.country === 'Saudi Arabia' && editIndex == null) {
      setIbanRequire(true)
      let selectedcountry = countryList?.find(
        country => country?.TerritoryShortName == bankdetails?.country
      )
      let Country = countryList?.find(
        list => list?.TerritoryShortName == bankdetails?.country
      )
      let selectcountry = selectedcountry?.TerritoryShortName
      setCountry(selectcountry)
      dispatch(getBankDetails(selectcountry))
      dispatch(getCurrency())
      let name = 'countryCode'
      let value = Country?.TerritoryCode
      dispatch(VendorProfileBankDetails(name, value))
    } else {
      setIbanRequire(false)
    }
  }, [bankdetails?.country ,countryList])

  function checkInput(value) {
    if (typeof(value) !== "number" && value.includes('<script>')) {
      return false;
    }
    return true;
  }

  const   handleChange = event => {
    event.persist()
    const { name, value } = event.target;
    debugger
    if (checkInput(value)) {
    if (event.target.name == 'countryCode') {
      // dispatch(VendorProfileBankDetails('countryID', event.target.value))
      let selectedcountry = countryList?.find(
        country => country.TerritoryCode == event.target.value
      )
      let Country = countryList?.find(
        list => list.TerritoryCode == event.target.value
      )
      let selectcountry = selectedcountry.TerritoryShortName
      setCountry(selectcountry)
      dispatch(getBankDetails(selectcountry))
      dispatch(getCurrency())
      let name = 'country'
      let value = Country.TerritoryShortName
      dispatch(VendorProfileBankDetails(name, value))
      dispatch(VendorProfileBankDetails('bankName', ''))
      dispatch(VendorProfileBankDetails('branchName', ''))

    }
    if (event.target.name == 'BankPartyId') {
      if (event.target.value !== '0') {
        // dispatch(VendorProfileBankDetails('bankNameID',event.target.value))
        let selectedBank = bankList.find(
          bank => bank.BankPartyId == event.target.value
        )
        let BankPartyNumber = selectedBank?.BankPartyNumber
        let bankName = selectedBank?.BankName
        dispatch(getBankBranches(BankPartyNumber))
        dispatch(VendorProfileBankDetails('BankPartyNumber', BankPartyNumber))
        dispatch(VendorProfileBankDetails("CountryName", selectedBank?.CountryName))
        dispatch(VendorProfileBankDetails('others', ''))

        let name = 'bankName'
        let value = bankName
        dispatch(VendorProfileBankDetails(name, value))
      dispatch(VendorProfileBankDetails('branchName', ''))
      } else if (event.target.name == 'others') {
        let name = 'others'
        dispatch(VendorProfileBankDetails(name, value))
      }
    }
    if (event.target.name == 'BranchPartyId') {
      if (event.target.value !== '0') {
        // dispatch(VendorProfileBankDetails('branchNameID', event.target.value))
        let selectedBranch = branchlist.find(
          list => list.BranchPartyId == event.target.value
        )
        let name = 'branchName'
        let value = selectedBranch.BankBranchName
        dispatch(VendorProfileBankDetails(name, value))
        dispatch(VendorProfileBankDetails('branchothers', ''))
        
      } else if (event.target.name == 'branchothers') {
        let name = 'branchothers'
        dispatch(VendorProfileBankDetails(name, value))
        // dispatch(VendorProfileBankDetails('BankPartyNumber', ''))
        // dispatch(VendorProfileBankDetails('bankName', ''))
        // dispatch(VendorProfileBankDetails("branchName", ''))
      }
    }
    if (event.target.name == 'others') {

      dispatch(VendorProfileBankDetails('BankPartyNumber', ''))
      dispatch(VendorProfileBankDetails('bankName', ''))
    }
    if (event.target.name == 'branchothers') {

      dispatch(VendorProfileBankDetails('branchName', ''))
    }
    if (event.target.name == 'currencyCode') {
      // dispatch(VendorProfileBankDetails('currencyID', event.target.value))
      let selectedcurrency = GetCurrency.find(
        list => list.CurrencyCode == event.target.value
      )
      let name = 'currency'
      let value = selectedcurrency.Name
      dispatch(VendorProfileBankDetails(name, value))
    }
    if (event.target.name == 'PrimaryOwnerIndicator') {
      let value = event.target.checked
      dispatch(VendorProfileBankDetails(name, value))
    } else {
      dispatch(VendorProfileBankDetails(name, value))
    }
  }
  else {
    return;
  }
  }

  const handleOnclick = () => {
    setEditIndex(null)
    setSelectedFileModel(true)
  }

  const SubmitDetails = () => {
    let {
      country,
      bankName,
      BankPartyId,
      BranchPartyId,
      branchName,
      accountNumber,
      iBAN,
      currency,
      others,
      branchothers
    } = bankdetails

    const Check = require('is-null-empty-or-undefined').Check
    var bankdDetail_error = false
    let v_country
    let v_bankName
    let v_branchName
    let v_accountNumber
    let v_iBAN
    let v_currency
    let v_others
    let v_branchothers

    if (!Check(country)) {
      v_country = 'success'
    } else {
      v_country = 'error'
      bankdDetail_error = true
    }

    if (!Check(BankPartyId.toString())) {
      v_bankName = 'success'
    } else {
      v_bankName = 'error'
      bankdDetail_error = true
    }

    if (!Check(BranchPartyId.toString())) {
      v_branchName = 'success'
    } else {
      v_branchName = 'error'
      bankdDetail_error = true
    }

    if (!Check(accountNumber)) {
      v_accountNumber = 'success'
    } else {
      v_accountNumber = 'error'
      bankdDetail_error = true
    }
    if (ibanRequire ? !Check(iBAN) :  v_iBAN = 'success') {
      v_iBAN = 'success'
    } else {
      v_iBAN = 'error'
      bankdDetail_error = true
    }
    if (!Check(currency)) {
      v_currency = 'success'
    } else {
      v_currency = 'error'
      bankdDetail_error = true
    }
    if (chkothers == true) {
      if (!Check(others)) {
        v_others = 'success'
      } else {
        v_others = 'error'
        bankdDetail_error = true
      }
    }
    if (branchOthers == true) {
      if (!Check(branchothers)) {
        v_branchothers = 'success'
      } else {
        v_branchothers = 'error'
        bankdDetail_error = true
      }
    }
    // if (!Check(others)) {
    //   if (verify(others)) {
    //     v_others = 'success'
    //   } else {
    //     v_others = 'error'
    //     bankdDetail_error = true
    //   }
    // } else {
    //   v_others = 'error'
    //   bankdDetail_error = true
    // }
    if (bankdDetail_error) {
      setError({
        country: v_country,
        bankName: v_bankName,
        branchName: v_branchName,
        accountNumber: v_accountNumber,
        iBAN: v_iBAN,
        currency: v_currency,
        others: v_others,
        branchothers: v_branchothers
      })
      setSelectedFileModel(true)
    } else {
      dispatch(VendorBankDetailsSubmit(editIndex))
      setError({
        country: '',
        bankName: '',
        branchName: '',
        accountNumber: '',
        iBAN: '',
        currency: '',
        others: '',
        branchothers: ''
      })
      setshow(true)
      setSelectedFileModel(false)
      setEditIndex(null)
    }
  }
  // else {
  //     dispatch(VendorBankDetailsSubmit())
  //     setshow(true)
  //     setSelectedFileModel(false);
  // }
  const viewFileHandler = details => {
    setSelectedFileModel(true)
  }

  const delDetails = fileIndex => {
    dispatch(VendorBankDetailsDelete(fileIndex))
  }

  const editBankDetails = async(row ,index) => {
    setEditLoading(true)
    setEditIndex(index)
    await dispatch(getBankDetails(row?.CountryName))
    await dispatch(getBankBranches(row?.BankPartyNumber))
  dispatch(VendorProfileBankDetailsEdit(row, index))
  setEditLoading(false)
  setSelectedFileModel(true);
  };
  
  const handleSubmit = () => {
    if (formState.bank_details.length == 0) {
      errorAlert('Bank Details Required')
    }
    // else if (vendordata?.vendorName !== "" && vendordata?.email !== "") {
    //   dispatch(submitVendorProfile(vendordata,Token))
    // }
    else {
      let vendordata = {
        vendorName: userCheck?.vendorName,
        email: userCheck?.email,
        bankDetails: formState.bank_details
      }
      dispatch(submitBankDetails(vendordata, Token))
    }
  }
  return (
    <GridContainer>
      {!saved_bankdetails?.length == 0 ? (
        <GridItem xs={12} sm={3} md={2} lg={12}>
          <Box
            boxShadow={3}
            style={{ padding: 5, background: 'rgb(245 245 245)' }}
          >
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Bank Name</TableCell>
                  <TableCell align='left'>Branch Name</TableCell>
                  <TableCell align='left'>Country</TableCell>
                  <TableCell align='left'>Currency</TableCell>
                  <TableCell align='left'>Account Number</TableCell>
                  <TableCell align='left'>IBAN</TableCell>
                  <TableCell align='left'>Primary</TableCell>
                  <TableCell align='left'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {saved_bankdetails?.map((row, index) => (
                  <TableRow>
                    <TableCell>{row?.BankPartyId == '0' ? row?.others : row?.bankName}</TableCell>
                    <TableCell>{row?.BranchPartyId == '0' ? row?.branchothers : row?.branchName}</TableCell>
                    <TableCell>{row?.country}</TableCell>
                    <TableCell>{row?.currency}</TableCell>
                    <TableCell>{row?.accountNumber}</TableCell>
                    <TableCell>{row?.iBAN}</TableCell>
                    <TableCell>
                      {row?.PrimaryOwnerIndicator == true ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell>
                      {!edit ? (
                        <>
                         <Tooltip title="Edit Bank" aria-label="Edit">
                               {editLoading && index == editIndex ? <CircularProgress style={{width:"25px"}}/> :  <Edit style={{ cursor: "pointer" , marginRight: '10px'}} fontSize="small" onClick={() => editBankDetails(row ,index)} />}
                              </Tooltip> 
                        <Tooltip title='Delete Bank' aria-label='delete'>
                          <DeleteIcon
                            fontSize='small'
                            onClick={() => delDetails(index)}
                          />
                        </Tooltip>
                        </>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                )) || []}
              </TableBody>
            </Table>
          </Box>
        </GridItem>
      ) : (
        ''
      )}
      <GridItem key={'addAttachment'} xs={12} sm={3} md={2} lg={12}>
        {!edit ? (
          <>
            {saved_bankdetails.length == 0 ? (
              <Typography
                variant='body2'
                component='h2'
                style={{ float: 'right', color: 'red' }}
              >
                Minimum one bank detail required *{' '}
              </Typography>
            ) : (
              ''
            )}
            <Card
              onClick={handleOnclick}
              style={{
                padding: '10px',
                textAlign: 'center',
                cursor: 'pointer',
                background: '#f5f5f5'
              }}
            >
              <AddCircleOutlineIcon
                style={{
                  alignSelf: 'center',
                  width: 50,
                  height: 125,
                  marginBottom: 10
                }}
                fontSize='large'
              />
              <Typography variant='body1' component='h6'>
                Bank Details
              </Typography>
            </Card>
          </>
        ) : (
          ''
        )}
      </GridItem>

      {selectedFileModel ? (
        <Dialog
          classes={{
            root: classes.center + ' ' + classes.modalRoot,
            paper: classes.modal
          }}
          fullWidth={true}
          maxWidth={'md'}
          open={selectedFileModel}
          keepMounted
          onClose={() => setSelectedFileModel(false)}
          aria-labelledby='selected-modal-slide-title'
          aria-describedby='selected-modal-slide-description'
        >
          <DialogContent id='selected-Select' className={classes.modalBody}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>Bank Details</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer justify='center' md={12} xs={12} sm={12}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    style={{ marginBottom: '10px' }}
                  >
                    <TextField
                      fullWidth={true}
                      required
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      error={error.country === 'error'}
                      helperText={
                        error.country === 'error' ? 'Country is required' : null
                      }
                      label='Country'
                      id='countryCode'
                      name='countryCode'
                      variant='outlined'
                      select
                      onChange={event => {
                        handleChange(event)
                      }}
                      value={bankdetails?.countryCode || ''}
                    >
                      <MenuItem selected disabled value=''>
                        Select Country
                      </MenuItem>
                      {countryList?.map(list => (
                        <MenuItem selected value={list.TerritoryCode}>
                          {list.TerritoryShortName}
                        </MenuItem>
                      )) || []}
                    </TextField>
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    style={{ marginBottom: '10px' }}
                  >
                    <TextField
                      fullWidth={true}
                      required
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      error={error.bankName === 'error'}
                      helperText={
                        error.bankName === 'error'
                          ? 'bank Name is required'
                          : null
                      }
                      label='Bank'
                      id='BankPartyId'
                      name='BankPartyId'
                      variant='outlined'
                      select
                      onChange={event => {
                        handleChange(event)
                      }}
                      value={bankdetails?.BankPartyId == 0 ? bankdetails?.BankPartyId.toString() : bankdetails?.BankPartyId }
                    >
                      <MenuItem disabled value=''>
                        Select Bank
                      </MenuItem>
                      {bankList?.map(list => (
                        <MenuItem value={list.BankPartyId}>
                          {list.BankName}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value='0'>Others</MenuItem> */}
                    </TextField>
                  </GridItem>
                  {bankdetails.BankPartyId == '0' ? (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={error.others === 'error'}
                        helperText={
                          error.others === 'error'
                            ? 'bank Name is required'
                            : null
                        }
                        label='Enter bank name'
                        id='others'
                        name='others'
                        variant='outlined'
                        onChange={event => {
                          handleChange(event)
                        }}
                          value={bankdetails.others || ""}
                      ></TextField>
                    </GridItem>
                  ) : (
                    ''
                  )}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    style={{ marginBottom: '10px' }}
                  >
                    <TextField
                      fullWidth={true}
                      required
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      error={error.branchName === 'error'}
                      helperText={
                        error.branchName === 'error'
                          ? 'branch Name is required'
                          : null
                      }
                      label='Branch'
                      id='BranchPartyId'
                      name='BranchPartyId'
                      variant='outlined'
                      select
                      onChange={event => {
                        handleChange(event)
                      }}
                        value={bankdetails?.BranchPartyId == 0  ? bankdetails?.BranchPartyId.toString() : bankdetails?.BranchPartyId || ""}
                    >
                      <MenuItem disabled value=''>
                        Select Branch
                      </MenuItem>
                      {branchlist?.map(list => (
                        <MenuItem value={list.BranchPartyId}>
                          {list.BankBranchName}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value='0'>Others</MenuItem> */}
                    </TextField>
                  </GridItem>
                  {bankdetails.BranchPartyId == '0' ? (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      style={{ marginBottom: '10px' }}
                    >
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={error.branchothers === 'error'}
                        helperText={
                          error.branchothers === 'error'
                            ? 'branch Name is required'
                            : null
                        }
                        label='Enter branch name'
                        id='branchothers'
                        name='branchothers'
                        variant='outlined'
                        onChange={event => {
                          handleChange(event)
                        }}
                          value={bankdetails.branchothers || ""}
                      ></TextField>
                    </GridItem>
                  ) : (
                    ''
                  )}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    style={{ marginBottom: '10px' }}
                  >
                    <TextField
                      fullWidth={true}
                      required
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      error={error.accountNumber === 'error'}
                      helperText={
                        error.accountNumber === 'error'
                          ? 'account Number is required'
                          : null
                      }
                      label='Account Number'
                      id='accountNumber'
                      name='accountNumber'
                      variant='outlined'
                      placeholder='Account Number ..'
                      type='text'
                      disabled={props.disabledCheck}
                      onChange={event => {
                        handleChange(event)
                      }}
                      value={bankdetails.accountNumber || ""}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    style={{ marginBottom: '10px' }}
                  >
                    {ibanRequire ? (
                      <TextField
                        fullWidth={true}
                        required
                        InputLabelProps={{
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={error.iBAN === 'error'}
                        helperText={
                          error.iBAN === 'error' ? 'IBAN is required' : null
                        }
                        label='IBAN'
                        id='iBAN'
                        name='iBAN'
                        variant='outlined'
                        placeholder='IBAN ..'
                        type='text'
                        disabled={props.disabledCheck}
                        onChange={event => {
                          handleChange(event)
                        }}
                        value={bankdetails.iBAN || ""}
                      />
                    ) : (
                      <TextField
                        fullWidth={true}
                        // required
                        InputLabelProps={{
                          style: { color: 'black' }
                          // classes: {
                          //   asterisk: classes.asterisk
                          // }
                        }}
                        // error={error.iBAN === 'error'}
                        // helperText={
                        //   error.iBAN === 'error' ? 'IBAN is required' : null
                        // }
                        label='IBAN'
                        id='iBAN'
                        name='iBAN'
                        variant='outlined'
                        placeholder='IBAN ..'
                        type='text'
                        disabled={props.disabledCheck}
                        onChange={event => {
                          handleChange(event)
                        }}
                        value={bankdetails.iBAN || ""}
                      />
                    )}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    style={{ marginBottom: '10px', float: 'left' }}
                  >
                    <TextField
                      fullWidth={true}
                      required
                      InputLabelProps={{
                        style: { color: 'black' },
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      error={error.currency === 'error'}
                      helperText={
                        error.currency === 'error'
                          ? 'currency is required'
                          : null
                      }
                      label='Currency'
                      id='currencyCode'
                      name='currencyCode'
                      variant='outlined'
                      select
                      onChange={event => {
                        handleChange(event)
                      }}
                      value={bankdetails?.currencyCode || ''}
                    >
                      <MenuItem selected disabled value=''>
                        Select a Currency
                      </MenuItem>
                      {GetCurrency?.map(list => (
                        <MenuItem selected value={list.CurrencyCode}>
                          {list.Name + ' ' + list.CurrencyCode}
                        </MenuItem>
                      )) || []}
                    </TextField>
                  </GridItem>
                  {!saved_bankdetails.find(
                    key => key.PrimaryOwnerIndicator == true
                  ) ? (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={12}
                      style={{ marginBottom: '10px' }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox name='PrimaryOwnerIndicator' color='info' />
                        }
                        label='Primary'
                        checked={bankdetails.PrimaryOwnerIndicator}
                        name='PrimaryOwnerIndicator'
                        onChange={event => {
                          handleChange(event)
                        }}
                      />
                    </GridItem>
                  ) : (
                    ''
                  )}
                  <GridItem
                    style={{
                      display: 'flex',
                      alignItems: 'right',
                      justifyContent: 'flex-end',
                      marginTop: '20px'
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Button
                      color='danger'
                      size='small'
                      onClick={() => {setSelectedFileModel(false); setEditIndex(null); dispatch(VendorProfileBankDetailsClear())}}
                      round
                    >
                      Close
                    </Button>
                    <Button
                      color='info'
                      size='small'
                      onClick={() => SubmitDetails()}
                      round
                    >
                      Submit
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </DialogContent>
        </Dialog>
      ) : (
        ''
      )}
    </GridContainer>
  )
}
