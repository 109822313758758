/* Imports */
import jwt from 'jsonwebtoken'
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useHistory } from 'react-router-dom'

export default function LineChartView(props) {
  const history = useHistory();
  let userDetail = jwt.decode(localStorage.getItem('cooljwt'))
  const [state, setState] = React.useState({
    Incomplete: props?.chartData?.incompleteCount,
    InitialReview: props?.chartData?.initialReviewCount,
    InReview: props?.chartData?.inReviewCount,
    InApproval: props?.chartData?.inApproveCount,
    Rejected: props?.chartData?.rejectedCount,
    CorrectionRequired: props?.chartData?.correctionReqCount,
    Cancelled: props?.chartData?.cancelledCount,
    MonthlyCount: props?.chartData?.MonthlyCount,
    fullPayment: props?.chartData?.fullPayment,
    partialPayment: props?.chartData?.partialPayment,
    paymentPending: props?.chartData?.paymentPending
  })

  React.useEffect(() => {
    setState({
      Incomplete: props?.chartData?.incompleteCount,
      InitialReview: props?.chartData?.initialReviewCount,
      InReview: props?.chartData?.inReviewCount,
      InApproval: props?.chartData?.inApproveCount,
      Rejected: props?.chartData?.rejectedCount,
      CorrectionRequired: props?.chartData?.correctionReqCount,
      Cancelled: props?.chartData?.cancelledCount,
      MonthlyCount: props?.chartData?.MonthlyCount,
      fullPayment: props?.chartData?.fullPayment,
      partialPayment: props?.chartData?.partialPayment,
      paymentPending: props?.chartData?.paymentPending
    })
  }, [props.chartData])

  const handleNavigation = (dataPointIndex) => {
    switch (dataPointIndex) {
      case 0:
        // for Total Invoices
        history.push(`/default/invoices/null/ap`)
        break;
      case 1:
        let incomplete = 1
        // for Incomplete Invoices
        history.push(`/default/invoices/1/ap`)
        break;
      case 2:
        let review = 1
        // for Initail Review Invoices
        history.push(`/default/invoices/1/ap`)
        break;
      case 3:
        // for Review Invoices
        history.push('/default/invoices/1/ap')
        break;
      case 4:
        // for approved Invoices
        history.push('/default/invoices/1/ap')
        break;
      case 5:
        // for pending payment Invoices
        history.push('/default/invoices/2/ap')
        break;
      case 6:
        // for paid Invoices
        history.push('/default/invoices/3/ap')
        break;
      case 7:
        // for Correction Required Invoices
        history.push('/default/invoices/7/ap') 
        break;
      case 8:
        // for Rejected Invoices
        history.push('/default/invoices/8/ap')
        break;
      case 9:
        // for Cancelled Invoices
        history.push('/default/invoices/5/ap')
        break;
      default:
        break;
    }
  }

  let summaryOptions = {
    fill: {
      colors: ['#095392']
    },
    tooltip: {
      enabled: false,
      y: {
        formatter: function (val) {
          return ''
        },
        title: {
          formatter: function (seriesName) {
            return ''
          }
        }
      }
    },
    chart: {
      toolbar: {
        show: false
      },

      type: 'bar',
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        // style: {
        //   backgroundColor: ["green"],
        // },
        // distributed: true,
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['black']
      }
    },
    legend: {
      show: false
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (!userDetail?.role?.isAdmin) {
            handleNavigation(config.dataPointIndex)
          }
        }
      }
    },

    xaxis: {
      categories: [
        'TOTAL INVOICES',
        'INCOMPLETE',
        'INITIAL-REVIEW',
        'IN-REVIEW',
        'IN-APRROVAL',
        'PENDING-PAYMENTS',
        // ["Partial paid"],
        'PAID-INVOICES',
        'CORRECTION REQUIRED',
        'REJECTED',
        'CANCELLED'
      ],
      labels: {
        /**
            * Allows users to apply a custom formatter function to x-axis labels.
            *
            // * @param { String } value - The default value generated
            // * @param { Number } timestamp - In a datetime series, this is the raw timestamp 
            // * @param { object } contains dateFormatter for datetime x-axis
            */
        formatter: function (value, timestamp, { w }) {
          return [
            value,
            value === 'INCOMPLETE'
              ? '(Requester-Supplier)'
              : value === 'INITIAL-REVIEW'
                ? '(Requester)'
                : value === 'IN-REVIEW'
                  ? '(Accounting Specialist)'
                  : value === 'IN-APRROVAL'
                    ? '(Accounting Manager)'
                    : value === 'PENDING-PAYMENTS'
                      ? '(Fusion)'
                      : value === 'PAID-INVOICES'
                        ? '(Fusion)'
                        : value === 'CANCELLED'
                          ? '(Fusion)'
                          : ''
          ]
        }
      }
    }
  }

  return (
    <div>
      <ReactApexChart
        options={summaryOptions}
        series={
          state
            ? [
              {
                data: [
                  parseInt(state.MonthlyCount),
                  parseInt(state.Incomplete),
                  parseInt(state.InitialReview),
                  parseInt(state.InReview),
                  parseInt(state.InApproval),
                  parseInt(state.paymentPending),
                  // parseInt(state.partialPayment),
                  parseInt(state.fullPayment),
                  parseInt(state.CorrectionRequired),
                  parseInt(state.Rejected),
                  parseInt(state.Cancelled)
                ]
              }
            ]
            : [
              {
                data: [0, 0, 0, 0]
              }
            ]
        }
        type='bar'
        height='280%'
      />
    </div>
  )
}
