import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";

import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";
import MaterialConfig from "react-awesome-query-builder/lib/config/material";

import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles
import { makeStyles, createStyles } from "@material-ui/core/styles";
import PurchaseOrderList from "actions/PurchaseOrderListAction";
import { Box, CircularProgress } from "@material-ui/core";
import { POFilter } from "actions/PurchaseOrderListAction";
import Card from "components/Card/Card";

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or BasicConfig

// You need to provide your own config. See below 'Config format'
const config = {
  ...InitialConfig,
  fields: {
    OrderNumber: {
      label: "PO Number",
      type: "number",
      valueSources: ["value"],
      preferWidgets: ["number"]
    },
    ProcurementBU: {
      label: "Procurement BU",
      type: "text",
      valueSources: ["value"],
      preferWidgets: ["text"]
    },
    Supplier: {
      label: "Supplier",
      type: "text",
      valueSources: ["value"],
      preferWidgets: ["text"]
    },
    PaymentTerms: {
      label: "Payment Terms",
      type: "text",
      valueSources: ["value"],
      preferWidgets: ["text"]
    },
    Status: {
      label: 'Status',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'Closed', title: 'Closed' },
          { value: 'Canceled', title: 'Canceled' },
          { value: 'Incomplete', title: 'Incomplete' },
          { value: 'Closed for Receiving', title: 'Closed for Receiving' },
          { value: 'Rejected', title: 'Rejected' },
          { value: 'Withdrawn', title: 'Withdrawn' },
          { value: 'Open', title: 'Open' },
          { value: 'Closed for Invoicing', title: 'Closed for Invoicing' },
          { value: 'Pending Approval', title: 'Pending Approval' }
        ],
      }
    },
    CreatedBy: {
      label: "Created By",
      type: "text",
      valueSources: ["value"],
      preferWidgets: ["text"]
    }
  }
};
// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { id: QbUtils.uuid(), type: "group" };

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        boxStyle: {
          border: "1px solid red"
        },

      },
      //   listStyleNone: {
      //     '& li':{
      //         listStyle: 'none';
      //     }
      // },
      queryComponent: {
        '& .group': {
          border: "2px white solid",
          background: "white"
        },
        '& .group--actions--tr button:last-child': {
          display: "none"
        }

      }
    }),
);

const Filters = ({ isOpen, SetisOpen, setnewdata }) => {
  const dispatch = useDispatch();
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const isloading = useSelector(state => state.suppliers.isLoadingFilters);

  // const Data = useSelector(state => state.suppliers.supplierList);
  const classes = useStyles();
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config
  });

  const onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState({ tree: immutableTree, config: config });

    const jsonTree = QbUtils.getTree(immutableTree);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  };

  const renderBuilder = (props) => (
    <div style={{ padding: '10px' }}>
      <Card>
        <div className={classes.queryComponent} style={{ background: "#fff !important" }}>
          <Builder  {...props} />
        </div>
      </Card>
    </div>
  );

  const handleSubmit = async () => {
    //getting Query from Query Builder
    let query = QbUtils.mongodbFormat(state.tree, state.config)
    let Fdata = {};
    let temp = {};

    // if (query) {
    //   for (let index = 0; index < Object.entries(query).length; index++) {
    //     let element = Object.entries(query)[index];
    //     console.log("element", element);
    //     if (element[1].length > 1) {
    //       let modified_query = element[1].map((regex) => {
    //         let object = {};
    //         for (const [key, value] of Object.entries(regex)) {
    //           object[key] = value;
    //           object[key].$options = "i"
    //         }
    //         return object;
    //       });
    //       temp = {
    //         [element[0]]: modified_query
    //       }
    //     }
    //     else {
    //       if (element[1].$regex) {
    //         element[1].$options = "i";
    //         temp = {
    //           [element[0]]: element[1]
    //         }
    //       }
    //     }
    let data = {}

    for (let index = 0; index < Object.entries(query).length; index++) {
      const element = Object.entries(query)[index];
      // console.log("element: ", element)
      let temp = {
        [element[0]]: element[1]
      }
      // console.log(temp)
      data = {
        ...data,
        ...temp
      }
    }
    Fdata = {
      data
    }
    //   }
    // }
    setnewdata(Fdata)
    //Dispatch Payload
    let dispatch_payload = {
      page: 0,
      rowPerPage: 10,
      Token: Token,
      newdata: Fdata
    };
    dispatch(POFilter(dispatch_payload));
  }

  const handleCancel = () => {
    let dispatch_payload = {
      page: 0,
      rowPerPage: 10,
      Token: Token
    };
    dispatch(PurchaseOrderList(dispatch_payload));
    SetisOpen(false)
  }

  return (
    <>
      <div>
        <Query
          {...config}
          value={state?.tree || []}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
        <>
          <Button
            color="danger"
            round
            disabled={isloading}
            className={classes.marginRight}
            onClick={handleSubmit}
          >
            {isloading == true ? (<CircularProgress style={{ width: 20, height: 20, color: "white" }} />) : ("Apply")}

          </Button>
          <Button
            color="info"
            round
            className={classes.marginRight}
            onClick={handleCancel}
          >
            Close
          </Button>
        </>
      </div>
    </>
  );
};
export default Filters