import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_SUCCESS_FILTERS,
  SUPPLIER_LIST_FAIL
} from "actions/Constant";


let initialState = {
  supplierList:{
  currentpage: 0,
  totalNumOfItems: null,
  totalpages: null,
  Vendors: [],
  },
  isLoadingFilters:false
}


export function supplierListReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_LIST_REQUEST:
      return { loading: true,isLoadingFilters:true, supplierList: [] };
    case SUPPLIER_LIST_SUCCESS:
      return { loading: false,isLoadingFilters:false, supplierList: action.payload };
    case SUPPLIER_LIST_SUCCESS_FILTERS:
      return { ...state,loading:false,isLoadingFilters:false,supplierList:action.payload };
    case SUPPLIER_LIST_FAIL:
      return { loading: false,isLoadingFilters:false, error: action.payload };
    default:
      return state;
  }
}