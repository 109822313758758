import React, { useState, useEffect } from 'react'
// @material-ui/icons
import {
  Button,
  MenuItem,
  makeStyles,
  CircularProgress,
  TextField
} from '@material-ui/core'
// @material-ui/core components
import MuiPhoneNumber from 'material-ui-phone-number'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { Animated } from 'react-animated-css'
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import Swal from 'sweetalert2'
import {
  successAlert,
  errorAlert,
  msgAlert
} from 'views/LDocs/Functions/Functions'
import axios from 'axios'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import jwt from 'jsonwebtoken'
import { setIsTokenExpired } from 'actions'
import VendorProfile from 'actions/VendorProfile'
import { FlashOffRounded } from '@material-ui/icons'
import { editVendorProfile } from 'actions/VendorProfile'
import { useLocation } from 'react-router-dom'


const style = theme => ({
  asterisk: {
    color: "red"
  },
});

const useStyles = makeStyles(style)
const sweetAlertStyle = makeStyles(styles2)
export default function Level1Profile(props) {
  const { error, setError, stepstate } = props
  const details = useSelector(state => state.VendorRegister.Vendors)
  const dispatch = useDispatch()
  const classes = useStyles()
  const location = useLocation()
  const [animateStep, setAnimateStep] = useState(true)
  const [decoded, setDecoded] = React.useState(null)
  const userCheck = useSelector(state => state.VendorRegister.generalinfo)
  const Returndata = useSelector(state => state.SubmitVendor.generalInfo)
  const editUser = useSelector(state => state.VendorRegister.editInfo)
  const [edit, setEdit] = useState(false)
  const { id, isedit } = useParams()
  useEffect(() => {
    if (isedit === 'true') {
      setEdit(true)
    }
    if (isedit === 'false') {
      setEdit(false)
    }
  }, [isedit])

  function checkInput(value) {
    if (value.includes('<script>')) {
      return false;
    }
    return true;
  }

  const handleChange = event => {
    event.persist()
    const { name, value } = event.target
    if (checkInput(value)) {
      dispatch(VendorProfile(name, value))
    } else {
      return;
    }
  }

  const handlePhoneChange = value => {
    dispatch(VendorProfile('contactNumber', value))
  }

  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')

  useEffect(() => {
    let userDetail = jwt.decode(Token)
    setDecoded(userDetail)
  }, [])

  return (
    <Animated
      animationIn='bounceInRight'
      animationOut='bounceOutLeft'
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >
      <GridContainer justify='center' md={12} xs={12} sm={12}>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: '10px' }}
        >
          <TextField
            fullWidth={true}
            required
            InputLabelProps={{
              style: { color: 'black' },
              classes: {
                asterisk: classes.asterisk
              }
            }}
            error={error.vendorName === 'error'}
            helperText={
              error.vendorName === 'error' ? 'Valid Name is required' : null
            }
            label='Supplier Name'

            id='vendorName'
            name='vendorName'
            type='text'
            variant='outlined'
            disabled={edit || stepstate?.currentStep !== 0}
            onChange={event => handleChange(event)}
            value={userCheck?.vendorName}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: '10px' }}
        >
          <TextField
            fullWidth={true}
            required
            InputLabelProps={{
              style: { color: 'black' },
              classes: {
                asterisk: classes.asterisk
              }
            }}
            error={error.vendorEmail === 'error'}
            helperText={
              error.vendorEmail === 'error' ? 'Valid Email is required' : null
            }
            label='Supplier Email'
            id='email'
            name='email'
            type='email'
            variant='outlined'
            disabled={
              stepstate?.currentStep !== 0 ||
              id ||
              location.pathname.includes('/vendor/vendor_profile') ||
              location.pathname.includes('/admin/vendor_profile')
            }
            onChange={event => handleChange(event)}
            value={userCheck?.email}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: '10px' }}
        >
          <MuiPhoneNumber
            variant='outlined'
            fullWidth
            required
            defaultCountry={'sa'}
            excludeCountries={['il']}
            InputLabelProps={{
              style: { color: 'black' },
              classes: {
                asterisk: classes.asterisk,
              }
            }}
            disableAreaCodes={true}
            autoFormat={true}
            countryCodeEditable={false}
            error={error.contactNumber === 'error'}
            helperText={
              error.contactNumber === 'error'
                ? 'Valid Contact number is required'
                : null
            }
            label='Contact Number'
            id='contactNumber'
            name='contactNumber'
            disabled={stepstate?.currentStep !== 0 || edit}
            onChange={handlePhoneChange}
            value={userCheck?.contactNumber}
          />
        </GridItem>
      </GridContainer>
    </Animated>
  )
}
