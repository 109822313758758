import React from "react";
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Dialog,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import GetAppIcon from "@material-ui/icons/GetApp";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import { formatDateTime } from "Functions";
import { saveAs } from "file-saver";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { viewAttachment } from "views/LDocs/Functions/Functions";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: "relative",
    maxWidth: 360,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {
    minWidth: "10%",
  },
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "3%",
  },
}));

const TrackingStatus = ({
  workflow,
  openTrackingModal,
  setOpenTrackingModal,
}) => {
  const classesList = useStyle();

  const { initialReviewStep, reviewStep, approveStep, exportStep } =
    workflow ?? {};

  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);

  const getSteps = (steps) => {
    return steps.map((step) => {
      const updatedStep = {
        ...step,
        forward: step?.forward
          ?.map((f) => ({ ...f, showReason: true }))
          .concat(step?.delegate?.map((d) => ({ ...d, showReason: false }))),
      };

      let filteredStep = updatedStep?.forward.filter((x) => x !== undefined);
      return {
        ...updatedStep,
        forward: filteredStep?.sort(
          (a, b) => new Date(a?.date) - new Date(b?.date)
        ),
      };
    });
  };

  const showFwdNotes = (step, row, index) => {
    if (step === "review") {
      let step = getSteps([row]);
      setShowFwdData(step);
    } else {
      let step = getSteps([row]);
      setShowFwdData(step);
    }
    setShowFwdDetails(true);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openTrackingModal}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenTrackingModal(false)}
        aria-labelledby="tag-modal-slide-title"
        aria-describedby="tag-modal-slide-description"
      >
        <DialogContent id="tag-modal-slide-description">
          <GridContainer>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4>Tracking Status</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Table className={classesList.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classesList.TableRow}>
                      <TableCell className={classesList.TableID}>
                        Review / Approve
                      </TableCell>
                      <TableCell className={classesList.TableID}>
                        Assigned To
                      </TableCell>
                      <TableCell className={classesList.TableID}>
                        Assigned Date
                      </TableCell>
                      <TableCell className={classesList.TableCell}>
                        Status
                      </TableCell>
                      <TableCell className={classesList.TableCell}>
                        Action Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ paddingBottom: 5 }}>
                    {initialReviewStep && (
                      <TableRow className={classesList.TableRow} key={1}>
                        <TableCell className={classesList.TableCell}>
                          Initial Review
                          {initialReviewStep?.isForward ||
                          initialReviewStep?.isDelegate ? (
                            <IconButton
                              onClick={() =>
                                showFwdNotes(
                                  "initialReviewStep",
                                  initialReviewStep
                                )
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>
                          {initialReviewStep?.reviewedBy || "-"}
                          <br />
                          {initialReviewStep?.assignTo || "-"}
                        </TableCell>
                        <TableCell>
                          {initialReviewStep?.assignDate
                            ? formatDateTime(initialReviewStep?.assignDate)
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {initialReviewStep?.status?.toUpperCase() ||
                            "PENDING"}
                        </TableCell>
                        <TableCell>
                          {initialReviewStep?.status !== "pending" &&
                          initialReviewStep?.date
                            ? formatDateTime(initialReviewStep?.date)
                            : "-"}
                        </TableCell>
                      </TableRow>
                    )}
                    {reviewStep?.map((item, index) => (
                      <TableRow className={classesList.TableRow} key={index}>
                        <TableCell className={classesList.TableCell}>
                          Review
                          {item?.isForward || item?.isDelegate ? (
                            <IconButton
                              onClick={() =>
                                showFwdNotes("review", item, index)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.reviewedBy || "-"}
                          <br />
                          {item?.assignTo || "-"}
                        </TableCell>
                        <TableCell>
                          {item?.assignDate
                            ? formatDateTime(item?.assignDate)
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {item?.status?.toUpperCase() || "PENDING"}
                        </TableCell>
                        <TableCell>
                          {item?.status !== "pending" && item?.date
                            ? formatDateTime(item?.date)
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}

                    {approveStep?.map((item, index) => (
                      <TableRow className={classesList.TableRow} key={index}>
                        <TableCell className={classesList.TableCell}>
                          <span>
                            Approve
                            {item?.isForward || item?.isDelegate ? (
                              <IconButton
                                onClick={() =>
                                  showFwdNotes("approve", item, index)
                                }
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          {item?.approvedBy || "-"}
                          <br />
                          {item?.assignTo || "-"}
                        </TableCell>
                        <TableCell>
                          {item?.assignDate
                            ? formatDateTime(item?.assignDate)
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {item?.status?.toUpperCase() || "PENDING"}
                        </TableCell>
                        <TableCell>
                          {item?.status !== "pending" && item?.date
                            ? formatDateTime(item?.date)
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow className={classesList.TableRow} key={1}>
                      <TableCell className={classesList.TableCell}>
                        Approve and Export
                        {exportStep?.isForward || exportStep?.isDelegate ? (
                          <IconButton
                            onClick={() =>
                              showFwdNotes("exportStep", exportStep)
                            }
                          >
                            <InfoIcon />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        {exportStep?.exportedBy || "-"}
                        <br />
                        {exportStep?.assignTo || "-"}
                      </TableCell>
                      <TableCell>
                        {exportStep?.assignDate
                          ? formatDateTime(exportStep?.assignDate)
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {exportStep?.status?.toUpperCase() || "PENDING"}
                      </TableCell>
                      <TableCell>
                        {exportStep?.status !== "pending" && exportStep?.date
                          ? formatDateTime(exportStep?.date)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
            <Button
              color="danger"
              onClick={() => setOpenTrackingModal(false)}
              round
              style={{ float: "right", marginLeft: "auto" }}
            >
              CLOSE
            </Button>
          </GridContainer>
        </DialogContent>
      </Dialog>

      {showFwdDetails ? (
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={showFwdDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowFwdDetails(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent id="tag-modal-slide-description">
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4>Forward/Deligate Details</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Foward/Deligate User</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ paddingBottom: 5 }}>
                      {showFwdData?.map((item, index) => (
                        <React.Fragment key={item}>
                          {item?.forward?.map((fwd, fwdIndex) => (
                            <React.Fragment key={fwd}>
                              <TableRow>
                                <TableCell>
                                  {formatDateTime(fwd?.date)}
                                </TableCell>
                                <TableCell>{fwd?.noteMsg}</TableCell>
                              </TableRow>
                              {fwd?.showReason && (
                                <TableRow>
                                  <TableCell colSpan={2}>
                                    <b>Reason:</b> {fwd?.reason}
                                  </TableCell>
                                </TableRow>
                              )}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
              <Button
                color="danger"
                onClick={() => setShowFwdDetails(false)}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
};

export default TrackingStatus;
