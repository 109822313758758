import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import {
  makeStyles,
  LinearProgress,
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  Grid,
  Avatar,
  ListItemText,
  TablePagination,
} from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle";
import GridContainer from "components/Grid/GridContainer.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
//Redux
import { useSelector, useDispatch } from "react-redux";
//ReduxActions
import { getNotification } from "actions";
//Axios
import axios from "axios";
//ICONS
import DoneAllIcon from "@material-ui/icons/DoneAll";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckIcon from "@material-ui/icons/Check";
import { Animated } from "react-animated-css";
import { formatDateTime } from "../Functions/Functions";
import { decode } from "jsonwebtoken";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Notifications() {
  const [notifications, setNotifications] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [animateTable, setAnimateTable] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  let Token = localStorage.getItem("cooljwt");
  let decoded = decode(Token);

  const handleMarkNotificationRead = (
    notification_id,
    status,
    notification
  ) => {
    const { eventName, notificationItem } = notification;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/notify/updateSysNotify`,
      data: {
        _id: notification_id,
        status: status,
      },
      headers: {
        "Content-Type": "application/json",
        cooljwt: Token,
      },
    }).then((response) => {
      dispatch(getNotification());
    });
    if (
      eventName &&
      eventName == "Invoice" &&
      notificationItem?.invoiceId &&
      notificationItem?.version &&
      notificationItem?.vendorId
    ) {
      if (decoded?.role?.isAdmin === false) {
        history.push(
          `/default/invoiceDetail?invoiceId=${notificationItem?.invoiceId}&&version=${notificationItem?.version}&&vendorId=${notificationItem?.vendorId}`
        );
      } else if (decoded?.isVendor) {
        history.push(
          `/vendor/invoiceDetail?invoiceId=${notificationItem?.invoiceId}&&version=${notificationItem?.version}&&vendorId=${notificationItem?.vendorId}`
        );
      }
    }
    if (eventName && eventName == "Vendor") {
      if (decoded?.role?.isAdmin == true) {
        history.push(
          `/admin/vendor_profile/${notificationItem?.vendorId}/false`
        );
      } else {
        history.push(
          `/default/vendorprofile/${notificationItem?.vendorId}/true/false`
        );
      }
    }
    if (eventName && eventName == "Receipt") {
      if (decoded?.role?.isAdmin == true) {
        history.push(`/admin/receipts/${notificationItem?.POHeaderId}`);
      } else {
        history.push(`/default/receipt/${notificationItem?.POHeaderId}`);
      }
    }
  };

  const getNotifications = async() => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/notify/showAllNotifications?page=${page}&items=10`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        setNotifications(response?.data?.data);
        setTotalPages(response?.data?.totalpages)
      })
      .catch((error) => {
        console.log(
          `Unable to get Notification please contact at ${process.env.REACT_APP_LDOCS_CONTACT_MAIL}`
        );
      });
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    getNotifications(newPage);
  };

  useEffect(() => {
    getNotifications(page);
  }, []);

  return (
    <div>
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={animateTable}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card profile>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitle}>Notifications</h4>
                </CardIcon>
              </CardHeader>
              <CardBody profile>
                <TablePagination
                  component="div"
                  count={totalPages}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPageOptions={false}
                  rowsPerPage={10}
                />
                <List>
                  {notifications?.map((notification) => {
                    return (
                      <ListItem
                        key={notification._id}
                        button
                        onClick={() => {
                          handleMarkNotificationRead(
                            notification._id,
                            "seen",
                            notification
                          );
                        }}
                        alignItems="flex-start"
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {notification.status == "un-seen" ? (
                              <NotificationsIcon color="yellow" />
                            ) : (
                              <NotificationsNoneIcon />
                            )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {notification.notificationAction}
                              </Typography>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                <Grid
                                  style={{}}
                                  container
                                  wrap="nowrap"
                                  spacing={1}
                                >
                                  <Grid item xs={11}>
                                    {notification.notifyMessage}
                                    <br />
                                    {formatDateTime(
                                      notification.notificationDate
                                    )}
                                  </Grid>
                                  <Grid item xs={1}>
                                    {notification.status == "un-seen" ? (
                                      <CheckIcon fontSize="small" />
                                    ) : (
                                      <DoneAllIcon
                                        color="primary"
                                        fontSize="small"
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Typography>
                              <Divider />
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    );
                  }) || []}
                </List>

                <TablePagination
                  component="div"
                  count={totalPages}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={10}
                  rowsPerPageOptions={false}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Animated>
    </div>
  );
}
