import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import { formatDateTime } from "../Functions/Functions";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles();

const ReceiptHistory = ({ showModal, setShowModal, receipt }) => {
  const classes = useStyles();

  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);

  const showFwdNotes = (data) => {
    let transformedSteps = [data];
    const allActions = transformedSteps.reduce((acc, step) => {
      if (Array.isArray(step.forward)) {
        acc.push(...step.forward.map((f) => ({ ...f, showReason: true })));
      }
      if (Array.isArray(step.delegate)) {
        acc.push(...step.delegate.map((d) => ({ ...d, showReason: false })));
      }
      return acc;
    }, []);
    const filteredActions = allActions.filter((x) => x !== undefined);
    setShowFwdData({
      forward: filteredActions.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      ),
    });
    setShowFwdDetails(true);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={showModal}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowModal(false)}
        aria-labelledby="tag-modal-slide-title"
        aria-describedby="tag-modal-slide-description"
      >
        <DialogContent
          id="tag-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitle}>History</h4>
                </CardIcon>
              </CardHeader>
              {receipt?.receiptHistory?.map((historyItem, index) => (
                <CardBody>
                  <Table className={classes.table} aria-label="simple table">
                    {console.log(historyItem)}
                    <TableHead>
                      <TableRow className={classes.TableRow} key={index}>
                        <TableCell className={classes.TableID}>
                          {index + 1}-
                        </TableCell>
                        <TableCell className={classes.TableID}>
                          Review / Approve
                        </TableCell>
                        <TableCell className={classes.TableID}>
                          Assigned To
                        </TableCell>
                        <TableCell className={classes.TableID}>
                          Assigned Date
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          Status
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          Action Date
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          Action Type
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          QTY./AMT.
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          TXN Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ paddingBottom: 5 }}>
                      {historyItem?.workFlowStatus?.reviewStep.length > 0 &&
                        historyItem?.workFlowStatus?.reviewStep.map(
                          (item, index) => (
                            <TableRow className={classes.TableRow} key={index}>
                              <TableCell
                                className={classes.TableCell}
                              ></TableCell>
                              <TableCell className={classes.TableCell}>
                                Review
                                {item?.isForward || item?.isDelegate ? (
                                  <IconButton
                                    onClick={() => showFwdNotes(item)}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </TableCell>

                              <TableCell>
                                {item?.reviewedBy || "-"}
                                <br />
                                {item?.assignTo || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.assignDate
                                  ? formatDateTime(item?.assignDate)
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {item?.status?.toUpperCase() || "PENDING"}
                              </TableCell>
                              <TableCell>
                                {item?.status !== "pending" && item?.date
                                  ? formatDateTime(item?.date)
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {historyItem?.receiptAction?.toUpperCase() ||
                                  "-"}
                              </TableCell>
                              <TableCell>
                                {historyItem?.isQuantity
                                  ? historyItem?.quantity
                                  : historyItem?.amount || ""}
                              </TableCell>
                              <TableCell>
                                {historyItem?.transactionDate
                                  ? formatDateTime(historyItem?.transactionDate)
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          )
                        )}

                      {historyItem?.workFlowStatus?.approveStep.length > 0 &&
                        historyItem?.workFlowStatus?.approveStep.map(
                          (item, index) => (
                            <TableRow className={classes.TableRow} key={index}>
                              <TableCell
                                className={classes.TableCell}
                              ></TableCell>
                              <TableCell className={classes.TableCell}>
                                Approve
                                {item?.isForward || item?.isDelegate ? (
                                  <IconButton
                                    onClick={() => showFwdNotes(item)}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </TableCell>

                              <TableCell>
                                {item?.approvedBy || "-"}
                                <br />
                                {item?.assignTo || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.assignDate
                                  ? formatDateTime(item?.assignDate)
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {item?.status?.toUpperCase() || "PENDING"}
                              </TableCell>
                              <TableCell>
                                {item?.status !== "pending" && item?.date
                                  ? formatDateTime(item?.date)
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {historyItem?.receiptAction?.toUpperCase() ||
                                  "-"}
                              </TableCell>
                              <TableCell>
                                {historyItem?.isQuantity
                                  ? historyItem?.quantity
                                  : historyItem?.amount || ""}
                              </TableCell>
                              <TableCell>
                                {historyItem?.transactionDate
                                  ? formatDateTime(historyItem?.transactionDate)
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      <TableRow className={classes.TableRow}>
                        <TableCell className={classes.TableCell}></TableCell>
                        <TableCell className={classes.TableCell}>
                          Approve and Export
                        </TableCell>
                        <TableCell>
                          {historyItem?.workFlowStatus?.exportStep
                            ?.exportedBy || "-"}
                          <br />
                          {historyItem?.workFlowStatus?.exportStep?.assignTo ||
                            "-"}
                        </TableCell>
                        <TableCell>
                          {historyItem?.workFlowStatus?.exportStep?.assignDate
                            ? formatDateTime(
                                historyItem?.workFlowStatus?.exportStep
                                  ?.assignDate
                              )
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {historyItem?.workFlowStatus?.exportStep?.status?.toUpperCase() ||
                            "PENDING"}
                        </TableCell>
                        <TableCell>
                          {historyItem?.workFlowStatus?.exportStep?.status !==
                            "pending" &&
                          historyItem?.workFlowStatus?.exportStep?.date
                            ? formatDateTime(
                                historyItem?.workFlowStatus?.exportStep?.date
                              )
                            : "-"}
                        </TableCell>
                        <TableCell>{"-"}</TableCell>
                        <TableCell>{"-"}</TableCell>
                        <TableCell>{"-"}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardBody>
              ))}
            </Card>
            <Button
              color="danger"
              className={classes.registerButton}
              onClick={() => setShowModal()}
              round
              style={{ float: "right", marginLeft: "auto" }}
            >
              CLOSE
            </Button>
          </GridContainer>
        </DialogContent>
      </Dialog>

      {showFwdDetails ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={showFwdDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowFwdDetails(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>
                      Forward/Deligate Details
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Foward/Deligate User</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ paddingBottom: 5 }}>
                      {showFwdData &&
                        showFwdData?.forward?.map((fwd, index) => (
                          <>
                            <TableRow className={classes.TableRow} key={index}>
                              <TableCell className={classes.TableCell}>
                                {formatDateTime(fwd?.date)}
                              </TableCell>
                              <TableCell>{fwd?.noteMsg}</TableCell>
                            </TableRow>
                            {fwd?.showReason && (
                              <TableRow
                                className={classes.TableRow}
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  <b>Reason:</b> {fwd?.reason}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => setShowFwdDetails(false)}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
};

export default ReceiptHistory;
