import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
// @material-ui/core components
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import defaultAvatar from "assets/img/placeholder.jpg";
import { isArray } from "lodash";
const sweetAlertStyle = makeStyles(styles2);
let Token = localStorage.getItem("cooljwt");
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    cardTitleText: {
        color: "white",
    },
    buttonRight: {},
};
const useStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        maxHeight: 500,
        position: "relative",
        maxWidth: 360,
    },
    listSection: {
        backgroundColor: "inherit",
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0,
    },
    table: {
        minWidth: "100%",
        border: 1,
    },
    TableCell: {},
    TableRow: {
        cursor: "pointer",
        background: "white",
        border: 1,
        width: "100%",
    },
    TableID: {
        maxWidth: "",
    },
    TableHead: {
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontWeight: 500,
    },
}));

export default function Certificates({ bankdetails }) {

    const useStyles = makeStyles(styles);
    const classesList = useStyle();
    const [animateStep, setAnimateStep] = useState(true);


    return (
        <Animated
            animationIn="bounceInRight"
            animationOut="bounceOutLeft"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={animateStep}
        >
            {bankdetails?.bankDetails?.length > 0 ? (
                <GridContainer style={{ overflowX: "scroll" }}>
                    <Table className={classesList.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className={classesList.TableRow}>

                            </TableRow>
                        </TableHead>
                        <TableBody style={{ paddingBottom: 5 }}>

                        </TableBody>
                    </Table>
                </GridContainer>
            ) : (
                "No record Found."
            )}
        </Animated>
    );
}
