import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { Animated } from "react-animated-css";
import axios from "axios";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Swal from "sweetalert2";
import {
  successAlert,
  errorAlert,
  msgAlert,
} from "views/LDocs/Functions/Functions";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import jwt from "jsonwebtoken";
import { useSelector } from "react-redux";
import login from "assets/img/login_salic1.jpg";
import ReCAPTCHA from "react-google-recaptcha";

const sweetAlertStyle = makeStyles(styles2);
const useStyles = makeStyles(styles);

export default function Totp() {
  const classes = useStyles();
  // const [password, setPassword] = useState("");
  // const [passwordError, setPasswordError] = useState("");
  // const [confPassword, setConfPassword] = useState("");
  // const [confPasswordError, setConfPasswordError] = useState("");
  const [strength, setStrength] = useState("");
  const [passwordError, setPasswordError] = useState({
    password: "",
    confirmpassword: "",
  });
  const [password, setPassword] = useState({
    password: "",
    confirmpassword: "",
  });
  const [apiErrorMessage, setAPIErrorMessage] = useState("");
  const [loggedIn, setloggedIn] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [mongoId, setmongoId] = useState("");
  const [decoded, setDecoded] = useState(null);
  const [repatchToken, setRepatchToken] = useState(null);

  // let token =
  //   useSelector(state => state.userReducer.Token) ||
  //   localStorage.getItem('cooljwt')
  // let decoded = jwt.decode(token)

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  useEffect(() => {
    setmongoId(getParameterByName("id"));
    // if (userToken) {
    //   const userDetails = jwt.decode(userToken)
    //   setDecoded(userDetails)
    // }
  }, [mongoId]);

  const handlePassword = (event) => {
    event.persist();
    setPassword((password) => ({
      ...password,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name == "password") {
      if (event.target.value.length < 4 && event.target.value.length > 0) {
        setStrength("Weak");
      } else if (
        event.target.value.length > 5 &&
        event.target.value.length < 8
      ) {
        setStrength("Moderate");
      } else if (
        event.target.value.length > 8 &&
        event.target.value.length < 12
      ) {
        setStrength("Strong");
      }
    }
  };
  const verifyPassword = (value) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(value)) {
      return true;
    }
    return false;
  };

  function handleClick(e) {
    // e.preventDefault();
    const Check = require("is-null-empty-or-undefined").Check;
    var password_error = false;
    let v_password;
    let v_confirmpassword;

    if (!Check(password.password)) {
      if (verifyPassword(password.password)) {
        v_password = "success";
      } else {
        setAPIErrorMessage(
          "Note: Password must contain atleast one upper case ,lower case ,numeric value and special character"
        );
        v_password = "success";
        password_error = true;
      }
    } else {
      v_password = "error";
      password_error = true;
    }

    if (!Check(password.confirmpassword)) {
      v_confirmpassword = "success";
    } else {
      v_confirmpassword = "error";
      password_error = true;
    }

    if (password.confirmpassword != password.password) {
      setAPIErrorMessage("Password not matched !");
    }
    if (password.password.length < 8) {
      setAPIErrorMessage("Atleast 8 characters");
    }

    if (password_error) {
      setStrength("");
      setIsloading(false);
      setPasswordError({
        password: v_password,
        confirmpassword: v_confirmpassword,
        recaptchaToken: repatchToken,
      });
    } else {
      setPasswordError("success");
      setAPIErrorMessage("");
      let url;
      if (decoded) {
        if (decoded?.isTenant) {
          url = `${process.env.REACT_APP_LDOCS_API_URL}/tenant/tenantpass`;
        } else if (decoded?.isVendor) {
          url = `${process.env.REACT_APP_LDOCS_API_URL}/vendor/vendorPass`;
        }
      } else {
        url = `${process.env.REACT_APP_LDOCS_API_URL}/vendor/vendorPass`;
      }
      if (!repatchToken) {
        setAPIErrorMessage("RECAPTCHA REQUIRED!");
        return;
      }
      setIsloading(true);
      setAPIErrorMessage("");
      axios({
        method: "post", //you can set what request you want to be
        url: url,
        data: {
          id: mongoId,
          password: password.password,
          confirmPassword: password.confirmpassword,
        },
      })
        .then((response) => {
          setIsloading(false);
          setloggedIn(true);
        })
        .catch((error) => {
          setIsloading(false);
          let err = error?.response?.data;
          console.log(
            typeof error.response != "undefined"
              ? error.response.data
              : error.message
          );
          setAPIErrorMessage(err);
        });
    }
  }
  return (
    <>
      <div
        style={{
          padding: "120px 0",
          position: "relative",
          minHeight: "100vh",
          display: "flex!important",
          margin: "0",
          border: "0",
          backgroundImage: ` url(${login})`,
          // color: whiteColor,
          alignItems: "center",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "fixed",
            left: "10%",
            right: "0%",
            top: "20%",
            margin: "0px",
            padding: "0px",
          }}
          className={classes.container}
        >
          {loggedIn ? (
            mongoId ? (
              <Redirect to="/supplier/login" />
            ) : (
              <Redirect to="/auth/login" />
            )
          ) : (
            ""
          )}

          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <Animated
                animationIn="bounceInRight"
                animationOut="bounceOutLeft"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div>
                  <Card login>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                    >
                      <h4 className={classes.cardTitle}>ACTIVATION</h4>
                    </CardHeader>
                    <CardBody>
                      <TextField
                        fullWidth
                        error={passwordError.password === "error"}
                        helperText={
                          passwordError.password === "error"
                            ? "Password is required"
                            : null
                        }
                        label="Password"
                        id="password"
                        name="password"
                        placeholder="Password must be eight characters"
                        onChange={(event) => {
                          handlePassword(event);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        type="password"
                        value={password.password || ""}
                      />
                      <TextField
                        fullWidth
                        error={passwordError.confirmpassword === "error"}
                        helperText={
                          passwordError.confirmpassword === "error"
                            ? "Confirm password is required"
                            : null
                        }
                        label="Confirm Password"
                        id="confirmpassword"
                        name="confirmpassword"
                        onChange={(event) => {
                          handlePassword(event);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        type="password"
                        value={password.confirmpassword || ""}
                      />
                      <h6
                        style={
                          strength == "Weak"
                            ? { color: "red" }
                            : strength == "Moderate"
                            ? { color: "blue" }
                            : strength == "Strong"
                            ? { color: "green" }
                            : {}
                        }
                      >
                        {strength}
                      </h6>
                      <div
                        style={
                          strength == "Weak"
                            ? {
                                width: "33.3%",
                                height: "2px",
                                background: "red",
                              }
                            : strength == "Moderate"
                            ? {
                                width: "66.6%",
                                height: "2px",
                                background: "blue",
                              }
                            : strength == "Strong"
                            ? {
                                width: "99.9%",
                                height: "2px",
                                background: "green",
                              }
                            : {}
                        }
                      ></div>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_GOOGLE_KEY}
                          onChange={(value) => setRepatchToken(value)}
                        />
                        <span style={{ color: "red" }}>{apiErrorMessage}</span>
                        <Button
                          color="info"
                          simple
                          size="lg"
                          block
                          onClick={handleClick}
                        >
                          {isLoading ? <CircularProgress /> : "Log In"}
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </Animated>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
}
