import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'
// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress'
// core components
import Button from 'components/CustomButtons/Button.js'
import defaultImage from 'assets/img/image_placeholder.jpg'
import defaultAvatar from 'assets/img/placeholder.jpg'
import Avatar from 'assets/img/default-avatar.png'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { setIsTokenExpired } from 'actions'
import VendorProfile from 'actions/VendorProfile'
import { successAlert } from 'Functions'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import {msgAlert} from 'Functions'

export default function ImageUpload (props) {
  const { stepstate } = props
  const [file, setFile] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isUploading, setIsUploading] = React.useState(false)
  const [decoded, setDecoded] = React.useState(null)
  const history = useHistory()
  const location = useLocation()
  //Token
  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  const Returndata = useSelector(state => state.SubmitVendor.generalInfo)
  const {
    isError,
    inProcess,
    isSuccess,
    errorMessage,
    isEdit,
    generalSuccess,
    lastStep
  } = useSelector(state => state.SubmitVendor)
  const dispatch = useDispatch()
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  )
  let fileInput = React.createRef()
  const userCheck = useSelector(state => state.VendorRegister.generalinfo)
  const { id, isedit } = useParams()
  const [edit, setEdit] = React.useState(false)
  const { generalinfo } = useSelector(state => state.VendorRegister)
  let profileImage = generalinfo?.profileImage || ''

  React.useEffect(() => {
    let userDetail = jwt.decode(Token)
    setDecoded(userDetail)
  }, [])

  React.useEffect(() => {
    if (profileImage == '' || null || undefined) {
      setImagePreviewUrl(Avatar)
    } else if (isedit === 'true' || isedit === 'false' || decoded?.isVendor) {
      setImagePreviewUrl(profileImage)
    }
  }, [profileImage])

  React.useEffect(() => {
    if (isedit === 'true') {
      setEdit(true)
    }
    if (isedit === 'false') {
      setEdit(false)
    }
  }, [isedit])

  const handleImageChange = e => {
    if (
      e.target.files[0].type === 'image/png' ||
      e.target.files[0].type === 'image/jpeg'
    ) {
      e.preventDefault()
      let reader = new FileReader()
      let file = e.target.files[0]
      props.handleImageChange(file, 1, props.name)
      setIsLoading(true)
      reader.onloadend = () => {
        setFile(file)
        props.setUserData(file)
        setImagePreviewUrl(reader.result)
        setIsLoading(false)
      }
      reader.readAsDataURL(file)
      fileInput.current.value = ''
    } else {
      msgAlert('File Type Not Supported')
      fileInput.current.value = ''
    }
  }

  const handleSubmit = () => {
    setIsUploading(true)
    let email = userCheck?.email
    var bodyFormData = new FormData()
    bodyFormData.append('profileImg', file)
    bodyFormData.append('email', email.toLowerCase())
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    let msg = ''
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/vendorProfileImg`,
      data: bodyFormData,
      config: config
    })
      .then(response => {
        setIsUploading(false)
      })
      .catch(error => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        setIsUploading(false)
        msg =
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        props.errorAlert(msg)
      })
  }
  const handleClick = () => {
    fileInput.current.click()
  }
  const handleRemove = () => {
    setFile(null)
    props.handleImageChange(null, 2, props.name)
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage)
    fileInput.current.value = ''
  }
  let {
    avatar,
    addButtonProps,
    changeButtonProps,
    removeButtonProps,
    uploadButtonProps
  } = props
  return (
    <div className='fileinput text-center' style={{ marginTop: '30px' }}>
      <input type='file' onChange={handleImageChange} ref={fileInput} />
      {isLoading ? (
        <CircularProgress disableShrink />
      ) : (
        <div
          className={'thumbnail'}
          style={{
            borderRadius: '50%',
            width: '200px',
            height: '180px',
            alignContent: 'center'
          }}
        >
          <img src={imagePreviewUrl} alt='...' />
        </div>
      )}

      {!edit ? (
        <div>
          {file === null ? (
            stepstate?.currentStep == 0 ? (
              <Button {...addButtonProps} onClick={() => handleClick()}>
                {avatar ? 'Add Photo' : 'Select image'}
              </Button>
            ) : (
              ''
            )
          ) : stepstate?.currentStep == 0 ? (
            <span>
              <Button {...changeButtonProps} onClick={() => handleClick()}>
                Change
              </Button>
              {avatar ? <br /> : null}
              <Button {...removeButtonProps} onClick={() => handleRemove()}>
                <i className='fas fa-times' /> Remove
              </Button>
              {/* <Button {...uploadButtonProps}
                  onClick={() => handleSubmit()}
                >
                  Upload
                </Button> */}
              {isUploading ? <CircularProgress disableShrink /> : ''}
            </span>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
}
