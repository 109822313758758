import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core'
// @material-ui/icons

import { Redirect } from 'react-router-dom'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'
import LoginPage from './LoginPage'
import LoginSecret from './LoginSecret'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import DeviceUUID, { getToken } from 'views/LDocs/Functions/Functions'
import { checkSelectAll } from 'views/LDocs/Functions/Functions'
import { useDispatch, useSelector } from 'react-redux'
import { setPermissions } from '../../../actions'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

let uuid = new DeviceUUID().get()
let os = new DeviceUUID().parse().os
let firebase_token
getToken().then(res => {
  firebase_token = res
})

export default function Auth () {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  let invDetailUrl = localStorage.getItem('url')
  const [login, setLogin] = useState(true)
  const [removeLogin, setRemoveLogin] = useState(true)
  const [removeotpCheck, setRemoveotpCheck] = useState(false)
  const [optCheck, setoptCheck] = useState(false)
  const [userData, setUserData] = useState(null)
  const [state, setState] = useState({
    ap: false,
    ar: false
  })

  const loginSuccess = async response => {
    let token = response.headers.cooljwt
    let decoded = jwt.decode(token)

    if (response?.data !== null) {
      dispatch(setPermissions(response?.data))
      let ap = checkSelectAll(response?.data, 'ap')
      let ar = checkSelectAll(response?.data, 'ar')
      setState({
        ap,
        ar
      })
    } else {
      dispatch(setPermissions({}))
    }

    if (!decoded.isTenant) {
      await axios({
        method: 'post', //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/user/updateUserFcm`,
        data: {
          osType: os,
          fcmToken: firebase_token,
          deviceId: uuid
        },
        headers: {
          cooljwt: response.headers.cooljwt
        }
      })
    }

    setUserData(decoded)
    // setTimeout(function () {
    //   setRemoveLogin(false);
    //   setRemoveotpCheck(true);
    //   setLogin(false);
    //   setoptCheck(true);
    // }, 500);
  }
  const loginOTPsuccess = () => {
    setoptCheck(false)

    setTimeout(function () {
      setRemoveotpCheck(false)
      setRemoveLogin(true)
      setLogin(true)
    }, 500)
  }

  return (
    <div className={classes.container}>
      {userData !== null ? (
        userData?.role?.isAdmin ? (
          <Redirect to='/admin/dashboard/ad' />
        ) : state.ap ? (
          <Redirect to='/default/dashboard/ap' />
        ) : (
          <Redirect to='/default/dashboard/ar' />
        )
      ) : (
        ''
      )}
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={12}>
          {removeLogin ? (
            <Animated
              animationIn='bounceInUp'
              animationOut='bounceOutUp'
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={login}
            >
              <div>
                <LoginPage loginSuccess={loginSuccess} />
              </div>
            </Animated>
          ) : (
            ''
          )}
          {removeotpCheck ? (
            <Animated
              animationIn='bounceInUp'
              animationOut='bounceOutUp'
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={optCheck}
            >
              <div>
                <LoginSecret loginOTPsuccess={loginOTPsuccess} />
              </div>
            </Animated>
          ) : (
            ''
          )}
        </GridItem>
      </GridContainer>
    </div>
  )
}
