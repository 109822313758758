export const defaultCurrency = {
    _id: "625fb170fb4ca6c2a30a8958",
    lookup_Id: 1,
    Code: "SAR",
    Symbol: "ر.س",
  };
  export const VendorSites = [
    " Jeddah, Saudi Arabia",
    " Ad-Dilam, Saudi Arabia",
    " Al-Abwa, Saudi Arabia",
    " Abha, Saudi Arabia",
  ];