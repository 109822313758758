import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    MenuItem,
    TextField,
    Switch,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Backdrop,
    Typography,
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import ChipInput from "material-ui-chip-input";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icon
import VisibilityIcon from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Swal from "sweetalert2";
import {
    successAlert,
    errorAlert,
    msgAlert,
} from "views/LDocs/Functions/Functions";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { setIsTokenExpired } from "actions";
import TimezoneSelect from "react-timezone-select";


const styles = ((theme) => ({
    root: {
      // textAlign: "center",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    cardTitleText: {
        color: "white",
    },
    buttonRight: {},
    statusImage: {
        width: 50,
        height: 50,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"}
}));

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function SyncConfigrations() {
    const Token =
        useSelector((state) => state.userReducer.Token) ||
        localStorage.getItem("cooljwt");
    const userDetails = jwt.decode(Token);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(false);
    const [mainLoading, setMainLoading] = React.useState(false);
    const [organizationFilter, setOrganizationFilter] = React.useState("");
    const [organizations, setOrganizations] = React.useState([]);
    const [data, setData] = React.useState();
    const [animateTableView, setAnimateTableView] = React.useState(true);
    const [animateTable, setAnimateTable] = React.useState(true);
    const [selectedTimezone, setSelectedTimezone] = React.useState("");
    const [state, setState] = React.useState({
        values: {
            syncTime: 1,
            syncPurchaseOrder: false,
            syncSuppliers: false,
            syncCoa: false,
            syncTaxClassification: false,
            syncInvoices: false,
            syncUsers: false,
            syncCurrencies: false,


        },
    });
    React.useEffect(() => {
        setMainLoading(true)
        let userDetail = jwt.decode(Token);
        axios({
            method: "get",
            url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getFusionConfigs`,
            headers: { cooljwt: Token },
        })
            .then((response) => {
                setMainLoading(false)
                let syncConfig = response.data;
                setState((state) => ({
                    ...state,
                    values: {
                        ...state.values,
                        syncTime: syncConfig.syncTime
                            ||
                            "",
                        syncPurchaseOrder: syncConfig.syncPurchaseOrder
                            ||
                            false,
                        syncSuppliers: syncConfig.syncSuppliers
                            ||
                            false,
                        syncCoa: syncConfig.syncCoa
                            ||
                            false,
                        syncTaxClassification: syncConfig.syncTaxClassification
                            ||
                            false,
                        syncInvoices: syncConfig.syncInvoices
                            ||
                            false,
                            syncUsers: syncConfig.syncUsers
                            ||
                            false,
                            syncCurrencies: syncConfig.syncCurrencies
                            ||
                            false,
                    },
                }));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const saveConfigration = () => {
        setIsLoading(true)
        let body = {
            syncTime: parseInt(state.values.syncTime),
            syncPurchaseOrder: state.values.syncPurchaseOrder,
            syncSuppliers: state.values.syncSuppliers,
            syncCoa: state.values.syncCoa,
            syncTaxClassification: state.values.syncTaxClassification,
            syncInvoices: state.values.syncInvoices,
            syncUsers:state.values.syncUsers,
            syncCurrencies:state.values.syncCurrencies

        };
        axios({
            method: "PUT",
            url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/fusionConfig`,
            data: body,
            headers: { cooljwt: Token },
        })
            .then((response) => {
                setIsLoading(false)
                successAlert("Configration Updated");
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (event) => {
        event.persist();
        if (event.target.name == "syncTime") {
            setState((state) => ({
                ...state,
                values: {
                    ...state.values,
                    [event.target.name]: event.target.value,
                },
            }));
        } else {
            setState((state) => ({
                ...state,
                values: {
                    ...state.values,
                    [event.target.name]: event.target.checked,
                },
            }));
        }

    };
    return (
        <div>
            {animateTableView ? (
                !mainLoading ?
                    <>
                        <Animated
                            animationIn="bounceInRight"
                            animationOut="bounceOutLeft"
                            animationInDuration={1000}
                            animationOutDuration={1000}
                            isVisible={animateTable}
                        >
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader color="info" icon>
                                            <CardIcon color="info">
                                                <h4 className={classes.cardTitleText}>
                                                    Sync Configrations
                                                </h4>
                                            </CardIcon>
                                        </CardHeader>
                                        <CardBody>
                                            <List>
                                                <ListItem>
                                                    <ListItemText
                                                        style={{ color: "black" }}
                                                        primary="Sync Purchase Orders & Receipts"
                                                        secondary={
                                                            "System will auto sync purchase orders"
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            value={state.values.syncPurchaseOrder}
                                                            checked={state.values.syncPurchaseOrder}
                                                            name="syncPurchaseOrder"
                                                            onChange={handleChange}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                {/* Enable Email Notifications */}
                                                <ListItem>
                                                    <ListItemText
                                                        style={{ color: "black" }}
                                                        primary="Sync Suppliers"
                                                        secondary={
                                                            "System will auto sync suppliers"
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            value={state.values.syncSuppliers}
                                                            checked={state.values.syncSuppliers}
                                                            name="syncSuppliers"
                                                            onChange={handleChange}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem>
                                                    <ListItemText
                                                        style={{ color: "black" }}
                                                        primary="Sync Chart of Accounts"
                                                        secondary={
                                                            "System will auto sync Coa"
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            value={state.values.syncCoa}
                                                            checked={state.values.syncCoa}
                                                            name="syncCoa"
                                                            onChange={handleChange}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem>
                                                    <ListItemText
                                                        style={{ color: "black" }}
                                                        primary="Sync Tax Classification"
                                                        secondary={
                                                            "System will auto sync Tax Classification"
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            value={state.values.syncTaxClassification}
                                                            checked={state.values.syncTaxClassification}
                                                            name="syncTaxClassification"
                                                            onChange={handleChange}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem>
                                                    <ListItemText
                                                        style={{ color: "black" }}
                                                        primary="Sync Invoices Status"
                                                        secondary={
                                                            "System will auto sync Invoices"
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            value={state.values.syncInvoices}
                                                            checked={state.values.syncInvoices}
                                                            name="syncInvoices"
                                                            onChange={handleChange}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem>
                                                    <ListItemText
                                                        style={{ color: "black" }}
                                                        primary="Sync Users"
                                                        secondary={
                                                            "System will auto sync Users"
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            value={state.values.syncUsers}
                                                            checked={state.values.syncUsers}
                                                            name="syncUsers"
                                                            onChange={handleChange}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem>
                                                    <ListItemText
                                                        style={{ color: "black" }}
                                                        primary="Sync Currency Conversion Rates"
                                                        secondary={
                                                            "System will auto sync Currency Conversion Rates"
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Checkbox
                                                            color="primary"
                                                            value={state.values.syncCurrencies}
                                                            checked={state.values.syncCurrencies}
                                                            name="syncCurrencies"
                                                            onChange={handleChange}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                                <ListItem>
                                                    <TextField
                                                        fullWidth={true}
                                                        label="Set Sync Duration (System will sync all above selected after specified hours)"
                                                        id="syncTime"
                                                        name="syncTime"
                                                        variant="standard"
                                                        select
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                        }}
                                                        value={state.values.syncTime || ""}
                                                    >
                                                        <MenuItem selected disabled value="">Select Time </MenuItem>
                                                        <MenuItem selected value="1">1 Hour</MenuItem>
                                                        <MenuItem selected value="2"> 2 Hours</MenuItem>
                                                        <MenuItem selected value="3"> 3 Hours</MenuItem>
                                                        <MenuItem selected value="4">4 Hours</MenuItem>
                                                        <MenuItem selected value="5">5 Hours</MenuItem>
                                                        <MenuItem selected value="6">6 Hours</MenuItem>
                                                        <MenuItem selected value="7"> 7 Hours</MenuItem>
                                                        <MenuItem selected value="8"> 8 Hours</MenuItem>
                                                        <MenuItem selected value="9">9 Hours</MenuItem>
                                                        <MenuItem selected value="10">10 Hours</MenuItem>
                                                        <MenuItem selected value="11">11 Hours</MenuItem>
                                                        <MenuItem selected value="12">12 Hours</MenuItem>
                                                        <MenuItem selected value="24">24 Hours</MenuItem>

                                                    </TextField>
                                                </ListItem>
                                            </List>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                            <Button
                                color="info"
                                className={classes.registerButton}
                                style={{ float: "right", marginTop: 20 }}
                                round
                                type="button"
                                onClick={saveConfigration}
                            >
                                {isLoading ? <CircularProgress style={{ color: "white" }} /> : "Save Configration"}
                            </Button>
                        </Animated>
                    </> 
                    :
                    <Backdrop className={classes.backdrop} open={mainLoading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            ) : (
                ""
            )}
        </div>
    );
}
