// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import VendorDashboard from "views/LDocs/Dashboard/VendorDashboard";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FilesList from "views/LDocs/Invoices/RecieveInvoice/FilesList";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CreateInvoice from "views/LDocs/Invoices/CreateInvoice/CreateInvoice";
import VendorPayment from "views/LDocs/VendorPayment/VendorPayment";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Customers from "views/LDocs/Customers/Customers";
import Payable from "views/LDocs/Payable/Payable";
import ReceiptIcon from '@material-ui/icons/Receipt';
import UserProfile from "views/LDocs/Profile/Profile";
import Verify from "views/LDocs/Verify/Verify";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import VendorProfile from "views/LDocs/VendorProfile/Profile";
import Notifications from "views/LDocs/Notifications/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import InvoiceDetail from "views/LDocs/Invoices/InvoiceDetail/InvoiceDetail";


export const vendorRoutes = (user) => {
  let SupplierId = user?.SupplierId
  return [

    SupplierId && {
      path: "/dashboard",
      name: "Dashboard",
      rtlName: "لوحة القيادة",
      icon: DashboardIcon,
      component: VendorDashboard,
      layout: "/vendor",
    },
    SupplierId && {

      path: "/create",
      name: "Create Invoice",
      rtlName: "عالتسعير",
      icon: NoteAddIcon,
      rtlMini: "ع",
      component: CreateInvoice,
      layout: "/vendor",

    },
    SupplierId && {

      path: "/invoices",
      name: "Invoices",
      rtlName: "لوحة القيادة",
      icon: InsertDriveFileIcon,
      component: FilesList,
      layout: "/vendor",
    },
    SupplierId && {
      path: "/customerledger",
      name: "Customer 360",
      rtlName: "لوحة القيادة",
      icon: ReceiptIcon,
      component: Payable,
      layout: "/vendor",
    },
    {
      path: "/vendor_profile",
      name: "Profile",
      rtlName: "لوحة القيادة",
      icon: SupervisedUserCircleIcon,
      component: VendorProfile,
      layout: "/vendor",
    },
    {
      path: "/verifier",
      component: Verify,
      layout: "/vendor",
    },
    {
      path: "/user_profile",
      component: UserProfile,
      layout: "/vendor",
    },
    {
      path: "/notifications",
      // name: "Notifications",
      // rtlName: "لوحة القيادة",
      // icon: NotificationsActiveIcon,
      component: Notifications,
      layout: "/vendor",
    },
    {
      path: "/invoiceDetail",
      component: InvoiceDetail,
      layout: "/vendor",
    },

  ]

};
