import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  MenuItem,
  TableBody,
  Table,
  Tooltip,
  TablePagination,
  IconButton,
  Dialog,
  DialogContent,
  makeStyles,
  Slide,
  TextField,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import * as Scroll from "react-scroll";
// core components
import Wizard from "./Wizard.js";
import axios from "axios";
import Success from "assets/img/statuses/Success.png";
import Rejected from "assets/img/statuses/Rejected.png";
import Pending from "assets/img/statuses/Pending.png";
import General from "./steps/general";
import Compliance from "./steps/compliance";
import Others from "./steps/others";
import Attachments from "./steps/attachments";
import Bankdetails from "./steps/bankdetails";
import { submitVendorProfile } from "actions/VendorProfile";
import { useDispatch, useSelector } from "react-redux";
import {
  successAlert,
  errorAlert,
  msgAlert,
  htmlAlert,
} from "views/LDocs/Functions/Functions";
import { submitCompliance } from "actions/VendorProfile.js";
import { submitAttachments } from "actions/VendorProfile.js";
import { submitOthers } from "actions/VendorProfile.js";
import { submitBankDetails } from "actions/VendorProfile.js";
import { CircularProgress } from "@material-ui/core";
import jwt, { decode } from "jsonwebtoken";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { clearAllState } from "actions/VendorProfile.js";
import { useParams, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { GetDetails } from "actions/VendorProfile.js";
import ReCAPTCHA from "react-google-recaptcha";
import { VendorProfileAttachments } from "actions/VendorProfile.js";
import { VendorAttachmentsDelete } from "actions/VendorProfile.js";

export default function BoardingSteps(props) {
  const {
    validationError,
    setError,
    handleSubmit,
    setStepstate,
    scrollHandler,
    setReLoad,
  } = props;
  const [state, setState] = useState({
    currentStep: 0,
  });
  const styles = makeStyles((theme) => ({}));
  const classes = styles();
  setStepstate(state);
  const { id, isedit } = useParams();
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [issedit, setIsEdit] = useState(false);
  const [stopMove, setStopMove] = useState(false);
  const [isupdate, setIsupdate] = useState(false);
  const [isgeneralSuccess, setIsgeneralSuccess] = useState(false);
  const [vendorMsg, setVendorMsg] = useState(false);
  const [finish, setIsfinish] = useState(false);
  const [decoded, setDecoded] = React.useState(null);
  const [view, setView] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [requiredAtth, setRequiredAtth] = useState(false);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [inSideKsa, setInsideKsa] = React.useState(false);
  const [showButton, setShowbutton] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [repatchToken, setRepatchToken] = useState(null);

  const [formState, setFormState] = React.useState({
    values: {
      status: "",
      comments: "",
    },
    errors: {
      status: "",
      reviewComments: "",
    },
  });
  let scroll = Scroll.animateScroll;
  let childClassRef = React.createRef(null);
  const { generalinfo } = useSelector((state) => state.VendorRegister);
  const { workflow } = useSelector((state) => state.VendorRegister) || {};
  const saved_contacts =
    useSelector((state) => state.VendorRegister.contacts?.saved_contacts) || [];
  const saved_address =
    useSelector((state) => state.VendorRegister.address?.saved_address) || [];
  const saved_site =
    useSelector((state) => state.VendorRegister.site?.saved_site) || [];
  const saved_attachments =
    useSelector(
      (state) => state.VendorRegister.attachments?.saved_attachments
    ) || [];
  const attachmentsList =
    useSelector((state) => state.VendorRegister?.attachmentList) || [];
  const saved_bankdetails =
    useSelector(
      (state) => state.VendorRegister.bankdetails?.saved_bankdetails
    ) || [];
  const countryList = useSelector((state) => state.VendorRegister.countryList);
  const compliancedata = useSelector(
    (state) => state.VendorRegister.compliance
  );
  const othersdata = useSelector((state) => state.VendorRegister.others);

  const {
    isError,
    inProcess,
    isSuccess,
    errorMessage,
    isEdit,
    generalSuccess,
    lastStep,
    VpToken,
  } = useSelector((state) => state.SubmitVendor);
  const dispatch = useDispatch();
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let vendorId = id || decoded?.id || "";
  const [attchmentData, setAttchmentData] = useState();
  let userDetail = jwt.decode(Token);

  //Path Name
  const location = useLocation();

  useEffect(() => {
    setVendorMsg(false);
    _getUserDetails();
  }, []);

  //Default Attachment Handler for KSA ..
  React.useEffect(() => {
    if (saved_address[0]?.country === "Saudi Arabia") {
      setAttchmentData(attachmentsList);
      setInsideKsa(true);
    } else {
      setAttchmentData(attachmentsList);
      setInsideKsa(false);
    }
  }, [saved_address]);

  //Handle Image ..
  useEffect(() => {
    if (generalSuccess) {
      handleSubmit();
    }
  }, [generalSuccess]);

  //Vendor Edit ..
  useEffect(() => {
    if (vendorId) {
      setEdit(true);
    }
    setDecoded(userDetail);
  }, [vendorId]);

  //Handle Admin Edit
  useEffect(() => {
    if (isedit === "true") {
      setView(true);
    }
    if (isedit === "false") {
      setView(false);
    }
  }, [isedit]);

  //GET USER INFORMATION
  const _getUserDetails = async () => {
    await axios
      .get(`http://ip-api.com/json/`)
      .then(async (response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Save General Information
  const submitVendor = () => {
    if (id) {
      let dispatchPayload = {
        generalinfo,
        saved_contacts,
        saved_address,
        saved_site,
        LastUpdatedBy: decoded?.displayName,
        isEdit: true,
        countryList,
        user: userData,
      };
      setIsupdate(true);
      setIsEdit(true);
      setIsgeneralSuccess(true);
      setIsfinish(false);
      setRequiredAtth(true);
      setApiError(true);
      dispatch(submitVendorProfile(dispatchPayload));
    } else {
      if (location.pathname === "/supplier/register") {
        let dispatchPayload = {
          generalinfo,
          saved_contacts,
          saved_address,
          saved_site,
          isEdit,
          CreatedBy: generalinfo?.vendorName,
          selfRegister: true,
          countryList,
          user: userData,
          recaptchaToken: repatchToken,
          vendorToken: !decoded ? VpToken : Token,
        };
        setIsupdate(false);
        setIsgeneralSuccess(true);
        setVendorMsg(true);
        setIsfinish(false);
        setRequiredAtth(true);
        setApiError(true);
        dispatch(submitVendorProfile(dispatchPayload));
      } else if (decoded) {
        if (location.pathname.includes("/vendor/vendor_profile")) {
          let dispatchPayload = {
            generalinfo,
            saved_contacts,
            saved_address,
            LastUpdatedBy: decoded?.name,
            saved_site,
            isEdit: true,
            countryList,
            user: userData,
            vendorToken: !decoded ? VpToken : Token,
          };
          setIsupdate(true);
          setIsEdit(true);
          setIsgeneralSuccess(true);
          setIsfinish(false);
          setRequiredAtth(true);
          setApiError(true);
          dispatch(submitVendorProfile(dispatchPayload));
        } else if (location.pathname.includes("/admin/vendor_profile/")) {
          let dispatchPayload = {
            generalinfo,
            saved_contacts,
            saved_address,
            saved_site,
            isEdit: true,
            CreatedBy: decoded?.displayName,
            LastUpdatedBy: decoded?.displayName,
            selfRegister: false,
            countryList,
            user: userData,
            vendorToken: !decoded ? VpToken : Token,
          };
          setIsupdate(false);
          setIsEdit(true);
          setIsgeneralSuccess(true);
          setIsfinish(false);
          setRequiredAtth(true);
          setApiError(true);
          dispatch(submitVendorProfile(dispatchPayload));
        } else if (location.pathname.includes("/default/vendorprofile/")) {
          let dispatchPayload = {
            generalinfo,
            saved_contacts,
            saved_address,
            saved_site,
            isEdit: true,
            CreatedBy: decoded?.displayName,
            LastUpdatedBy: decoded?.displayName,
            selfRegister: false,
            countryList,
            user: userData,
            vendorToken: !decoded ? VpToken : Token,
          };
          setIsupdate(false);
          setIsEdit(true);
          setIsgeneralSuccess(true);
          setIsfinish(false);
          setRequiredAtth(true);
          setApiError(true);
          dispatch(submitVendorProfile(dispatchPayload));
        } else if (location.pathname.includes("/admin/vendor_Register")) {
          let dispatchPayload = {
            generalinfo,
            saved_contacts,
            saved_address,
            saved_site,
            isEdit: true,
            CreatedBy: decoded?.displayName,
            LastUpdatedBy: decoded?.displayName,
            selfRegister: false,
            countryList,
            user: userData,
            vendorToken: !decoded ? VpToken : Token,
          };
          setIsupdate(false);
          setIsEdit(true);
          setIsgeneralSuccess(true);
          setIsfinish(false);
          setRequiredAtth(true);
          setApiError(true);
          dispatch(submitVendorProfile(dispatchPayload));
        }
      }
    }
  };
  const compilance = () => {
    setIsgeneralSuccess(true);
    setApiError(true);
    if (id) {
      setIsupdate(true);
    }
    if (state.currentStep == 3) {
      setIsgeneralSuccess(true);
      let email = generalinfo.email.toLowerCase();
      let updatedBy = decoded?.displayName;
      let vendorToken = !decoded ? VpToken : Token;
      dispatch(
        submitCompliance(
          compliancedata,
          email,
          updatedBy,
          repatchToken,
          vendorToken
        )
      );
    }
  };
  const others = () => {
    setIsgeneralSuccess(true);
    setApiError(true);
    if (id) {
      setIsupdate(true);
    }
    if (state.currentStep == 4) {
      setRequiredAtth(true);
      let email = generalinfo.email.toLowerCase();
      let updatedBy = decoded?.displayName;
      let vendorToken = !decoded ? VpToken : Token;
      dispatch(
        submitOthers(othersdata, email, updatedBy, repatchToken, vendorToken)
      );
    }
  };
  const attachments = () => {
    let error = false;
    let errorString = '<ul style="text-align:left">';
    if (inSideKsa) {
      let requiredAttachments = attchmentData
        .filter((att) => att.insideKsa)
        .map((attach) => {
          let attachmentCount =
            saved_attachments.filter(
              (att) =>
                att.attachmentTitle.split("/")[0] == attach.attachmentTitle
            )?.length || 0;

          if (attachmentCount < 1) {
            error = true;
            errorString += `<li>${attach.attachmentTitle}</li>`;
          }
          return {
            title: attach.attachmentTitle,
            count: attachmentCount,
          };
        });
      errorString += "</ul>";
    } else {
      let requiredAttachments = attchmentData
        .filter((att) => att.outsideKsa)
        .map((attach) => {
          let attachmentCount =
            saved_attachments.filter(
              (att) =>
                att.attachmentTitle.split("/")[0] == attach.attachmentTitle
            )?.length || 0;

          if (attachmentCount < 1) {
            error = true;
            errorString += `<li>${attach.attachmentTitle}</li>`;
          }
          return {
            title: attach.attachmentTitle,
            count: attachmentCount,
          };
        });
      errorString += "</ul>";
    }
    if (state.currentStep == 1 && error == true) {
      htmlAlert({ title: `Attachment Required`, html: errorString });
      setRequiredAtth(false);
      setIsupdate(false);
    } else {
      setIsupdate(true);
      setRequiredAtth(true);
      setIsgeneralSuccess(true);
      setApiError(true);
      let email = generalinfo.email.toLowerCase();
      let updatedBy = decoded?.displayName;
      let vendorToken = !decoded ? VpToken : Token;
      dispatch(
        submitAttachments(
          saved_attachments,
          email,
          updatedBy,
          repatchToken,
          vendorToken
        )
      );
    }
  };
  const bank_details = () => {
    if (id) {
      setIsupdate(true);
    }
    if (state.currentStep == 2) {
      let email = generalinfo.email.toLowerCase();
      let updatedBy = decoded?.displayName;
      let vendorToken = !decoded ? VpToken : Token;
      setIsgeneralSuccess(true);
      setApiError(true);
      dispatch(
        submitBankDetails(
          saved_bankdetails,
          email,
          updatedBy,
          repatchToken,
          vendorToken
        )
      );
    }
  };
  const moveNext = (step) => {
    scrollHandler();
    let currentStep = childClassRef.current?.state?.currentStep || "";

    if (typeof step === "number") {
      setState({
        ...state,
        currentStep: step,
      });
      childClassRef.current.navigationStepChange(step);
    } else {
      setState({
        ...state,
        currentStep: state?.currentStep + 1,
      });
      childClassRef.current.navigationStepChange(state?.currentStep + 1);
    }
  };
  const handlePrevious = (step) => {
    let currentStep = childClassRef.current?.state?.currentStep;
    if (typeof step === "number") {
      setState({
        ...state,
        currentStep: step,
      });
      childClassRef.current.navigationStepChange(step);
    } else {
      setState({
        ...state,
        currentStep: state?.currentStep - 1,
      });
      childClassRef.current.navigationStepChange(state?.currentStep - 1);
    }
  };

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyLength = (value) => {
    var fieldlength = value.length;
    if (fieldlength >= 6) {
      return true;
    }
    return false;
  };

  const verifyContact = (value) => {
    var fieldlength = value.length;
    if (fieldlength <= 4) {
      return false;
    }
    return true;
  };

  const handlerSubmit = () => {
    // if (check == false) {
    //   setStopMove(false)
    // } else {
    //   setStopMove(true)
    // }

    let {
      vendorName,
      email,
      contactNumber,
      taxPayerIdcrNum,
      taxRegistrationNum,
      type_of_organization,
    } = generalinfo || {};

    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;
    let v_email;
    let v_vendor_name;
    let v_contact_number;
    let v_taxPayerIdcrNum;
    let v_taxRegistrationNum;
    let v_type_of_organization;

    if (!Check(email)) {
      if (verifyEmail(email)) {
        v_email = "success";
      } else {
        v_email = "error";
        error = true;
      }
    } else {
      v_email = "error";
      error = true;
    }

    if (!Check(vendorName)) {
      v_vendor_name = "success";
    } else {
      v_vendor_name = "error";
      error = true;
    }

    if (!Check(contactNumber)) {
      if (verifyContact(contactNumber)) {
        v_contact_number = "success";
      } else {
        v_contact_number = "error";
        error = true;
      }
    } else {
      v_contact_number = "error";
      error = true;
    }

    // if (!Check(contactNumber)) {
    //   v_contact_number = 'success'
    // } else {
    //   v_contact_number = 'error'
    //   error = true
    // }

    if (!Check(taxPayerIdcrNum)) {
      if (verifyLength(taxPayerIdcrNum)) {
        v_taxPayerIdcrNum = "success";
      } else {
        v_taxPayerIdcrNum = "error";
        error = true;
      }
    } else {
      v_taxPayerIdcrNum = "error";
      error = true;
    }
    if (!Check(taxRegistrationNum)) {
      if (verifyLength(taxRegistrationNum)) {
        v_taxRegistrationNum = "success";
      } else {
        v_taxRegistrationNum = "error";
        error = true;
      }
    } else {
      v_taxRegistrationNum = "error";
      error = true;
    }

    if (!Check(type_of_organization)) {
      v_type_of_organization = "success";
    } else {
      v_type_of_organization = "error";
      error = true;
    }

    if (error) {
      scrollHandler();
      setError({
        vendorName: v_vendor_name,
        contactNumber: v_contact_number,
        vendorEmail: v_email,
        taxPayerIdcrNum: v_taxPayerIdcrNum,
        taxRegistrationNum: v_taxRegistrationNum,
        type_of_organization: v_type_of_organization,
        error: error,
      });
    } else {
      setError({
        vendorName: "",
        contactNumber: "",
        vendorEmail: "",
        taxPayerIdcrNum: "",
        taxRegistrationNum: "",
        type_of_organization: "",
        error: "",
      });
      //
    }
    if (saved_contacts.length == 0 && saved_address.length == 0 && !error) {
      msgAlert("Minimum one Contact and Address is required");
    }
    if (saved_contacts.length == 0 && saved_address.length !== 0 && !error) {
      msgAlert("Minimum one Contact is required");
    }
    if (saved_contacts.length !== 0 && saved_address.length == 0 && !error) {
      msgAlert("Minimum one Address is required");
    }
    if (state.currentStep == 2 && saved_bankdetails.length == 0 && !error) {
      msgAlert("Bank detail is required");
    } else if (
      !error &&
      saved_contacts.length !== 0 &&
      saved_address.length !== 0 &&
      saved_bankdetails !== 0
    ) {
      if (!decoded) {
        window.grecaptcha.reset();
        setRepatchToken(null);
      }
      switch (state.currentStep) {
        case 0:
          submitVendor();
          break;
        case 1:
          attachments();
          break;
        case 2:
          bank_details();
          break;
        case 3:
          compilance();
          break;
        case 4:
          others();
          break;
        default:
          break;
      }
    }
  };

  const displayAttachmentErrMsg = (errorArr) => {
    let errorString = '<ul style="text-align:left">';
    let requiredAttachments = errorArr.map((attach, index) => {
      let attachmentCount = index + 1;
      let fileIndex = saved_attachments?.findIndex(
        (att) => att?.attachmentTitle === attach.name
      );
      dispatch(VendorAttachmentsDelete(fileIndex));
      errorString += `<li>${attach.name} is not valid</li>`;
      return {
        title: attach.name,
        count: attachmentCount,
      };
    });
    errorString += "</ul>";

    htmlAlert({ title: `Attachment Failed`, html: errorString });
  };

  useEffect(() => {
    //Error Handling
    if (!inProcess && isError && apiError && state.currentStep !== 1) {
      errorAlert(errorMessage || "Something Went Wrong ...");
    }
    // for attachments step validation
    if (state.currentStep === 1 && !inProcess && isError && apiError) {
      displayAttachmentErrMsg(errorMessage?.wrongFiles);
    }
    //Stop move will be false in save (Button)
    if (!inProcess && isSuccess && isgeneralSuccess && !isError) {
      if (
        state.currentStep !== 4 &&
        !location.pathname.includes("/vendor/vendor_profile") &&
        !location.pathname.includes("/default/vendorprofile/") &&
        !location.pathname.includes("/admin/vendor_profile")
      ) {
        moveNext();
      } else if (
        state.currentStep == 4 &&
        location.pathname.includes("/supplier/register")
      ) {
        successAlert(
          "Thank you for your interest to work with SALIC, your application submit successfully and it will go through review and approval process. Please check your email for details."
        );
        history.push("/supplier/login");
      } else if (location.pathname.includes("/admin/vendor_profile")) {
        successAlert("Updated Successfully");
      } else if (
        state.currentStep == 4 &&
        location.pathname.includes("/admin/vendor_Register")
      ) {
        successAlert("Registered Successfully");
        history.push("/admin/suppliers");
      } else if (location.pathname.includes("/vendor/vendor_profile")) {
        successAlert("Updated Successfully");
      } else if (location.pathname.includes("/default/vendorprofile")) {
        successAlert("Updated Successfully");
      }
    }
    // isSuccess && generalSuccess && vendorAlert()
    // isSuccess && isgeneralSuccess && MsgAlert()
    // // isSuccess && decoded?.role?.isAdmin && isEdit && editAlert();
    // isSuccess && stopMove && moveNext()
    // isSuccess && isupdate && requiredAtth && editAlert()
    // isSuccess && finish && finishalert()
    // isSuccess && lastStep && decoded?.role?.isAdmin && goback()
  }, [isError, isSuccess, generalSuccess]);

  const handlerChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  /*getting/destructure workflow */
  let workFlowStatus = workflow?.workFlowStatus;

  const reviewSupplier = async () => {
    setReviewModal(true);
  };

  const reviewSupplierNow = async () => {
    setisloading(true);
    let postData = {
      vendorId: vendorId,
      action:
        workFlowStatus?.status == "initialReviewStep"
          ? "initialReview"
          : workFlowStatus?.status == "reviewStep"
          ? "review"
          : workFlowStatus?.status == "approveStep"
          ? "approve"
          : "",
      user: decoded.email,
      comment: formState.values.comments,
      status: formState.values.status,
    };
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/updateFlowStatus`,
      data: postData,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        dispatch(GetDetails(id, Token));
        setReviewModal(false);
        setisloading(false);
        setReLoad(true);
        if (workFlowStatus?.status == "initialReviewStep" || "ReviewStep") {
          successAlert("Reviewed Successfully");
        }
        if (workFlowStatus?.status == "approveStep") {
          successAlert("Approved Successfully");
        }
        if (formState?.values?.status === "correctionRequired") {
          successAlert("Sent For Correction Required");
        }
      })
      .catch((err) => {
        let error = err?.response?.data;
        errorAlert(error);
        setisloading(false);
        setReviewModal(false);
      });
  };

  return (
    <>
      <Wizard
        validate
        steps={[
          {
            stepName: "General",
            stepComponent: General,
            stepId: "general",
          },
          {
            stepName: "Attachments",
            stepComponent: Attachments,
            stepId: "attachments",
          },
          {
            stepName: "Bank Details",
            stepComponent: Bankdetails,
            stepId: "bankdetails",
          },
          {
            stepName: "Compliance",
            stepComponent: Compliance,
            stepId: "compliance",
          },
          {
            stepName: "Others",
            stepComponent: Others,
            stepId: "others",
          },
        ]}
        title={"User Details"}
        subtitle=""
        seterror={setError}
        ref={childClassRef}
        edit={edit}
        validationError={validationError}
        moveNext={moveNext}
        movePrevious={handlePrevious}

        // finishButtonClick={(e) => alert(e)}
        // Pass Props here
      />

      {/* if admin and requester and last step  */}

      {!showButton
        ? state.currentStep === 4 &&
          location.pathname !== "/admin/vendor_Register" &&
          location.pathname !== "/supplier/register" &&
          userDetail &&
          !userDetail?.isVendor &&
          workFlowStatus?.approveStep?.status !== "APPROVED"
          ? workFlowStatus?.status === "approveStep"
            ? workFlowStatus[workFlowStatus.status]?.approvedBy ==
                decoded?.email && (
                <Button
                  color="danger"
                  style={{ float: "right" }}
                  onClick={() => reviewSupplier()}
                >
                  {isloading ? (
                    <CircularProgress />
                  ) : workFlowStatus?.status == "initialReviewStep" ? (
                    "Initial Review"
                  ) : workFlowStatus?.status == "reviewStep" ? (
                    "Review"
                  ) : workFlowStatus?.status == "approveStep" ? (
                    "Approve"
                  ) : (
                    ""
                  )}
                </Button>
              )
            : workFlowStatus[(workFlowStatus?.status)]?.reviewedBy ==
                decoded?.email && (
                <Button
                  color="danger"
                  style={{ float: "right" }}
                  onClick={() => reviewSupplier()}
                >
                  {isloading ? (
                    <CircularProgress />
                  ) : workFlowStatus?.status == "initialReviewStep" ? (
                    "Initial Review"
                  ) : workFlowStatus?.status == "reviewStep" ? (
                    "Review"
                  ) : workFlowStatus?.status == "approveStep" ? (
                    "Approve"
                  ) : (
                    ""
                  )}
                </Button>
              )
          : ""
        : ""}

      {!view ? (
        <>
          {state?.currentStep > 0 ? (
            <Button style={{ float: "left" }} onClick={handlePrevious}>
              Previous
            </Button>
          ) : (
            ""
          )}

          {/* {state.currentStep === 4 &&
            userDetail &&
            !userDetail?.isVendor &&
            workFlowStatus[workFlowStatus?.status]?.status == 'pending' && (
              <Button
                color='danger'
                style={{ float: 'right' }}
                onClick={() => reviewSupplier()}
              >
                Approved/Rejected
              </Button>
            )} */}

          {state?.currentStep < 4 ? (
            <>
              {!isedit && location.pathname.includes("/default/vendorprofile/") ? (
                <Button
                  color="danger"
                  style={{ float: "right" }}
                  onClick={() => moveNext()}
                >
                  Next
                </Button>
              ) : (
                ""
              )}
              {location.pathname.includes("/vendor/vendor_profile") ||
              location.pathname.includes("/default/vendorprofile/") ||
              location.pathname.includes("/admin/vendor_profile/") ? (
                <>
                  <Button
                    color="danger"
                    style={{ float: "right" }}
                    onClick={() => moveNext()}
                  >
                    Next
                  </Button>
                  <Button
                    style={{ float: "right" }}
                    color="danger"
                    onClick={handlerSubmit}
                  >
                    {inProcess ? (
                      <CircularProgress style={{ color: "#fff" }} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </>
              ) : (
                ""
              )}
              {location.pathname.includes("/supplier/register") ||
              location.pathname.includes("/admin/vendor_Register") ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  {!decoded && (
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GOOGLE_KEY}
                      onChange={(value) => setRepatchToken(value)}
                    />
                  )}
                  <Button
                    disabled={!decoded && !repatchToken}
                    onClick={() => handlerSubmit()}
                    color="danger"
                  >
                    {inProcess ? (
                      <CircularProgress style={{ color: "#fff" }} />
                    ) : (
                      "Save & Next"
                    )}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {!decoded && (
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_KEY}
                  onChange={(value) => setRepatchToken(value)}
                />
              )}
              <Button
                color="danger"
                disabled={!decoded && !repatchToken}
                onClick={handlerSubmit}
              >
                {inProcess ? (
                  <CircularProgress style={{ color: "#fff" }} />
                ) : location.pathname.includes("/admin/vendor_Register") ||
                  location.pathname.includes("/supplier/register") ? (
                  "Submit"
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          {state?.currentStep > 0 ? (
            <Button style={{ float: "left" }} onClick={handlePrevious}>
              Previous
            </Button>
          ) : (
            ""
          )}
          {state?.currentStep < 4 ? (
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={moveNext}
            >
              Next
            </Button>
          ) : (
            ""
          )}
        </>
      )}
      {reviewModal ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              open={reviewModal}
              // TransitionComponent={Transition}
              keepMounted
              onClose={() => setReviewModal(false)}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          {/* <h4 className={classes.cardTitle}>Approve/Reject</h4> */}
                          <h4>
                            {workFlowStatus?.status == "initialReviewStep"
                              ? "INITIAL REVIEW"
                              : workFlowStatus?.status == "reviewStep"
                              ? "REVIEW"
                              : workFlowStatus?.status == "approveStep"
                              ? "APPROVE"
                              : "Action"}
                          </h4>
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            fullWidth={true}
                            select
                            label="Status"
                            name="status"
                            onChange={(event) => {
                              handlerChange(event);
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                            >
                              Choose Status
                            </MenuItem>
                            {workFlowStatus?.status === "approveStep" ? (
                              <MenuItem value="APPROVED">
                                <div className="fileinput text-center">
                                  <div className="thumbnail img-circle3">
                                    <img
                                      src={Success}
                                      alt={"MARK AS APPROVED"}
                                    />
                                  </div>
                                  MARK AS APPROVE
                                </div>
                              </MenuItem>
                            ) : (
                              <MenuItem value="REVIEWED">
                                <div className="fileinput text-center">
                                  <div className="thumbnail img-circle3">
                                    <img
                                      src={Success}
                                      alt={"MARK AS REVIEWED"}
                                    />
                                  </div>
                                  MARK AS REVIEW
                                </div>
                              </MenuItem>
                            )}
                            <MenuItem value="correctionRequired">
                              <div className="fileinput text-center">
                                <div className="thumbnail img-circle3">
                                  <img
                                    src={Pending}
                                    alt={"correction Required"}
                                  />
                                </div>
                                CORRECTION REQUIRED
                              </div>
                            </MenuItem>
                            <MenuItem value="rejected">
                              <div className="fileinput text-center">
                                <div className="thumbnail img-circle3">
                                  <img
                                    src={Rejected}
                                    alt={"MARK AS REJECTED"}
                                  />
                                </div>
                                MARK AS REJECTED
                              </div>
                            </MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            fullWidth={true}
                            label="Comments"
                            name="comments"
                            onChange={(event) => {
                              handlerChange(event);
                            }}
                            // value={formState.values.reviewComments || ""}
                          ></TextField>
                        </GridItem>
                        <span style={{ float: "right" }}>
                          <Button
                            color="info"
                            className={classes.registerButton}
                            round
                            type="button"
                            onClick={() => reviewSupplierNow()}
                          >
                            {isloading ? (
                              <CircularProgress style={{ color: "#fff" }} />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                          <Button
                            color="danger"
                            className={classes.registerButton}
                            onClick={() => setReviewModal(false)}
                            round
                          >
                            CLOSE
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
    </>
  );
}
