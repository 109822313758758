import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, Checkbox } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icon
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import View from "./ViewAccount";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import AddAccount from "./addAccount";
import { addZeroes, errorAlert, successAlert } from "../Functions/Functions";
import { msgAlert } from "Functions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function COA() {
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);
  const [organizationFilter, setOrganizationFilter] = React.useState("");
  const [organizations, setOrganizations] = React.useState([]);
  const [viewModal, setViewModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [Updating, setUpdating] = React.useState(false);
  const [AccDetail, setAccDetail] = React.useState();
  const [decoded, setDecoded] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [importingExcel, setImportingExcel] = React.useState(false);
  const [exportingExcel, setExportingExcel] = React.useState(false);
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let userDetail = jwt.decode(Token);
  let importInput = React.createRef();
  const dispatch = useDispatch();
  React.useEffect(() => {
    userDetail = jwt.decode(Token);
    setDecoded(userDetail);
    getCOA();
    // getOrganizations(userDetail);
  }, []);

  const viewAccount = (row) => {
    setAccDetail(row);
    setUpdating(false);
    setViewModal(true);
  };

  const updateAcc = (row) => {
    setAccDetail(row);
    setUpdating(true);
    setViewModal(true);
  };

  //Get COA
  const getCOA = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getAccounts/null`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        response.data.map(({ records }) => {
          let accessor_data = records.map((prop) => {
            return {
              id: prop._id,
              Acc_NO: prop.Acc_NO,
              Acc_Opening_Balance: addZeroes(prop.Acc_Opening_Balance),
              Status: <Checkbox checked={prop.Status} />,
              Acc_Type: prop.Acc_Type,
              Acc_Ref_Remarks: prop.Acc_Ref_Remarks,
              Acc_Description: prop.Acc_Description,
              Acc_category:prop.Acc_category,
              summary_flag:prop.Summery_Flag,
              default: <Checkbox checked={prop.default} />,
              actions: (
                <div className="actions-right">
                  <Tooltip title="Update Account" aria-label="updateAccount">
                    <Button
                      justIcon
                      round
                      simple
                      icon={EditIcon}
                      onClick={() => updateAcc(prop)}
                      color="info"
                      className="View"
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="View Account" aria-label="viewlocation">
                    <Button
                      justIcon
                      round
                      simple
                      icon={VisibilityIcon}
                      onClick={() => viewAccount(prop)}
                      color="warning"
                      className="View"
                    >
                      <VisibilityIcon />
                    </Button>
                  </Tooltip>
                </div>
              ),
            };
          });
          setData((data) => [...data, ...accessor_data]);
        });

        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setIsLoading(false);
      });
  };

  const importTemp = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    setImportingExcel(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/uploadAccountsSheet`,
      data: formData,
      headers: { cooljwt: Token },
    })
      .then(async (result) => {
        getCOA();
        setImportingExcel(false);
        successAlert("Imported Successfully");
      })
      .catch((error) => {
        setImportingExcel(false);
        errorAlert("Please Use Valid Template");
      });
  };

  const exportTOXlrs = () => {
    setExportingExcel(true);
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/exportAccountToXlxs`,
      headers: {
        cooljwt: Token,
        // responseType: 'blob',
      },
    })
      .then((response) => {
        setExportingExcel(false);
        const downloadUrl = `${process.env.REACT_APP_LDOCS_API_URL}/${response.data.path}`;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", ""); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        setExportingExcel(false);
        console.log(error);
      });
  };


  const syncCoa = async () => {
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/syncAccountsandDepartment`,
      headers: {
        cooljwt: Token,
        // responseType: 'blob',
      },
    })
      .then((response) => {       
      })
      .catch((error) => {
        console.log(error);
      });
      msgAlert("Sync is in progress. It may take some time, you can continue using system.");
  }

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classes.center}>
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={classicModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setClassicModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}
            >
              <AddAccount
                closeModal={() => setClassicModal(false)}
                getCOA={getCOA}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={viewModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setViewModal(false)}
            aria-labelledby="view-modal-slide-title"
            aria-describedby="view-modal-slide-description"
          >
            <DialogContent
              id="view-modal-slide-description"
              className={classes.modalBody}
            >
              {viewModal ? (
                <View
                  closeModal={() => setViewModal(false)}
                  getCOA={getCOA}
                  AccDetail={AccDetail}
                  Updating={Updating}
                />
              ) : (
                ""
              )}
            </DialogContent>
          </Dialog>
        </GridItem>
      </GridContainer>
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Chart of Accounts</h4>
                </CardIcon>
                {/* <Button
                  color="danger"
                  round
                  className={classes.marginRight}
                  style={{ float: "right", marginLeft: 20 }}
                  onClick={() => setClassicModal(true)}
                >
                  Add New
                </Button> */}
                <Tooltip
                  id="tooltip-top"
                  title="Export to Excel"
                  style={{ float: "right" }}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="danger"
                    round
                    className={classes.marginRight}
                    onClick={exportTOXlrs}
                  >
                      {exportingExcel ? <CircularProgress style={{width:20,height:20, color:"white"}} /> : "Export"} 
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Export to Excel"
                  style={{ float: "right" }}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="info"
                    round
                    className={classes.marginRight}
                    onClick={syncCoa}
                  >
                    Sync
                  </Button>
                </Tooltip>
                <div
                  style={{
                    display: "flex",
                    float: "right",
                    flexDirection: "column",
                    marginRight: 5,
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="Import from EXCLE"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="info"
                      round
                      disabled={importingExcel}
                      className={classes.marginRight}
                      onClick={() => importInput.current.click()}
                    >
                      {importingExcel ? <CircularProgress style={{width:20,height:20, color:"white"}} /> : "Import"} 
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Template"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <a
                      href={`${process.env.REACT_APP_LDOCS_API_URL}/invoicetemplates/Accounts.xlsx`}
                      download
                      target="_blank"
                    >
                      Import Template
                    </a>
                  </Tooltip>
                </div>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <ReactTable
                    data={data}
                    sortable={false}
                    columns={[
                      {
                        Header: "Account Description",
                        accessor: "Acc_Description",
                        filterable: true,
                        filter: "fuzzyText",
                      },
                      {
                        Header: "Account Number",
                        accessor: "Acc_NO",
                        filterable: true,
                        filter: "fuzzyText",
                      },
                      {
                        Header:"Account Category",
                        accessor:"Acc_category",
                        filterable: true,
                        filter: "fuzzyText",
                      },
                      {
                        Header: "Account Type",
                        accessor: "Acc_Type",
                        filterable: true,
                        filter: "fuzzyText",
                      },
                      {
                        Header: "Summary Flag",
                        accessor: "summary_flag",
                        filterable: true,
                        filter: "fuzzyText",
                      },
                      {
                        Header: "Status",
                        accessor: "Status",
                      },
                      {
                        Header: "Default",
                        accessor: "default",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem
            style={{
              marginTop: "20px",
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <div className="fileinput text-center">
              <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={importTemp}
                ref={importInput}
              />
            </div>
          </GridItem>
        </GridContainer>
      </Animated>
    </div>
  );
}
