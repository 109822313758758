import { LOGS_LIST_SUCCESS , LOGS_LIST_REQUEST, LOGS_LIST_FAIL} from "actions/Constant";

let  initialState = {
    loading:false,
    logsList:[],
    error:null
}


export function logsListReducer(state = initialState, action) {
  switch (action.type) {
    case LOGS_LIST_REQUEST:
      return { loading: true, logsList: [] };
    case LOGS_LIST_SUCCESS:
      return { loading: false, logsList: action.payload };
    case LOGS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}