import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Animated } from "react-animated-css";
import { successAlert } from "Functions";
import { Link, useHistory } from "react-router-dom";
import { errorAlert } from "Functions";
import login from "assets/img/login_salic1.jpg";
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = makeStyles(styles);

export default function ForgetVendorPassword(props) {
  const [emailError, setemailError] = useState({
    email: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [apiErrorMessage, setAPIErrorMessage] = useState("");
  const [logging, setlogging] = useState(false);
  const [recoveryemail, setRecoveryemail] = useState("");
  const [repatchToken, setRepatchToken] = useState(null);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleChange = (event) => {
    event.persist();
    setRecoveryemail(event.target.value);
  };
  const handlePassword = (event) => {
    event.persist();
    setPassword(event.target.value);
  };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const sendRecoveryEmail = async () => {
    const Check = require("is-null-empty-or-undefined").Check;
    var email_error = false;
    let v_recoveryEmail;

    if (!Check(recoveryemail)) {
      if (verifyEmail(recoveryemail)) {
        v_recoveryEmail = "success";
      } else {
        v_recoveryEmail = "error";
        email_error = true;
      }
    } else {
      v_recoveryEmail = "error";
      email_error = true;
    }

    if (email_error) {
      setemailError({
        email: v_recoveryEmail,
      });
    } else {
      setemailError({
        email: "",
      });
      if (!repatchToken) {
        setAPIErrorMessage("RECAPTCHA REQUIRED!");
        return;
      }
      setAPIErrorMessage("");
      setlogging(true);
      await axios({
        method: "post", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/forgetPassword`,
        data: {
          email: recoveryemail,
          recaptchaToken: repatchToken,
        },
      })
        .then((res) => {
          setlogging(false);
          successAlert("Email Sent Successfully");
        })
        .catch((err) => {
          let error = err?.response?.data;
          console.log(err);
          setlogging(false);
          errorAlert(error);
        });
    }
  };
  const handleLoginIn = (event) => {
    history.push("/supplier/new_password");
  };
  return (
    <div
      style={{
        padding: "120px 0",
        position: "relative",
        minHeight: "100vh",
        display: "flex!important",
        margin: "0",
        border: "0",
        backgroundImage: ` url(${login})`,
        // color: whiteColor,
        alignItems: "center",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
      }}
    >
      <div
        //   style={{
        //     position: 'fixed',
        //     left: '20%',
        //     right: '0%',
        //     top: '30%',
        //     margin: '0px',
        //     padding: '0px'
        //   }}
        className={classes.container}
      >
        <Animated
          animationIn="bounceInUp"
          animationOut="bounceOutUp"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={true}
        >
          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                    >
                      <h4 className={classes.cardTitle}>Forgot Password</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        error={emailError.email === "error"}
                        helperText={
                          emailError.email === "error"
                            ? "Valid email is required"
                            : null
                        }
                        labelText="Email ID"
                        id="loginName"
                        name="loginName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => {
                            handleChange(event);
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          type: "email",
                        }}
                      />
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_KEY}
                        onChange={(value) => setRepatchToken(value)}
                      />
                      <h6 style={{ color: "red" }}>{apiErrorMessage}</h6>
                    </CardBody>
                    <CardFooter
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={sendRecoveryEmail}
                      >
                        {logging ? (
                          <CircularProgress style={{ color: "#fff" }} />
                        ) : (
                          "Send Recovery Email"
                        )}
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        <Link to={"/supplier/login"}>login</Link>
                        <Link to={"/supplier/register"}>Sign up</Link>
                      </div>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </Animated>
      </div>
    </div>
  );
}
