import { TAX_CLASSIFICATION_FAIL, TAX_CLASSIFICATION_REQUEST, TAX_CLASSIFICATION_SUCCESS } from "actions/Constant";

const initialState = {
    taxClassification: [],
    loading:false
}

export function taxClassificationReducer(state = initialState, action) {

    switch (action.type) {
        case TAX_CLASSIFICATION_REQUEST:
            return { loading: true, taxClassification: [] }
        case TAX_CLASSIFICATION_SUCCESS:
            return { loading: false, taxClassification: action.payload }
        case TAX_CLASSIFICATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }

}

