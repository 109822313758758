export const Purchase_Order_LIST_REQUEST = "Purchase Order_LIST_REQUEST";
export const Purchase_Order_LIST_SUCCESS = "Purchase Order_LIST_SUCCESS";
export const Purchase_Order_SUCCESS_FILTERS = "Purchase_Order_SUCCESS_FILTERS";
export const Purchase_Order_LIST_FAIL = "Purchase Order_LIST_FAIL";

export const INVOICE_FILTER_VALUE = "INVOICE_FILTER_VALUE";
export const SHOW_INVOICE_PAY_VALUE = "SHOW_INVOICE_PAY_VALUE";
export const SHOW_PAID_INVOICE = "SHOW_PAID_INVOICE";

export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_SUCCESS_FILTERS = "SUPPLIER_LIST_SUCCESS_FILTERS";
export const SUPPLIER_LIST_FAIL = "SUPPLIER_LIST_FAIL";

export const LOGS_LIST_REQUEST = "LOGS_LIST_REQUEST";
export const LOGS_LIST_SUCCESS = "LOGS_LIST_SUCCESS";
export const LOGS_LIST_FAIL = "LOGS_LIST_FAIL";

export const TAX_CLASSIFICATION_REQUEST = "TAX_CLASSIFICATION_REQUEST";
export const TAX_CLASSIFICATION_SUCCESS = "TAX_CLASSIFICATION_SUCCESS";
export const SYNC_TAX_CLASSIFICATION_REQUEST =
  "SYNC_TAX_CLASSIFICATION_REQUEST";
export const SYNC_TAX_CLASSIFICATION_SUCCESS =
  "SYNC_TAX_CLASSIFICATION_SUCCESS";
export const TAX_CLASSIFICATION_FAIL = "TAX_CLASSIFICATION_FAIL";

export const VP_CLEAR_ALL_STATE = "VP_CLEAR_ALL_STATE";

export const ORGANIZATION_LIST_REQUEST = "ORGANIZATION_LIST_REQUEST";
export const ORGANIZATION_LIST_SUCCESS = "ORGANIZATION_LIST_SUCCESS";
export const ORGANIZATION_LIST_FAIL = "ORGANIZATION_LIST_FAIL";

export const GET_VENDOR_DETAIL_REQUEST = "GET_VENDOR_DETAIL_REQUEST";
export const GET_VENDOR_DETAIL_SUCCESS = "GET_VENDOR_DETAIL_SUCCESS";
export const GET_VENDOR_DETAIL_FAIL = "GET_VENDOR_DETAIL_FAIL";

export const VP_GENERAL_REQUEST = "VP_GENERAL_REQUEST";
export const VP_GENERAL_SUCCESS = "VP_GENERAL_SUCCESS";
export const VP_GENERAL_FAIL = "VP_GENERAL_FAIL";

export const VP_TOKEN = "VP_TOKEN";

export const VP_PROFILEIMG_REQUEST = "VP_PROFILEIMG_REQUEST";
export const VP_PROFILEIMG_SUCCESS = "VP_PROFILEIMG_SUCCESS";
export const VP_PROFILEIMG_FAIL = "VP_PROFILEIMG_FAIL";

export const SUPPLIER_TYPE_REQUEST = "SUPPLIER_TYPE_REQUEST";
export const SUPPLIER_TYPE_SUCCESS = "SUPPLIER_TYPE_SUCCESS";
export const SUPPLIER_TYPE_FAIL = "SUPPLIER_TYPE_FAIL";

export const ORGANIZATION_TYPE_REQUEST = "ORGANIZATION_TYPE_REQUEST";
export const ORGANIZATION_TYPE_SUCCESS = "ORGANIZATION_TYPE_SUCCESS";
export const ORGANIZATION_TYPE_FAIL = "ORGANIZATION_TYPE_FAIL";

export const ATTACHMENT_TYPE_REQUEST = "ATTACHMENT_TYPE_REQUEST";
export const ATTACHMENT_TYPE_SUCCESS = "ATTACHMENT_TYPE_SUCCESS";
export const ATTACHMENT_TYPE_FAIL = "ATTACHMENT_TYPE_FAIL";

export const VP_CONTACT_REQUEST = "VP_CONTACT_REQUEST";
export const VP_CONTACT_SUCCESS = "VP_CONTACT_SUCCESS";
export const VP_CONTACT_EDIT = "VP_CONTACT_EDIT";
export const VP_CONTACT_FAIL = "VP_CONTACT_FAIL";

export const VP_CONTACT_DISPATCH = "VP_CONTACT_DISPATCH";
export const VP_CONTACT_DELETE = "VP_CONTACT_DELETE";

export const VP_ADDRESS_REQUEST = "VP_ADDRESS_REQUEST";
export const VP_ADDRESS_SUCCESS = "VP_ADDRESS_SUCCESS";
export const VP_ADDRESS_EDIT = "VP_ADDRESS_EDIT";
export const VP_ADDRESS_CLEAR = "VP_ADDRESS_CLEAR";
export const VP_ADDRESS_ONCHANGE = "VP_ADDRESS_ONCHANGE";
export const VP_ADDRESS_FAIL = "VP_ADDRESS_FAIL";

export const VP_ADDRESS_DISPATCH = "VP_ADDRESS_DISPATCH";
export const VP_ADDRESS_DELETE = "VP_ADDRESS_DELETE";

export const VP_SITE_REQUEST = "VP_SITE_REQUEST";
export const VP_SITE_SUCCESS = "VP_SITE_SUCCESS";
export const VP_SITE_FAIL = "VP_SITE_FAIL";

export const VP_SITE_DISPATCH = "VP_SITE_DISPATCH";
export const VP_SITE_DELETE = "VP_SITE_DELETE";

export const VP_ATTACHMENTS_REQUEST = "VP_ATTACHMENTS_REQUEST";
export const VP_ATTACHMENTS_SUCCESS = "VP_ATTACHMENTS_SUCCESS";
export const VP_ATTACHMENTS_FAIL = "VP_ATTACHMENTS_FAIL";

export const VP_ATTACHMENTS_DISPATCH = "VP_ATTACHMENTS_DISPATCH";
export const VP_ATTACHMENTS_DELETE = "VP_ATTACHMENTS_DELETE";

export const VP_BANKDETAILS_REQUEST = "VP_BANKDETAILS_REQUEST";
export const VP_BANKDETAILS_SUCCESS = "VP_BANKDETAILS_SUCCESS";
export const VP_BANKDETAILS_EDIT = "VP_BANKDETAILS_EDIT";
export const VP_BANKDETAILS_CLEAR = "VP_BANKDETAILS_CLEAR";
export const VP_BANKDETAILS_FAIL = "VP_BANKDETAILS_FAIL";

export const VP_BANKDETAILS_DISPATCH = "VP_BANKDETAILS_DISPATCH";
export const VP_BANKDETAILS_DELETE = "VP_BANKDETAILS_DELETE";

export const VP_COMPLIANCE_REQUEST = "VP_COMPLIANCE_REQUEST";
export const VP_COMPLIANCE_SUCCESS = "VP_COMPLIANCE_SUCCESS";
export const VP_COMPLIANCE_FAIL = "VP_COMPLIANCE_FAIL";

export const VP_OTHERS_REQUEST = "VP_OTHERS_REQUEST";
export const VP_OTHERS_SUCCESS = "VP_OTHERS_SUCCESS";
export const VP_OTHERS_FAIL = "VP_OTHERS_FAIL";

export const VP_GETCOUNTRY_REQUEST = "VP_GETCOUNTRY_REQUEST";
export const VP_GETCOUNTRY_SUCCESS = "VP_GETCOUNTRY_SUCCESS";
export const VP_GETCOUNTRY_FAIL = "VP_GETCOUNTRY_FAIL";

export const VP_CITY_REQUEST = "VP_CITY_REQUEST";
export const VP_CITY_SUCCESS = "VP_CITY_SUCCESS";
export const VP_CITY_FAIL = "VP_CITY_FAIL";

export const VP_GETBANK_REQUEST = "VP_GETBANK_REQUEST";
export const VP_GETBANK_SUCCESS = "VP_GETBANK_SUCCESS";
export const VP_GETBANK_FAIL = "VP_GETBANK_FAIL";

export const VP_BRANCHDETAILS_REQUEST = "VP_BRANCHDETAILS_REQUEST";
export const VP_BRANCHDETAILS_SUCCESS = "VP_BRANCHDETAILS_SUCCESS";
export const VP_BRANCHDETAILS_FAIL = "VP_BRANCHDETAILS_FAIL";

export const VP_GETCURRENCY_REQUEST = "VP_GETCURRENCY_REQUEST";
export const VP_GETCURRENCY_SUCCESS = "VP_GETCURRENCY_SUCCESS";
export const VP_GETCURRENCY_FAIL = "VP_GETCURRENCY_FAIL";

export const VP_GENERAL_SUBMIT_REQUEST = "VP_GENERAL_SUBMIT_REQUEST";
export const VP_GENERAL_SUBMIT_SUCCESS = "VP_GENERAL_SUBMIT_SUCCESS";
export const VP_GENERAL_SUBMIT_FAIL = "VP_GENERAL_SUBMIT_FAIL";

export const VP_ATTACHMENTS_SUBMIT_REQUEST = "VP_ATTACHMENTS_SUBMIT_REQUEST";
export const VP_ATTACHMENTS_SUBMIT_SUCCESS = "VP_ATTACHMENTS_SUBMIT_SUCCESS";
export const VP_ATTACHMENTS_SUBMIT_FAIL = "VP_ATTACHMENTS_SUBMIT_FAIL";
export const VP_ATTACHMENTS_SUBMIT_CLEAR = "VP_ATTACHMENTS_SUBMIT_CLEAR";

export const VP_BANK_DETAILS_SUBMIT_REQUEST = "VP_BANK_DETAILS_SUBMIT_REQUEST";
export const VP_BANK_DETAILS_SUBMIT_SUCCESS = "VP_BANK_DETAILS_SUBMIT_SUCCESS";
export const VP_BANK_DETAILS_SUBMIT_FAIL = "VP_BANK_DETAILS_SUBMIT_FAIL";
export const VP_BANK_DETAILS_SUBMIT_CLEAR = "VP_BANK_DETAILS_SUBMIT_CLEAR";

export const VP_COMPLIANCE_SUBMIT_REQUEST = "VP_COMPLIANCE_SUBMIT_REQUEST";
export const VP_COMPLIANCE_SUBMIT_SUCCESS = "VP_COMPLIANCE_SUBMIT_SUCCESS";
export const VP_COMPLIANCE_SUBMIT_FAIL = "VP_COMPLIANCE_SUBMIT_FAIL";
export const VP_COMPLIANCE_SUBMIT_CLEAR = "VP_COMPLIANCE_SUBMIT_CLEAR";

export const VP_OTHERS_SUBMIT_REQUEST = "VP_OTHERS_SUBMIT_REQUEST";
export const VP_OTHERS_SUBMIT_SUCCESS = "VP_OTHERS_SUBMIT_SUCCESS";
export const VP_OTHERS_SUBMIT_FAIL = "VP_OTHERS_SUBMIT_FAIL";
export const VP_OTHERS_SUBMIT_CLEAR = "VP_OTHERS_SUBMIT_CLEAR";

export const GET_DETAILS_BY_ID_REQUEST = "GET_DETAILS_BY_ID_REQUEST";
export const GET_DETAILS_BY_ID_SUCCESS = "GET_DETAILS_BY_ID_SUCCESS";
export const GET_DETAILS_BY_ID_FAIL = "GET_DETAILS_BY_ID_FAIL";
