import React, { useEffect } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDataAction,
  getNotification,
  getTasks,
  setDarkMode,
  setIsTokenExpired,
  logoutUserAction,
} from "../actions";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { checkIsVendorDesk } from "../views/LDocs/Authorization/checkAuthority";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { createMuiTheme, CssBaseline } from "@material-ui/core";

// core components
import VendorNavbar from "components/Navbars/VendorNavbar";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { decode } from "jsonwebtoken";
import { vendorRoutes } from "routes/Vendor";
import UserProfile from "views/LDocs/Profile/Profile";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import Verify from "views/LDocs/Verify/Verify";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { msgAlert, tokenExpiredAlert } from "Functions";
import { useIdleTimer } from "react-idle-timer";
import DeviceUUID from "../../src/views/LDocs/Functions/Functions";
import { sessionTimeout } from "Functions";

let uuid = new DeviceUUID().get();

var ps;

const useStyles = makeStyles(styles);

const checkTimeCompare = (dat) => {
  var taskDate = new Date(dat);
  var nowDate = new Date(Date.now());
  if (
    taskDate.getHours() === nowDate.getHours() &&
    taskDate.getFullYear() === nowDate.getFullYear() &&
    taskDate.getMinutes() === nowDate.getMinutes() &&
    taskDate.getMonth() === nowDate.getMonth() &&
    taskDate.getDate() === nowDate.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

export default function Dashboard(props) {
  const notifications = useSelector((state) => state.userReducer.notifications);
  const isTokenExpired = useSelector(
    (state) => state.userReducer.isTokenExpired
  );
  const Token = useSelector((state) => state.userReducer.Token);
  const { generalinfo } = useSelector((state) => state.VendorRegister);
  let decoded = decode(Token);
  let routes = vendorRoutes(decoded);
  const tasks = useSelector((state) => state.userReducer.tasks);
  const { ...rest } = props;
  const sweetAlertStyle = makeStyles(styles2);
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [darkmood, setDarkMood] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-1.jpg"));
  const [vendorImage, setVendorImage] = React.useState();
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo.png"));
  const [darkLogo, setDarkLogo] = React.useState(
    require("assets/img/logo.png")
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const handleNavigate = (invInfo) => {
    if (invInfo) {
      history.push(
        `/vendor/invoiceDetail?invoiceId=${invInfo?.invoiceId}&&version=${invInfo?.version}&&vendorId=${invInfo?.vendorId}`
      );
    }
  };

  const notify = (msg, invInfo) =>
    toast(msg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClick: () => handleNavigate(invInfo),
    });

  // useEffect(() => {
  //   history.push("/blank")
  // }, []);

  React.useEffect(() => {
    if (decoded?.isVendor) {
      let profileImage = generalinfo?.profileImage || "";
      setVendorImage(profileImage);
    }
  }, [decoded]);
  function fetchData() {
    dispatch(getNotification());
    dispatch(getTasks());
  }

  React.useEffect(() => {
    fetchData();
    setInterval(() => {
      fetchData();
    }, 20000);
    //if (checkIsVendorDesk()) {
    dispatch(getUserDataAction());
    //}
  }, []);

  useEffect(() => {
    if (isTokenExpired) {
      tokenExpiredAlert(
        hideSessionAlert,
        "Session Expired ! Please Login Again"
      );
    }
  }, [isTokenExpired]);

  const theme = createMuiTheme({
    palette: {
      type: darkmood ? "dark" : "light",
    },
  });

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    // if(checkIsVendorDesk()){
    if (navigator.platform.indexOf("Win") > -1) {
      if (mainPanel.current) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = "hidden";
        mainPanel.current.scrollTo(0, 0);
      }
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (mainPanel.current) {
          ps.destroy();
        }
      }
      window.removeEventListener("resize", resizeFunction);
    };

    //  }
  }, []);
  // else if (isVendor && error.response) {
  //   error.response.status == 401 && dispatch(setIsTokenExpired(true));
  //   history.push('/supplier/login')
  // }

  //   useEffect(() => {
  // if (decoded) {
  //   userCheck();
  // }
  //   }, []);

  //   const userCheck = async () => {
  //     await axios({
  //       method: 'get',
  //       url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getAuth`,
  //       headers: { cooljwt: Token }
  //     })
  //       .then(res => {})
  //       .catch(err => {
  //         let error = err?.response?.data
  //         if (decoded?.isVendor == true) {
  //           localStorage.clear()
  //           history.push('/supplier/login')
  //         } else {
  //           localStorage.clear()
  //           history.push('/')
  //         }
  //       })
  //   }

  useEffect(() => {
    var myElement = document.getElementsByClassName("ps");
    myElement.scrollTop = 0;
    window.scrollTo(0, 0);
    if (mainPanel.current) {
      mainPanel.current.scrollTo(0, 0);
    }
  }, [history.location.pathname]);

  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.png"));
        break;
      default:
        setLogo(require("assets/img/logo.png"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes?.length; i++) {
      if (routes[i]?.collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i]?.views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i]?.layout + routes[i]?.path) !==
          -1
        ) {
          return routes[i]?.name;
        } else {
          let name = window.location.pathname.replace("/vendor/", "");
          if (name == "verifier") {
            return "Verifier";
          } else if (name == "user-profile") {
            return "User Profile";
          }
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop?.collapse) {
        return getRoutes(prop?.views);
      }
      if (prop?.layout === "/vendor") {
        return (
          <Route
            path={prop?.layout + prop?.path}
            component={prop?.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const darkMoodStatus = () => {
    setDarkMood(!darkmood);
    dispatch(setDarkMode(!darkmood));
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const sweetClass = sweetAlertStyle();
  const [alert, setAlert] = React.useState(null);

  const hideSessionAlert = () => {
    localStorage.clear();
    history.push("/supplier/login");
    dispatch(setIsTokenExpired(false));
  };

  const handleMarkNotificationRead = (notification_id, status) => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/notify/updateSysNotify`,
      data: {
        _id: notification_id,
        status: status,
      },
      headers: {
        "Content-Type": "application/json",
        cooljwt: Token,
      },
    }).then((response) => {
      fetchData();
    });
  };

  useEffect(() => {
    notifications
      .filter((not) => not.status !== "viewed")
      .map((notif, index) => {
        handleMarkNotificationRead(notif._id, "viewed");
        notify(`${notif.notifyMessage}`, notif?.notificationItem);
      });
  }, [notifications]);

  const onIdle = () => {
    sessionTimeout(
      handleLoggedOut,
      "Your session is expired. Please re-login to continue."
    );
  };

  useIdleTimer({
    timeout: 1500000,
    onIdle: onIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  const handleLoggedOut = async () => {
    dispatch(logoutUserAction());
    history.push("/supplier/login");
    await axios({
      method: "delete", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/deleteFcm`,
      data: {
        deviceId: uuid,
      },
      headers: {
        cooljwt: Token,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.wrapper}>
        {alert}
        {checkIsVendorDesk() ? (
          <Redirect exact from="/" to="/auth/login" />
        ) : (
          <React.Fragment>
            <Sidebar
              routes={routes?.filter((route) => route?.name !== undefined)}
              logoText={process.env.REACT_APP_LDOCS_FOOTER_COPYRIGHT_LEVEL_1}
              logo={logo}
              image={image}
              vendorImage={vendorImage}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              bgColor={bgColor}
              miniActive={miniActive}
              {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
              <VendorNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                isDarkmode={darkmood}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {getRoutes(routes)}
                      {decoded?.supplierStatus == "APPROVED" &&
                      decoded?.SupplierId !== "" ? (
                        <Redirect from="/vendor" to="/vendor/dashboard" />
                      ) : (
                        <Redirect from="/vendor" to="/vendor/vendor_profile" />
                      )}
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {getRoutes(routes)}
                    {decoded?.supplierStatus == "APPROVED" &&
                    decoded?.SupplierId !== "" ? (
                      <Redirect from="/vendor" to="/vendor/dashboard" />
                    ) : (
                      <Redirect from="/vendor" to="/vendor/vendor_profile" />
                    )}
                  </Switch>
                </div>
              )}
              {getRoute() ? <Footer fluid /> : null}
              {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          darkmood={darkmood}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          changeDarkMood={darkMoodStatus.bind(this)}
          miniActive={miniActive}
        /> */}
            </div>
          </React.Fragment>
        )}
      </div>
    </ThemeProvider>
  );
}
