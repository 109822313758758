import React, { useState, useEffect } from "react";
// @material-ui/icons
import {
  makeStyles,
  CircularProgress,
  TextField,
  MenuItem,
  useForkRef,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
// @material-ui/core components

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Animated } from "react-animated-css";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Swal from 'sweetalert2'
import { successAlert, errorAlert, msgAlert } from "views/LDocs/Functions/Functions";
import axios from "axios";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import VendorProfile from "actions/VendorProfile";
import { others } from "actions/VendorProfile";
import { submitOthers } from "actions/VendorProfile";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

export default function Other(props) {
  const classes = useStyles();
  const [animateStep, setAnimateStep] = useState(true);
  const vendordata = useSelector(state => state.VendorRegister.others)
  const Token = useSelector(state => state.userReducer.Token) || localStorage.getItem('cooljwt');
  const dispatch = useDispatch();
  const { id, isedit } = useParams();
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (isedit === "true") {
      setEdit(true)
    }
    if (isedit === "false") {
      setEdit(false)
    }
  }, [isedit]);

  function checkInput(value) {
    if (value.includes('<script>')) {
      return false;
    }
    return true;
  }

  const handleChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (checkInput(value)) {
      dispatch(others(name, value))
    } else {
      return;
    }
  };

  const handleSubmit = () => {
    dispatch(submitOthers(vendordata, Token))
  }
  return (
    <Animated
      animationIn="bounceInRight"
      animationOut="bounceOutLeft"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >

      <GridContainer justify="center" md={12} xs={12} sm={12}>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label=" Financial Information"
            id="financialIssues"
            name="financialIssues"
            variant="outlined"
            multiline
            // placeholder="..."
            minRows={5}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.financialIssues || ""}

          />
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="Insurance And Warrenties"
            id="insuranceAndWarrenties"
            name="insuranceAndWarrenties"
            variant="outlined"
            multiline
            // placeholder="..."
            minRows={5}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.insuranceAndWarrenties || ""}

          />
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="Experiences / References"
            id="experienceOrReference"
            name="experienceOrReference"
            variant="outlined"
            multiline
            // placeholder="..."
            minRows={5}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.experienceOrReference || ""}

          />
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="Disputes"
            id="disputes"
            name="disputes"
            variant="outlined"
            multiline
            // placeholder="..."
            minRows={5}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.disputes || ""}

          />
        </GridItem>
      </GridContainer>
    </Animated>
  );
}


