import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, Typography } from "@material-ui/core";
// @material-ui/icons
import { Redirect } from "react-router-dom";

import { useMediaQuery } from 'react-responsive'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import LoginVendorPage from "./LoginVendorPage";
import LoginSecret from "./LoginSecret";
import { Animated } from "react-animated-css";
import leftIMage from 'assets/img/login/left-image.png';

const useStyles = makeStyles(styles);

export default function LoginVendor() {
  const classes = useStyles();
  const [login, setLogin] = useState(false);
  const [removeLogin, setRemoveLogin] = useState(true);
  const [removeotpCheck, setRemoveotpCheck] = useState(false);
  const [loggined, setIsLoggined] = useState(false);
  const [optCheck, setoptCheck] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const loginSuccess = () => {
    setLogin(true);
  };
  // const loginOTPsuccess = () => {
  //   setoptCheck(false);

  //   setTimeout(function() {
  //     setRemoveotpCheck(false);
  //     setRemoveLogin(true);
  //     setLogin(true);
  //   }, 500);
  // };

  return (
    login ? <Redirect to="/vendor/dashboard" /> :
      <div className={classes.container}>
        {isTabletOrMobile ?
          <GridContainer >
            {/* <GridItem xs={12} sm={12} md={12} style={{justifyContent:"center"}}>
              <img src={leftIMage} width={330} />
            </GridItem> */}
            <GridItem xs={12} sm={12} md={12}>
              {removeLogin ? (
                <Animated
                  animationIn="bounceInUp"
                  animationOut="bounceOutUp"
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <div style={{ justifyContent: "center", textAlign: "center" }}>
                    <Typography component="h2" variant="h6" style={{ color: "#054b8a", wordSpacing: "6px", fontWeight: "600", justifyContent: "center" }}>
                      SALIC is a major contributor to archiving food security strategy
                    </Typography>
                    <LoginVendorPage loginSuccess={loginSuccess} />
                  </div>
                </Animated>
              ) : (
                ""
              )}
            </GridItem>
          </GridContainer>
          :
          <GridContainer >
            <GridItem xs={12} sm={6} md={6}>
              <img src={leftIMage} width={430} />
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              {removeLogin ? (
                <Animated
                  animationIn="bounceInUp"
                  animationOut="bounceOutUp"
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <div style={{ justifyContent: "center", textAlign: "center" }}>
                    <Typography component="h2" variant="h6" style={{ color: "#054b8a", wordSpacing: "6px", fontWeight: "600", justifyContent: "center" }}>
                      SALIC is a major contributor to archiving food security strategy
                    </Typography>
                    <LoginVendorPage loginSuccess={loginSuccess} />
                  </div>
                </Animated>
              ) : (
                ""
              )}
            </GridItem>
          </GridContainer>
        }

      </div>
  );
}
