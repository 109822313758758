import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js'
import Footer from 'components/Footer/Footer.js'

import styles from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.js'

import register from 'assets/img/register.jpeg'
import login from 'assets/img/login_salic.png'
import lock from 'assets/img/lock.jpeg'
import error from 'assets/img/clint-mckoy.jpg'
import Auth from 'views/LDocs/Authorization/Auth'
import Tenant from 'views/LDocs/Authorization/Tenant'
import LoginVendor from 'views/LDocs/Authorization/LoginVendor'
import LoginSecret from 'views/LDocs/Authorization/LoginSecret'
import Totp from 'views/LDocs/Authorization/Totp'
import LoginActivate from 'views/LDocs/Authorization/LoginActivate'
import AuthDev from 'views/LDocs/Authorization/AuthDev'
import VendorProfile from 'views/LDocs/VendorProfile/Profile'
import axios from 'axios'
import { decode } from 'jsonwebtoken'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

export default function Pages (props) {
  const history = useHistory()
  const { ...rest } = props
  let token = localStorage.getItem('cooljwt')
  let invDetailUrl = localStorage.getItem('url')
  let userDetails = decode(token)
  const isAdmin = userDetails?.isTenant || userDetails?.role?.isAdmin
  const isVendor = userDetails?.isVendor
  // ref for the wrapper div
  const wrapper = React.createRef()
  // styles
  const classes = useStyles()

  React.useEffect(() => {
    document.body.style.overflow = 'unset'
    if (token) {
      userCheck()
    }
    // Specify how to clean up after this effect:
    return function cleanup () {}
  }, [])

  const userCheck = async () => {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getAuth`,
      headers: { cooljwt: token }
    })
      .then(res => {})
      .catch(err => {
        let error = err?.response?.data
        if (userDetails?.isVendor == true) {
          localStorage.clear()
          history.push('/supplier/login')
        } else {
          localStorage.clear()
          history.push('/')
        }
      })
  }

  const getBgImage = () => {
    if (window.location.pathname === '/auth/login') {
      return { background: '#f3f3f3' }
    } else if (window.location.pathname === '/auth/dev') {
      return { background: '#f3f3f3' }
    } else {
      return { backgroundImage: 'url(' + login + ')' }
    }
  }

  return (
    <>
      {token ? (
          isAdmin ? (
          <Redirect to='/admin/dashboard/ad' />
        ) : isVendor ? (
          <Redirect to='/vendor/dashboard' />
        ) : (
          <Redirect to='/default/dashboard/ap' />
        )
      ) : (
        <div>
          <AuthNavbar brandText={''} {...rest} />
          <div className={classes.wrapper} ref={wrapper}>
            <div className={classes.fullPage} style={getBgImage()}>
              <Switch>
                <Route path='/auth/regTenant' exec component={Tenant} />
                <Route path='/auth/login' exec component={Auth} />
                <Route path='/auth/dev' exec component={AuthDev} />
                <Route path='/auth/twofa' exec component={LoginSecret} />
                <Route path='/auth/activate' exec component={Totp} />
                <Route path='/auth/totp' exec component={LoginActivate} />
                <Redirect from='/auth' to='/auth/login' />
              </Switch>
              <Footer white />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
