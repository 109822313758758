import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  MenuItem,
  TextField,
  Switch,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Backdrop,
  Typography
} from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import ChipInput from 'material-ui-chip-input'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
// @material-ui/icon
import VisibilityIcon from '@material-ui/icons/Visibility'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import Swal from 'sweetalert2'
import {
  successAlert,
  errorAlert,
  msgAlert
} from 'views/LDocs/Functions/Functions'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import { setIsTokenExpired } from 'actions'
import TimezoneSelect from 'react-timezone-select'

const styles = theme => ({
  root: {
    // textAlign: "center",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {},
  statusImage: {
    width: 50,
    height: 50
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const useStyles = makeStyles(styles)
const sweetAlertStyle = makeStyles(styles2)

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

export default function EmailMessage () {
  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  const userDetails = jwt.decode(Token)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isLoading, setIsLoading] = React.useState(false)
  const [mainLoading, setMainLoading] = React.useState(false)
  const [eventName, seteventName] = React.useState([])
  const [sentTo, setSentTo] = React.useState([])
  const [tags, setTags] = React.useState([])
  const [animateTableView, setAnimateTableView] = React.useState(true)
  const [animateTable, setAnimateTable] = React.useState(true)
  const [eventObject, setEventObject] = React.useState('')
  const [temp , setTemp] = React.useState('')
  const [state, setState] = React.useState({
    values: {
      eventName: '',
      emailTo: '',
      subject: '',
      message: ''
    }
  })
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const htmlToDraftBlocks = html => {
    const blocksFromHtml = htmlToDraft(html)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    const editorState = EditorState.createWithContent(contentState)
    return editorState
  }
  React.useEffect(() => {
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setState(state => ({
      ...state,
      values: {
        ...state.values,
        message: content
      }
    }))
  }, [editorState])

  // React.useEffect(() => {
  //   if (state?.values?.eventName == temp) {
  //       setSentTo([])
  //   }
  // }, [state.values.eventName])

  React.useEffect(() => {
    setMainLoading(true)
    let userDetail = jwt.decode(Token)
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getEmailConfigs`,
      headers: { cooljwt: Token }
    })
      .then(response => {
        setMainLoading(false)
        seteventName(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const saveConfigration = () => {
    setIsLoading(true)
    let body = {
      eventObjectId: eventObject._id,
      message: state.values.message,
      subject: state.values.subject
    }
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/updateEmailConfigs`,
      data: body,
      headers: { cooljwt: Token }
    })
      .then(response => {
        setIsLoading(false)
        successAlert('Configration Updated');
      })
      .catch(err => {
        setIsLoading(false)
        errorAlert(err.response.data.error)
        console.log('err', err)
      })
  }

  const handleChange = event => {
    event.persist()
    if (event.target.name == 'eventName') {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [event.target.name]: event.target.value,
          emailTo:""
        }
      }))
      
      let filtername = eventName.find(
        list => list.eventName == event.target.value
      )
      setSentTo(filtername?.eventObject)
    }
    if (event.target.name == 'emailTo') {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [event.target.name]: event.target.value
        }
      }))
      let filteremailTo = sentTo.find(
        list => list.emailTo == event.target.value
      )
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          subject: filteremailTo.subject
        }
      }))
      setEventObject(filteremailTo)
      setTags(filteremailTo.tags)
      setEditorState(htmlToDraftBlocks(filteremailTo?.message))
    } else {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [event.target.name]: event.target.value
        }
      }))
    }
  }
  return (
    <div>
      {animateTableView ? (
        <>
          <Animated
            animationIn='bounceInRight'
            animationOut='bounceOutLeft'
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={animateTable}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color='info' icon>
                    <CardIcon color='info'>
                      <h4 className={classes.cardTitleText}>Email Message</h4>
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    <GridContainer justify='center' md={12} xs={12} sm={12}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        style={{ marginBottom: '10px' }}
                      >
                        <TextField
                          fullWidth={true}
                          label='Event'
                          id='eventName'
                          name='eventName'
                          variant='standard'
                          select
                          onChange={event => {
                            handleChange(event)
                          }}
                          // value={state.values.syncTime || ""}
                        >
                          <MenuItem selected disabled value=''>
                            Select Event{' '}
                          </MenuItem>
                          {eventName?.map(res => (
                            <MenuItem selected value={res?.eventName}>
                              {res?.eventName}
                            </MenuItem>
                          )) || []}
                        </TextField>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        style={{ marginBottom: '10px' }}
                      >
                        <TextField
                          fullWidth={true}
                          label='Sent To'
                          id='emailTo'
                          name='emailTo'
                          variant='standard'
                          select
                          onChange={event => {
                            handleChange(event)
                          }}
                          value={state.values.emailTo || ""}
                        >
                          <MenuItem selected disabled value=''>
                            Select Sent To{' '}
                          </MenuItem>
                          {sentTo?.map(res => (
                            <MenuItem value={res?.emailTo}>
                              {res?.emailTo}
                            </MenuItem>
                          )) || []}
                        </TextField>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginBottom: '10px' }}
                      >
                        <TextField
                          fullWidth={true}
                          label='Subject'
                          id='subject'
                          name='subject'
                          variant='standard'
                          placeholder='Subject ..'
                          type='text'
                          onChange={event => {
                            handleChange(event)
                          }}
                          value={state.values.subject || ''}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <>
                          <Typography>Email Message</Typography>
                          <Card style={{ padding: 5 }}>
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={setEditorState}
                            />
                          </Card>
                        </>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        {tags?.length == 0 ? (
                          ''
                        ) : (
                          <Typography style={{ color: 'red' }}>
                            {' '}
                            Note: Please use these tags in message
                          </Typography>
                        )}
                        {tags?.map(res => (
                          <ul>
                            <li>{res}</li>
                          </ul>
                        )) || []}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <Button
              color='info'
              className={classes.registerButton}
              style={{ float: 'right', marginTop: 20 }}
              round
              type='button'
              onClick={saveConfigration}
            >
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} />
              ) : (
                'Save Configration'
              )}
            </Button>
          </Animated>
        </>
      ) : (
        ''
      )}
    </div>
  )
}
