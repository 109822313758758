/*eslint-disable*/
import React, { useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";

// @material-ui/core components
import {
  makeStyles,
  Typography,
  LinearProgress,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  CircularProgress,
  Divider,
  Checkbox,
} from "@material-ui/core";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import jwt from "jsonwebtoken";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import List from "@material-ui/core/List";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "Functions";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import { addZeroes } from "views/LDocs/Functions/Functions";
const useStyle = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  list: {
    color: "black",
  },
}));
export default function Header({ PurchaseOrder, onBack }) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let history = useHistory();
  const dispatch = useDispatch();
  const decoded = jwt.decode(Token);
  const classes = useStyle();
  const classesList = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  //Use Effect Hook
  useEffect(() => {
    loadFunctions();
  }, []);

  //Load All Functions
  const loadFunctions = async () => {};

  {
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <h4 className={classes.cardTitleText}>Purchase Order Details</h4>
            </CardIcon>
            <Button
              color="danger"
              round
              style={{ float: "right" }}
              className={classes.marginRight}
              onClick={onBack}
            >
              Go Back
            </Button>
          </CardHeader>
          <CardBody>
            <GridContainer style={{ marginTop: "10px", marginBottom: "20px" }}>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                {/* List 1 */}
                <List className={classesList.list}>
                  <ListItemText
                    primary="Procurement BU"
                    secondary={PurchaseOrder?.ProcurementBU || "NIL"}
                  />
                  <ListItemText
                    primary="Requisitioning BU"
                    secondary={PurchaseOrder?.RequisitioningBU || "NIL"}
                  />
                  <ListItemText
                    primary="SoldToLegalEntity"
                    secondary={PurchaseOrder?.SoldToLegalEntity || "NIL"}
                  />
                  <ListItemText
                    primary="Order"
                    secondary={PurchaseOrder?.OrderNumber || "NIL"}
                  />
                  <ListItemText
                    primary="Status"
                    secondary={PurchaseOrder?.Status || "NIL"}
                  />
                  <ListItemText
                    primary="Funds Status"
                    secondary={"Need to Check" || "NIL"}
                  />
                  <ListItemText
                    primary="Buyer"
                    secondary={PurchaseOrder?.Buyer || "NIL"}
                  />
                  <ListItemText
                    primary="Creation Date"
                    secondary={formatDate(PurchaseOrder?.CreationDate) || "NIL"}
                  />
                </List>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                {/* List 2 */}
                <List className={classesList.list}>
                  <ListItemText
                    primary={"Supplier"}
                    secondary={PurchaseOrder?.Supplier || "NIL"}
                  />
                  <ListItemText
                    primary={"Supplier Site"}
                    secondary={PurchaseOrder?.SupplierSite || "NIL"}
                  />
                  <ListItemText
                    primary={"Supplier Contact"}
                    secondary={PurchaseOrder?.SupplierContact || "NIL"}
                  />
                  <ListItemText
                    primary={"Communication Method"}
                    secondary={"Need to Check" || "NIL"}
                  />
                  <ListItemText
                    primary={"Bill TO Location"}
                    secondary={PurchaseOrder?.BillToLocation || "NIL"}
                  />
                  <ListItemText
                    primary={"Ship TO Location"}
                    secondary={"Need to Check" || "NIL"}
                  />
                </List>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <List className={classesList.list}>
                  <ListItemText
                    primary={"Ordered"}
                    secondary={addZeroes(PurchaseOrder?.Ordered) || "NIL"}
                  />
                  <ListItemText
                    primary={"Total Tax"}
                    secondary={`${PurchaseOrder?.CurrencyCode ||
                      "NIL"} ${PurchaseOrder?.TotalTax || "NIL"}`}
                  />
                  <ListItemText
                    primary={"Total"}
                    secondary={`${PurchaseOrder?.CurrencyCode ||
                      "NIL"} ${addZeroes(PurchaseOrder?.Total) || "NIL"}`}
                  />
                  <ListItemText
                    primary={"Requisition"}
                    secondary={PurchaseOrder?.RequiredAcknowledgment || "NIL"}
                  />
                  <ListItemText
                    primary={"Source Agreement"}
                    secondary={PurchaseOrder?.SourceAgreementNumber || "NIL"}
                  />
                  <ListItemText
                    primary={"Supplier Order"}
                    secondary={PurchaseOrder?.SupplierOrder || "NIL"}
                  />
                </List>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "left", marginTop: 20 }}
              >
                <Typography>{PurchaseOrder?.Description || "NIL"}</Typography>
              </GridItem>
            </GridContainer>
            <Divider style={{ marginBottom: 20 }} />
            <Typography variant="h6" component="h2">
              Terms
            </Typography>
            <GridContainer style={{ marginTop: "10px", marginBottom: "20px" }}>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                {/* List 1 */}
                <List className={classesList.list}>
                  <ListItemText
                    primary="Required Acknowledgment"
                    secondary={PurchaseOrder?.RequiredAcknowledgment || "NIL"}
                  />
                  <ListItemText
                    primary="Payment Terms"
                    secondary={PurchaseOrder?.PaymentTerms || "NIL"}
                  />
                </List>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                {/* List 2 */}
                <List className={classesList.list}>
                  <ListItemText
                    primary={"Shipping Method	"}
                    secondary={PurchaseOrder?.ShippingMethod || "NIL"}
                  />
                  <ListItemText
                    primary={"Freight Terms"}
                    secondary={PurchaseOrder?.FreightTerms || "NIL"}
                  />
                  <ListItemText
                    primary={"FOP"}
                    secondary={PurchaseOrder?.FOB || "NIL"}
                  />
                </List>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <List className={classesList.list}>
                  <ListItemText
                    primary={"Pay on Receipt"}
                    secondary={
                      (
                        <Checkbox
                          checked={PurchaseOrder?.PayOnReceiptFlag}
                          disabled
                          size="small"
                        />
                      ) || "NIL"
                    }
                  />
                  <ListItemText
                    primary={"Confirming Order "}
                    secondary={
                      (
                        <Checkbox
                          checked={PurchaseOrder?.ConfirmingOrderFlag}
                          disabled
                          size="small"
                        />
                      ) || "NIL"
                    }
                  />
                </List>
              </GridItem>
            </GridContainer>
            <Divider style={{ marginBottom: 20 }} />
            <Typography variant="h6" component="h2">
              Note
            </Typography>
            <GridContainer style={{ marginTop: "10px", marginBottom: "20px" }}>
              <GridItem xs={12}>
                {/* List 1 */}
                <List className={classesList.list}>
                  <ListItemText
                    primary="Note to Supplier"
                    secondary={PurchaseOrder?.NoteToSupplier || "NIL"}
                  />
                  <ListItemText
                    primary="Note to Receiver"
                    secondary={PurchaseOrder?.NoteToReceiver || "NIL"}
                  />
                </List>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}
