import React, { useEffect, useState, useRef } from 'react'
// @material-ui/core components
import {
  TextField,
  MenuItem,
  makeStyles,
  Slide,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Select,
  ListSubheader,
  Divider
} from '@material-ui/core'
import {
  Paper,
  TableHead,
  TableContainer,
  TablePagination,
} from '@material-ui/core'
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import axios from 'axios'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { useDispatch, useSelector } from 'react-redux'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Iframe from 'react-iframe'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ScanningDocumentAnimation from 'components/ScanningDocumentAnimation/ScanningDocumentAnimation'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import { useHistory } from 'react-router-dom'

import { errorAlert } from 'views/LDocs/Functions/Functions'
import {
  Person,
  Refresh,
  SignalCellularNull,
  Visibility,
  VisibilityOff
} from '@material-ui/icons'
import { Autocomplete, TimelineItem } from '@material-ui/lab'
import { setIsTokenExpired } from 'actions'
import PurchaseOrderView from 'views/Components/PurchaseOrderView/PurchaseOrderView'
import PurchaseOrderItems from 'views/LDocs/Receipts/CreateReceipt/PurchaseOrderItems'
import { addZeroes } from 'views/LDocs/Functions/Functions'
import { formatDate } from 'views/LDocs/Functions/Functions'
import moment from 'moment'
import PurchaseOrderList from 'actions/PurchaseOrderListAction'
import { formatDateTime } from 'views/LDocs/Functions/Functions'
import { POFilter } from 'actions/PurchaseOrderListAction'

const sweetAlertStyle = makeStyles(styles2)

const styles = makeStyles(theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {},
  table: {
    minWidth: '100%'
  },
  itemName: {
    width: 400
  },
  itemNumber: {
    width: '55%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  asterisk: {
    color: 'red'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const CreateReceipt = props => {
  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  const productdata = useSelector(state => state.purchaseOrder.PoList);
  const loading = useSelector(state => state.purchaseOrder)
  const { loading: loadingSave } = loading

  const history = useHistory()
  const isAr =
    history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    ) == 'ar'
      ? true
      : false
  const dispatch = useDispatch()
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [loadingPurchaseOrders, setLoadingPurchaseOrders] = useState(false)
  const [receipts, setReceipts] = useState([])
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null)
  const [purchaseOrderModal, setPurchaseOrderModal] = useState(false)
  const [poLoading, setPoLoading] = useState(false)
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [RowsPerPage, setRowsPerPage] = useState(5)
  const [_PurchaseOrderList, setPurchaseOrderList] = React.useState([]);
  const [vendorLoading, setVendorLoading] = React.useState(false)

  const userDetails = jwt.decode(Token)
  const isVendor = userDetails.isVendor
  let initialState = {
    loadingPurchaseOrder: false,
    autoCompleteOpen: true,
    supplierInputValue: '',
    poInputValue: '',
    poHeaderId: '',
    vendors: [],
    selectedVendor: '',
    selectedPurchaseOrder: null,
    changedPoInputValue: false,
    values: {
      poNumber: ''
    },
    errors: {
      poNumber: ''
    }
  }
  const [formState, setFormState] = useState(initialState)

  useEffect(() => {
    if (selectedPurchaseOrder !== null) {
      _addItemsFromPurchaseOrder(selectedPurchaseOrder)
    }
  }, [receipts, selectedPurchaseOrder])

  useEffect(() => {
    _getSuppliers()
  }, [])

  const _getPurchaseOrders = async () => {
    setPurchaseOrders([])
    setLoadingPurchaseOrders(true)
    let supId = null
    await axios({
      method: 'get', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL
        }/invoice/getPurchaseOrdersBySupplierId/${supId}/${formState.poInputValue
        }/${false}`,
      headers: {
        cooljwt: Token
      }
    })
      .then(res => {
        // setReceipts(null)
        setLoadingPurchaseOrders(false)
        setPurchaseOrders(res.data || [])
        // if (edit && fileData.po && !formState.changedPoInputValue) {
        //   let selectedPo = res.data.find(po => po.OrderNumber == fileData.po)
        //   setFormState(formState => ({
        //     ...formState,
        //     selectedPurchaseOrder: selectedPo
        //   }))
        //   _getPurchaseOrderDetails(selectedPo?.POHeaderId, false)
        //   _getReceipts(selectedPo?.POHeaderId, false)
        //   if (!isVendor && fileData.isReceipt) {
        //     _getReceipts(selectedPo?.POHeaderId)
        //   }
        // }
      })
      .catch(error => {
        setLoadingPurchaseOrders(false)
        if (error?.response) {
          error?.response?.status == 401 && dispatch(setIsTokenExpired(true))
        }
        if (!isVendor && error.response.status == 401) {
          errorAlert('Unable to Fetch Purchase Orders')
        }
        // setReceipts(null)
        // setPurchaseOrders([])
      })
  }

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = async event => {
    setRowsPerPage(parseInt(event.target.value), 5);
    let dispatch_payload = {
      page: 0,
      rowPerPage: RowsPerPage,
      Token: Token,
    }
    dispatch(PurchaseOrderList(dispatch_payload));
  }
  useEffect(() => {

    if (!formState.selectedVendor) {
      let dispatch_payload = {
        page,
        rowPerPage: RowsPerPage,
        Token: Token,
        isReceipt: true
      }
      dispatch(PurchaseOrderList(dispatch_payload))
    } else {
      let dispatch_payload = {
        page,
        rowPerPage: 5,
        Token: Token,
        newdata: { data: { Supplier: formState.selectedVendor?.level1?.Supplier } },
        isReceipt: true,
      }
      dispatch(POFilter(dispatch_payload))
    }

  }, [page]);

  const _getReceipts = async PHId => {
    //PHId = Purchase Order Header Number
    const userDetails = jwt.decode(Token)
    setPoLoading(true)
    await axios({
      method: 'get', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getRecieptByPoHeaderId/${PHId}`,
      headers: {
        cooljwt: Token
      }
    })
      .then(res => {
        let list = res.data
        let hashMapArray = new Map()
        // New Hashed Map
        list.map(r => {
          if (hashMapArray.has(r.LineNumber)) {
            hashMapArray.set(r.LineNumber, [
              ...hashMapArray.get(r.LineNumber),
              r
            ])
          } else {
            hashMapArray.set(r.LineNumber, [r])
          }
        })

        let receipts_filtered = {}
        for (const [key, value] of hashMapArray.entries()) {
          receipts_filtered[key] = value
        }

        setReceipts(receipts_filtered)
        _getPurchaseOrderDetails(PHId, true)
      })
      .catch(error => {
        setPoLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        if (!isVendor && error.response.status == 401) {
          errorAlert('Unable to Retrive Receipts ')
        }
        setReceipts([])
      })
  }
  const classes = styles()

  const handleChange = event => {
    event.persist()

    if (event.target.name == 'poNumber') {
      let selectedPo = purchaseOrders.find(
        po => po.OrderNumber === event.target.value
      )

      if (selectedPo) {
        // _getPurchaseOrderDetails(selectedPo.POHeaderId, true)
        // setFormState(formState => ({
        //   ...formState,
        //   values: {
        //     ...formState.values,
        //     paymentTerms: formState.isPrePayment
        //       ? 'Advance Payment'
        //       : selectedPo.PaymentTerms || ''
        //   }
        // }))
        if (!isVendor) {
          // _getReceipts(selectedPo.POHeaderId)
        }
      }
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      }
    }))
  }

  const _getTransactionsSum = (transactions, billed) => {
    let initialValue = 0
    let amount = 0
    let isQuality = false
    if (transactions?.length > 0) {
      let sum =
        transactions?.reduce(function (accumulator, curValue) {
          if (billed) {
            if (curValue.QuantityBilled) {
              amount = curValue.QuantityBilled
              isQuality = true
            } else if (curValue.AmountBilled) {
              amount = curValue.AmountBilled
              isQuality = false
            } else {
              amount = 0
            }
          } else {
            if (curValue.Quantity) {
              amount = curValue.Quantity
              isQuality = true
            } else if (curValue.Amount) {
              amount = curValue.Amount
              isQuality = false
            } else {
              amount = 0
            }
          }
          return accumulator + amount
        }, initialValue) || 0

      return { sum, isQuality }
    } else {
      return { sum: 0, isQuality: false }
    }
  }
  const FormatDateTime = (date) => {
    let Token = localStorage.getItem("cooljwt");
    let user = jwt.decode(Token);
    var offset;
    if (user?.tenantConfigs) {
      let tenantConfig = user.tenantConfigs;
      let timeStamp = tenantConfig.timeZone;
      offset = timeStamp.offset * 60;
    } else {
      offset = moment().utcOffset();
    }
    var now = new Date(date);
    const someday = moment(now)
      .utcOffset(offset)
      .format('THH:mm');
    return someday;
  };

  const _addItemsFromPurchaseOrder = usedPurchasesOrder => {
    let schedules = usedPurchasesOrder?.schedules
    let itemsFromPurchaseOrder =
      usedPurchasesOrder?.lines?.map(line_item => {
        let scheduleLineNumber = schedules.find(
          s => s.LineNumber == line_item.LineNumber
        )

        let unitPrice = scheduleLineNumber.Price || line_item?.Ordered || 0
        let transaction_receipts = receipts[line_item?.LineNumber]
        let transaction_sum = _getTransactionsSum(transaction_receipts, false)
          .sum
        let transaction_sum_billed = _getTransactionsSum(
          transaction_receipts,
          true
        ).sum
        let received_sum = _getTransactionsSum(
          transaction_receipts?.find(t => t.TransactionTypeCode === 'RECEIVE')
        )
        let correct_sum = _getTransactionsSum(
          transaction_receipts?.find(t => t.TransactionTypeCode === 'DELIVER')
        )
        let returned_sum = _getTransactionsSum(
          transaction_receipts?.find(
            t => t.TransactionTypeCode === 'RETURN TO VENDOR'
          )
        )
        let received = transaction_sum + received_sum.sum - returned_sum.sum
        let currency_code = line_item?.CurrencyCode || ''
        let date = new Date()
        // let formatdate = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes()
        // let currentDate = moment(formatdate).format('YYYY-MM-DDTHH:MM')
        return {
          description: line_item?.Description || '',
          lineNumber: line_item?.LineNumber,
          // ordered: `${addZeroes(unitPrice || 0)}`,
          ordered: addZeroes(usedPurchasesOrder?.poLineDetails.find((poline) => poline?.POLineNumber === line_item?.LineNumber).Ordered),
          // received: `${addZeroes(received)}`,
          received: addZeroes(usedPurchasesOrder?.poLineDetails.find((poline) => poline?.POLineNumber === line_item?.LineNumber).Received),
          invoiced: addZeroes(usedPurchasesOrder?.poLineDetails.find((poline) => poline?.POLineNumber === line_item?.LineNumber).Invoiced),
          id: line_item?.LineNumber || '',
          currency: line_item.Currency || '',
          currency_code: line_item?.CurrencyCode || '',
          additionalDetails: '',
          // transaction_date: FormatDateTime(date) ,
          transaction_date: '',
          quantity: '',
          attachments: [],
          isQuantity: line_item?.Quantity == null ? false : true
        }
      }) || []
    setItems([...itemsFromPurchaseOrder])
    setPoLoading(false)
  }

  // Add Items from Selected Receipts
  // const _addItemsFromReceipts = receiptNum => {
  //   if (formState.isReceipt) {
  //     let existingItems = items.filter(
  //       item => item.receiptNumber !== receiptNum || item.receiptNumber != ''
  //     )
  //     let schedules = selectedPurchaseOrder?.schedules
  //     let itemsFromReceipts =
  //       receipts[receiptNum].map(line_item => {
  //         //Quantity form line_item
  //         //Unit Price from schedule
  //         //Tax from schedule
  //         let scheduleLineNumber = schedules.find(
  //           s => s.LineNumber == line_item.POLineNumber
  //         )
  //         let poQuantity = scheduleLineNumber?.Quantity || 1
  //         let unitPrice =
  //           scheduleLineNumber?.Price || scheduleLineNumber?.Ordered || ''
  //         let totalTax = scheduleLineNumber.TotalTax / poQuantity || 0
  //         let taxPercentage = (totalTax / unitPrice) * 100

  //         let unitTax = null
  //         let perUnitTaxPercentage = null
  //         let totalAmount = null
  //         let quantity = null

  //         let isAmountMatching = false

  //         if (line_item.Quantity) {
  //           let receiptQuauntity =
  //             line_item?.Quantity != null
  //               ? line_item?.Quantity - line_item?.QuantityBilled || 0
  //               : null
  //           unitTax = (unitPrice * receiptQuauntity * taxPercentage) / 100
  //           perUnitTaxPercentage = taxPercentage
  //           totalAmount = receiptQuauntity * unitPrice + unitTax
  //           quantity = receiptQuauntity
  //         } else {
  //           let receiptAmount =
  //             line_item?.Amount != null
  //               ? line_item?.Amount - line_item?.AmountBilled || 0
  //               : null
  //           unitPrice = receiptAmount
  //           unitTax = (unitPrice * taxPercentage) / 100
  //           perUnitTaxPercentage = taxPercentage
  //           totalAmount = unitPrice + unitTax
  //           quantity = 1
  //           isAmountMatching = true
  //         }

  //         // let poQuantity = scheduleLineNumber?.Quantity || 1;
  //         // let unitTax = totalTax / poQuantity ;
  //         // let perUnitTaxPercentage = unitTax / unitPrice * 100 || 0;

  //         // let quantity = line_item.Quantity || 1;
  //         // let totalTax = scheduleLineNumber.TotalTax || 0;

  //         // let calculatedTaxPerUnit = totalTax / quantity  || 0;
  //         // let perUnitTaxPercentage = calculatedTaxPerUnit / unitPrice * 100 || 0;
  //         //Amount Calculation after Tax Add
  //         // let totalAmount =line_item.Quantity == null  ?  receiptQuauntity      : (receiptQuauntity * unitPrice) + (unitTax * line_item.Quantity) || 0;

  //         let selectedPOAccrualAccount = selectedPurchaseOrder?.distributions
  //           ? selectedPurchaseOrder.distributions[0]?.POAccrualAccount
  //           : formState.defaultCoa.expReceipt
  //         return {
  //           itemName: line_item?.ItemDescription || '',
  //           unitCost: unitPrice || 0,
  //           quantity: quantity || 1,
  //           discount: 0,
  //           poInline: null,
  //           isAmountMatching,
  //           // expenseType: formState.defaultCoa.expReceipt,
  //           expenseType: selectedPOAccrualAccount,
  //           category: null,
  //           receiptNumber: receiptNum,
  //           vat: parseFloat(perUnitTaxPercentage)?.toFixed(2) || 0,
  //           taxClassification: line_item?.TaxClassificationCode || '',
  //           ReceiptLineNumber: line_item.LineNumber,
  //           PurchaseOrderNumber: line_item.PONumber,
  //           PurchaseOrderLineNumber: line_item.POLineNumber,
  //           PurchaseOrderScheduleLineNumber:
  //             scheduleLineNumber?.ScheduleNumber || '',
  //           liabilityType: formState.defaultCoa.libReceipt,
  //           unitCost_bc: conversionRate(
  //             formState.values.currency,
  //             baseCurrency,
  //             currencyLookups,
  //             parseFloat(scheduleLineNumber?.Price),
  //             false,
  //             formState.isPo,
  //             formState.conversionRate
  //           ),
  //           amount_bc: conversionRate(
  //             formState.values.currency,
  //             baseCurrency,
  //             currencyLookups,
  //             parseFloat(totalAmount),
  //             false,
  //             formState.isPo,
  //             formState.conversionRate
  //           ),
  //           amount: parseFloat(totalAmount)?.toFixed(2),
  //           additionalDetails: ''
  //         }
  //       }) || []
  //     setAddingItem(false)
  //     setEditIndex(null)
  //     setItems([...existingItems, ...itemsFromReceipts])
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isAr])

  useEffect(() => {
    if (formState.poInputValue) {
      _getPurchaseOrders()
    }
  }, [formState.poInputValue])

  const _getPurchaseOrderDetails = async (id, load) => {
    //id == PO header ID
    let url = `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPODetails/${id}`
    // setFormState(formState => ({
    //   ...formState,
    //   loadingPurchaseOrder: true
    // }))
    await axios({
      method: 'get',
      url: url,
      headers: { cooljwt: Token }
    })
      .then(response => {
        if (typeof response.data == 'object') {
          setSelectedPurchaseOrder(response.data)
          _addItemsFromPurchaseOrder(response.data)
        } else {
          setSelectedPurchaseOrder(null)
        }
        // setIsLoading(false)
      })
      .catch(error => {
        setPoLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        )
        setSelectedPurchaseOrder(null)
        setFormState(formState => ({
          ...formState,
          loadingPurchaseOrder: false
        }))
      })
  }

  const viewPurchaseOrder = async () => {
    let selectedPo = purchaseOrders.find(
      po => po.OrderNumber === formState.values.poNumber
    )
    // await _getPurchaseOrderDetails(selectedPo?.POHeaderId);
    selectedPurchaseOrder && setPurchaseOrderModal(true)
  }
  const _getPurchaseOrderss = async (supplierId, newInputValue) => {
    // setPurchaseOrder([])
    setPoLoading(true)
    let formData = {
      isAdmin: userDetails.role.isAdmin,
      displayName: userDetails?.displayName,
      po: newInputValue ? newInputValue : '',
      action: "PO",
      supplierId: supplierId ? supplierId : null,
      forPoOnly: true
    }
    await axios({
      method: 'post', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getOpenPoForRecAll`,
      data: formData,
      headers: {
        cooljwt: Token
      }
    })
      .then(res => {
        setPoLoading(false)
        setPurchaseOrderList(res?.data)
      })
      .catch(error => {
        setPoLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
      })
  }

  const _getSuppliers = async () => {
    setVendorLoading(true)
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendorsAll`,
      headers: { cooljwt: Token }
    })
      .then((res) => {
        setFormState(formState => ({
          ...formState,
          vendors: res?.data?.result,
        }))
        setVendorLoading(false)

      })
      .catch(error => {
        setVendorLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
      })
  }

  const selectedPO = (row) => {
    setItems([])
    setSelectedPurchaseOrder(null)
    setFormState(formState => ({
      ...formState,
      supplierInputValue: row?.Supplier?.substring(
        0,
        3
      )
    }))
    if (row?.POHeaderId) {
      _getReceipts(row?.POHeaderId)
      setFormState(formState => ({
        ...formState,
        poHeaderId: row?.POHeaderId
      }))
      executeScroll();
    }
  }

  const refresh = () => {
    setFormState(formState => ({
      ...formState,
      poInputValue: '',
      selectedPurchaseOrder: '',
      poHeaderId: '',
      selectedVendor: ''
    }))
    setItems([])
    let dispatch_payload = {
      page: 0,
      rowPerPage: 5,
      Token: Token,
      isReceipt: true
    }
    dispatch(PurchaseOrderList(dispatch_payload))
  }

  return (
    <div>

      <Animated
        animationIn='bounceInRight'
        animationOut='bounceOutLeft'
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        {purchaseOrderModal ? (
          <Dialog
            classes={{
              root: classes.center + ' ' + classes.modalRoot,
              paper: classes.modal
            }}
            fullWidth={true}
            maxWidth={'xl'}
            open={purchaseOrderModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setPurchaseOrderModal(false)}
            aria-labelledby='vendor-modal-slide-title'
            aria-describedby='vendor-modal-slide-description'
          >
            <DialogContent id='vendorSelect' className={classes.modalBody}>
              <PurchaseOrderView
                PurchaseOrder={selectedPurchaseOrder}
                onBack={() => setPurchaseOrderModal(false)}
              />
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
        {/* <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>
                    Create Receipt
                  </h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px'
                    }}
                  >
                    <Autocomplete
                      id='purchase_order_auto_complete'
                      options={purchaseOrders || []}
                      variant='outlined'
                      inputValue={formState.poInputValue || ''}
                      onInputChange={(event, newInputValue) => {
                        setFormState(formState => ({
                          ...formState,
                          poInputValue: newInputValue,
                          changedPoInputValue: true
                        }))
                      }}
                      value={formState.selectedPurchaseOrder || ''}
                      getOptionLabel={option =>
                        option?.OrderNumber?.toString() || ''
                      }
                      // loading={isLoading}
                      onChange={(event, newValue) => {

                        setFormState(formState => ({
                          ...formState,
                          selectedPurchaseOrder: newValue,
                          supplierInputValue: newValue?.Supplier?.substring(
                            0,
                            3
                          )
                        }))
                        if (newValue?.POHeaderId) {
                          _getReceipts(newValue?.POHeaderId)
                          setFormState(formState => ({
                            ...formState,
                            poHeaderId: newValue?.POHeaderId
                          }))
                        }
                      }}
                      sx={{ width: 300 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant='outlined'
                          name='poNumber'
                          InputLabelProps={{
                            style: { color: 'black' }
                          }}
                          label='Purchase Order Number'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingPurchaseOrders ? (
                                  <CircularProgress
                                    color='inherit'
                                    size={20}
                                  />
                                ) : null}

                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    style={{
                      marginTop: '15px',
                      marginBottom: '10px'
                    }}
                  >
                    {!formState.loadingPurchaseOrder ? (
                      <Tooltip title='View PO'>
                        <IconButton
                          style={{ background: 'lightgrey' }}
                          onClick={() => viewPurchaseOrder(true)}
                        >
                          {purchaseOrderModal ? (
                            <VisibilityOff fontSize='small' />
                          ) : (
                            <Visibility fontSize='small' />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <CircularProgress />
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            {poLoading ? <LinearProgress /> : ""}
          </GridItem>
        </GridContainer> */}
        <Card>
          <CardHeader color='info' icon>
            <CardIcon color='info'>
              <h4 className={classes.cardTitleText}>
                Create Receipt
              </h4>
            </CardIcon>
            <Tooltip
              id='tooltip-top'
              title='Refresh'
              style={{ float: 'right' }}
              placement='bottom'
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                onClick={refresh}
                simple
                color='info'
                justIcon
              >
                <Refresh className={classes.underChartIcons} />
              </Button>
            </Tooltip>
          </CardHeader>
          <CardBody>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                marginTop: '10px',
                marginBottom: '10px'
              }}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px'
                  }}
                >
                  <Autocomplete
                    id='filter-demo'
                    fullWidth
                    options={formState.vendors || []}
                    variant='outlined'
                    inputValue={formState.supplierInputValue || ''}
                    onInputChange={(event, newInputValue) => {
                      setFormState(formState => ({
                        ...formState,
                        supplierInputValue: newInputValue,
                      }))
                    }}
                    value={formState.selectedVendor || ''}
                    getOptionLabel={option => option?.level1?.Supplier || ''}
                    onChange={(event, newValue) => {
                      setFormState(formState => ({
                        ...formState,
                        selectedVendor: newValue
                      }))
                      if (newValue?.level1?.SupplierId) {
                        let dispatch_payload = {
                          page: 0,
                          rowPerPage: 5,
                          Token: Token,
                          newdata: { data: { Supplier: newValue?.level1?.Supplier } },
                          isReceipt: true,

                        }
                        dispatch(POFilter(dispatch_payload))
                        _getPurchaseOrderss(newValue?.level1?.SupplierId, '')
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label={'Select Supplier'}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {vendorLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px'
                  }}
                >
                  <Autocomplete
                    id='filter-demo'
                    fullWidth
                    options={_PurchaseOrderList || []}
                    // style={{ marginLeft: '20px' }}
                    value={formState.selectedPurchaseOrder || ''}
                    variant='outlined'
                    getOptionLabel={option => option?.OrderNumber || ''}
                    onInputChange={(event, newInputValue) => {
                      setFormState(formState => ({
                        ...formState,
                        poInputValue: newInputValue
                      }))
                      _getPurchaseOrderss(null, newInputValue)
                    }}
                    onChange={(event, newValue) => {
                      setFormState(formState => ({
                        ...formState,
                        selectedPurchaseOrder: newValue
                      }))
                      if (newValue?.OrderNumber) {
                        //Dispatch Payload
                        let dispatch_payload = {
                          page: 0,
                          rowPerPage: 5,
                          Token: Token,
                          newdata: { data: { OrderNumber: newValue?.OrderNumber } },
                          isReceipt: true,

                        };
                        dispatch(POFilter(dispatch_payload));
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label={'Search By PO'}
                        placeholder={'Search'}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {poLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>

            <>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>PO Number</TableCell>
                      <TableCell>Procurement BU</TableCell>
                      <TableCell>Supplier </TableCell>
                      <TableCell>Payment Terms</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingSave ? (
                      <CircularProgress
                        style={{
                          marginTop: '50px',
                          marginLeft: '400px',
                          width: '100px',
                          height: '100px'
                        }}
                      />
                    ) : (
                      productdata?.PurchaseOrders?.map((row, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell> {row.OrderNumber}</TableCell>
                            <TableCell>{row.ProcurementBU}</TableCell>
                            <TableCell>{row.Supplier}</TableCell>
                            <TableCell>{row.PaymentTerms}</TableCell>
                            <TableCell>{row.Status}</TableCell>
                            <TableCell>{row.Total}</TableCell>
                            <TableCell>
                              <>

                                <Link
                                  to={`/default/purchase_order/${row.POHeaderId}`}
                                >
                                  <Button
                                    round
                                    color={'info'}
                                    className='Edit'
                                    size='sm'
                                  >
                                    View
                                  </Button>
                                </Link>
                                <Button
                                  round
                                  color={formState.poHeaderId == row.POHeaderId ? 'danger' : 'info'}
                                  className='Edit'
                                  onClick={() => selectedPO(row, index)}
                                  size='sm'
                                >
                                  Select
                                </Button>
                              </>
                            </TableCell>
                          </TableRow>
                        </>
                      )) || []
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  component='div'
                  count={productdata?.totalNumOfItems}
                  // rowsPerPageOptions={[10, 25, 40, 50]}
                  rowsPerPageOptions={[]}
                  page={page}
                  onPageChange={handleChangePage}
                  labelRowsPerPage=''
                  rowsPerPage={RowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </>
          </CardBody>
        </Card>
        {poLoading ? <LinearProgress /> : ""}
        <PurchaseOrderItems
          setItems={setItems}
          items={items}
          myRef={myRef}
          selectedPurchaseOrder={selectedPurchaseOrder}
          formState={formState}
        />
      </Animated>

    </div>
  )
}
export default CreateReceipt
