import React, { useState, useEffect } from "react";
// @material-ui/icons
import {
    makeStyles,
    TextField
} from "@material-ui/core";
// core components
import { Animated } from "react-animated-css";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { formatDateTime } from "Functions";
const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

export default function GeneralInfo({ generalInfo }) {


    const classes = useStyles();
    const [animateStep, setAnimateStep] = useState(true);
    return (
        <Animated
            animationIn="bounceInRight"
            animationOut="bounceOutLeft"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={animateStep}
        >
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        fullWidth={true}
                        label="Supplier"
                        id="name"
                        name="name"
                        type="text"
                        disabled={true}
                        value={generalInfo?.Supplier || ''}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        fullWidth={true}
                        label="Supplier Id"
                        id="licenseNumber"
                        name="licenseNumber"
                        type="text"
                        disabled={true}
                        value={
                            generalInfo?.SupplierId || ""
                        }
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        fullWidth={true}
                        label="Supplier Number"
                        id="email"
                        name="email"
                        type="email"
                        disabled={true}
                        value={generalInfo?.SupplierNumber || ""}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        fullWidth={true}
                        label="Supplier Party Id"
                        id="loginname"
                        name="loginname"

                        type="text"
                        disabled={true}
                        value={generalInfo?.SupplierPartyId || ''}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        fullWidth={true}
                        label="Supplier Type Code"
                        id="cellnumber"
                        name="cellnumber"
                        type="text"
                        disabled={true}
                        value={generalInfo?.SupplierTypeCode || ''}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        fullWidth={true}
                        label="Tax Organization Type Code"
                        id="supplierSite"
                        name="supplierSite"

                        type="text"
                        disabled={true}
                        value={
                            generalInfo?.TaxOrganizationTypeCode || ""
                        }
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        fullWidth={true}
                        label="Status"
                        id="referenceTicket"
                        name="referenceTicket"
                        multiline
                        rows="3"
                        type="text"
                        disabled={true}
                        value={
                            generalInfo?.Status || ""
                        }
                    />
                    <TextField
                        fullWidth={true}
                        label="Last Updated By"
                        id="referenceTicket"
                        name="referenceTicket"
                        multiline
                        rows="3"
                        type="text"
                        disabled={true}
                        value={
                            generalInfo?.LastUpdatedBy || ""
                        }
                    />
                    <TextField
                        fullWidth={true}
                        label="Last Update Date"
                        id="referenceTicket"
                        name="referenceTicket"
                        multiline
                        rows="3"
                        type="text"
                        disabled={true}
                        value={
                            generalInfo?.LastUpdateDate || ""
                        }
                    />
                    <TextField
                        fullWidth={true}
                        label="Creation Date"
                        id="referenceTicket"
                        name="referenceTicket"
                        multiline
                        rows="3"
                        type="text"
                        disabled={true}
                        value={
                            generalInfo?.CreationDate || ""
                        }
                    />
                    <TextField
                        fullWidth={true}
                        label="Created By"
                        id="referenceTicket"
                        name="referenceTicket"
                        multiline
                        rows="3"
                        type="text"
                        disabled={true}
                        value={
                            generalInfo?.CreatedBy || ""
                        }
                    />

                    <TextField
                        fullWidth={true}
                        label="Inactive Date"
                        id="referenceTicket"
                        name="referenceTicket"
                        multiline
                        rows="3"
                        type="text"
                        disabled={true}
                        value={
                            formatDateTime(generalInfo?.InactiveDate) || ""
                        }
                    />

                </GridItem>
            </GridContainer>
        </Animated>
    );
}


