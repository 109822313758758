import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Button from "components/CustomButtons/Button.js";
// core components
import Lines from './Lines';
import Distributions from './Distributions';
import Reciepts from './Reciepts';
import Schedule from './Schedule';

import Wizard from "./Wizard.js";
import { Link } from "react-router-dom";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);

export default function Tabs({ lines,distributions,schedules,Transations}) {

  const classes = useStyles();
  const steps = [
    {
      stepName: "Lines",
      stepComponent: Lines,
      stepId: "about",
    },
    {
      stepName: "Distributions",
      stepComponent: Distributions,
      stepId: "account",
    },
    {
      stepName: "Reciepts",
      stepComponent: Reciepts,
      stepId: "address",
    },
    {
      stepName: "Schedule",
      stepComponent: Schedule,
      stepId: "address",
    }
  ];
  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
        <Wizard
          validate
          steps={steps.filter((s) => s.stepName != null || undefined || "")}
          lines={lines} distributions={distributions} schedules={schedules} Transations={Transations}


        />
    </GridItem>
  );
}
