import {CircularProgress, Dialog, DialogContent, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button.js'
import React from 'react'
import { formatDateTime } from '../Functions/Functions'
import CardBody from 'components/Card/CardBody'

const useStyles = makeStyles();
const TrackingStatus = ({ initialReview, review, approve, approveandexported, canceled, closeTrackingModal, setOpenTrackingModal, openTrackingModal, trackingLoading}) => {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                classes={{
                    root: classes.center + ' ' + classes.modalRoot,
                    paper: classes.modal
                }}
                fullWidth={true}
                maxWidth={'lg'}
                open={openTrackingModal}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenTrackingModal(false)}
                aria-labelledby='tag-modal-slide-title'
                aria-describedby='tag-modal-slide-description'
            >
                <DialogContent
                    id='tag-modal-slide-description'
                    className={classes.modalBody}
                >
                    <GridContainer>
                        <Card>
                            <CardHeader color='info' icon>
                                <CardIcon color='info'>
                                    <h4 className={classes.cardTitle}>Tracking Status</h4>
                                </CardIcon>
                            </CardHeader>
                            <CardBody>
                                {trackingLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <Table className={classes.table} aria-label='simple table'>
                                        <TableHead>
                                            <TableRow className={classes.TableRow}>
                                                <TableCell className={classes.TableID}>
                                                    Review / Approve
                                                </TableCell>
                                                <TableCell className={classes.TableID}>
                                                    Assigned To
                                                </TableCell>
                                                <TableCell className={classes.TableID}>
                                                    Assigned Date
                                                </TableCell>
                                                <TableCell className={classes.TableCell}>
                                                    Status
                                                </TableCell>
                                                <TableCell className={classes.TableCell}>
                                                    Action Date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ paddingBottom: 5 }}>
                                            <TableRow className={classes.TableRow}>
                                                <TableCell className={classes.TableCell}>
                                                    Initial Review
                                                    <br />
                                                    (Requester)
                                                </TableCell>
                                                <TableCell>
                                                    {initialReview?.reviewedBy || '-'}
                                                    <br />
                                                    {initialReview?.assignTo || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {initialReview?.assignDate
                                                        ? formatDateTime(initialReview?.assignDate)
                                                        : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {initialReview?.status == 'completed'
                                                        ? initialReview?.status.toUpperCase()
                                                        : initialReview?.status == 'correctionRequired'
                                                            ? initialReview?.status.toUpperCase()
                                                            : initialReview?.status == 'rejected'
                                                                ? initialReview?.status.toUpperCase()
                                                                : 'PENDING' || 'PENDING'}
                                                </TableCell>
                                                <TableCell>
                                                    {initialReview?.status !== 'pending' &&
                                                        initialReview?.date
                                                        ? formatDateTime(initialReview?.date)
                                                        : '-'}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className={classes.TableRow}>
                                                <TableCell className={classes.TableCell}>
                                                    Review
                                                    <br />
                                                    (Accounting Specialist)
                                                </TableCell>
                                                <TableCell>
                                                    {review?.reviewedBy || '-'}
                                                    <br />
                                                    {review?.assignTo || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {review?.assignDate
                                                        ? formatDateTime(review?.assignDate)
                                                        : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {review?.status == 'completed'
                                                        ? review?.status.toUpperCase()
                                                        : review?.status == 'correctionRequired'
                                                            ? review?.status.toUpperCase()
                                                            : review?.status == 'rejected'
                                                                ? review?.status.toUpperCase()
                                                                : 'PENDING' || 'PENDING'}
                                                </TableCell>
                                                <TableCell>
                                                    {review?.status !== 'inProgress' && review?.date
                                                        ? formatDateTime(review?.date)
                                                        : '-'}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className={classes.TableRow}>
                                                <TableCell className={classes.TableCell}>
                                                    Approve
                                                    <br />
                                                    (Accounting Manager)
                                                </TableCell>
                                                <TableCell>
                                                    {approve?.approvedBy || '-'}
                                                    <br />
                                                    {approve?.assignTo || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {approve?.assignDate
                                                        ? formatDateTime(approve?.assignDate)
                                                        : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {approve?.status == 'completed'
                                                        ? approve?.status.toUpperCase()
                                                        : approve?.status == 'correctionRequired'
                                                            ? approve?.status.toUpperCase()
                                                            : approve?.status == 'rejected'
                                                                ? approve?.status.toUpperCase()
                                                                : 'PENDING' || 'PENDING'}
                                                </TableCell>
                                                <TableCell>
                                                    {approve?.status !== 'pending' && approve?.date
                                                        ? formatDateTime(approve?.date)
                                                        : '-'}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className={classes.TableRow}>
                                                <TableCell className={classes.TableCell}>
                                                    Approve and Export
                                                    <br />
                                                    (Requester)
                                                </TableCell>
                                                <TableCell>
                                                    {approveandexported?.exportedBy || '-'}
                                                    <br />
                                                    {approveandexported?.assignTo || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {approveandexported?.assignDate
                                                        ? formatDateTime(approveandexported?.assignDate)
                                                        : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {approveandexported?.status == 'completed'
                                                        ? approveandexported?.status.toUpperCase()
                                                        : 'PENDING' || 'PENDING'}
                                                </TableCell>
                                                <TableCell>
                                                    {approveandexported?.status !== 'pending' &&
                                                        approveandexported?.date
                                                        ? formatDateTime(approveandexported?.date)
                                                        : '-'}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow className={classes.TableRow}>
                                                <TableCell className={classes.TableCell}>
                                                    Invoice Status
                                                    {/* <br />
                            (Requester) */}
                                                </TableCell>
                                                <TableCell>
                                                    {canceled?.paidBy || '-'}
                                                    <br />
                                                    {canceled?.assignTo || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {canceled?.assignDate
                                                        ? formatDateTime(canceled?.assignDate)
                                                        : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {canceled?.status == 'inProgress'
                                                        ? 'PENDING'
                                                        : canceled?.status == 'completed'
                                                            ? 'COMPLETED'
                                                            : canceled?.status == 'Canceled'
                                                                ? 'CANCELED'
                                                                : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {canceled?.status !== 'pending' && canceled?.date
                                                        ? formatDateTime(canceled?.date)
                                                        : '-'}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                )}
                            </CardBody>
                        </Card>
                        <Button
                            color='danger'
                            className={classes.registerButton}
                            onClick={() => closeTrackingModal()}
                            round
                            style={{ float: 'right', marginLeft: 'auto' }}
                        >
                            CLOSE
                        </Button>
                    </GridContainer>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TrackingStatus