import React, { useState, useEffect } from "react";
// @material-ui/icons
import {
  Button,
  makeStyles,
  CircularProgress,
  TextField,
  useForkRef,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@material-ui/core";
// @material-ui/core components

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Animated } from "react-animated-css";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Swal from "sweetalert2";
import {
  successAlert,
  errorAlert,
  msgAlert,
} from "views/LDocs/Functions/Functions";
import axios from "axios";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import MultipleSelect from "./MultiSelect";
import Datetime from "react-datetime";
import dateFormat from "dateformat";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

export default function Step1(props) {
  console.log({ props });
  // debugger
  const classes = useStyles();
  const [animateStep, setAnimateStep] = useState(true);
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [Users, setUsers] = useState([]);
  const [getAllUsersLoading, setGetAllUsersLoading] = useState(false);
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const dispatch = useDispatch();
  var row = props.userData;
  const today = new Date().toISOString().split("T")[0];
  const [formState, setFormState] = useState({
    isLoading: false,
    values: {
      displayName:
        typeof row.level1 != "undefined" ? row.level1.displayName : "",
      lastName: typeof row.level1 != "undefined" ? row.level1.lastName : "",
      firstName: typeof row.level1 != "undefined" ? row.level1.firstName : "",
      PCNP:
        typeof row.level1 != "undefined"
          ? row.level1.personalCellNumberPrimary
          : "",
      personalEmail:
        typeof row.level1 != "undefined" ? row.level1.personalEmail : "",
      PSN:
        typeof row.level1 != "undefined"
          ? row.level1.personalSecondaryNumber
          : "",
      canCreateAnyInvoice:
        typeof row.level1 != "undefined" ? row.level1.canCreateAnyInvoice : "",
      deligateUser: {
        displayName:
          typeof row.level1 != "undefined" &&
          row.level1?.delegateList?.length > 0
            ? row.level1?.delegateList[0]?.deligateUser
            : "",
      },
      deligateUserEmail:
        typeof row.level1 != "undefined" && row.level1?.delegateList?.length > 0
          ? row.level1?.delegateList[0]?.deligateUserEmail
          : "",
      leaveStart:
        typeof row.level1 != "undefined" && row.level1?.delegateList?.length > 0
          ? moment(row.level1?.delegateList[0]?.leaveStart).format("YYYY-MM-DD")
          : "",
      leaveEnd:
        typeof row.level1 != "undefined" && row.level1?.delegateList?.length > 0
          ? moment(row.level1?.delegateList[0]?.leaveEnd).format("YYYY-MM-DD")
          : "",
      managerDisplayName:
        typeof row.level1 != "undefined" ? row.level1?.managerDisplayName : "",
      managerEmail:
        typeof row.level1 != "undefined" ? row.level1?.managerEmail : "",
    },
    selectedUser: row?.level1?.userList,
    errors: {},
  });
  useEffect(() => {
    _getAllUsers();
  }, []);

  const _getAllUsers = async () => {
    setGetAllUsersLoading(true);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/user/viewAllpurchaseOrders`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        setGetAllUsers(response?.data);
        setGetAllUsersLoading(false);
      })
      .catch((error) => {
        setGetAllUsersLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        let msg =
          typeof error.response != "undefined"
            ? error.response.data
            : error.message;
        errorAlert(msg);
      });
  };

  const getAllUser = async (search) => {
    setGetUserLoading(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getUser`,
      headers: { cooljwt: Token },
      data: {
        enterData: search,
      },
    })
      .then((response) => {
        setGetUserLoading(false);
        setUsers(response?.data?.value);
      })
      .catch((error) => {
        setGetUserLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        let msg =
          typeof error.response != "undefined"
            ? error.response.data
            : error.message;
        errorAlert(msg);
      });
  };

  const saveSelectedUsers = async (email) => {
    // setGetAllUsersLoading(true)
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/user/updateUsersList`,
      headers: { cooljwt: Token },
      data: {
        email: email,
        UserList: formState.selectedUser,
      },
    })
      .then((response) => {
        // setGetAllUsersLoading(false)
      })
      .catch((error) => {
        // setGetAllUsersLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        let msg =
          typeof error.response != "undefined"
            ? error.response.data
            : error.message;
        errorAlert(msg);
      });
  };

  const setWorkFlows = (x) => {
    var selectedusers = [];
    x.map((item) => {
      selectedusers.push({
        id: getAllUsers.find((itm) => itm.level1?.displayName == item)?._id,
        displayName: item,
        email: getAllUsers.find((itm) => itm.level1?.displayName == item)
          ?.level3?.email,
      });
    });
    setFormState((formState) => ({
      ...formState,
      selectedUser: selectedusers,
    }));
  };

  const handleChange = (event) => {
    event.persist();
    if (event.target.name == "canCreateAnyInvoice") {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.checked,
        },
      }));
      if (event.target.checked) {
        _getAllUsers();
      }
    } else if (event.target.name == "deligateUser") {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value,
        },
      }));
    } else if (
      event.target.name == "leaveStart" ||
      event.target.name == "leaveEnd"
    ) {
      const selected = event.target.value;
      if (selected >= today) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value,
          },
        }));
      }
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value.toUpperCase(),
        },
      }));
    }
  };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const saveUserLevelOne = () => {
    setFormState((formState) => ({
      ...formState,
      isLoading: true,
    }));
    let displayName;
    // let lastName;
    // let firstName;
    // let PCNP;
    // let personalEmail;
    // let PSN;
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;

    if (!Check(formState.values.displayName)) {
      displayName = "success";
    } else {
      displayName = "error";
      error = true;
    }
    // if (!Check(formState.values.personalEmail)) {
    //   if (verifyEmail(formState.values.personalEmail)) {
    //     personalEmail = "success";
    //   } else {
    //     personalEmail = "error";
    //     error = true;
    //   }
    // } else {
    //   personalEmail = "error";
    //   error = true;
    // }
    // if (!Check(formState.values.lastName)) {
    //   lastName = "success";
    // } else {
    //   lastName = "error";
    //   error = true;
    // }
    // if (!Check(formState.values.firstName)) {
    //   firstName = "success";
    // } else {
    //   firstName = "error";
    //   error = true;
    // }
    // if (!Check(formState.values.PCNP)) {
    //   PCNP = "success";
    // } else {
    //   PCNP = "error";
    //   error = true;
    // }
    // if (!Check(formState.values.PSN)) {
    //   PSN = "success";
    // } else {
    //   PSN = "error";
    //   error = true;
    // }
    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        displayName: displayName,
        // lastName: lastName,
        // firstname: firstName,
        // PCNP: PCNP,
        // personalEmail: personalEmail,
        // PSN: PSN
      },
    }));
    if (error) {
      setFormState((formState) => ({
        ...formState,
        isLoading: false,
      }));
      return false;
    } else {
      saveSelectedUsers(props.userData.level3.email);
      var data = {
        isDeligation:
          formState.values.deligateUser &&
          formState.values.leaveStart &&
          formState.values.leaveEnd
            ? true
            : false,
        firstName: formState.values.firstName,
        lastName: formState.values.lastName,
        displayName: formState.values.displayName,
        personalEmail: formState.values.personalEmail,
        personalCellNumberPrimary: formState.values.PCNP,
        personalSecondaryNumber: formState.values.PSN,
        email: props.userData.level3.email,
        canCreateAnyInvoice: formState.values.canCreateAnyInvoice,
        deligateUser: formState.values.deligateUser.displayName,
        deligateUserEmail: formState.values.deligateUserEmail,
        leaveStart: formState.values.leaveStart,
        leaveEnd: formState.values.leaveEnd,
        managerDisplayName: formState.values.managerDisplayName,
        managerEmail: formState.values.managerEmail,
      };
      let msg = "";
      axios({
        method: "put",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/user/level1Update`,
        data: data,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          if (row.level1 != "undefined") {
            props.userData.level1.displayName = data.displayName;
            props.userData.level1.lastName = data.lastName;
            props.userData.level1.firstName = data.firstName;
            props.userData.level1.personalCellNumberPrimary =
              data.personalCellNumberPrimary;
            props.userData.level1.personalEmail = data.personalEmail;
            props.userData.level1.personalSecondaryNumber =
              data.personalSecondaryNumber;
            props.userData.level1.canCreateAnyInvoice =
              data.canCreateAnyInvoice;
            props.userData.level1.deligateUser = data.deligateUser;
            props.userData.level1.leaveStart = data.leaveStart;
            props.userData.level1.leaveEnd = data.leaveEnd;
            props.userData.level1.managerDisplayName = data.managerDisplayName;
            props.userData.level1.managerEmail= data.managerEmail;
            props.updateUserData(props.userData);
          }
          setFormState((formState) => ({
            ...formState,
            isLoading: false,
          }));
          msg = "Level 1 Info Updated Successfully!";
          successAlert(msg);
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          setFormState((formState) => ({
            ...formState,
            isLoading: false,
          }));
          msg =
            typeof error.response != "undefined"
              ? error.response.data
              : error.message;
          errorAlert(msg);
        });
    }
  };
  useEffect(() => {
    getUserImage();
    if (formState.values.canCreateAnyInvoice) {
      _getAllUsers();
    }
  }, []);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userImage, setUserImage] = React.useState();
  const getUserImage = () => {
    if (typeof row.level1.profileImgT != "undefined") {
      var base64Flag = `data:${row.level1.profileImgT};base64,`;
      let profileImage = base64Flag + row.level1.profileImg;
      setUserImage(profileImage);
      setIsLoading(false);
    } else {
      setUserImage(defaultAvatar);
      setIsLoading(false);
    }
  };

  const handleDateChange = (moment, name) => {
    debugger;
    let selectedDate = "";
    if (typeof moment._d != "undefined") {
      selectedDate = dateFormat(moment._d, "dd/mm/yyyy, h:MM:ss TT");
    }
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: selectedDate,
      },
    }));
  };

  return (
    <Animated
      animationIn="bounceInRight"
      animationOut="bounceOutLeft"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >
      <GridContainer justify="center" md={12} xs={12} sm={12}>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            error={formState.errors.displayName === "error"}
            helperText={
              formState.errors.displayName === "error"
                ? "Valid Display Name is required"
                : null
            }
            label="Display Name"
            id="displayName"
            name="displayName"
            onChange={(event) => {
              handleChange(event);
            }}
            type="text"
            disabled={props.disabledCheck}
            value={formState.values.displayName || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            error={formState.errors.lastName === "error"}
            helperText={
              formState.errors.lastName === "error"
                ? "Valid Last Name is required"
                : null
            }
            label="Last Name"
            id="lastName"
            name="lastName"
            onChange={(event) => {
              handleChange(event);
            }}
            type="text"
            disabled={props.disabledCheck}
            value={formState.values.lastName || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            error={formState.errors.firstName === "error"}
            helperText={
              formState.errors.firstName === "error"
                ? "Valid First Name is required"
                : null
            }
            label="First Name"
            id="firstName"
            name="firstName"
            onChange={(event) => {
              handleChange(event);
            }}
            type="text"
            disabled={props.disabledCheck}
            value={formState.values.firstName || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            error={formState.errors.PCNP === "error"}
            helperText={
              formState.errors.PCNP === "error"
                ? "Valid Cell Number is required"
                : null
            }
            label="Personal Cell Number Primary"
            id="PCNP"
            name="PCNP"
            onChange={(event) => {
              handleChange(event);
            }}
            type="text"
            disabled={props.disabledCheck}
            value={formState.values.PCNP || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            error={formState.errors.personalEmail === "error"}
            helperText={
              formState.errors.personalEmail === "error"
                ? "Valid Email is required"
                : null
            }
            label="Personal Email"
            id="personalEmail"
            name="personalEmail"
            onChange={(event) => {
              handleChange(event);
            }}
            type="email"
            disabled={props.disabledCheck}
            value={formState.values.personalEmail || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            error={formState.errors.PSN === "error"}
            helperText={
              formState.errors.PSN === "error"
                ? "Valid Secondary Number is required"
                : null
            }
            label="Personal Secondary Number"
            id="PSN"
            name="PSN"
            onChange={(event) => {
              handleChange(event);
            }}
            type="text"
            disabled={props.disabledCheck}
            value={formState.values.PSN || ""}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4} style={{ marginTop: "15px" }}>
          <FormControlLabel
            control={<Checkbox name="canCreateAnyInvoice" color="info" />}
            label="View All Purchase Orders"
            name="canCreateAnyInvoice"
            disabled={props.disabledCheck}
            checked={formState.values.canCreateAnyInvoice}
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </GridItem>

        {formState.values.canCreateAnyInvoice &&
          !getAllUsersLoading &&
          getAllUsers?.length > 0 && (
            <GridItem xs={12} sm={12} md={12} lg={4}>
              <MultipleSelect
                getAllUsers={getAllUsers}
                onChange={setWorkFlows}
                formState={formState}
              />
            </GridItem>
          )}
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            error={formState.errors.displayName === "error"}
            helperText={
              formState.errors.displayName === "error"
                ? "Valid Display Name is required"
                : null
            }
            label="User Manager"
            id="managerDisplayName"
            name="managerDisplayName"
            onChange={(event) => {
              handleChange(event);
            }}
            type="text"
            disabled
            value={formState.values.managerDisplayName || ""}
          />
        </GridItem>
        {!formState.values.canCreateAnyInvoice && (
          <GridItem xs={12} sm={12} md={12} lg={4}></GridItem>
        )}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          <Divider />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          <Autocomplete
            id="filter-demo"
            options={Users}
            value={formState.values.deligateUser || ""}
            variant="standard"
            getOptionLabel={(option) => option?.displayName?.toUpperCase()}
            loading={getUserLoading}
            onInputChange={(event, newInputValue) => {
              getAllUser(newInputValue);
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = {
                  displayName: newValue?.displayName,
                };
                setFormState((formState) => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    ["deligateUser"]: obj,
                    ["deligateUserEmail"]: newValue.mail,
                  },
                }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Users"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {getUserLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}

                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                // value={filters.user}
              />
            )}
          />
          {/* <TextField
            className={classes.textField}
            fullWidth={true}
            label="Deligate User"
            name="deligateUser"
            onChange={(event) => {
              handleChange(event);
            }}
            select
            disabled={props.disabledCheck}
            value={formState.values.deligateUser || ""}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Choose User
            </MenuItem>
            {getAllUsers?.map((user, index) => (
              <MenuItem key={index} value={user?._id}>
                {user?.level1?.displayName?.toUpperCase()}
              </MenuItem>
            )
            )}
          </TextField> */}
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          <TextField
            className={classes.textField}
            fullWidth={true}
            type="date"
            label="Leave Start Date"
            name="leaveStart"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              handleChange(event);
            }}
            value={formState.values.leaveStart || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={4}
          lg={4}
          style={{ marginBottom: "10px", marginTop: "10px" }}
        >
          <TextField
            className={classes.textField}
            fullWidth={true}
            type="date"
            label="Leave End Date"
            name="leaveEnd"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              handleChange(event);
            }}
            value={formState.values.leaveEnd || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={4}
          style={{ marginTop: "15px" }}
        ></GridItem>
        {props.disabledCheck ? (
          isLoading ? (
            ""
          ) : (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <legend>User Image</legend>
              <div className="fileinput text-center">
                <div className="thumbnail">
                  <img src={userImage} alt="..." />
                </div>
              </div>
            </GridItem>
          )
        ) : (
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#095392",
                color: "white",
              }}
              className={classes.registerButton}
              round
              onClick={saveUserLevelOne}
            >
              Save Level 1 Info
            </Button>
            {formState.isLoading ? <CircularProgress disableShrink /> : ""}
          </GridItem>
        )}
      </GridContainer>
    </Animated>
  );
}
