import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
// @material-ui/core components
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import defaultAvatar from "assets/img/placeholder.jpg";
import { isArray } from "lodash";
import { formatDateTime } from "Functions";
import { addZeroes } from "views/LDocs/Functions/Functions";
const sweetAlertStyle = makeStyles(styles2);
let Token = localStorage.getItem("cooljwt");
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};
const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: "relative",
    maxWidth: 360,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {},
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "",
  },
  TableHead: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
}));

export default function Lines({ Transations }) {
  const useStyles = makeStyles(styles);
  const classesList = useStyle();
  const [animateStep, setAnimateStep] = useState(true);

  let filtered_lines = Transations?.map((obj) => {
    let new_line = obj;
    //Clearing The Extra Fields
    delete new_line.links;
    delete new_line.schedules;
    delete new_line._id;
    // delete new_line.TransactionTypeCode !== 'RECEIVE'
    return new_line;
  }) || []
  return (
    <Animated
      animationIn="bounceInRight"
      animationOut="bounceOutLeft"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >
      {filtered_lines.length > 0 ? (
        <GridContainer style={{ overflowX: "scroll", height: 400 }}>
        <Table className={classesList.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classesList.TableRow}>
            {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Transaction Id</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Transaction Type Code</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Receipt Number</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Transaction Type</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Transaction Date</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Quantity</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>UOM Code</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Unit Of Measure</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Primary Quantity</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Primary U O M Code</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Primary Unit Of Measure</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Secondary Quantity</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Secondary U O M Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Secondary Unit Of Measure</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Amount	</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Currency Code</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Currency Name</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Currency Conversion Type</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Currency Conversion Rate</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Currency Conversion Date</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Item Id</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Item Number</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Item Description</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Item Revision</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Source Document Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Source Document</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Destination Type Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Destination Type</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Parent Transaction Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Parent Transaction Type Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Parent Transaction Type</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Organization Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Organization Code	</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Organization Name</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Group Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Consigned Flag</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Subinventory</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Deliver To Location Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Deliver To Location Name</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Deliver To Person Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Deliver To Person Name</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Shipment Header Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Shipment Line Id</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Line Number</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Header Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Line Id</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Line Location Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Distribution Id</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Number</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Line Number</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Shippment Number</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Distribution Number</Typography></TableCell> */}
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Vendor Id</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Vendor Name</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Vendor Site Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Vendor Site Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Employee Id</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Employee Name	</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Inspection Status Code	</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Inspection Status</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Accrual Status Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Default Taxation Country Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Transaction Business Category Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Product Type Code</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Final Discharge Location Id</Typography></TableCell> */}
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Quantity Billed</Typography></TableCell>
              <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Amount Billed</Typography></TableCell>
              {/* <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Synced By</Typography></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody style={{ paddingBottom: 5 }}>
          {filtered_lines?.map((line) => (
            line.TransactionTypeCode == 'RECEIVE' && line?.TransactionId ? 
              <TableRow>
                {/* <TableCell className={classesList.TableID}>
                  {line?.TransactionId || ""}
                </TableCell> */}
                {/* <TableCell className={classesList.TableID}>
                  {line?.TransactionTypeCode || ""}
                </TableCell> */}
                {/* <TableCell className={classesList.TableID}>
                  { line?.TransactionType || ""}
                </TableCell> */}
                <TableCell className={classesList.TableID}>
                  {line?.ReceiptNumber || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.TransactionDate ? formatDateTime(line?.TransactionDate) : ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.Quantity || ""}
                </TableCell>
                {/* <TableCell className={classesList.TableID}>
                  {line?.UOMCode || ""}
                </TableCell> */}
                {/* <TableCell className={classesList.TableID}>
                  {line?.UnitOfMeasure || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.PrimaryQuantity || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.PrimaryUOMCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.PrimaryUnitOfMeasure || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.SecondaryQuantity || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.SecondaryUOMCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.SecondaryUnitOfMeasure || ""}
                </TableCell> */}
                <TableCell className={classesList.TableID}>
                  {addZeroes(line?.Amount) || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.CurrencyCode || ""}
                </TableCell>
                {/* <TableCell className={classesList.TableID}>
                  {line?.CurrencyName || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.CurrencyConversionType || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.CurrencyConversionRate || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.CurrencyConversionDate || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ItemId || ""}
                </TableCell> */}
                 {/* <TableCell className={classesList.TableID}>
                  {line?.ItemNumber || ""}
                </TableCell> */}
                <TableCell className={classesList.TableID}>
                  {line?.ItemDescription || ""}
                </TableCell>
                {/*<TableCell className={classesList.TableID}>
                  {line?.ItemRevision || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.SourceDocumentCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.SourceDocument || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.DestinationTypeCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.DestinationType || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ParentTransactionId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ParentTransactionTypeCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ParentTransactionType || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.OrganizationId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.OrganizationCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.OrganizationName || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.GroupId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ConsignedFlag || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.Subinventory || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.DeliverToLocationId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.DeliverToLocationName || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.DeliverToPersonId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.DeliverToPersonName || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ShipmentHeaderId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ShipmentLineId || ""}
                </TableCell> */}
                
                <TableCell className={classesList.TableID}>
                  {line?.LineNumber || ""}
                </TableCell>
                {/* <TableCell className={classesList.TableID}>
                  {line?.POHeaderId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.POLineId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.POLineLocationId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.PODistributionId || ""}
                </TableCell> */}
                <TableCell className={classesList.TableID}>
                  {line?.PONumber || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.POLineNumber || ""}
                </TableCell>
                {/* <TableCell className={classesList.TableID}>
                  {line?.POShippmentNumber || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.PODistributionNumber || ""}
                </TableCell> */}
                {/* <TableCell className={classesList.TableID}>
                  {line?.VendorId || ""}
                </TableCell> */}
                <TableCell className={classesList.TableID}>
                  {line?.VendorName || ""}
                </TableCell>
                {/* <TableCell className={classesList.TableID}>
                  {line?.VendorSiteId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.VendorSiteCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.EmployeeId || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.EmployeeName || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.InspectionStatusCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.InspectionStatus || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.AccrualInspectionStatus || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.TransactionBusinessCategoryCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.ProductTypeCode || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {line?.FinalDischargeLocationId || ""}
                </TableCell> */}
                <TableCell className={classesList.TableID}>
                  {line?.QuantityBilled || ""}
                </TableCell>
                <TableCell className={classesList.TableID}>
                  {addZeroes(line?.AmountBilled) || ""}
                </TableCell>
                {/* <TableCell className={classesList.TableID}>
                  {line?.SyncedBy}
                </TableCell> */}
              </TableRow>
              : ""
            ))}
          </TableBody>
        </Table>
      </GridContainer>
      ) : (
        "No Record Found."
      )}
    </Animated>
  );
}