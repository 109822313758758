import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux";
import { PersistGate } from 'redux-persist/integration/react'
import addNotification from 'react-push-notification';



import AdminLayout from "layouts/Admin.js";
import VendorLayout from "layouts/Vendor.js";
import AuthSupplier from "layouts/AuthSupplier";
import NewVendorPassword from "views/LDocs/Authorization/NewVendorPassword"
import AuthLayout from "layouts/Auth.js";
import DefaultLayout from "layouts/Default.js";
import InvoiceDetail from "views/LDocs/Invoices/InvoiceDetail/InvoiceDetail";
import ActivateClient from "views/LDocs/Clients/ActivateClient";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import { registerServiceWorker } from "./register-sw";
import AcknowledgedClient from "views/LDocs/Clients/AcknowledgedClient";


import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./azure/authConfig";
import Totp from "views/LDocs/Authorization/Totp";
import ForgetVendorPassword from "views/LDocs/Authorization/ForgetVendorPassword";
import Blank from "layouts/Blank";



const msalInstance = new PublicClientApplication(msalConfig);




if(process.env.REACT_APP_LDOCS_ENVIROMENT !== "development"){
  navigator.serviceWorker.addEventListener("notificationclick", (message) => {
  });
  navigator.serviceWorker.addEventListener("message", (message) => {
    var payload = message.data['firebase-messaging-msg-data'];
    addNotification(
      {
        title: payload.notification.title,
        message: payload.notification.body, //optional
        duration: 6000, //optional, default: 5000,
        native: true, //optional, makes the push notification a native OS notification
        icon: "https://i.ibb.co/k409jfP/logo.png", // optional, Native only. Sets an icon for the notification.
        vibrate: 4, // optional, Native only. Sets a vibration for the notification.
        silent: false, // o
        onClick: () => window.open(payload.data.url)
      });
  });
}



const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');



// const hist = createBrowserHistory({ basename: '/salic' });
const hist = createBrowserHistory();
registerServiceWorker();
hideLoader();
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={hist}>
        <Switch>
          <Route path="/default" component={DefaultLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/supplier" component={AuthSupplier}/>
          <Route path="/sup/new_password" exec component={Totp} />
          <Route path="/sup/forgot_password" exec component={ForgetVendorPassword} />
          <Route path="/sup/newpassword" exec component={NewVendorPassword} />
          <Route path="/admin" component={AdminLayout} />
          <Route path="/vendor" component={VendorLayout} />
          <Route path="/blank" component={Blank} />
          <Route path="/client/activate" component={ActivateClient} />
          <Route path="/client/acknowladged" component={AcknowledgedClient} />
          {/**  <Redirect from="/" to="/admin/dashboard" />  */}
          <Redirect from="/" to="/auth" />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
  </MsalProvider>,
  document.getElementById("root")
);
