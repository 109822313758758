import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  makeStyles,
  CircularProgress,
  Slide,
  DialogContent,
  Dialog,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  TextField,
  withStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Avatar,
  Divider,
} from "@material-ui/core";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import defaultImage from "assets/img/image_placeholder.jpg";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Button from "components/CustomButtons/Button.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import Wizard from "./Wizard.js";
import Step1 from "./steps/level1";
import Step2 from "./steps/level2";
import axios from "axios";
import { setIsTokenExpired } from "actions";
import MoneyIcon from "@material-ui/icons/Money";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import FileAdvanceView from "../Invoices/AdvanceView/FileAdvanceView.js";
import { addZeroes } from "../Functions/Functions.js";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    // color: "white",
  },
  buttonRight: {},
  textFieldColor: {
    // color: "white",
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    marginLeft: "auto",
    marginRight: "auto",
  },
  img: {
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
    width: "100%",
  },
}));

export default function DepartmentLedger(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const dispatch = useDispatch();
  const decoded = jwt.decode(Token);
  const isVendor = decoded.isVendor;
  const classes = useStyles();
  const [gridView, setGridView] = React.useState(true);
  const [qrModal, setQrModal] = React.useState(false);
  const [animateQr, setAnimateQr] = React.useState(false);
  const [row, setRow] = React.useState(null);
  const [loading, setIsLoading] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [paidCount,setPaidCount] = React.useState(0);
  const [page, setPage] = React.useState(0) 
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [componentState, setComponentState] = React.useState({
    orgs: [],
    Department_CostCenter:null,
    depAccounts:[],
    isLoading: false,
    supplierInputValue: "",
    vendors: [],
    overDueInvoices: 0,
    fullPaid: 0,
    partiallyPay: 0,
    toBePaid: 0,
    payments: [],
    invoices: [],
    currencyCode: "",
    selectedVendor: null,
    selectedCustomer: null,
    paid_invoices:[],
    unpaid_invoices:[]
  });

  //Open Advance View
  const viewQrView = (row) => {
    axios({
      method: "post", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion`,
      data: {
        invoiceId: row.invoiceId,
        version: row.version,
        vendorId: row.vendorId,
      },
      headers: {
        cooljwt: Token,
      },
    })
      .then(async (invoiceRes) => {
        const invoice = invoiceRes.data;
        if (invoice) {
          setRow(invoice);
          setQrModal(true);
          setGridView(false);
          setAnimateQr(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Close Views
  const goBack = () => {
    setQrModal(false);
    setGridView(true);
    setAnimateQr(false);
  };

  const handleFilter = (event) => {
    event.preventDefault();
    setSelected(event.target.value);
  };


  const getData = () => {
    setIsLoading(true);
    let orgID =  decoded.orgDetail.organizationId;
    let filter = null;
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/GetDetailsByDepartmentCostCenter/${orgID}/${componentState.Department_CostCenter?.Acc_NO}?page=${page}&items=${rowsPerPage}`,
      headers: {
        cooljwt: Token,
      },
    })
      .then((response) => {
        let data = response.data;
        setCount(response.data.invoices.totalNumOfItems || 0)
        setPaidCount(response.data.paidInvoices.totalNumOfItems || 0)
        setComponentState((componentState) => ({
          ...componentState,
          invoices: response?.data?.invoices?.results,
          payments: data.payments,
          fullPaid: data.fullPaid[0] ? data.fullPaid[0].totalAmount : 0,
          overDueInvoices: data.overDueInvoices[0]
            ? data.overDueInvoices[0].totalAmount
            : 0,
          partiallyPay: data.partiallyPay[0]
            ? data.partiallyPay[0].totalAmount
            : 0,
          toBePaid: data.toBePaid[0] ? data.toBePaid[0].totalAmount : 0,
          currencyCode:
            data.invoices.length > 0 ? data.invoices[0].LC_currency.Code : "",
            unpaid_invoices:data?.unPaidInvoices || [],
            paid_invoices:response?.data?.paidInvoices?.results || [],
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setIsLoading(false);
      });
  };





  // React.useEffect(() => {
  //   let vendorID = props.vendor;
  //   let orgID = props.org;
  //   vendorID && orgID ? getData() : isVendor ? getCustomers() : getVendors();
  // }, [componentState.supplierInputValue]);



  const _exportToXlrs = async () => {
    let orgID =  decoded.orgDetail.organizationId;
    let data = {
      organizationId : orgID,
      costCenterId:componentState.Department_CostCenter?.Acc_NO,
      tenantId:decoded.tenantId

    }
    await axios({
      method: "post", //you can set what request you want to be
      url:`${process.env.REACT_APP_LDOCS_API_URL}/report/paidInvoiceToXlxs360`,
      data:data,
      headers: {
        cooljwt: Token,
        // responseType: 'blob',
      },
    })
      .then((response) => {
        console.log(response);
        const downloadUrl = `${process.env.REACT_APP_LDOCS_API_URL}/${response.data.path}`;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", ""); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
  }


  const getDepAccounts = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getAccounts/Department_CostCenter`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        setComponentState((formState) => ({
          ...formState,
          depAccounts:response.data[0]?.records || []
        }));
      })
      .catch((error) => {
        if (error.response) {  error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
        console.log(`Unable to get Companies please contact at ${process.env.REACT_APP_LDOCS_CONTACT_MAIL}`)
      });
  }

  useEffect(()=>{
    getDepAccounts();
  },[]);

  React.useEffect(() => {
    getData();
  }, [componentState.Department_CostCenter, page,rowsPerPage]);

  return (
    <div>
      {gridView ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={gridView}
        >
          {/* {isVendor || decoded.orgDetail} */}
          <div>
            <GridContainer>
              {!props.vendor && !props.org ? (
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <GridContainer>
                    
                      </GridContainer>
                </GridItem>
              ) : (
                ""
              )}
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <GridContainer>
                <GridItem xs={12} sm={12} md={3} lg={3}>
                      <Card
                        style={{
                          paddingLeft: 20,
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingRight: 20,
                          color: "white",
                        }}
                      >
                        <h4
                          className={classes.cardCategory}
                          style={{ color: "grey" }}
                        >
                          {"Department / Cost Center"}
                        </h4>
                        <Divider style={{ background: "grey" }} />
                        <Autocomplete
                    id="filter-demo"
                    options={componentState.depAccounts}
                    value={componentState.Department_CostCenter || ""}
                    getOptionLabel={(option) =>
                      `${option?.Acc_NO || ""} - ${option?.Acc_Description || ""}`
                    }
                    onChange={(event, newValue) => {
                      setComponentState((formState) => ({
                        ...formState,
                        Department_CostCenter:newValue
                      }));
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=" "
                        // value={formState.values.Department_CostCenter}
                      />
                    )}
                  />
                   
                      </Card>
                    </GridItem>
                  <GridItem xs={12} sm={12} md={3} lg={3}>
                    <Card style={{ paddingBottom: 10 }}>
                      <CardHeader
                        style={{ padding: -10 }}
                        color="info"
                        stats
                        icon
                      >
                        <CardIcon color="info">
                          <MoneyIcon />
                        </CardIcon>
                        <h3
                          className={classes.cardTitle}
                          style={{ color: "grey" }}
                        >
                          PAID
                        </h3>
                        <h4
                          className={classes.cardTitle}
                          style={{ color: "grey" }}
                        >
                          {componentState.currencyCode}{" "}
                          {addZeroes(componentState.fullPaid)}
                        </h4>
                      </CardHeader>
                      <CardFooter stats></CardFooter>
                    </Card>

                  </GridItem>
                  <GridItem xs={12} sm={6} md={3} lg={3}>
                    <Card style={{ paddingBottom: 10 }}>
                      <CardHeader
                        style={{ padding: -10 }}
                        color="danger"
                        stats
                        icon
                      >
                        <CardIcon color="danger">
                          <HourglassEmptyIcon />
                        </CardIcon>
                        <h3
                          className={classes.cardTitle}
                          style={{ color: "grey" }}
                        >
                          PENDING
                        </h3>
                        <Divider style={{ background: "white" }} />
                        <h4
                          className={classes.cardTitle}
                          style={{ color: "grey" }}
                        >
                          {componentState.currencyCode}{" "}
                          {addZeroes(componentState.toBePaid)}
                        </h4>
                      </CardHeader>
                      <CardFooter stats></CardFooter>
                    </Card>

                  </GridItem>
                  <GridItem xs={12} sm={6} md={3} lg={3}>
                    <Card style={{ paddingBottom: 10 }}>
                      <CardHeader
                        style={{ padding: -10 }}
                        color="info"
                        stats
                        icon
                      >
                        <CardIcon color="info">
                          <TimerOffIcon />
                        </CardIcon>
                        <h3
                          className={classes.cardTitle}
                          style={{ color: "grey" }}
                        >
                          OVER DUE
                        </h3>
                        <h4
                          className={classes.cardTitle}
                          style={{ color: "grey" }}
                        >
                          {componentState.currencyCode}{" "}
                          {addZeroes(componentState.overDueInvoices)}
                        </h4>
                      </CardHeader>
                      <CardFooter stats></CardFooter>
                    </Card>

                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card profile>
                    <CardHeader color="info" icon>
                 
                    <Button
                    color="info"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => _exportToXlrs()}
                  >
                    Export
                  </Button>
                  </CardHeader>
                      <CardBody profile>
                        <Wizard
                          validate
                          steps={[
                            {
                              stepName: "Invoices",
                              stepComponent: Step1,
                              stepId: "transactions",
                            },
                            {
                              stepName: "Paid Invoices",
                              stepComponent: Step2,
                              stepId: "invoices",
                            },
                          ]}
                          count={count}
                          paidCount={paidCount}
                          page={page}
                          setPage={setPage}
                          rowsPerPage={rowsPerPage}
                          setRowsPerPage={setRowsPerPage}
                          paid_invoices={componentState?.paid_invoices}
                          unpaid_invoices={componentState?.unpaid_invoices}
                          invoices={componentState?.invoices}
                          transactions={componentState.payments}
                          loading={loading}
                          isVendor={isVendor}
                          openAdvanceView={viewQrView}
                        />
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </Animated>
      ) : (
        ""
      )}
      {/* Advance View Model */}
      {qrModal ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateQr}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>360&#176; View</h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <FileAdvanceView isVendor={isVendor} fileData={row} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
    </div>
  );
}
