import {
    ORGANIZATION_LIST_SUCCESS,
    ORGANIZATION_LIST_REQUEST,
    ORGANIZATION_LIST_FAIL
  } from "actions/Constant";
  
  
  let initialState = {
      organizationList:[],
      loading: false,
      error:null
  }
  
  
  export function organizationListReducer(state = initialState, action) {
    switch (action.type) {
      case ORGANIZATION_LIST_REQUEST:
        return { loading: true, organizationList: [] };
      case ORGANIZATION_LIST_SUCCESS:
        return { loading: false, organizationList: action.payload };
      case ORGANIZATION_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }