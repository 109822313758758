import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  MenuItem,
  TableBody,
  Table,
  Tooltip,
  TablePagination,
  IconButton,
  Dialog,
  DialogContent,
  makeStyles,
  Slide,
  TextField,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

//Icon Imports
import Edit from "@material-ui/icons/Edit";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Pending from "assets/img/statuses/Pending.png";
import Success from "assets/img/statuses/Success.png";
import Rejected from "assets/img/statuses/Rejected.png";
import NoStatus from "assets/img/statuses/NoStatus.png";
import axios from "axios";
import { Link } from "react-router-dom";
import SupplierList from "actions/SupplierListAction";
import { SupplierFilter } from "actions/SupplierListAction";
import { useDispatch, useSelector } from "react-redux";
import jwt, { decode } from "jsonwebtoken";
import Button from "components/CustomButtons/Button.js";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { successAlert, errorAlert } from "Functions";
import RateReview from "@material-ui/icons/RateReview";
import { verifyEmail } from "Functions";
import OrganizationList from "actions/OrganizationListAction";
import { formatDateTime } from "Functions";
import { msgAlert } from "Functions";
import { some, stubFalse } from "lodash";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import InfoIcon from "@material-ui/icons/Info";
import TrackingStatus from "./TrackingStatus";
import { Delete } from "@material-ui/icons";
import { confirmAlert } from "views/LDocs/Functions/Functions";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: "relative",
    maxWidth: 360,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {
    minWidth: "10%",
  },
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "3%",
  },
}));

function SupplierPagination({
  isFilterEnable,
  newdata,
  filterStatus,
  filterDate,
  setFilterStatus,
  isStatus,
}) {
  const classesList = useStyle();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [RowsPerPage, setRowsPerPage] = useState(10);
  const [decoded, setDecoded] = React.useState(null);
  const [monogoId, setMonogoId] = React.useState();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState(false);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [initialReview, setInitialReview] = React.useState("");
  const [review, setReview] = React.useState("");
  const [approve, setApprove] = React.useState("");
  const [approveExported, setApproveExported] = React.useState("");
  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);
  const [row, setRow] = useState(null);

  const [state, setState] = React.useState({
    editSupplierModal: false,
    updatingSupplier: false,
    selectedSupplier: null,
    values: {
      email: "",
      organization: "",
    },
    errors: {
      email: "",
      organization: "",
    },
  });

  const [status, setStatus] = React.useState();
  const [postStatus, setPoststatus] = React.useState();
  const [isloading, setisloading] = useState(false);
  const [fusloading, setFusloading] = useState(false);
  const [supId, setSupId] = useState();
  const [formState, setFormState] = React.useState({
    values: {},
    errors: {
      status: "",
      reviewComments: "",
    },
  });
  const styles = makeStyles((theme) => ({}));
  const classes = styles();

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const dispatch = useDispatch();

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let userDetail = jwt.decode(Token);

  React.useEffect(() => {
    // dispatch(SupplierList(page, RowsPerPage, Token));
    dispatch(OrganizationList(Token));
    userDetail = jwt.decode(Token);
    setDecoded(userDetail);
  }, []);

  useEffect(() => {
    if (isFilterEnable == true) {
      let dispatch_payload = {
        page,
        RowsPerPage: RowsPerPage,
        token: Token,
        newdata: newdata,
        checkStatus: false,
      };
      dispatch(SupplierFilter(dispatch_payload));
    } else if (!filterStatus) {
      let dispatch_payload = {
        page,
        RowsPerPage: RowsPerPage,
        token: Token,
        Status: "ALL",
        checkStatus: false,
        startDate: filterDate.from,
        endDate: filterDate.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
  }, []);

  React.useEffect(() => {
    if (filterStatus) {
      let dispatch_payload = {
        page: page,
        RowsPerPage: RowsPerPage,
        token: Token,
        newdata: newdata,
        Status: filterStatus,
        checkStatus: false,
        startDate: filterDate.from,
        endDate: filterDate.to,
      };
      dispatch(SupplierList(dispatch_payload));
    } else {
      let dispatch_payload = {
        page: page,
        RowsPerPage: RowsPerPage,
        token: Token,
        newdata: newdata,
        Status: "ALL",
        checkStatus: false,
        startDate: filterDate.from,
        endDate: filterDate.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
  }, [page]);
  const supplierData = useSelector((state) => state.suppliers.supplierList);
  const organizationData = useSelector(
    (state) => state.organizations.organizationList
  );
  const loading = useSelector((state) => state.suppliers);
  const { loading: loadingSave } = loading;

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    if (filterStatus) {
      let dispatch_payload = {
        page: page,
        RowsPerPage: event.target.value,
        token: Token,
        newdata: newdata,
        Status: filterStatus,
        checkStatus: false,
        startDate: filterDate.from,
        endDate: filterDate.to,
      };
      dispatch(SupplierList(dispatch_payload));
    } else {
      let dispatch_payload = {
        page: page,
        RowsPerPage: event.target.value,
        token: Token,
        newdata: newdata,
        Status: "ALL",
        checkStatus: false,
        startDate: filterDate.from,
        endDate: filterDate.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
  };
  const handleFilterChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleFilterChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value), 10);
    let dispatch_payload = {
      page,
      rowPerPage: event.target.value,
      token: Token,
      newdata: newdata,
      checkStatus: false,
    };
    dispatch(SupplierFilter(dispatch_payload));
  };
  const _supplierEmailUpdate = (selectedSupplier) => {
    setOpenUserModal(true);
    setState((state) => ({
      ...state,
      selectedSupplier: selectedSupplier,
      values: {
        ...state.values,
        email: selectedSupplier?.level1?.email || "",
        organization: selectedSupplier?.organizations[0]?.organizationId || "",
      },
    }));
  };

  const _trackingStatus = (selectedSupplier) => {
    setOpenTrackingModal(true);
    setSelectedWorkFlow(selectedSupplier?.workFlowStatus);
    setInitialReview(selectedSupplier?.workFlowStatus?.initialReviewStep);
    setReview(selectedSupplier?.workFlowStatus?.reviewStep);
    setApprove(selectedSupplier?.workFlowStatus?.approveStep);
    setApproveExported(selectedSupplier?.workFlowStatus?.exportStep);
  };
  const closeTrackingModal = () => {
    setOpenTrackingModal(false);
    setInitialReview("");
    setReview("");
    setApprove("");
    setApproveExported("");
  };

  const _updateRecord = async () => {
    let email;
    const Check = require("is-null-empty-or-undefined").Check;
    let error = false;

    if (!Check(state.values.email)) {
      if (verifyEmail(state.values.email)) {
        email = "success";
      } else {
        email = "error";
        error = true;
      }
    } else {
      email = "error";
      error = true;
    }

    setState((state) => ({
      ...state,
      errors: {
        ...state.errors,
        email: email,
      },
    }));
    if (error) {
      return false;
    } else {
      await axios({
        method: "post", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/vendorFusionRegistration`,
        data: {
          email: state.values.email,
          supplierId: state.selectedSupplier?.level1?.SupplierId,
          organizationId: state.values.organization,
        },
        headers: {
          cooljwt: Token,
        },
      })
        .then((res) => {
          if (filterStatus) {
            let dispatch_payload = {
              page: page,
              RowsPerPage: RowsPerPage,
              token: Token,
              newdata: newdata,
              Status: filterStatus,
              startDate: filterDate.from,
              endDate: filterDate.to,
            };
            dispatch(SupplierList(dispatch_payload));
          } else {
            let dispatch_payload = {
              page: page,
              rowPerPage: RowsPerPage,
              token: Token,
              newdata: newdata,
              Status: "ALL",
              checkStatus: false,
            };
            dispatch(SupplierFilter(dispatch_payload));
          }
          setOpenUserModal(false);
          successAlert("Activation Email has been sent to supplier");
        })
        .catch((error) => {
          setOpenUserModal(false);
          console.log(error || error?.response || error?.response?.data);
          // if (error.response) {
          //   error.response.status == 401 && dispatch(setIsTokenExpired(true));
          // }
          errorAlert("Vendor Not Exist");
        });
    }
  };

  const _handleChange = (event) => {
    event.persist();
    //Setting Value in State
    setState((state) => ({
      ...state,
      values: {
        ...state.values,
        [event.target.name]: event.target?.value,
      },
    }));
  };

  const _closeSupplierEmailUpdateModal = () => {
    setOpenUserModal(false);
  };
  React.useEffect(() => {}, [page]);

  const reviewFile = async (row) => {
    let currentStatus = row.workFlowStatus?.status;
    let action = "";
    switch (currentStatus) {
      case "initialReviewStep":
        action = "initialReview";
        break;
      case "reviewStep":
        action = "review";
        break;
      case "approveStep":
        action = "approve";
        break;
      case "CorrectionRequired":
        action = "CorrectionRequired";
        break;
      default:
        action = "";
        break;
    }
    setRow({ ...row, action });
    setReviewModal(true);
  };

  const handlerChange = (event) => {
    // console.log("event",event);
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  //Review Handler
  const reviewFileNow = async () => {
    setisloading(true);

    let action = row.action;
    let status = formState.values.status;
    let postData = {
      vendorId: row._id,
      action: action,
      user: decoded.email,
      comment: formState.values.comments,
      status,
    };
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/updateFlowStatus`,
      data: postData,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setisloading(false);
        setReviewModal(false);
        if (status === "rejected") {
          successAlert("Rejected Successfully");
        } else if (status === "APPROVED") {
          successAlert("Approved Successfully");
        } else if (status === "correctionRequired") {
          successAlert("Sent For Correction Required");
        } else if (status === "REVIEWED") {
          successAlert("Reviewed Successfully");
        }

        if (filterStatus) {
          let dispatch_payload = {
            Status: filterStatus,
            page: 0,
            RowsPerPage: 10,
            Token: Token,
            checkStatus: false,
            startDate: filterDate.from,
            endDate: filterDate.to,
          };
          dispatch(SupplierList(dispatch_payload));
          setFilterStatus(null);
        } else {
          let dispatch_payload = {
            page,
            rowPerPage: RowsPerPage,
            token: Token,
            newdata: newdata,
            checkStatus: false,
          };
          dispatch(SupplierFilter(dispatch_payload));
        }
      })
      .catch((err) => {
        let error = err?.response?.data;
        errorAlert(error);
        setisloading(false);
        setReviewModal(false);
      });
  };

  const _deleteSupplier = async (id) => {
    await axios({
      method: "delete", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/deleteIncompleteSupplier?id=${id}`,
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        successAlert("Supplier Deleted Successfully");
        if (filterStatus) {
          let dispatch_payload = {
            page: page,
            RowsPerPage: RowsPerPage,
            token: Token,
            newdata: newdata,
            Status: filterStatus,
            startDate: filterDate.from,
            endDate: filterDate.to,
          };
          dispatch(SupplierList(dispatch_payload));
        } else {
          let dispatch_payload = {
            page: page,
            rowPerPage: RowsPerPage,
            token: Token,
            newdata: newdata,
            Status: "ALL",
            checkStatus: false,
          };
          dispatch(SupplierFilter(dispatch_payload));
        }
      })
      .catch((error) => {
        // if (error.response) {
        //   error.response.status == 401 && dispatch(setIsTokenExpired(true));
        // }
        errorAlert(error || "Failed in updating user");
      });
  };

  const exporttofusion = async (id) => {
    setSupId(id);
    setFusloading(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/exportVendorToFusion`,
      data: { MongoId: id },
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setFusloading(false);
        successAlert("Supplier Exported Successfully");
        if (filterStatus) {
          let dispatch_payload = {
            Status: filterStatus,
            page: 0,
            RowsPerPage: 10,
            Token: Token,
            checkStatus: false,
            startDate: filterDate.from,
            endDate: filterDate.to,
          };
          dispatch(SupplierList(dispatch_payload));
          setFilterStatus(null);
        } else {
          let dispatch_payload = {
            page,
            rowPerPage: RowsPerPage,
            token: Token,
            newdata: newdata,
            checkStatus: false,
          };
          dispatch(SupplierFilter(dispatch_payload));
        }
      })
      .catch((err) => {
        let error = err?.response?.data;
        errorAlert(error);
        setFusloading(false);
      });
  };

  const getSteps = (step) => {
    step = {
      ...step,
      forward: step?.forward
        ?.map((step) => ({ ...step, showReason: true }))
        .concat(
          step?.delegate?.map((step) => ({ ...step, showReason: false }))
        ),
    };
    let filteredStep = step?.forward.filter((x) => x !== undefined);
    return {
      ...step,
      forward: filteredStep?.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      ),
    };
  };

  const showFwdNotes = (step) => {
    if (step === "initialReview") {
      let step = getSteps(initialReview);
      setShowFwdData(step);
    } else if (step === "review") {
      let step = getSteps(review);
      setShowFwdData(step);
    } else {
      let step = getSteps(approve);
      setShowFwdData(step);
    }
    setShowFwdDetails(true);
  };

  function canShowEditButton(workflow, userEmail, isAdmin) {
    // Check if user email matches any reviewedBy or approvedBy fields
    const isReviewer = workflow.reviewStep.some(
      (step) => step.reviewedBy === userEmail
    );
    const isApprover = workflow.approveStep.some(
      (step) => step.approvedBy === userEmail
    );

    // Show edit button if user is an admin or if their email matches either reviewedBy or approvedBy
    return isAdmin || isReviewer || isApprover;
  }

  return (
    <GridContainer>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        {" "}
        {openUserModal ? (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={openUserModal}
            onClose={() => _closeSupplierEmailUpdateModal()}
            aria-labelledby="pdf-modal-slide-title"
            aria-describedby="pdf-modal-slide-description"
          >
            <DialogContent id="onBoardingIM" className={classes.modalBody}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      Supplier On-Boarding
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <TextField
                        error={state.errors.organization === "error"}
                        helperText={
                          state.errors.organization === "error"
                            ? "Valid organization is required"
                            : null
                        }
                        className={classes.textField}
                        fullWidth={true}
                        label="Organization"
                        name="organization"
                        select
                        multiple
                        onChange={(event) => {
                          _handleChange(event);
                        }}
                        value={state.values.organization || ""}
                      >
                        <MenuItem disabled>Select Organization</MenuItem>
                        {organizationData?.map((org) => (
                          <MenuItem key={org?._id} value={org?._id || ""}>
                            {org?.organizationName || ""}
                          </MenuItem>
                        )) || []}
                      </TextField>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <TextField
                        error={state.errors.email === "error"}
                        helperText={
                          state.errors.email === "error"
                            ? "Valid email is required"
                            : null
                        }
                        className={classes.textField}
                        fullWidth={true}
                        label="Supplier Email"
                        name="email"
                        type="text"
                        onChange={(event) => {
                          _handleChange(event);
                        }}
                        value={state.values.email || ""}
                      />
                    </GridItem>
                  </GridContainer>

                  <Button
                    color="danger"
                    size="small"
                    style={{ float: "right" }}
                    onClick={() => _closeSupplierEmailUpdateModal()}
                    round
                  >
                    Close
                  </Button>
                  <Button
                    color="info"
                    size="small"
                    style={{ float: "right" }}
                    onClick={_updateRecord}
                    // disabled={
                    //   state.values.email ==
                    //   state.selectedSupplier?.level1?.email
                    // }
                    round
                  >
                    Register
                  </Button>
                </CardBody>
              </Card>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name </TableCell>
                <TableCell style={{ textAlign: "left", width: 300 }}>
                  Status{" "}
                </TableCell>
                <TableCell>Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Last Synched</TableCell>
                <TableCell style={{ textAlign: "right" }} colSpan={2}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingSave ? (
                <CircularProgress
                  style={{
                    marginTop: "50px",
                    marginLeft: "400px",
                    width: "100px",
                    height: "100px",
                  }}
                />
              ) : (
                supplierData?.Vendors?.map((row, index) => (
                  <>
                    {fusloading && supId == row._id ? (
                      <TableRow>
                        <TableCell colSpan={8}>
                          {" "}
                          <LinearProgress style={{ width: "100%" }} />
                        </TableCell>{" "}
                      </TableRow>
                    ) : (
                      ""
                    )}
                    <TableRow key={index}>
                      <TableCell>{row.level1?.Supplier}</TableCell>
                      <TableCell align="left" style={{ padding: "0px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip
                            title={
                              row.level1.supplierStatus?.toUpperCase() || ""
                            }
                          >
                            <Chip
                              variant="outlined"
                              size="small"
                              label={
                                `${row.level1.supplierStatus.toUpperCase()}` ||
                                ""
                              }
                              clickable
                              onClick={() => _trackingStatus(row)}
                              style={{
                                border:
                                  row.level1.supplierStatus === "INCOMPLETE"
                                    ? "1px solid orange"
                                    : row.level1.supplierStatus === "PENDING"
                                    ? "1px solid orange"
                                    : row.level1.supplierStatus === "REJECTED"
                                    ? "1px solid red"
                                    : row.level1.supplierStatus ===
                                      "APPROVED AND EXPORTED"
                                    ? "1px solid green"
                                    : row.level1.supplierStatus === "APPROVED"
                                    ? "1px solid green"
                                    : "1px solid orange",
                                color:
                                  row.level1.supplierStatus === "INCOMPLETE"
                                    ? "orange"
                                    : row.level1.supplierStatus === "PENDING"
                                    ? "orange"
                                    : row.level1.supplierStatus === "REJECTED"
                                    ? "red"
                                    : row.level1.supplierStatus ===
                                      "APPROVED AND EXPORTED"
                                    ? "green"
                                    : row.level1.supplierStatus === "APPROVED"
                                    ? "green"
                                    : "orange",
                              }}
                            />
                          </Tooltip>
                          {row.level1.supplierStatus ===
                            "APPROVED AND EXPORTED" &&
                          row?.workFlowStatus?.workFlowId &&
                          row?.workFlowStatus?.workFlowId !== null ? (
                            <Tooltip title={"Exported From InvoiceMate"}>
                              <div
                                style={{ float: "right", marginLeft: "8px" }}
                              >
                                <PlaylistAddCheckIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{row.level1?.SupplierNumber}</TableCell>
                      <TableCell>{row.level1?.email}</TableCell>
                      <TableCell>
                        {" "}
                        {row.level1?.CreatedBy} <br />{" "}
                        <small>
                          {formatDateTime(row.level1?.CreationDate)}
                        </small>
                      </TableCell>
                      <TableCell colSpan={2}>
                        {row?.LastSyncDate == null || undefined
                          ? ""
                          : formatDateTime(row?.LastSyncDate)}
                      </TableCell>
                      <TableCell colSpan={2}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          {decoded?.role?.isAdmin ? (
                            <Tooltip
                              title="View Supplier Details"
                              aria-label="viewDetails"
                            >
                              <Link
                                to={`/admin/vendor_profile/${row._id}/${true}`}
                              >
                                <IconButton>
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="View Supplier Details"
                              aria-label="viewDetails"
                            >
                              <Link
                                to={`/default/vendorprofile/${
                                  row._id
                                }/${true}/${false}`}
                              >
                                <IconButton>
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                          {decoded?.role?.isAdmin ? (
                            <Tooltip
                              title="Supplier On-Boarding"
                              aria-label="regSupplier"
                            >
                              <IconButton
                                onClick={() => {
                                  _supplierEmailUpdate(row);
                                }}
                              >
                                <HowToRegIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          <Tooltip
                            title="Tracking Status"
                            aria-label="Tracking"
                          >
                            <IconButton
                              onClick={() => {
                                _trackingStatus(row);
                              }}
                            >
                              <FactCheckIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {/* 
                          {row.workFlowStatus?.approveStep.status !== 'APPROVED'
                            ? row.workFlowStatus.status === 'approveStep'
                              ? row.workFlowStatus[row.workFlowStatus.status]
                                  ?.approvedBy == decoded?.email && (
                                  <Tooltip title='Approve' aria-label='Approve'>
                                    <Button
                                      justIcon
                                      round
                                      simple
                                      icon={RateReview}
                                      onClick={() => reviewFile(row)}
                                      // color="info"
                                    >
                                      <RateReview
                                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                      />
                                    </Button>
                                  </Tooltip>
                                )
                              : row.workFlowStatus[row.workFlowStatus.status]
                                  ?.reviewedBy == decoded?.email && (
                                  <Tooltip title='Review' aria-label='review'>
                                    <Button
                                      justIcon
                                      round
                                      simple
                                      icon={RateReview}
                                      onClick={() => reviewFile(row)}
                                      // color="info"
                                    >
                                      <RateReview
                                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                      />
                                    </Button>
                                  </Tooltip>
                                )
                            : ''} */}

                          {canShowEditButton(
                            row?.workFlowStatus,
                            decoded?.email,
                            decoded?.role?.isAdmin
                          ) ? (
                            <Tooltip
                              title="Edit Supplier"
                              aria-label="editsupplier"
                            >
                              <Link
                                to={
                                  decoded?.role?.isAdmin
                                    ? `/admin/vendor_profile/${
                                        row?._id
                                      }/${false}`
                                    : `/default/vendorprofile/${
                                        row._id
                                      }/${false}/${false}`
                                }
                              >
                                <IconButton>
                                  <Edit style={{ color: "#757575" }} />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {row.level1.supplierStatus === "INCOMPLETE" && (
                            <Tooltip
                              title="Delete Supplier"
                              aria-label="Tracking"
                            >
                              <IconButton
                                onClick={() => {
                                  confirmAlert({
                                    action: () => _deleteSupplier(row?._id),
                                  });
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {(typeof row?.workFlowStatus?.reviewStep ===
                            "array" &&
                            row?.workFlowStatus?.reviewStep?.some(
                              (step) => step.reviewedBy === decoded?.email
                            )) ||
                          (typeof row?.workFlowStatus?.approveStep ===
                            "array" &&
                            row?.workFlowStatus?.approveStep?.some(
                              (step) => step.approvedBy === decoded?.email
                            )) ||
                          decoded?.role?.isAdmin ? (
                            <>
                              {row?.level1?.supplierStatus == "APPROVED" &&
                              row?.exportedToFusion == false ? (
                                <Tooltip
                                  title="Export To Fusion"
                                  aria-label="supplier"
                                >
                                  <IconButton>
                                    <FileUploadIcon
                                      onClick={() => exporttofusion(row?._id)}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                )) || []
              )}
            </TableBody>
          </Table>
          {!isFilterEnable ? (
            <TablePagination
              component="div"
              count={supplierData?.totalNumOfItems}
              rowsPerPageOptions={[10, 25, 40, 50]}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={RowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )}
          {isFilterEnable ? (
            <TablePagination
              component="div"
              count={supplierData?.totalNumOfItems}
              rowsPerPageOptions={[10, 20, 30]}
              page={page}
              onPageChange={handleFilterChangePage}
              rowsPerPage={RowsPerPage}
              onRowsPerPageChange={handleFilterChangeRowsPerPage}
            />
          ) : (
            ""
          )}
        </TableContainer>
      </GridItem>

      {reviewModal ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              open={reviewModal}
              // TransitionComponent={Transition}
              keepMounted
              onClose={() => setReviewModal(false)}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          {row?.action.toUpperCase()}
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            error={formState.errors.status === "error"}
                            fullWidth={true}
                            select
                            helperText={
                              formState.errors.status === "error"
                                ? "Status is required"
                                : null
                            }
                            label="Status"
                            name="status"
                            onChange={(event) => {
                              handlerChange(event);
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                            >
                              Choose Status
                            </MenuItem>

                            {row?.action === "approve" ? (
                              <MenuItem value="APPROVED">
                                <div className="fileinput text-center">
                                  <div className="thumbnail img-circle3">
                                    <img
                                      src={Success}
                                      alt={"MARK AS APPROVED"}
                                    />
                                  </div>
                                  MARK AS APPROVE
                                </div>
                              </MenuItem>
                            ) : (
                              <MenuItem value="REVIEWED">
                                <div className="fileinput text-center">
                                  <div className="thumbnail img-circle3">
                                    <img
                                      src={Success}
                                      alt={"MARK AS REVIEWED"}
                                    />
                                  </div>
                                  MARK AS REVIEW
                                </div>
                              </MenuItem>
                            )}
                            <MenuItem value="correctionRequired">
                              <div className="fileinput text-center">
                                <div className="thumbnail img-circle3">
                                  <img
                                    src={Pending}
                                    alt={"correction Required"}
                                  />
                                </div>
                                CORRECTION REQUIRED
                              </div>
                            </MenuItem>
                            <MenuItem value="rejected">
                              <div className="fileinput text-center">
                                <div className="thumbnail img-circle3">
                                  <img
                                    src={Rejected}
                                    alt={"MARK AS REJECTED"}
                                  />
                                </div>
                                MARK AS REJECTED
                              </div>
                            </MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            error={formState.errors.reviewComments === "error"}
                            fullWidth={true}
                            helperText={
                              formState.errors.reviewComments === "error"
                                ? "Comments is required"
                                : null
                            }
                            label="Comments"
                            name="comments"
                            onChange={(event) => {
                              handlerChange(event);
                            }}
                            // value={formState.values.reviewComments || ""}
                          ></TextField>
                        </GridItem>
                        <span style={{ float: "right" }}>
                          <Button
                            color="info"
                            className={classes.registerButton}
                            round
                            type="button"
                            onClick={() => reviewFileNow(row)}
                          >
                            {isloading ? (
                              <CircularProgress style={{ color: "#fff" }} />
                            ) : (
                              row?.action.toUpperCase()
                            )}
                          </Button>
                          <Button
                            color="danger"
                            className={classes.registerButton}
                            onClick={() => setReviewModal(false)}
                            round
                          >
                            CLOSE
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {openTrackingModal ? (
        <TrackingStatus
          workflow={selectedWorkFlow}
          openTrackingModal={openTrackingModal}
          setOpenTrackingModal={setOpenTrackingModal}
        />
      ) : (
        ""
      )}
      {showFwdDetails ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={showFwdDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowFwdDetails(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>
                      Forward/Deligate Details
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Foward/Deligate User</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ paddingBottom: 5 }}>
                      {showFwdData &&
                        showFwdData?.forward?.map((fwd, index) => (
                          <>
                            <TableRow className={classes.TableRow} key={index}>
                              <TableCell className={classes.TableCell}>
                                {formatDateTime(fwd?.date)}
                              </TableCell>
                              <TableCell>{fwd?.noteMsg}</TableCell>
                            </TableRow>
                            {fwd?.showReason && (
                              <TableRow
                                className={classes.TableRow}
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  <b>Reason:</b> {fwd?.reason}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => setShowFwdDetails(false)}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </GridContainer>
  );
}
export default SupplierPagination;
