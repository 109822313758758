//Material icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ErrorIcon from "@material-ui/icons/Error";
import EmailIcon from '@mui/icons-material/Email';
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import ContactsIcon from "@material-ui/icons/Contacts";
import QueueIcon from "@material-ui/icons/Queue";
import Refresh from "@material-ui/icons/Refresh";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import PeopleIcon from "@material-ui/icons/People";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsIcon from "@material-ui/icons/Settings";
import PhonelinkSetupSharpIcon from "@material-ui/icons/PhonelinkSetupSharp";
//Components
import UsersList from "views/LDocs/Users/UsersList";
import TaskList from "views/LDocs/Users/UserPendingTask/TaskList";
import BuildNetwork from "views/LDocs/BuidNetwork/BuildNetwork";
import Dashboard from "views/LDocs/Dashboard/Dashboard";
import Notifications from "views/LDocs/Notifications/Notifications";
import Titles from "views/LDocs/Title/TitleForm";
import Workflow from "views/LDocs/Workflow/Workflow";
import SignatureStamp from "views/LDocs/SignatureStamp/SignatureStamp";
import Warnings from "views/LDocs/Warnings/Warnings";
import Client from "views/LDocs/Clients/Client";
import Verify from "views/LDocs/Verify/Verify";
import Currency from "views/LDocs/Currency/Currency";
import GeneralConfigrations from "views/LDocs/Configrations/GeneralConfigrations";
import DescriptionIcon from "@material-ui/icons/Description";
import InvoiceConfigrations from "views/LDocs/Configrations/InvoiceConfigration";
import Permissions from "views/LDocs/Permissions/Permissions";
import Roles from "views/LDocs/Roles/RolesForm";
import COA from "views/LDocs/COA/COAForm";
import SupplierDetails from "views/Pages/Supplier/SupplierDetails";
import DefaultAccounts from "views/LDocs/COA/Default/COADefault";
import SupplierListView from "views/Pages/Supplier/SupplierlistView";
import TaxClassificationlist from "views/Pages/TaxClassification/TaxClassificationlist";
import UserProfile from "views/LDocs/Profile/Profile";
import VendorProfile from "views/LDocs/VendorProfile/Profile";
import SyncConfigrations from "views/LDocs/Configrations/SyncConfiguration";
import EmailMessage from "views/LDocs/Configrations/EmailMessage";
import ReceiveReceipt from "views/LDocs/Receipts/ReceiveReceipt";
import PurchaseOrder from "views/Pages/PurchaseOrder/PurchaseOrderList";
import PurchaseOrderDetails from "views/Pages/PurchaseOrder/PurchaseOrderDetails";
import PieChartIcon from "@material-ui/icons/PieChart";


var adminRoutes = (props) => {
  const { isApEnable, isArEnable } = props;
  return [
    {
      path: "/dashboard/ad",
      name: "Dashboard",
      rtlName: "لوحة القيادة",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Admin Desk",
      rtlName: "صفحات",
      icon: ContactsIcon,
      state: "adminCollapse",
      views: [
        {
          path: "/buildNetwork/ad",
          name: "Build Network",
          icon: QueueIcon,
          component: BuildNetwork,
          layout: "/admin",
        },
        {
          path: "/invoiceWorkflows/ad",
          name: "Invoice Workflows",
          icon: DeviceHubIcon,
          component: Workflow,
          layout: "/admin",
        },
        {
          path: "/coa/ad",
          name: "Chart of Accounts",
          icon: DescriptionIcon,
          component: COA,
          layout: "/admin",
        },
         {
          path: "/coa/default/ad",
          name: "Default Accounts",
          icon: DescriptionIcon,
          component: DefaultAccounts,
          layout: "/admin",
        },
        isApEnable && {
          path: "/suppliers",
          name: "Suppliers",
          icon: PeopleIcon,
          component: SupplierListView,
          layout: "/admin",
        },
        {
          path: "/receipt",
          name: "Manage Receipts",
          rtlName: "لوحة القيادة",
          icon: DescriptionIcon,
          component: ReceiveReceipt,
          layout: "/admin",
        },
        {
          path: "/receipt",
          component: ReceiveReceipt,
          layout: "/admin",
        },
        {
          path: "/purchase_orders",
          name: "Purchase Orders",
          rtlName: "لوحة القيادة",
          icon: PieChartIcon,
          component: PurchaseOrder,
          layout: "/admin",
        },
        {
          path: "/supplier/:id/",
          component: SupplierDetails,
          layout: "/admin",
        }, 
        {
          path: "/vendor_Register",
          component: VendorProfile,
          rtlName: "لوحة القيادة",
          layout: "/admin",
        }, 
        {
          path: "/vendor_profile/:id/:isedit",
          component: VendorProfile,
          rtlName: "لوحة القيادة",
          layout: "/admin",
        },
        isArEnable && {
          path: "/client/ad",
          name: "Customer",
          icon: PeopleIcon,
          component: Client,
          layout: "/admin",
        },
        {
          path: "/users/ad",
          name: "Users",
          icon: SupervisedUserCircleIcon,
          component: UsersList,
          layout: "/admin",
        },
        {
          path: "/pending_users_task/ad",
          name: "Pending User Tasks",
          icon: SupervisedUserCircleIcon,
          component: TaskList,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/verifier/ad",
      name: "Tracking & Validation",
      rtlName: "أشكال عادية",
      rtlMini: "صو",
      icon: VerifiedUserIcon,
      component: Verify,
      layout: "/admin",
    },
    {
      path: "/notifications",
      // name: "Notifications",
      // rtlName: "لوحة القيادة",
      // icon: NotificationsActiveIcon,
      component: Notifications,
      layout: "/admin",
    },
    {
       path: "/tax_classification",
      name: "Tax Classification",
      rtlName: "لوحة القيادة",
      icon: SettingsIcon,
      component: TaxClassificationlist,
      layout: "/admin",
    },
    {
      path: "/logs",
      name: "Logs",
      icon: ErrorIcon,
      component: Warnings,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Configuration",
      rtlName: "صفحات",
      icon: SettingsIcon,
      state: "configCollapse",
      views: [
        {
          path: "/generalconfigration/ad",
          name: "General Configuration",
          icon: PhonelinkSetupSharpIcon,
          component: GeneralConfigrations,
          layout: "/admin",
        },
        {
          path: "/syncconfigration",
          name: "Sync Configuration",
          icon: Refresh,
          component: SyncConfigrations,
          layout: "/admin",
        },
        {
          path: "/emailmessage",
          name: "Email Messages",
          icon: EmailIcon,
          component: EmailMessage,
          layout: "/admin",
        },
        {
          path: "/invoiceconfigration/ad",
          name: "Invoice Configuration",
          icon: DescriptionIcon,
          component: InvoiceConfigrations,
          layout: "/admin",
        },
        {
          path: "/currency/ad",
          name: "Currency",
          icon: MonetizationOnIcon,
          component: Currency,
          layout: "/admin",
        },
        {
          path: "/permissions/ad",
          name: "Permissions",
          icon: SettingsIcon,
          component: Permissions,
          layout: "/admin",
        },
        {
          path: "/roles/ad",
          name: "Roles",
          icon: SettingsIcon,
          component: Roles,
          layout: "/admin",
        },
      ],
    },
    {
      path: "/user_profile",
      component: UserProfile,
      layout: "/admin",
    },
    {
      path: "/purchase_order/:id",
      component: PurchaseOrderDetails,
      layout: "/admin",
    },
    {
      path: "/receipts/:id",
      component: ReceiveReceipt,
      layout: "/admin",
    },
  ];
};

export default adminRoutes;
