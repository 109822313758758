import React, { useState, useEffect } from 'react'
import { makeStyles, Tooltip, Chip, CircularProgress ,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Table,
  Dialog,
  DialogContent,
  TablePagination} from '@material-ui/core'
// @material-ui/core components
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { Animated } from 'react-animated-css'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import defaultAvatar from 'assets/img/placeholder.jpg'
import AttachmentIcon from '@material-ui/icons/Attachment'
import ReactTable from 'react-table'
import { formatDateTime } from 'views/LDocs/Functions/Functions'
import { currentTracking } from 'views/LDocs/Functions/Functions'
import { addZeroes,msgAlert } from "views/LDocs/Functions/Functions";
import { formatDate } from 'views/LDocs/Functions/Functions'
import Button from 'components/CustomButtons/Button.js'

const sweetAlertStyle = makeStyles(styles2)
let Token = localStorage.getItem('cooljwt')
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {}
}

export default function Step2 ({ paid_invoices, loading, openAdvanceView,paidCount,page,setPage,rowsPerPage,setRowsPerPage }) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [animateStep, setAnimateStep] = useState(true)
  const [data, setData] = useState([])
  const [openTrackingModal, setOpenTrackingModal] = React.useState(false)
  const [trackingLoading, setTrackingLoading] = React.useState(false)
  const [initialReview, setInitialReview] = React.useState('')
  const [review, setReview] = React.useState('')
  const [approve, setApprove] = React.useState('')
  const [approveandexported, setApproveAndExported] = React.useState('')
  const [canceled, setCanceled] = React.useState('')

  const _trackingStatus = trackingStatus => {
    if (trackingStatus) {
      setOpenTrackingModal(true)
      // setTrackingLoading(true)
      setInitialReview(trackingStatus?.initialReview)
      setReview(trackingStatus?.underReview)
      setApprove(trackingStatus?.underApprove)
      setApproveAndExported(trackingStatus?.paymentInProcess)
      setCanceled(trackingStatus?.paid)

    } else {
      setOpenTrackingModal(false)
      setTrackingLoading(false)
      msgAlert('Tracking Status not Found')
    }
  }
  const closeTrackingModal = () => {
    setOpenTrackingModal(false)
    setInitialReview('')
    setReview('')
    setApprove('')
    setApproveAndExported('')
    setCanceled('')
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }


  useEffect(() => {
    setData(
      paid_invoices.map((prop, key) => {
        var currentStatus = currentTracking(prop.trackingStatus)
        let exportedDate = prop?.trackingStatus?.paymentInProcess?.date;
        let isCorrectionRequiredInWorkflow =
          prop.workFlowStatus == 'correctionRequired'
        let payload = {
          invoiceId: prop.invoiceId,
          version: prop.version,
          vendorId: prop.vendorId
        }
        return {
          invoiceId: (
            <span
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => openAdvanceView(payload)}
            >
              {prop.originalInvoiceId}
            </span>
          ),
          amount: `${prop?.LC_currency?.Code}   ${addZeroes(prop.netAmt_bc)}`,
          dueDate: (
            <div className='actions-right'>{formatDate(prop.dueDate)}</div>
          ),
          balanceDue: `${prop?.LC_currency?.Code}   ${addZeroes(
            prop.balanceDue
          )}`,
          createdDate: formatDateTime(prop.createdDate),
          invoiceDate: formatDate(prop.invoiceDate),
          exportOn: exportedDate ? formatDateTime(exportedDate || "") : '',
          supplierName: prop.vendorName,
          status:
            prop.trackingStatus.paid.status == 'partial' ? (
              <Tooltip title='PARTIALLY PAID'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='PARTIALLY PAID'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  style={{
                    border: 'lightgreen 1px solid',
                    color: 'lightgreen'
                  }}
                />
              </Tooltip>
            ) : prop.trackingStatus.paid.status == 'completed' ? (
              <Tooltip title='FULLY PAID'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='FULLY PAID'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  style={{ border: 'green 1px solid', color: 'green' }}
                />
              </Tooltip>
            ):
              prop.trackingStatus.paid.status == 'Canceled' ? (
                <Tooltip title='CANCELED'>
                  <Chip
                    variant='outlined'
                    size='small'
                    // avatar={<Avatar>M</Avatar>}
                    label='CANCELED'
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color='secondary'
                  />
                </Tooltip>
            ) : currentStatus.status == 'readyToPay' ? (
              <Tooltip title='READY TO PAY'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='READY TO PAY'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  style={{ border: 'orange 1px solid', color: 'orange' }}
                />
              </Tooltip>
            ) : prop?.trackingStatus?.paymentInProcess?.status ==
              'completed' ? (
              <Tooltip title='APPROVED AND EXPORTED'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='APPROVED AND EXPORTED'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  style={{ border: 'green 1px solid', color: 'green' }}
                />
              </Tooltip>
               ) : prop?.workFlowStatus== 'correctionRequired' && prop?.markedAs !== 'rejected'? (
                <Tooltip title='Correction Required'>
                  <Chip
                    variant='outlined'
                    size='small'
                    // avatar={<Avatar>M</Avatar>}
                    label='CORRECTION REQUIRED'
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{ border: 'orange 1px solid', color: 'orange' }}
                  />
                </Tooltip>
            ) : prop?.trackingStatus.underApprove.status == 'inProgress' ? (
              <Tooltip title='PENDING'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='PENDING'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  color='primary'
                />
              </Tooltip>
            ) : prop?.trackingStatus?.underApprove?.status == 'completed' &&
              prop?.trackingStatus?.paymentInProcess?.status !== 'completed' ? (
              <Tooltip title='APPROVED'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='APPROVED'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  style={{ border: 'green 1px solid', color: 'green' }}
                />
              </Tooltip>
            ) : prop?.trackingStatus.underReview.status == 'inProgress' ? (
              <Tooltip title='PENDING'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='PENDING'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  color='primary'
                />
              </Tooltip>
            ) : prop?.trackingStatus.initialReview.status == 'inProgress' ? (
              <Tooltip title='PENDING'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='PENDING'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  color='primary'
                />
              </Tooltip>
            ) : currentStatus.status == 'rejected' ? (
              <Tooltip title='REJECTED'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='REJECTED'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  color='secondary'
                />
              </Tooltip>
            ) : currentStatus.status == 'Canceled' ? (
              <Tooltip title='Canceled'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='Canceled'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  color='secondary'
                />
              </Tooltip>
            ) : (currentStatus.status == 'correctionRequired' &&
                currentStatus.val == 1) ||
              isCorrectionRequiredInWorkflow ? (
              <Tooltip title='SENT FOR CORRECTION'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='SENT FOR CORRECTION'
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  clickable
                  style={{ border: 'orange 1px solid', color: 'orange' }}
                />
              </Tooltip>
            ) : (
              <Tooltip title='PENDING'>
                <Chip
                  variant='outlined'
                  size='small'
                  // avatar={<Avatar>M</Avatar>}
                  label='PENDING'
                  clickable
                  onClick={() => _trackingStatus(prop?.trackingStatus)}
                  color='primary'
                />
              </Tooltip>
            )
        }
      })
    )
  }, [paid_invoices])

  return (
    <Animated
      animationIn='bounceInRight'
      animationOut='bounceOutLeft'
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >
      <GridContainer>
        <GridItem xs={12}>
          {loading ? (
            <div
              style={{ textAlign: 'center', marginTop: 100, marginBottom: 100 }}
            >
              <CircularProgress style={{ width: 200, height: 200 }} />
            </div>
          ) : (
            <>
            <ReactTable
              data={data}
              sortable={false}
              style={{ textAlign: 'initial' }}
              columns={[
                {
                  Header: 'Invoice Number',
                  accessor: 'invoiceId',
                  filterable: true,
                  filter: 'fuzzyText',
                  sortType: 'basic'
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  width:200
                },
                {
                  Header: 'Supplier',
                  accessor: 'supplierName'
                },
                {
                  Header: 'Amount',
                  accessor: 'amount'
                },
                {
                  Header: "Submit Date",
                  accessor: "createdDate",
                },
                {
                  Header: "Invoice Date",
                  accessor: "invoiceDate",
                },
                {
                  Header: 'Exported On',
                  accessor: 'exportOn'
                },
                // {
                //   Header: 'Due Date',
                //   accessor: 'dueDate'
                // }
              ]}
              defaultPageSize={rowsPerPage}
              showPagination={false}
              className='-striped -highlight'
            />
            <TablePagination
            component='div'
            count={paidCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </>
          )}
        </GridItem>
      </GridContainer>
      {openTrackingModal ? (
        <Dialog
          classes={{
            root: classes.center + ' ' + classes.modalRoot,
            paper: classes.modal
          }}
          fullWidth={true}
          maxWidth={'lg'}
          open={openTrackingModal}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenTrackingModal(false)}
          aria-labelledby='tag-modal-slide-title'
          aria-describedby='tag-modal-slide-description'
        >
          <DialogContent
            id='tag-modal-slide-description'
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color='info' icon>
                  <CardIcon color='info'>
                    <h4 style={{color:'white'}}>Tracking Status</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {trackingLoading ? (
                    <CircularProgress />
                  ) : (
                    <Table className={classes.table} aria-label='simple table'>
                      <TableHead>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableID}>
                            Review / Approve
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned To
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned Date
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Status
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Action Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ paddingBottom: 5 }}>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Initial Review
                            <br />
                            (Requester)
                          </TableCell>
                          <TableCell>
                            {initialReview?.reviewedBy || '-'}
                            <br />
                            {initialReview?.assignTo || '-'}
                          </TableCell>
                          <TableCell>
                            {initialReview?.assignDate
                              ? formatDateTime(initialReview?.assignDate)
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {initialReview?.status == 'completed'
                              ? initialReview?.status.toUpperCase()
                              : initialReview?.status == 'correctionRequired' ? initialReview?.status.toUpperCase()
                              : initialReview?.status == 'rejected' ? initialReview?.status.toUpperCase()
                              : 'PENDING' || 'PENDING'}
                          </TableCell>
                          <TableCell>
                            {initialReview?.status !== 'pending' &&
                            initialReview?.date
                              ? formatDateTime(initialReview?.date)
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Review
                            <br />
                            (Accounting Specialist)
                          </TableCell>
                          <TableCell>
                            {review?.reviewedBy || '-'}
                            <br />
                            {review?.assignTo || '-'}
                          </TableCell>
                          <TableCell>
                            {review?.assignDate
                              ? formatDateTime(review?.assignDate)
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {review?.status == 'completed'
                              ? review?.status.toUpperCase()
                              : review?.status == 'correctionRequired' ? review?.status.toUpperCase()
                              : review?.status == 'rejected' ? review?.status.toUpperCase()
                              : 'PENDING' || 'PENDING'}
                          </TableCell>
                          <TableCell>
                            {review?.status !== 'inProgress' && review?.date
                              ? formatDateTime(review?.date)
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve
                            <br />
                            (Accounting Manager)
                          </TableCell>
                          <TableCell>
                            {approve?.approvedBy || '-'}
                            <br />
                            {approve?.assignTo || '-'}
                          </TableCell>
                          <TableCell>
                            {approve?.assignDate
                              ? formatDateTime(approve?.assignDate)
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {approve?.status == 'completed'
                              ? approve?.status.toUpperCase()
                              : approve?.status == 'correctionRequired' ? approve?.status.toUpperCase()
                              : approve?.status == 'rejected' ? approve?.status.toUpperCase()
                              : 'PENDING' || 'PENDING'}
                          </TableCell>
                          <TableCell>
                            {approve?.status !== 'pending' && approve?.date
                              ? formatDateTime(approve?.date)
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve and Export
                            <br />
                            (Requester)
                          </TableCell>
                          <TableCell>
                            {approveandexported?.exportedBy || '-'}
                            <br />
                            {approveandexported?.assignTo || '-'}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.assignDate
                              ? formatDateTime(approveandexported?.assignDate)
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.status == 'completed'
                              ? approveandexported?.status.toUpperCase()
                              : 'PENDING' || 'PENDING'}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.status !== 'pending' && approveandexported?.date
                              ? formatDateTime(approveandexported?.date)
                              : '-'}
                          </TableCell>
                        </TableRow>


                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Invoice Status
                            {/* <br />
                            (Requester) */}
                          </TableCell>
                          <TableCell>
                            {canceled?.paidBy || '-'}
                            <br />
                            {canceled?.assignTo || '-'}
                          </TableCell>
                          <TableCell>
                            {canceled?.assignDate
                              ? formatDateTime(canceled?.assignDate)
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {canceled?.status == 'inProgress'? "PENDING" : canceled?.status == 'completed' ?  "COMPLETED" : canceled?.status == 'Canceled' ?  "CANCELED" :  '-'}
                          </TableCell>
                          <TableCell>
                            {canceled?.status !== 'pending' && canceled?.date
                              ? formatDateTime(canceled?.date)
                              : '-'}
                          </TableCell>
                        </TableRow>



                      </TableBody>
                    </Table>
                  )}
                </CardBody>
              </Card>
              <Button
                color='danger'
                className={classes.registerButton}
                onClick={() => closeTrackingModal()}
                round
                style={{ float: 'right', marginLeft: 'auto' }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ''
      )}
    </Animated>
  )
}
