import { Purchase_Order_LIST_FAIL } from "actions/Constant";
import { Purchase_Order_LIST_REQUEST } from "actions/Constant";
import { Purchase_Order_LIST_SUCCESS } from "actions/Constant";
import { Purchase_Order_SUCCESS_FILTERS } from "actions/Constant";

let initialState = {

}


export function productListReducer(state = initialState, action) {
  switch (action.type) {
    case Purchase_Order_LIST_REQUEST:
      return { loading: true,isLoadingFilters:true, PoList: [] };
    case Purchase_Order_LIST_SUCCESS:
      return { loading: false,isLoadingFilters:false, PoList: action.payload };
      case Purchase_Order_SUCCESS_FILTERS:
        return { loading: false,isLoadingFilters:false, PoList: action.payload };
    case Purchase_Order_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}