import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import SupplierNavbar from "components/Navbars/SupplierNavbar";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authSuppStyle.js";
import { useLocation , useHistory} from "react-router-dom";
import login from "assets/img/login_salic1.jpg";
import LoginVendor from "views/LDocs/Authorization/LoginVendor";
import VendorProfile from "views/LDocs/VendorProfile/Profile";
import ForgetVendorPassword from "views/LDocs/Authorization/ForgetVendorPassword";
import NewVendorPassword from "views/LDocs/Authorization/NewVendorPassword";
import { decode } from "jsonwebtoken";

const useStyles = makeStyles(styles);

export default function AuthSupplier(props) {
  const location = useLocation();
  const history = useHistory();
  const { ...rest } = props;
  let token = localStorage.getItem("cooljwt");
  let userDetails = decode(token);
  let isAdmin = userDetails?.isTenant || userDetails?.role?.isAdmin || false;
  let isVendor = userDetails?.isVendor || false;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, []);

  const getBgImage = () => {
    if (window.location.pathname === "/supplier/login") {
      return { background: "#f3f3f3" };
    } else if (window.location.pathname === "/supplier/register") {
      return { background: "#f3f3f3" };
    } else {
      return { backgroundImage: "url(" + login + ")" };
    }
  };

  // useEffect(() => {
  //   history.push("/blank")
  // }, []);

  return (
    <>
      {token ? (
        isAdmin ? (
          <Redirect to="/admin/dashboard/ad" />
        ) : isVendor ? (
          <Redirect to="/vendor/dashboard" />
        ) : (
          <Redirect to="/default/dashboard/ap" />
        )
      ) : (
        <div>
          <SupplierNavbar brandText={""} {...rest} />
          <div className={classes.wrapper} ref={wrapper}>
            <div className={classes.fullPage} style={getBgImage()}>
              <Switch>
                <Route path="/supplier/login" exec component={LoginVendor} />
                <Route
                  path="/supplier/register"
                  exec
                  component={VendorProfile}
                />
                <Redirect from="/supplier" to="/supplier/login" />
              </Switch>
              <Footer white />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
