import React from "react";
// react component for creating dynamic tables
import { useParams, useHistory } from "react-router-dom";
// @material-ui/core components
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import { msgAlert } from "Functions";
import PurchaseOrderView from "views/Components/PurchaseOrderView/PurchaseOrderView";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PurchaseOrderDetails() {
  const { id,isPO } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [PurchaseOrder, setPurchaseOrder] = React.useState(null);
  const history = useHistory();

  const Token =
  useSelector((state) => state.userReducer.Token) ||
  localStorage.getItem("cooljwt"); 

  let userDetail = jwt.decode(Token);

  React.useEffect(() => {
    if (id) {
      getPurchaseOrder(id);
    } else {
      msgAlert("Please Pass a Valid PO ID");
    }
  }, []);

  const getPurchaseOrder = () => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPODetails/${id}`;
    axios({
      method: "get",
      url: url,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        if(typeof response.data == "object"){
            setPurchaseOrder(response.data);
        }else{
            setPurchaseOrder(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setIsLoading(false);
      });
  };
  return (
    <div>
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <GridContainer>
            {!isLoading ? <PurchaseOrderView PurchaseOrder={PurchaseOrder} onBack={() => history.goBack()} isPO={isPO} /> : <CircularProgress />}
        </GridContainer>
      </Animated>
    </div>
  );
}
