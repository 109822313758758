import React, { useState, useEffect } from 'react'
// react component for creating dynamic tables
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import { Link } from 'react-router-dom'

// @material-ui/core components
import { useDispatch, useSelector } from 'react-redux'
import {
  TextField,
  MenuItem,
  IconButton,
  Modal,
  withStyles,
  Typography,
  Fade,
  Box,
  Backdrop,
  Chip
} from '@material-ui/core'
import moment from 'moment'
import { Route, useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
import Pending from 'assets/img/statuses/Pending.png'
import Success from 'assets/img/statuses/Success.png'
import Rejected from 'assets/img/statuses/Rejected.png'
// @material-ui/icons
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import axios from 'axios'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import { setIsTokenExpired } from 'actions'
import Refresh from '@material-ui/icons/Refresh'
import RateReview from '@material-ui/icons/RateReview'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'
import EditAttributesIcon from '@material-ui/icons/EditAttributes'
import {
  addZeroes,
  conversionRate,
  successAlert,
  errorAlert,
  msgAlert,
  formatDateTime
} from 'views/LDocs/Functions/Functions'
import {
  Edit,
  EditOutlined,
  PhotoSizeSelectLargeTwoTone
} from '@material-ui/icons'
import PurchaseOrderList from 'actions/PurchaseOrderListAction'
import SyncPo from 'views/Components/PurchaseOrderView/Childs/SyncPo'
import { Autocomplete } from '@mui/material'
import PurchaseOrderView from 'views/Components/PurchaseOrderView/PurchaseOrderView'
import Filters from './Filters'
import { htmlAlert, successHtmlAlert } from '../Functions/Functions'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'

const useStyles = makeStyles(theme => ({
  root: {},
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {},
  table: {
    minWidth: '100%',
    border: 1
  },
  TableCell: {
    minWidth: '10%'
  },
  TableRow: {
    cursor: 'pointer',
    background: 'white',
    border: 1,
    width: '100%'
  },
  TableID: {
    maxWidth: '3%'
  },
  asterisk: {
    color: 'red'
  }
}
const StyledTableRow = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    width: '7%'
  },
  body: {
    fontSize: 14,
    border: '1px solid lightgrey',
    width: '7%'
  }
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    width: '7%'
  },
  body: {
    fontSize: 14,
    border: '1px solid lightgrey',
    width: '5%'
  }
}))(TableCell)

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const ReturnCorrectReceipt = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [totalNumOfItems, setTotalNumOfItems] = React.useState(0)
  const [PurchaseOrder, setPurchaseOrder] = React.useState([])
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null)
  const [poLoading, setPoLoading] = React.useState(false)
  const [decoded, setDecoded] = React.useState(null)
  const [view, setView] = React.useState('grid')
  const [page, setPage] = useState(0)
  const [RowsPerPage, setRowsPerPage] = useState(30)
  const [isloading, setIsloading] = useState(false)
  const [pOId, setPOId] = useState()
  const [POHeaderId, setPOHeaderId] = useState('')
  const [receiptModal, setreceiptModal] = useState(false)
  const [reviewModal, setReviewModal] = React.useState(false)
  const [receiptDetail, setReceiptDetail] = useState('')
  const [reviewLoading, setReviewloading] = useState(false)
  const [receiptId, setReceiptId] = useState('')
  const [fusloading, setFusloading] = useState(false)
  const [openTrackingModal, setOpenTrackingModal] = useState(false)
  const [approve, setApprove] = React.useState('')
  const [approveExported, setApproveExported] = React.useState('')
  const [filterValue, setFilterValue] = React.useState('ALL')
  const [groupedReceipts, setGroupedReceipts] = useState([])
  const [expanded, setExpanded] = React.useState(null)
  const [seacrhType, setSearchType] = React.useState('PO')

  const [formState, setFormState] = useState({
    selectedPurchaseOrder: '',
    poInputValue: '',
    values: {
      status: '',
      comments: ''
    },
    errors: {
      status: '',
      reviewComments: ''
    }
  })
  const handleExpandClick = key => {
    if (key === expanded) {
      setExpanded(null)
    } else {
      setExpanded(key)
    }
  }

  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  let userDetail = jwt.decode(Token)

  useEffect(() => {
    if (formState.selectedPurchaseOrder !== '') {
      _getReceipts()
    }
  }, [page, RowsPerPage])

  useEffect(() => {
    if (formState.poInputValue) {
      _getPurchaseOrders()
    }
  }, [formState.poInputValue])

  const FormatDateTime = date => {
    let Token = localStorage.getItem('cooljwt')
    let user = jwt.decode(Token)
    var offset
    if (user?.tenantConfigs) {
      let tenantConfig = user.tenantConfigs
      let timeStamp = tenantConfig.timeZone
      offset = timeStamp.offset * 60
    } else {
      offset = moment().utcOffset()
    }
    var now = new Date(date)
    const someday = moment(now)
      .utcOffset(offset)
      // .format('YYYY-MM-DDTHH:mm');
      .format('DD-MM-YYYY hh:mm A')
    return someday
  }

  const _getPurchaseOrders = async () => {
    setPurchaseOrder([])
    setPoLoading(true)
    let formData = {
      isAdmin: userDetail.role.isAdmin,
      displayName: userDetail?.displayName,
      po: formState.poInputValue,
      action: seacrhType
    }
    await axios({
      method: 'post', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPoForRec`,
      data: formData,
      headers: {
        cooljwt: Token
      }
    })
      .then(res => {
        setPoLoading(false)
        setPurchaseOrder(res?.data)
      })
      .catch(error => {
        setPoLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
      })
  }
  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = async event => {
    setRowsPerPage(parseInt(event.target.value), 30)
  }

  const _getPurchaseOrderDetails = async id => {
    //id == PO header ID
    let url = `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPODetails/${id}`
    await axios({
      method: 'get',
      url: url,
      headers: { cooljwt: Token }
    })
      .then(response => {
        if (typeof response.data == 'object') {
          setSelectedPurchaseOrder(response.data)
        }
      })
      .catch(error => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        )
        setSelectedPurchaseOrder(null)
      })
  }

  const viewReceipt = () => {
    setreceiptModal(true)
  }

  const _grouping = receipts => {
    let hashMapArray = new Map()
    // New Hashed Map
    receipts.map(r => {
      if (hashMapArray.has(r.ReceiptNumber)) {
        hashMapArray.set(r.ReceiptNumber, [
          ...hashMapArray.get(r.ReceiptNumber),
          r
        ])
      } else {
        hashMapArray.set(r.ReceiptNumber, [r])
      }
    })

    let receipts_filtered = {}
    for (const [key, value] of hashMapArray.entries()) {
      receipts_filtered[key] = value
    }
    setGroupedReceipts(receipts_filtered)
  }

  const _getReceipts = async (POId, value) => {
    setIsLoading(true)
    let PoHeaderId = POId || POHeaderId
    let filter_Value = 'EXPORTED'
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getReciptsFromPOH/${PoHeaderId}/${seacrhType}/${filter_Value}?page=${page}&items=${RowsPerPage}`,
      headers: { cooljwt: Token }
    })
      .then(res => {
        setIsLoading(false)
        setData(res?.data?.Recipts)
        _grouping(res?.data?.Recipts)
        setTotalNumOfItems(res?.data?.totalNumOfItems)
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        // errorAlert(error?.response?.data?.Error|| error?.response?.data || 'Error in getting Receipt')
        console.log(
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        )
      })
  }

  const syncPo = async () => {
    await axios
      .get(`${process.env.REACT_APP_LDOCS_API_URL}/invoice/syncPurchaseOrder`, {
        headers: {
          cooljwt: Token
        }
      })
      .then(res => {})
    msgAlert(
      'Sync is in progress. It may take some time, you can continue using system.'
    )
  }
  const syncSinglePo = async POHeaderId => {
    setPOId(POHeaderId)
    setIsloading(true)
    await axios
      .get(
        `${process.env.REACT_APP_LDOCS_API_URL}/invoice/syncSinglePO/${POHeaderId}`,
        {
          headers: {
            cooljwt: Token
          }
        }
      )
      .then(res => {
        setIsloading(false)
        successAlert('PO synced successfully')
      })
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 190,
    bgcolor: '#fff',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4
  }
  const reviewFile = row => {
    setReceiptDetail(row)
    setReviewModal(true)
  }

  const handlerChange = event => {
    // console.log("event",event);
    event.persist()
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      }
    }))
  }

  //Review Handler
  const reviewFileNow = async () => {
    let status
    let comment
    const Check = require('is-null-empty-or-undefined').Check
    let error = false

    if (!Check(formState.values.status)) {
      status = 'success'
    } else {
      status = 'error'
      error = true
    }
    if (
      formState.values.status == 'approved'
        ? (comment = 'success')
        : !Check(formState.values.comments)
    ) {
      comment = 'success'
    } else {
      comment = 'error'
      error = true
    }

    setFormState(formState => ({
      ...formState,
      errors: {
        ...formState.errors,
        status: status,
        reviewComments: comment
      }
    }))
    if (error) {
      return false
    } else {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          status: '',
          reviewComments: ''
        }
      }))
      setReviewloading(true)
      let postData = {
        receipt: receiptDetail,
        action: 'approve',
        status: formState.values.status,
        comment: formState.values.comments
      }
      await axios({
        method: 'put',
        url: `${process.env.REACT_APP_LDOCS_API_URL}/receipt/updateRecStatus`,
        data: postData,
        headers: { cooljwt: Token }
      })
        .then(res => {
          setReviewloading(false)
          setReviewModal(false)
          _getReceipts()
          if (formState.values.status == 'approved') {
            successAlert('Approved Successfully')
          } else {
            successAlert('Rejected')
          }
        })
        .catch(err => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              status: '',
              comments: ''
            }
          }))
          let error = err?.response?.data
          errorAlert(error)
          setReviewloading(false)
          setReviewModal(false)
        })
    }
  }
  const exporttofusion = async (key, row) => {
    setReceiptId(key)
    setFusloading(true)
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/receipt/exportRecToFusion`,
      data: { rows: row },
      headers: { cooljwt: Token }
    })
      .then(res => {
        setFusloading(false)
        _getReceipts(null, 'ALL')
        if (res?.data?.Exported && res?.data?.Exported?.length > 0) {
          let successMsg = res?.data?.Exported || []
          let secerror = res?.data?.Error || []

          let successString = '<h5> Successfully Exported Receipt Number </h5>'

          {
            successMsg.map((index, success) => {
              successString += `${success == 0 ? index : `, ${index}`}`
            })
          }

          let secerrorString =
            '<h5> Error in Export Receipt Number </h5> <ul style="text-align:left">'

          {
            secerror.map(err => {
              secerrorString += `<li>${err}</li>`
            })
          }
          secerrorString += '</ul>'

          successHtmlAlert({ html: successString })
        }
      })
      .catch(err => {
        if (
          err?.response?.data.Error &&
          err?.response?.data?.Error?.length > 0
        ) {
          let error = err?.response?.data?.Error || []
          let secerror = err?.response?.data?.Success || []

          let errorString =
            '<h5> Error in Export Receipt Number </h5> <ul style="text-align:left">'

          {
            error.map(err => {
              errorString += `<li>${err}</li>`
            })
          }
          errorString += '</ul>'

          let secerrorString = '<h5>Successfully Exported Receipt Number</h5>'

          {
            secerror.map((success, err) => {
              secerrorString += `${err == 0 ? success : `, ${success}`}`
            })
          }

          htmlAlert({ html: errorString })
        } else {
          errorAlert(err?.response?.data)
        }
        setFusloading(false)
      })
  }

  const _trackingStatus = selectedReceipt => {
    let workFlowStatus = selectedReceipt?.workFlowStatus
    setOpenTrackingModal(true)
    setApprove(workFlowStatus?.approveStep)
    setApproveExported(workFlowStatus?.exportStep)
  }
  const closeTrackingModal = () => {
    setOpenTrackingModal(false)
    setApprove('')
    setApproveExported('')
  }
  const handleFilter = event => {
    let value = event.target.value
    _getReceipts(null, value)
    setFilterValue(value)
  }
  const handleChange = event => {
    setPurchaseOrder([])
    setFormState(formState => ({
      ...formState,
      selectedPurchaseOrder: ''
    }))
    setSearchType(event.target.value)
  }

  return (
    <>
      <Animated
        animationIn='bounceInRight'
        animationOut='bounceOutLeft'
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={view == 'grid' ? true : false}
      >
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>
                    Correct/Return Receipts
                  </h4>
                </CardIcon>
                {/* <Button
                  color="danger"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={syncPo}
                >
                  Sync
                </Button>
                <Button
                  color="info"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={() => setIsFilterBox(true)}
                >
                  Filter
                </Button> */}
              </CardHeader>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginTop: '20px', display: 'flex' }}
              >
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={2}
                  style={{ marginBottom: '10px' }}
                >
                  <TextField
                    fullWidth={true}
                    label='Select Search Type'
                    id='seacrhType'
                    name='seacrhType'
                    variant='outlined'
                    select
                    onChange={event => {
                      handleChange(event)
                    }}
                    value={seacrhType || ''}
                  >
                    <MenuItem disabled value=''>
                      Select Search Type
                    </MenuItem>
                    <MenuItem value='PO'>Search PO</MenuItem>
                    <MenuItem value='RECEIPT'>Search Receipt</MenuItem>
                  </TextField>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={2}>
                  <Autocomplete
                    id='filter-demo'
                    options={PurchaseOrder || []}
                    value={formState.selectedPurchaseOrder || ''}
                    variant='outlined'
                    // getOptionLabel={option => option?.OrderNumber || ''}
                    getOptionLabel={option =>
                      seacrhType === 'PO' && option !== ''
                        ? option?.OrderNumber
                        : seacrhType === 'RECEIPT' && option !== ''
                        ? option?.ReceiptNumber
                        : '' || ''
                    }
                    onInputChange={(event, newInputValue) => {
                      setFormState(formState => ({
                        ...formState,
                        poInputValue: newInputValue
                      }))
                    }}
                    onChange={(event, newValue) => {
                      setPage(0)
                      setRowsPerPage(30)
                      setFormState(formState => ({
                        ...formState,
                        selectedPurchaseOrder: newValue
                      }))
                      if (seacrhType == 'PO' && newValue?.POHeaderId) {
                        _getReceipts(newValue?.POHeaderId, 'ALL')
                        setPOHeaderId(newValue?.POHeaderId)
                        _getPurchaseOrderDetails(newValue?.POHeaderId)
                      } else {
                        _getReceipts(newValue?.ReceiptNumber, 'ALL')
                      }
                    }}
                    sx={{ width: 300 }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label={
                          seacrhType === 'PO'
                            ? 'Search By PO'
                            : 'Search By Receipt'
                        }
                        placeholder={'Search'}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {poLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridItem>
              <CardBody>
                {isLoading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        style={{ minWidth: 650 }}
                        aria-label='simple table'
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell style={{ fontWeight: 'bolder' }}>
                              Receipt Number
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align='left'>
                              Status
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              PO Number
                            </StyledTableCell>
                            {/* <StyledTableCell align='left'>
                              Transaction Type
                            </StyledTableCell> */}
                            <StyledTableCell align='left'>
                              Vendor Name
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              Actions{' '}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.entries(groupedReceipts)
                            .reverse()
                            .map(([key, value]) => (
                              <>
                                {fusloading && receiptId == key ? (
                                  <TableRow>
                                    <TableCell colSpan={8}>
                                      {' '}
                                      <LinearProgress
                                        style={{ width: '100%' }}
                                      />
                                    </TableCell>{' '}
                                  </TableRow>
                                ) : (
                                  ''
                                )}

                                <StyledTableRow key={key}>
                                  <StyledTableCell
                                    style={{ fontWeight: 'bolder' }}
                                    colSpan={2}
                                    component='th'
                                    scope='row'
                                  >
                                    <IconButton
                                      className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded
                                      })}
                                      // className={expanded}
                                      onClick={() => handleExpandClick(key)}
                                      aria-expanded={expanded}
                                      aria-label='show more'
                                    >
                                      <ExpandMoreIcon />
                                    </IconButton>
                                    {value[0]?.workFlowStatus?.status ===
                                    'EXPORTED'
                                      ? key
                                      : '' || ''}
                                  </StyledTableCell>
                                  <StyledTableCell align='left'>
                                    <Tooltip
                                      title={
                                        value[0]?.workFlowStatus?.status?.toUpperCase() ||
                                        ''
                                      }
                                    >
                                      <Chip
                                        variant='outlined'
                                        size='small'
                                        label={
                                          `${value[0]?.workFlowStatus?.status?.toUpperCase()}` ||
                                          ''
                                        }
                                        clickable
                                        onClick={() =>
                                          _trackingStatus(value[0])
                                        }
                                        style={{
                                          border:
                                            value[0]?.workFlowStatus?.status ===
                                            'PENDING'
                                              ? '1px solid orange'
                                              : value[0]?.workFlowStatus
                                                  ?.status === 'REJECTED'
                                              ? '1px solid red'
                                              : value[0]?.workFlowStatus
                                                  ?.status ===
                                                'APPROVED AND EXPORTED'
                                              ? '1px solid green'
                                              : value[0]?.workFlowStatus
                                                  ?.status === 'APPROVED'
                                              ? '1px solid green'
                                              : '1px solid green',
                                          color:
                                            value[0]?.workFlowStatus?.status ===
                                            'PENDING'
                                              ? 'orange'
                                              : value[0]?.workFlowStatus
                                                  ?.status === 'REJECTED'
                                              ? 'red'
                                              : value[0]?.workFlowStatus
                                                  ?.status ===
                                                'APPROVED AND EXPORTED'
                                              ? 'green'
                                              : value[0]?.workFlowStatus
                                                  ?.status === 'APPROVED'
                                              ? 'green'
                                              : 'green'
                                        }}
                                      />
                                    </Tooltip>
                                  </StyledTableCell>
                                  <StyledTableCell align='left'>
                                    {value[0]?.PONumber}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align='left'>
                                    {value[0]?.TransactionTypeCode}
                                  </StyledTableCell> */}
                                  {/* <StyledTableCell align='left'>
                                    {moment(
                                      value[0]?.SysytemTransactionDate ||
                                        value[0]?.TransactionDate
                                    ).format('DD-MM-YYYY hh:mm A')}
                                  </StyledTableCell> */}
                                  <StyledTableCell align='left'>
                                    {value[0]?.VendorName}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align='left'>
                                    {value[0]?.CurrencyCode}
                                  </StyledTableCell> */}
                                  <StyledTableCell align='left'>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                      }}
                                    >
                                      {value[0]?.Quantity !== null &&
                                      value[0]?.workFlowStatus?.status ==
                                        'EXPORTED' &&
                                      value[0]?.workFlowStatus?.exportStep
                                        ?.exportedBy == userDetail?.email ? (
                                        <>
                                          {userDetail?.role?.isDefault ? (
                                            <>
                                              <Link
                                                to={`/default/receipt_action/Return/${value[0].ReceiptNumber}/${value[0]?.LineNumber}/ap`}
                                              >
                                                <Button
                                                  color='danger'
                                                  size='small'
                                                  style={{ width: '120px' }}
                                                >
                                                  Return Receipt
                                                </Button>
                                              </Link>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      {value[0]?.Amount !== null &&
                                      value[0]?.workFlowStatus?.status ==
                                        'EXPORTED' &&
                                      value[0]?.workFlowStatus?.exportStep
                                        ?.exportedBy == userDetail?.email ? (
                                        <>
                                          {userDetail?.role?.isDefault ? (
                                            <Link
                                              to={`/default/receipt_action/Correction/${value[0].ReceiptNumber}/${value[0]?.LineNumber}/ap`}
                                            >
                                              <Button
                                                color='danger'
                                                size='small'
                                                style={{ width: '130px' }}
                                              >
                                                Correct Receipt
                                              </Button>
                                            </Link>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>

                                {expanded == key ? (
                                  <React.Fragment>
                                    <StyledTableRow key={key}>
                                      <StyledTableCell
                                        style={{ padding: 0 }}
                                        colSpan='10'
                                      >
                                        <Collapse
                                          in={expanded == key}
                                          timeout='auto'
                                          unmountOnExit
                                        >
                                          <Table
                                            className={classes.table}
                                            size='small'
                                            aria-label='a dense table'
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <StyledTableCell align='left'>
                                                  Line Number
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                  Description
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                  Transaction Date
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                  Quantity
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                  Currency
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                  Amount
                                                </StyledTableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {value?.map((row, index) => (
                                                <StyledTableRow
                                                  key={index}
                                                  style={{ width: '100%' }}
                                                >
                                                  {/* <StyledTableCell
                                                    align='left'
                                                    style={{
                                                      background: '#808080a1'
                                                    }}
                                                  >
                                                    {row?.ReceiptNumber}{' '}
                                                  </StyledTableCell> */}
                                                  <StyledTableCell align='left'>
                                                    {' '}
                                                    {row?.LineNumber}
                                                  </StyledTableCell>
                                                  <StyledTableCell align='left'>
                                                    {' '}
                                                    {row?.ItemDescription}
                                                  </StyledTableCell>
                                                  <StyledTableCell align='left'>
                                                    {FormatDateTime(
                                                      row?.SysytemTransactionDate ||
                                                        row?.TransactionDate
                                                    )}
                                                  </StyledTableCell>
                                                  <StyledTableCell align='left'>
                                                    {row?.Quantity}
                                                  </StyledTableCell>
                                                  <StyledTableCell align='left'>
                                                    {row?.CurrencyCode}
                                                  </StyledTableCell>
                                                  <StyledTableCell align='left'>
                                                    {addZeroes(row?.Amount)}
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </Collapse>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </React.Fragment>
                                ) : (
                                  ''
                                )}
                              </>
                            ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component='div'
                        count={totalNumOfItems}
                        rowsPerPageOptions={[30, 40, 50]}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={RowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      {/* {!isFilterEnable ? (
                        <TablePagination
                          component="div"
                          count={productdata?.totalNumOfItems}
                          rowsPerPageOptions={[10, 25, 40, 50]}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={RowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />) : ""}
                      {isFilterEnable ? (
                        <TablePagination
                          component="div"
                          count={productdata?.totalNumOfItems}
                          rowsPerPageOptions={[10, 20, 30]}
                          page={page}
                          onPageChange={handleFilterChangePage}
                          rowsPerPage={RowsPerPage}
                          onRowsPerPageChange={handleFilterChangeRowsPerPage}
                        />) : ""} */}
                    </TableContainer>
                  </>
                )}
              </CardBody>
            </Card>
          </GridItem>
          {receiptModal ? (
            <Dialog
              classes={{
                root: classes.center + ' ' + classes.modalRoot,
                paper: classes.modal
              }}
              fullWidth={true}
              maxWidth={'xl'}
              open={receiptModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setreceiptModal(false)}
              aria-labelledby='vendor-modal-slide-title'
              aria-describedby='vendor-modal-slide-description'
            >
              <DialogContent id='vendorSelect' className={classes.modalBody}>
                <PurchaseOrderView
                  PurchaseOrder={selectedPurchaseOrder}
                  onBack={() => setreceiptModal(false)}
                />
              </DialogContent>
            </Dialog>
          ) : (
            ''
          )}
          {reviewModal ? (
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} className={classes.center}>
                <Dialog
                  classes={{
                    root: classes.center + ' ' + classes.modalRoot,
                    paper: classes.modal
                  }}
                  fullWidth={true}
                  maxWidth={'sm'}
                  open={reviewModal}
                  // TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setReviewModal(false)}
                  aria-labelledby='tag-modal-slide-title'
                  aria-describedby='tag-modal-slide-description'
                >
                  <DialogContent
                    id='tag-modal-slide-description'
                    className={classes.modalBody}
                  >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color='info' icon>
                            <CardIcon color='info'>
                              <h4>Approve</h4>
                            </CardIcon>
                          </CardHeader>
                          <CardBody>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px'
                              }}
                            >
                              <TextField
                                className={classes.textField}
                                error={formState.errors.status === 'error'}
                                fullWidth={true}
                                select
                                helperText={
                                  formState.errors.status === 'error'
                                    ? 'Status is required'
                                    : null
                                }
                                label='Status'
                                name='status'
                                onChange={event => {
                                  handlerChange(event)
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  Choose Status
                                </MenuItem>

                                <MenuItem value='approved'>
                                  <div className='fileinput text-center'>
                                    <div className='thumbnail img-circle3'>
                                      <img
                                        src={Success}
                                        alt={'MARK AS APPROVED'}
                                      />
                                    </div>
                                    MARK AS APPROVE
                                  </div>
                                </MenuItem>
                                {/* <MenuItem value='correctionRequired'>
                                  <div className='fileinput text-center'>
                                    <div className='thumbnail img-circle3'>
                                      <img
                                        src={Pending}
                                        alt={'correction Required'}
                                      />
                                    </div>
                                    CORRECTION REQUIRED
                                  </div>
                                </MenuItem> */}
                                <MenuItem value='rejected'>
                                  <div className='fileinput text-center'>
                                    <div className='thumbnail img-circle3'>
                                      <img
                                        src={Rejected}
                                        alt={'MARK AS REJECTED'}
                                      />
                                    </div>
                                    MARK AS REJECTED
                                  </div>
                                </MenuItem>
                              </TextField>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px'
                              }}
                            >
                              <TextField
                                className={classes.textField}
                                error={
                                  formState.errors.reviewComments === 'error'
                                }
                                fullWidth={true}
                                required={
                                  formState.values.status == 'rejected'
                                    ? true
                                    : false
                                }
                                InputLabelProps={{
                                  classes: {
                                    asterisk: classes.asterisk
                                  }
                                }}
                                helperText={
                                  formState.errors.reviewComments === 'error'
                                    ? 'Comments is required'
                                    : null
                                }
                                label='Comments'
                                name='comments'
                                onChange={event => {
                                  handlerChange(event)
                                }}
                                // value={formState.values.reviewComments || ""}
                              ></TextField>
                            </GridItem>
                            <span style={{ float: 'right' }}>
                              <Button
                                color='info'
                                className={classes.registerButton}
                                round
                                type='button'
                                onClick={() => reviewFileNow()}
                              >
                                {reviewLoading ? (
                                  <CircularProgress style={{ color: '#fff' }} />
                                ) : (
                                  'APPROVE'
                                )}
                              </Button>
                              <Button
                                color='danger'
                                className={classes.registerButton}
                                onClick={() => setReviewModal(false)}
                                round
                              >
                                CLOSE
                              </Button>
                            </span>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </DialogContent>
                </Dialog>
              </GridItem>
            </GridContainer>
          ) : (
            ''
          )}
          {openTrackingModal ? (
            <Dialog
              classes={{
                root: classes.center + ' ' + classes.modalRoot,
                paper: classes.modal
              }}
              fullWidth={true}
              maxWidth={'md'}
              open={openTrackingModal}
              // TransitionComponent={Transition}
              keepMounted
              onClose={() => setOpenTrackingModal(false)}
              aria-labelledby='tag-modal-slide-title'
              aria-describedby='tag-modal-slide-description'
            >
              <DialogContent
                id='tag-modal-slide-description'
                className={classes.modalBody}
              >
                <GridContainer>
                  <Card>
                    <CardHeader color='info' icon>
                      <CardIcon color='info'>
                        <h4 className={classes.cardTitle}>Tracking Status</h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <Table
                        className={classes.table}
                        aria-label='simple table'
                      >
                        <TableHead>
                          <TableRow className={classes.TableRow}>
                            <TableCell className={classes.TableID}>
                              Approve
                            </TableCell>
                            <TableCell className={classes.TableID}>
                              Assigned To
                            </TableCell>
                            <TableCell className={classes.TableID}>
                              Assigned Date
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Status
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Action Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ paddingBottom: 5 }}>
                          <TableRow className={classes.TableRow}>
                            <TableCell className={classes.TableCell}>
                              Approve
                            </TableCell>

                            <TableCell>
                              {approve?.approvedBy || '-'}
                              <br />
                              {approve?.assignTo || '-'}
                            </TableCell>
                            <TableCell>
                              {approve?.assignDate
                                ? formatDateTime(approve?.assignDate)
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {approve?.status?.toUpperCase() || 'PENDING'}
                            </TableCell>
                            <TableCell>
                              {approve?.status !== 'pending' && approve?.date
                                ? formatDateTime(approve?.date)
                                : '-'}
                            </TableCell>
                          </TableRow>
                          <TableRow className={classes.TableRow}>
                            <TableCell className={classes.TableCell}>
                              Approve and Export
                            </TableCell>
                            <TableCell>
                              {approveExported?.exportedBy || '-'}
                              <br />
                              {approveExported?.assignTo || '-'}
                            </TableCell>
                            <TableCell>
                              {approveExported?.assignDate
                                ? formatDateTime(approveExported?.assignDate)
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {approveExported?.status?.toUpperCase() ||
                                'PENDING'}
                            </TableCell>
                            <TableCell>
                              {approveExported?.status !== 'pending' &&
                              approveExported?.date
                                ? formatDateTime(approveExported?.date)
                                : '-'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardBody>
                  </Card>
                  <Button
                    color='danger'
                    className={classes.registerButton}
                    onClick={() => closeTrackingModal()}
                    round
                    style={{ float: 'right', marginLeft: 'auto' }}
                  >
                    CLOSE
                  </Button>
                </GridContainer>
              </DialogContent>
            </Dialog>
          ) : (
            ''
          )}
        </GridContainer>
      </Animated>
    </>
  )
}
export default ReturnCorrectReceipt
