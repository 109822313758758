import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
// @material-ui/core components
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import defaultAvatar from "assets/img/placeholder.jpg";
import { isArray } from "lodash";
import { formatDateTime } from "Functions";

const sweetAlertStyle = makeStyles(styles2);
let Token = localStorage.getItem("cooljwt");
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};
const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: "relative",
    maxWidth: 360,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {},
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "",
  },
  TableHead: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
}));

export default function Lines({ lines }) {
  const useStyles = makeStyles(styles);
  const classesList = useStyle();
  const [animateStep, setAnimateStep] = useState(true);
  let filtered_lines = lines?.map((obj) => {
    let new_line = obj;
    //Clearing The Extra Fields
    delete new_line.links;
    delete new_line.schedules;
    return new_line;
  }) || []

  return (
    <Animated
      animationIn="bounceInRight"
      animationOut="bounceOutLeft"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >
      {filtered_lines?.length > 0 ? (
        <GridContainer style={{ overflowX: "scroll",  height: 400 }}>
          <Table className={classesList.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classesList.TableRow}>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Line</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Type</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Quantity</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Uc Price</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Ordered</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Description</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Total Tax</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Total</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Location</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Status</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>PO Charge Account</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Requested Delivery Date</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Budget Date</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Funds Status</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Category Name</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Delivery Location</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Destination Type</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Invoice Close Tolerence Percent</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Invoice Match Option</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Match Approval Level</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Negotiated</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Organization</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Requester</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Requisition</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Transations Business Category</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Product Type</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Assiable Value</Typography></TableCell>
                <TableCell className={classesList.TableCell}><Typography className={classesList.TableHead}>Details</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ paddingBottom: 5 }}>
              {filtered_lines?.map((line) => (
                <TableRow>
                  <TableCell className={classesList.TableID}>
                    {line?.LineNumber || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Type || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Quantity || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.UcPrice || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Ordered || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Description || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.TotalTax || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Total || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Location || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Status || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.POChargeAccount || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.RequestedDeliveryDate ? formatDateTime(line?.RequestedDeliveryDate)  : ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.BudgetDate ? formatDateTime(line?.BudgetDate) : ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.FundsStatus || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.CategoryName || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.DeliveryLocation || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.DestinationType || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.InvoiceCloseTolerencePercent || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.InvoiceMatchOption || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.MatchApprovalLevel || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Negotiated || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Organization || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Requester || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Requistion || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.TransationsBusinessCategory || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.ProductType || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.AssiableValue || ""}
                  </TableCell>
                  <TableCell className={classesList.TableID}>
                    {line?.Details || ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GridContainer>
      ) : (
        "No Record Found."
      )}
    </Animated>
  );
}
