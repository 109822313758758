import React from 'react'
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
} from '@material-ui/core'
import { formatDateTime } from '../Functions/Functions'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info';

const ForwardTaskModal = ({ showFwdData }) => {

    const useStyles = makeStyles()
    const classes = useStyles()
    return (
        <div>
            <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Foward/Deligate User</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ paddingBottom: 5 }}>
                    {showFwdData && showFwdData?.forward?.map((fwd, index) => (
                        <>
                            <TableRow className={classes.TableRow} key={index}>
                                <TableCell className={classes.TableCell}>
                                    {formatDateTime(fwd?.date)}
                                </TableCell>
                                <TableCell>
                                    {fwd?.noteMsg}
                                </TableCell>
                            </TableRow>
                            {fwd?.showReason &&
                                <TableRow className={classes.TableRow} key={index}>
                                    <TableCell colSpan={3}>
                                        <b>Reason:</b> {fwd?.reason}
                                    </TableCell>
                                </TableRow>
                            }
                        </>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default ForwardTaskModal
