import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js'
import Footer from 'components/Footer/Footer.js'

import styles from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.js'

import register from 'assets/img/register.jpeg'
import login from 'assets/img/login_salic.png'
import lock from 'assets/img/lock.jpeg'
import error from 'assets/img/clint-mckoy.jpg'
import Auth from 'views/LDocs/Authorization/Auth'
import Tenant from 'views/LDocs/Authorization/Tenant'
import LoginVendor from 'views/LDocs/Authorization/LoginVendor'
import LoginSecret from 'views/LDocs/Authorization/LoginSecret'
import Totp from 'views/LDocs/Authorization/Totp'
import LoginActivate from 'views/LDocs/Authorization/LoginActivate'
import AuthDev from 'views/LDocs/Authorization/AuthDev'
import VendorProfile from 'views/LDocs/VendorProfile/Profile'
import axios from 'axios'
import { decode } from 'jsonwebtoken'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

export default function Blank () {


  return (
    <>
     <div>Site Temporarily Unavailable!</div>
    </>
  )
}
