import React, { useEffect } from 'react'
import MaterialTable, { MTableFilterRow, MTableToolbar } from 'material-table'
// @material-ui/core components
import GridContainer from 'components/Grid/GridContainer.js'

import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
//Redux
import { useSelector, useDispatch } from 'react-redux'
//ReduxActions
import { getEvents } from 'actions'
import { Animated } from 'react-animated-css'
import LogsList from 'actions/LogsListAction'
import { MenuItem, TablePagination, TextField } from '@material-ui/core'
import { SpriteEventDispatcher } from '@amcharts/amcharts4/core'
import axios from 'axios'
import { setIsTokenExpired } from 'actions'
import Button from 'components/CustomButtons/Button.js'

export default function Warnings () {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const [RowsPerPage, setRowsPerPage] = React.useState(10)
  const [eventType, setEventType] = React.useState({
    eventMethod: null,
    eventType: null,
    eventStatus: null,
    eventTime: null,
    eventBy: null
  })
  const [eventValue, setEventValue] = React.useState('')
  const [eventMethods, setEventMethods] = React.useState([])
  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  const logsState = useSelector(state => state?.logs)
  const { logsList, loading } = logsState

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
    dispatch(
      LogsList({
        page:newPage ,
        RowsPerPage,
        Token: Token,
        eventType: eventType
      })
    )
  }

  const handleChangeRowsPerPage = async event => {
    setRowsPerPage(parseInt(event.target.value), 30)
    dispatch(
      LogsList({
        page ,
        RowsPerPage: event.target.value,
        Token: Token,
        eventType: eventType
      })
    )
  }

  useEffect(async () => {
    await axios({
      method: 'get', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/notify/getEventMethods`,
      headers: { cooljwt: Token }
    })
      .then(response => {
        setEventMethods(response.data)
      })
      .catch(error => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        )
      })
  }, [])

  useEffect(() => {
    dispatch(
      LogsList({
        page,
        RowsPerPage,
        Token: Token,
        eventType: eventType
      })
    )
  }, [])

  useEffect(() => {
    setPage(0)
    setRowsPerPage(10)
    if (
      eventType.eventMethod !== null ||
      eventType.eventStatus !== null ||
      eventType.eventType !== null ||
      eventType.eventTime !== null ||
      eventType.eventBy !== null
    ) {
      let page = 0;
      let RowsPerPage = 10;
      dispatch(
        LogsList({
          page,
          RowsPerPage,
          Token: Token,
          eventType: eventType
        })
      )
    }
  }, [eventType])

  const _handleChange = event => {
    event.persist()
    const { name, value } = event.target
    setEventType(eventType => ({
      ...eventType,
      [name]: value
    }))
  }
  return (
    <div>
      <Animated
        animationIn='bounceInRight'
        animationOut='bounceOutLeft'
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card profile>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={{}}>Events Logs</h4>
                </CardIcon>
              </CardHeader>
              <CardBody profile>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-around'
                  }}
                >
                  {' '}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <TextField
                      label='Event Method'
                      name='eventMethod'
                      onChange={event => {
                        _handleChange(event)
                      }}
                      value={eventType.eventMethod || ''}
                      fullWidth={true}
                      select
                    >
                      <MenuItem disabled>Select Event</MenuItem>
                      {eventMethods?.map(list => (
                        <MenuItem value={list}>{list}</MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <TextField
                      label='Event Type'
                      name='eventType'
                      onChange={event => {
                        _handleChange(event)
                      }}
                      value={eventType.eventType || ''}
                      fullWidth={true}
                      select
                    >
                      <MenuItem disabled>Select Event</MenuItem>
                      <MenuItem value='Export'>Export</MenuItem>
                      <MenuItem value='INSERT'>Insert</MenuItem>
                      <MenuItem value='UPDATE'>Update</MenuItem>
                    </TextField>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <>
                      <TextField
                        label='Event Status'
                        name='eventStatus'
                        onChange={event => {
                          _handleChange(event)
                        }}
                        value={eventType.eventStatus || ''}
                        fullWidth={true}
                        select
                      >
                        <MenuItem disabled>Select Status</MenuItem>
                        <MenuItem value='success'>Success</MenuItem>
                        <MenuItem value='error'>Error</MenuItem>
                      </TextField>
                    </>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    style={{ marginTop: '26px', marginBottom: '10px' }}
                  >
                    <TextField
                      // className={classes.textField}
                      fullWidth={true}
                      // label={'Event Date'}
                      name='eventTime'
                      type={'date'}
                      onChange={event => {
                        _handleChange(event)
                      }}
                      value={eventType.eventTime || ''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={2}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <TextField
                      // className={classes.textField}
                      fullWidth={true}
                      label={'Enter Email'}
                      name='eventBy'
                      type={'text'}
                      onChange={event => {
                        _handleChange(event)
                      }}
                      value={eventType.eventBy || ''}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={1}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Button
                      round
                      onClick={() => {
                        setEventType({
                          eventMethod: null,
                          eventType: null,
                          eventStatus: null,
                          eventTime: null,
                          eventBy: null
                        });
                        dispatch(
                          LogsList({
                            page,
                            RowsPerPage,
                            Token: Token,
                            eventType: eventType
                          })
                        )
                      }}
                      color='danger'
                      className='Edit'
                    >
                      Clear
                    </Button>
                  </GridItem>
                </GridItem>
                <MaterialTable
                  style={{ boxShadow: 'none' }}
                  detailPanel={rowData => {
                    return (
                      <div style={{ paddingLeft: 20, paddingRight: 10 }}>
                        <h6>API End Point</h6>
                        <code>{rowData?.apiEndPoint || ''}</code>
                        <hr />
                        <h6>Details</h6>
                        <p>{rowData?.eventDescription || ''}</p>
                        <hr />
                        <h6>Request Object</h6>
                        <code>{rowData?.eventObject || ''}</code>
                      </div>
                    )
                  }}
                  title={null}
                  columns={[
                    { title: 'Event Method', field: 'eventMethod' },
                    { title: 'Event Type', field: 'eventType' },

                    // { title: 'Inovoice ID', field: 'invoiceId' },
                    {
                      title: 'Status',
                      field: 'eventStatus',
                      lookup: { success: 'Success', error: 'Error' }
                    },
                    {
                      title: 'Event Time',
                      field: 'eventTime',
                      type: 'datetime'
                    },
                    {
                      title: 'Event By',
                      field: 'eventBy'
                      //customFilterAndSearch: (term, rowData) => term == rowData.name.length
                    }
                    // {
                    //   title: "Event Module",
                    //   field: "eventModule",
                    //   lookup: { "File System": "File System" },
                    // },
                  ]}
                  isLoading={loading}
                  data={logsList?.Results}
                  options={{
                    search: false,
                    showTitle: false,
                    paging: false,
                    filtering: false,

                    showFirstLastPageButtons: false,
                    loadingType: 'overlay'
                  }}
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 30]}
                  rowsPerPage={RowsPerPage}
                  count={logsList?.totalNumOfItems}
                  page={page}
                  onChangePage={(e, page) => handleChangePage(e, page)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Event Method</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>Invoice ID </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Event Time</TableCell>
              <TableCell>Event By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (<CircularProgress style={{ marginTop: "50px", marginLeft: "400px", width: "100px", height: "100px" }} />) : (
                logsList?.Results?.
                  map((row, index) => (
                    <>
                    {isloading && pOId == row.POHeaderId ? <TableRow><TableCell colSpan={8}> <LinearProgress style={{width:"100%"}} /></TableCell> </TableRow>  : ""}
                    <TableRow key={index}>
                      <TableCell> {row.OrderNumber}</TableCell>
                      <TableCell>{row.ProcurementBU}</TableCell>
                      <TableCell>{row.Supplier}</TableCell>
                      <TableCell>{row.PaymentTerms}</TableCell>
                      <TableCell>{row.Status}</TableCell>
                      <TableCell>{row.Total}</TableCell>
                      <TableCell>{formatDateTime(row?.LastSyncDate)}</TableCell>
                      <TableCell>
                        <Tooltip title="View PO Details" aria-label="viewDetails">
                          <Link to={`/default/purchase_order/${row.POHeaderId}`}>
                            <VisibilityIcon fontSize="small" />
                          </Link>
                        </Tooltip>
                        <Tooltip title="Sync Single PO" aria-label="syncPo">
                          <Refresh fontSize="small" color="primary" onClick={() => syncSinglePo(row.POHeaderId)} style={{ cursor: "pointer", marginLeft: 10 }} />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    </>
                  )) || []
              )
            }
          </TableBody>
        </Table>
        {!isFilterEnable ? (
          <TablePagination
            component="div"
            count={productdata?.totalNumOfItems}
            rowsPerPageOptions={[10, 25, 40, 50]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={RowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />) : ""}
        {isFilterEnable ? (
          <TablePagination
            component="div"
            count={productdata?.totalNumOfItems}
            rowsPerPageOptions={[10, 20, 30]}
            page={page}
            onPageChange={handleFilterChangePage}
            rowsPerPage={RowsPerPage}
            onRowsPerPageChange={handleFilterChangeRowsPerPage}
          />) : ""}
      </TableContainer> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Animated>
    </div>
  )
}
