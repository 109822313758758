import React, { useState } from 'react'
import jwt from 'jsonwebtoken'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import axios from 'axios'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  LinearProgress,
  makeStyles,
  TextField,
  MenuItem
} from '@material-ui/core'
import FileAdvanceView from '../AdvanceView/FileAdvanceView'
import { setIsTokenExpired } from 'actions'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import Swal from 'sweetalert2'
import {
  successAlert,
  errorAlert,
  msgAlert
} from 'views/LDocs/Functions/Functions.js'
import { useLocation, useHistory } from 'react-router-dom'
import Button from 'components/CustomButtons/Button.js'
import Pending from 'assets/img/statuses/Pending.png'
import Success from 'assets/img/statuses/Success.png'
import Rejected from 'assets/img/statuses/Rejected.png'

const useStyle = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: 'relative',
    maxWidth: 360
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  table: {
    minWidth: '100%',
    border: 1
  },
  TableCell: {
    minWidth: '10%'
  },
  TableRow: {
    cursor: 'pointer',
    background: 'white',
    border: 1,
    width: '100%'
  },
  TableID: {
    maxWidth: '3%'
  }
}))
export default function InvoiceDetail () {
  const Token =
    useSelector(state => state.userReducer.Token) ||
    localStorage.getItem('cooljwt')
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const logo = require('assets/img/logo.png')
  const [userDetails, setUserDetails] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [hideInfo, setHideInfo] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowPerPage] = useState(10)
  const [invoiceDetails, setInvoiceDetails] = useState(null)
  const [reviewData, setReviewData] = useState(null)
  const [reviewModal, setReviewModal] = useState(false)
  const [isReviewingFile, setIsReviewingFile] = useState(false)
  const [required, setRequired] = useState(false)
  const [refreshDate, setRefreshDate] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [formState, setFormState] = React.useState({
    values: {
      status:'',
      comments:'',
      reviewComments:''},
    errors: {
      status: '',
      reviewComments: ''
    }
  })
  const styles = makeStyles(theme => ({}))
  const classes = styles()
  let params = window.location.href;
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    var results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
const getInvData = async() => {
  setIsLoaded(true)
  await axios({
    method: 'post', //you can set what request you want to be
    url:
      invoice.isAR == 'true'
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ar`
        : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ap`,
    data: {
      invoiceId: invoice.id || getParameterByName('invoiceId'),
      version: invoice.version,
      vendorId: invoice.isAR == 'true' ? null : invoice.vendorId,
      clientId: invoice.isAR == 'true' ? invoice.clientId : null,
      isAR: invoice.isAR == 'true' ? true : false
    }
  })
    .then(async invoiceRes => {
      const invoice = invoiceRes.data
      setInvoice(invoice)
      setHideInfo(false)
      setIsLoaded(false)
    })
    .catch(error => {
      error?.response &&
        error?.response?.status == 401 &&
        errorAlert('TOKEN HAS BEEN EXPIRED!')
      setInvoice(null)
      setIsLoaded(false)
      localStorage.setItem('url', null)
      errorAlert('UNABLE TO FIND INVOICE IN SYSTEM')
      console.log(error)
    })
}

  React.useEffect(() => {
    if (location.pathname.includes('/invoiceDetail')) {
      let url = window.location.href
      url = url.split('/')[4]
      localStorage.setItem('url', `/default/${url}`)

      if (Token == null) {
        history.push('/auth/login')
      }
    }
    if (Token !== null) {
      const userData = jwt.decode(Token)
      setUserDetails(userData)
      const invoice = {
        id: getParameterByName('invoiceId'),
        version: getParameterByName('version'),
        vendorId: getParameterByName('vendorId'),
        clientId: getParameterByName('clientId'),
        isAR: getParameterByName('isAR')
      }
      let action =
      invoice?.trackingStatus?.underApprove.status == 'inProgress'
          ? 'approve'
          : 'review';
      axios({
        method: 'post', //you can set what request you want to be
        url:
          invoice.isAR == 'true'
            ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ar`
            : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ap`,
        data: {
          invoiceId: invoice.id,
          version: invoice.version,
          vendorId: invoice.isAR == 'true' ? null : invoice.vendorId,
          clientId: invoice.isAR == 'true' ? invoice.clientId : null,
          isAR: invoice.isAR == 'true' ? true : false
        }

        // headers: {
        //   cooljwt: Token || null,
        // },
      })
        .then(async invoiceRes => {
          const invoice = invoiceRes.data
          setInvoice(invoice)
          setHideInfo(false)
        })
        .catch(error => {
          error?.response &&
            error?.response?.status == 401 &&
            errorAlert('TOKEN HAS BEEN EXPIRED!')
          setInvoice(null)
          localStorage.setItem('url', null)
          errorAlert('UNABLE TO FIND INVOICE IN SYSTEM')
          console.log(error)
        })

      // getting review data from DB
        axios({
          method: 'post', //you can set what request you want to be
          url:`${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInv`,
          data: {
            invoiceId: invoice.id,
            version: invoice.version,
            vendorId: invoice.isAR == 'true' ? null : invoice.vendorId,
            action:action
          }
        })
          .then(async invoiceRes => {
            const reviewInfo = invoiceRes.data
            setReviewData(reviewInfo)
          })
          .catch(error => {
            error?.response &&
              error?.response?.status == 401 &&
              errorAlert('TOKEN HAS BEEN EXPIRED!')
            // setInvoice(null)
            // errorAlert('UNABLE TO FIND INVOICE IN SYSTEM')
            console.log(error)
          })
    }
  }, [params])

  const handlerChange = event => {
    event.persist()
    if (event.target.name == 'status') {
      if (
        event.target.value == 'reviewed' ||
        event.target.value == 'approved'
      ) {
        setRequired(false)
      } else {
        setRequired(true)
      }
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      }
    }))
  }

  const _reviewModal = () => {
    setReviewModal(true)
  }

  const reviewFileNow = () => {
    let action =
    invoice?.trackingStatus?.underApprove.status == 'inProgress'
        ? 'approve'
        : 'review'
    setIsReviewingFile(true)
    let status
    let reviewComments
    const Check = require('is-null-empty-or-undefined').Check
    var error = false
    if (!Check(formState.values.status)) {
      status = 'success'
    } else {
      status = 'error'
      error = true
    }
    if (
      required
        ? !Check(formState.values.comments)
        : (reviewComments = 'success')
    ) {
      reviewComments = 'success'
    } else {
      reviewComments = 'error'
      error = true
    }
    setFormState(formState => ({
      ...formState,
      errors: {
        ...formState.errors,
        status: status,
        reviewComments: reviewComments
      }
    }))
    if (error) {
      setIsReviewingFile(false)
      return false
    } else {
      let data = {
        ...reviewData,
        reviewComments: formState.values.comments,
        status: formState.values.status,
        updateTime: new Date()
          .toLocaleString()
          .replace(/t/, ' ')
          .replace(/\..+/, '')
      }
      axios({
        method: 'post',
        url:
          action == 'approve'
            ? `${process.env.REACT_APP_LDOCS_API_URL}/invoiceApprove/approveUpdate`
            : `${process.env.REACT_APP_LDOCS_API_URL}/invoiceReview/reviewUpdate?page=${page}&items=${rowsPerPage}`,
        data: data,
        headers: {
          cooljwt: Token
        }
      })
        .then(response => {
          setFormState(formState => ({
            ...formState,
            errors: {
              ...formState.errors,
              status: '',
              reviewComments: ''
            }
          }))
          setReviewModal(false)
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              status: '',
              comments: ''
            }
          }))
          if (formState.values.status == 'reviewed') {
            successAlert('Invoice Reviewed Successfully!')
          }
          if (formState.values.status == 'approved') {
            successAlert('Invoice Approved Successfully!')
          }
          if (formState.values.status == 'correctionRequired') {
            successAlert('Invoice sent for correction required')
          }
          if (formState.values.status == 'rejected') {
            successAlert('Invoice Rejected')
          }
          // dispatch(
          //   sendNotification(
          //     `${InvoiceData.fileId} Invoice is Reviewed`,
          //     InvoiceData.fileOwner
          //   )
          // )
          // dispatch(getNotification())
          setIsReviewingFile(false)
          getInvData();
          setRefreshDate(true);
          window.location.reload();
        })
        .catch(error => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true))
          }
          console.log(
            typeof error.response != 'undefined'
              ? error.response.data
              : error.message
          )
          let err = error?.response?.data
          errorAlert(err || 'There is some issue ..')
          setIsReviewingFile(false)
          setReviewModal(false)
        })
    }
  }
  return (
    <React.Fragment>
      {}
      <Card>
        <CardHeader color='info' icon>
          <CardIcon color='info'>
            <h4>360 View</h4>
          </CardIcon>
          {invoice?.trackingStatus?.underReview?.status ==
            'inProgress' &&
            invoice?.trackingStatus?.underReview?.reviewedBy ==
            userDetails?.email ? (
            <Button
              color='danger'
              size='small'
              style={{ float: 'right' }}
              onClick={() => _reviewModal()}
              round
            >
              Action
            </Button>
          ):('')} 
          
          {invoice?.trackingStatus?.underApprove?.status ==
              'inProgress' &&
              invoice?.trackingStatus?.underApprove?.approvedBy ==
              userDetails?.email ? (
            <Button
              color='danger'
              size='small'
              style={{ float: 'right' }}
              onClick={() => _reviewModal()}
              round
            >
              Action
            </Button>
          ) : (
            ''
          )}
        </CardHeader>
        <CardBody>
          {invoice && !isLoaded ? (
            <FileAdvanceView
              isAr={getParameterByName('isAR')}
              fileData={invoice}
              hideInfo={hideInfo}
              isVendor={userDetails?.isVendor ? userDetails?.isVendor : false}
              setInvoiceDetails={setInvoiceDetails}
              refreshDate={refreshDate}
            />
          ) : (
            <LinearProgress />
          )}
        </CardBody>
      </Card>
      {reviewModal ? (
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12}>
            <Dialog
              classes={{
                root: classes.center + ' ' + classes.modalRoot,
                paper: classes.modal
              }}
              fullWidth={true}
              maxWidth={'sm'}
              open={reviewModal}
              // TransitionComponent={Transition}
              keepMounted
              onClose={() => setReviewModal(false)}
              aria-labelledby='tag-modal-slide-title'
              aria-describedby='tag-modal-slide-description'
            >
              <DialogContent
                id='tag-modal-slide-description'
                className={classes.modalBody}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color='info' icon>
                        <CardIcon color='info'>
                          <h4>
                            {invoice?.trackingStatus?.underReview
                              .status == 'inProgress'
                              ? 'Review'
                              : invoice?.trackingStatus?.underApprove
                                  .status == 'inProgress'
                              ? 'Approve'
                              : ''}
                          </h4>
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px'
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            error={formState.errors.status === 'error'}
                            fullWidth={true}
                            select
                            helperText={
                              formState.errors.status === 'error'
                                ? 'Status is required'
                                : null
                            }
                            label='Status'
                            name='status'
                            onChange={event => {
                              handlerChange(event)
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              Choose Status
                            </MenuItem>

                            {invoice?.trackingStatus?.underReview
                              .status == 'inProgress' ? (
                              <MenuItem value='reviewed'>
                                <div className='fileinput text-center'>
                                  <div className='thumbnail img-circle3'>
                                    <img
                                      src={Success}
                                      alt={'MARK AS REVIEWED'}
                                    />
                                  </div>
                                  MARK AS REVIEWED
                                </div>
                              </MenuItem>
                            ) : (
                              <MenuItem value='approved'>
                                <div className='fileinput text-center'>
                                  <div className='thumbnail img-circle3'>
                                    <img
                                      src={Success}
                                      alt={'MARK AS APPROVED'}
                                    />
                                  </div>
                                  MARK AS APPROVED
                                </div>
                              </MenuItem>
                            )}
                            <MenuItem value='correctionRequired'>
                              <div className='fileinput text-center'>
                                <div className='thumbnail img-circle3'>
                                  <img
                                    src={Pending}
                                    alt={'correction Required'}
                                  />
                                </div>
                                CORRECTION REQUIRED
                              </div>
                            </MenuItem>
                            <MenuItem value='rejected'>
                              <div className='fileinput text-center'>
                                <div className='thumbnail img-circle3'>
                                  <img
                                    src={Rejected}
                                    alt={'MARK AS REJECTED'}
                                  />
                                </div>
                                MARK AS REJECTED
                              </div>
                            </MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px'
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            error={formState.errors.reviewComments === 'error'}
                            fullWidth={true}
                            required={required}
                            helperText={
                              formState.errors.reviewComments === 'error'
                                ? 'Comments is required'
                                : null
                            }
                            label='Comments'
                            name='comments'
                            onChange={event => {
                              handlerChange(event)
                            }}
                            // value={formState.values.reviewComments || ""}
                          ></TextField>
                        </GridItem>
                        <span style={{ float: 'right' }}>
                          <Button
                            color='info'
                            className={classes.registerButton}
                            round
                            type='button'
                            onClick={() => reviewFileNow()}
                          >
                            {isReviewingFile ? (
                              <CircularProgress style={{ color: '#fff' }} />
                            ) : (
                              'Submit'
                            )}
                          </Button>
                          <Button
                            color='danger'
                            className={classes.registerButton}
                            onClick={() => setReviewModal(false)}
                            round
                          >
                            CLOSE
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ''
      )}
    </React.Fragment>
  )
  // ) : (
  //   <Redirect to='/auth/login' />
  // )
}
