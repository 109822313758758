/*eslint-disable*/
import React, { useState, useEffect } from "react";
// @material-ui/core components
import {
  makeStyles,
  Typography,
  LinearProgress,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  CircularProgress,
  Divider,
  Checkbox,
} from "@material-ui/core";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import jwt from "jsonwebtoken";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import List from "@material-ui/core/List";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addZeroes,
  formatDateTime,
  validateInvoice,
  successAlert,
  errorAlert,
  msgAlert,
} from "Functions";
import { formatDate } from "Functions";
import CardBody from "components/Card/CardBody";
import Tabs from "./Childs/Tabs";
const useStyle = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  list: {
    color: "black",
  },
}));
export default function SupplierView({ supplierDetails }) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const dispatch = useDispatch();
  const decoded = jwt.decode(Token);
  const classes = useStyle();
  const classesList = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  //Use Effect Hook
  useEffect(() => {
    loadFunctions();
  }, []);

  //Load All Functions
  const loadFunctions = async () => {
  };

  {
    return typeof supplierDetails !== "object" ? (
      <Typography>Use Valid Supplier ID</Typography>
    ) : (
      <>
        {/* Tabs */}
        <Tabs  
          generalInfo={supplierDetails?.level1}
          certificates={supplierDetails?.level2}
          bankdetails={supplierDetails?.level3}/>
      
        
      </>
    );
  }
}
