import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  MenuItem,
  Checkbox,
  Divider,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icon
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import {
  addZeroes,
  errorAlert,
  successAlert,
} from "views/LDocs/Functions/Functions";
import GenerateAccount from "./GenerateAccount";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  marginTop: {
    marginTop: "15px",
  },
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DefaultAccounts() {
  const classes = useStyles();
  const [data, setData] = React.useState({
    loading: true,
    values: {
      expPetty: "100-10111-11000000-000-000000-000000",
      libPetty: "100-10111-11000000-000-000000-000000",
      expReceipt: "100-10111-11000000-000-000000-000000",
      libReceipt: "100-10111-11000000-000-000000-000000",
      expPre: "100-10111-11000000-000-000000-000000",
      libaPre: "100-10111-11000000-000-000000-000000",
    },
    coa: {
      Company: [],
      Department_CostCenter: [],
      Account1: [],
      Subsidiary: [],
      Spare1: [],
      Spare2: [],
    },
  });
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let userDetail = jwt.decode(Token);
  const dispatch = useDispatch();
  React.useEffect(() => {
    userDetail = jwt.decode(Token);
    getCOA();
  }, []);

  const handleChange = (name, value) => {
    setData((data) => ({
      ...data,
      values: {
        ...data.values,
        [name]: value,
      },
    }));
  };

  const getCOAValues = async () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getCombinationCOA`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        let defaultValues = response.data;
        setData((data) => ({
          ...data,
          values: {
            ...data.values,
            expPetty: defaultValues?.pettyCash?.expenseAcc,
            libPetty: defaultValues?.pettyCash?.liabilityAcc,
            expReceipt: defaultValues?.withReceipt?.expenseAcc,
            libReceipt: defaultValues?.withReceipt?.liabilityAcc,
            expPre: defaultValues?.prePayment?.expenseAcc,
            libaPre: defaultValues?.prePayment?.liabilityAcc,
          },
        }));
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const getCOA = async () => {
    getCOAValues();
    axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getAccounts/null`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        setData((data) => ({
          ...data,
          loading: false,
          coa: {
            ...data.coa,
            Company:
              response.data.find((c) => c._id == "Company")?.records.filter(c => c.Summery_Flag == "N") || [],
            Department_CostCenter:
              response.data.find((d) => d._id == "Department_CostCenter")
                ?.records.filter(c => c.Summery_Flag == "N") || [],
            Account1:
              response.data.find((a) => a._id == "Account1")?.records.filter(c => c.Summery_Flag == "N") || [],
            Subsidiary:
              response.data.find((s) => s._id == "Subsidiary")?.records.filter(c => c.Summery_Flag == "N") || [],
            Spare1: response.data.find((s) => s._id == "Spare1")?.records.filter(c => c.Summery_Flag == "N") || [],
            Spare2: response.data.find((s) => s._id == "Spare2")?.records.filter(c => c.Summery_Flag == "N") || [],
          },
        }));
      })
      .catch((error) => {
        setData((data) => ({
          ...data,
          loading: false,
        }));
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const saveCOADefault = () => {
    let COADefaultData = {
      prePayment: {
        liabilityAcc: data.values.libaPre,
        expenseAcc: data.values.expPre,
      },
      withReceipt: {
        liabilityAcc: data.values.libReceipt,
        expenseAcc: data.values.expReceipt,
      },
      pettyCash: {
        liabilityAcc: data.values.libPetty,
        expenseAcc: data.values.expPetty,
      },
    };

    axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/SaveDefaultCombinationCOA`,
      data: COADefaultData,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        getCOAValues();
        successAlert("Updated Successfully");
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        errorAlert("Error in Updating");
      });
  };

  return (
    <div>
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        {!data.loading ? (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      Pre-Payment / Advance Payments
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <GenerateAccount
                        title="Expense Account"
                        accounts={data.coa}
                        value={data.values.expPre.split("-")}
                        saveVal={(handleChange)}
                        inputName={"expPre"}
                      />
                    </GridItem>
                    <Divider />
                    <GridItem xs={12}>
                      <GenerateAccount
                        title="Liabilty Account"
                        accounts={data.coa}
                        value={data.values.libaPre.split("-")}
                        saveVal={handleChange}
                        inputName={"libaPre"}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="danger" icon>
                  <CardIcon color="danger">
                    <h4 className={classes.cardTitleText}>
                      With Receipt Invoices
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <GenerateAccount
                        title="Expense Account"
                        accounts={data.coa}
                        value={data.values.expReceipt.split("-")}
                        saveVal={handleChange}
                        inputName={"expReceipt"}
                      />
                    </GridItem>
                    <Divider />
                    <GridItem xs={12}>
                      <GenerateAccount
                        title="Liabilty Account"
                        accounts={data.coa}
                        value={data.values.libReceipt.split("-")}
                        saveVal={handleChange}
                        inputName={"libReceipt"}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      Standard / Petty Cash Invoices
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {" "}
                  <GridContainer>
                    <GridItem xs={12}>
                      <GenerateAccount
                        title="Expense Account"
                        accounts={data.coa}
                        value={data.values.expPetty.split("-")}
                        saveVal={handleChange}
                        inputName={"expPetty"}
                      />
                    </GridItem>
                    <Divider />
                    <GridItem xs={12}>
                      <GenerateAccount
                        title="Liabilty Account"
                        accounts={data.coa}
                        value={data.values.libPetty.split("-")}
                        saveVal={handleChange}
                        inputName={"libPetty"}
                      />
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="danger"
                    className={classes.registerButton}
                    style={{ float: "right", marginTop: 20 }}
                    round
                    type="button"
                    onClick={saveCOADefault}
                  >
                    Update
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <CircularProgress />
        )}
      </Animated>
    </div>
  );
}
