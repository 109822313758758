import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../../actions";
import DeviceUUID, { getToken } from "views/LDocs/Functions/Functions";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

let uuid = new DeviceUUID().get();
let os = new DeviceUUID().parse().os;
let firebase_token;
getToken().then((res) => {
  firebase_token = res;
});

const useStyles = makeStyles(styles);
export default function LoginVendorPage(props) {
  const [loginnameError, setloginnameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiErrorMessage, setAPIErrorMessage] = useState("");
  const [logging, setlogging] = useState(false);
  const [loginName, setloginname] = useState("");
  const [password, setPassword] = useState("");
  const [repatchToken, setRepatchToken] = useState(null);
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleLoginName = (event) => {
    event.persist();
    setloginname(event.target.value);
  };
  const handlePassword = (event) => {
    event.persist();
    setPassword(event.target.value);
  };
  const handleLoginIn = (event) => {
    event.preventDefault();
    if (!repatchToken) {
      setAPIErrorMessage("RECAPTCHA REQUIRED!");
      return;
    }
    setAPIErrorMessage("");
    setlogging(true);
    axios
      .post(`${process.env.REACT_APP_LDOCS_API_URL}/vendor/vendorLogin`, {
        email: loginName,
        password: password,
        recaptchaToken: repatchToken,
      })
      .then(async (response) => {
        await axios({
          method: "post", //you can set what request you want to be
          url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/updateFcm`,
          data: {
            osType: os,
            fcmToken: firebase_token,
            deviceId: uuid,
            recaptchaToken: repatchToken,
          },
          headers: {
            cooljwt: response.data.token,
          },
        });
        setlogging(false);
        localStorage.setItem("cooljwt", response.data.token);
        dispatch(setToken(response.data.token));
        setloginnameError("success");
        setPasswordError("success");
        props.loginSuccess();
      })
      .catch((error) => {
        if (error?.response?.status == 502) {
          setAPIErrorMessage(
            "Service temporarily unavailable, please try again later"
          );
        } else {
          setAPIErrorMessage(
            typeof error.response != "undefined"
              ? error?.response?.data
              : error?.message
          );
        }
        setlogging(false);
        setloginnameError("error");
        setPasswordError("error");
        setTimeout(function() {
          setAPIErrorMessage("");
        }, 2000);
      });
  };
  return (
    <form onSubmit={handleLoginIn}>
      <Card login>
        <CardHeader
          className={`${classes.cardHeader} ${classes.textCenter}`}
          color="info"
        >
          <h4 className={classes.cardTitle}>Supplier Portal</h4>
        </CardHeader>
        <CardBody>
          <CustomInput
            success={loginnameError === "success"}
            error={loginnameError === "error"}
            labelText="Email ID"
            id="loginName"
            name="loginName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                handleLoginName(event);
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              type: "email",
            }}
          />
          <CustomInput
            success={passwordError === "success"}
            error={passwordError === "error"}
            labelText="Password"
            id="password"
            name="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                handlePassword(event);
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>
                    lock_outline
                  </Icon>
                </InputAdornment>
              ),
              type: "password",
              autoComplete: "off",
            }}
          />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_KEY}
            onChange={(value) => setRepatchToken(value)}
          />
          {apiErrorMessage && (
            <>
              <br />
              <h6 style={{ color: "red" }}>{apiErrorMessage}</h6>
            </>
          )}
        </CardBody>
        <CardFooter style={{ display: "flex", flexDirection: "column" }}>
          <Button color="primary" variant="outlined" fullWidth type="submit">
            {logging ? <CircularProgress style={{ color: "#fff" }} /> : "Login"}
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 10,
            }}
          >
            <Link to={"/sup/forgot_password"}>forgot password?</Link>
            <Link to={"/supplier/register"}>Sign up</Link>
          </div>
        </CardFooter>
      </Card>
    </form>
  );
}
