import {
  VP_GENERAL_REQUEST,
  VP_GENERAL_SUCCESS,
  VP_GENERAL_FAIL,
} from "actions/Constant";
import {
  VP_CONTACT_REQUEST,
  VP_CONTACT_SUCCESS,
  VP_CONTACT_EDIT,
  VP_CONTACT_FAIL,
} from "actions/Constant";
import {
  VP_ADDRESS_REQUEST,
  VP_ADDRESS_SUCCESS,
  VP_ADDRESS_CLEAR,
  VP_ADDRESS_EDIT,
  VP_ADDRESS_FAIL,
} from "actions/Constant";
import {
  VP_SITE_REQUEST,
  VP_SITE_SUCCESS,
  VP_SITE_FAIL,
} from "actions/Constant";
import {
  VP_BANKDETAILS_REQUEST,
  VP_BANKDETAILS_SUCCESS,
  VP_BANKDETAILS_EDIT,
  VP_BANKDETAILS_FAIL,
  VP_BANKDETAILS_CLEAR,
} from "actions/Constant";
import {
  VP_ATTACHMENTS_REQUEST,
  VP_ATTACHMENTS_SUCCESS,
  VP_ATTACHMENTS_FAIL,
} from "actions/Constant";
import { VP_ADDRESS_DISPATCH } from "actions/Constant";
import { VP_ADDRESS_DELETE } from "actions/Constant";
import { VP_SITE_DISPATCH } from "actions/Constant";
import { VP_SITE_DELETE } from "actions/Constant";
import { VP_BANKDETAILS_DISPATCH } from "actions/Constant";
import { VP_BANKDETAILS_DELETE } from "actions/Constant";
import { VP_ATTACHMENTS_DELETE } from "actions/Constant";
import { VP_CONTACT_DISPATCH, VP_CONTACT_DELETE } from "actions/Constant";
import { VP_CITY_SUCCESS } from "actions/Constant";
import { VP_TOKEN } from "actions/Constant";
import { VP_ADDRESS_ONCHANGE } from "actions/Constant";
import {
  VP_COMPLIANCE_REQUEST,
  VP_COMPLIANCE_SUCCESS,
  VP_COMPLIANCE_FAIL,
} from "actions/Constant";
import {
  GET_DETAILS_BY_ID_REQUEST,
  GET_DETAILS_BY_ID_SUCCESS,
  GET_DETAILS_BY_ID_FAIL,
} from "actions/Constant";
import { VP_CLEAR_ALL_STATE } from "actions/Constant";
import { VP_GETCOUNTRY_SUCCESS } from "actions/Constant";
import { VP_BRANCHDETAILS_SUCCESS } from "actions/Constant";
import { VP_GETCURRENCY_SUCCESS } from "actions/Constant";
import { VP_OTHERS_SUCCESS } from "actions/Constant";
import {
  VP_GETBANK_REQUEST,
  VP_GETBANK_SUCCESS,
  VP_GETBANK_FAIL,
} from "actions/Constant";
import {
  VP_GENERAL_SUBMIT_REQUEST,
  VP_GENERAL_SUBMIT_SUCCESS,
  VP_GENERAL_SUBMIT_FAIL,
} from "actions/Constant";
import {
  VP_COMPLIANCE_SUBMIT_REQUEST,
  VP_COMPLIANCE_SUBMIT_SUCCESS,
  VP_COMPLIANCE_SUBMIT_FAIL,
} from "actions/Constant";
import {
  VP_OTHERS_SUBMIT_REQUEST,
  VP_OTHERS_SUBMIT_SUCCESS,
  VP_OTHERS_SUBMIT_FAIL,
} from "actions/Constant";
import {
  VP_ATTACHMENTS_SUBMIT_REQUEST,
  VP_ATTACHMENTS_SUBMIT_SUCCESS,
  VP_ATTACHMENTS_SUBMIT_FAIL,
} from "actions/Constant";
import {
  VP_BANK_DETAILS_SUBMIT_SUCCESS,
  VP_BANK_DETAILS_SUBMIT_REQUEST,
  VP_BANK_DETAILS_SUBMIT_FAIL,
} from "actions/Constant";
import {
  SUPPLIER_TYPE_REQUEST,
  SUPPLIER_TYPE_SUCCESS,
  SUPPLIER_TYPE_FAIL,
} from "actions/Constant";
import {
  ORGANIZATION_TYPE_REQUEST,
  ORGANIZATION_TYPE_SUCCESS,
  ORGANIZATION_TYPE_FAIL,
} from "actions/Constant";
import { ATTACHMENT_TYPE_SUCCESS } from "actions/Constant";

const initialState = {
  //General Information
  generalinfo: {
    vendorName: "",
    email: "",
    contactNumber: "",
    profileImage: "",
    procurement: "SALIC BU",
    BusinessRelationshipCode: "SPEND_AUTHORIZED",
    TaxOrganizationTypeCode: "CORPORATION",
    BusinessRelationship: "Spend Authorized",
    TaxOrganizationType: "Corporation",
    SupplierType: "Supplier",
    SupplierTypeCode: "SUPPLIER",
    taxPayerIdcrNum: "",
    taxRegistrationNum: "",
    taxRegistratioCountry: "Saudi Arabia",
    taxRegistratioCountryCode: "SA",
    generalInformation: "",
    type_of_organization_code: "",
    type_of_organization: "",
    other_type: "",
    productAndServices: "",
    other_SupplierType: "",
  },

  // Contact information
  contacts: {
    values: {
      firstName: "",
      lastName: "",
      email: "",
    },
    saved_contacts: [],
  },

  // Addresses Information
  address: {
    values: {
      addressLine1: "",
      addressName: "",
      addressName: "",
      city: "",
      cityCode: "",
      country: "Saudi Arabia",
      countryCode: "SA",
      ordering: true,
      remitTo: true,
    },
    saved_address: [],
  },

  // Site Information
  site: {
    values: {
      SupplierSite: "",
      procurementBU: "SALIC BU",
      SupplierAddressName: "",
      purchasing: true,
      pay: true,
    },
    saved_site: [],
  },

  // Attachments
  attachments: {
    saved_attachments: [],
    values: {},
  },

  // Bank Details
  bankdetails: {
    values: {
      country: "Saudi Arabia",
      countryCode: "SA",
      CountryName: "",
      bankName: "",
      branchName: "",
      BranchPartyId: "",
      BankPartyId: "",
      BankPartyNumber: "",
      accountNumber: "",
      iBAN: "",
      currency: "Saudi Riyal",
      currencyCode: "SAR",
      PrimaryOwnerIndicator: false,
      others: "",
      branchothers: "",
      BankAccountId: "",
      PaymentInstrumentAssignmentId: "",
    },
    saved_bankdetails: [],
  },

  //Complaince Detaisl
  compliance: {
    resources: "",
    qualityManagement: false,
    subContractors: false,
    QMSystem: "",
    healthAndSafety: "",
    environmentAndSustainability: "",
    staffNumber: "",
  },

  //Others
  others: {
    financialIssues: "",
    insuranceAndWarrenties: "",
    experienceOrReference: "",
    disputes: "",
  },

  //Lists
  supplierList: [],
  organizationList: [],
  BranchList: [],
  city: [],
  userloading: false,

  //workflow
  workflow: {},

  supplierStatus: {},
};

export function VendorRegister(state = initialState, action, event) {
  switch (action.type) {
    case VP_GENERAL_REQUEST:
      return { ...state };
    case VP_GENERAL_SUCCESS:
      return {
        ...state,
        generalinfo: {
          ...state.generalinfo,
          [action.payload.name]: action.payload.value,
        },
      };

    case VP_CONTACT_REQUEST:
      return { ...state };
    case VP_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          values: {
            ...state.contacts.values,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case VP_CONTACT_EDIT:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          values: {
            firstName: action.payload.row.firstName,
            lastName: action.payload.row.lastName,
            email: action.payload.row.email,
          },
        },
      };
    case VP_CONTACT_DISPATCH:
      let new_conatct_detail = state.contacts.values || {};
      let savedContacts = state.contacts.saved_contacts;
      if (action.payload.editIndex !== null) {
        savedContacts[action.payload.editIndex] = new_conatct_detail;
      } else {
        savedContacts = [...state.contacts.saved_contacts, new_conatct_detail];
      }
      return {
        ...state,
        contacts: {
          ...state.contacts,
          saved_contacts: savedContacts,
          values: {
            firstName: "",
            lastName: "",
            email: "",
          },
        },
      };
    case VP_CONTACT_DELETE:
      let conatct_detail = state.contacts.saved_contacts;
      let contacts = conatct_detail.filter(
        (a, index) => index !== action.payload
      );
      return {
        ...state,
        contacts: {
          ...state.contacts,
          saved_contacts: contacts,
        },
      };

    case VP_ADDRESS_REQUEST:
      return { ...state };
    case VP_ADDRESS_SUCCESS:
      return {
        ...state,
        address: {
          ...state.address,
          values: {
            ...state.address.values,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case VP_ADDRESS_CLEAR:
      return {
        ...state,
        address: {
          ...state.address,
          values: {
            addressLine1: "",
            addressName: "",
            addressName: "",
            city: "",
            cityCode: "",
            country: "Saudi Arabia",
            countryCode: "SA",
            ordering: true,
            remitTo: true,
          },
        },
      };

    case VP_ADDRESS_EDIT:
      return {
        ...state,
        address: {
          ...state.address,
          values: {
            addressLine1: action.payload.row.addressLine1,
            addressName: action.payload.row.addressName,
            city: action.payload.row.city,
            cityCode: action.payload.row.cityCode,
            country: action.payload.row.country,
            countryCode: action.payload.row.countryCode,
            ordering: action.payload.row.ordering,
            remitTo: action.payload.row.remitTo,
          },
        },
      };
    case VP_ADDRESS_DISPATCH:
      let new_address_detail = state.address.values;
      let savedAddresses = state.address.saved_address;
      if (action.payload.editIndex !== null) {
        savedAddresses[action.payload.editIndex] = new_address_detail;
      } else {
        savedAddresses = [...state.address.saved_address, new_address_detail];
      }
      return {
        ...state,
        address: {
          ...state.address,
          saved_address: savedAddresses,
          values: {
            addressLine1: "",
            addressName: "",
            addressName: "",
            city: "",
            cityCode: "",
            country: "Saudi Arabia",
            countryCode: "SA",
            ordering: true,
            remitTo: true,
          },
        },
      };
    case VP_ADDRESS_DELETE:
      let address_detail = state.address.saved_address;
      let address = address_detail.filter(
        (a, index) => index !== action.payload
      );
      return {
        ...state,
        address: {
          ...state.address,
          saved_address: address,
        },
      };

    case VP_SITE_REQUEST:
      return { ...state };
    case VP_SITE_SUCCESS:
      return {
        ...state,
        site: {
          ...state.site,
          values: {
            ...state.site.values,
            ["SupplierSite"]: action.payload.value.substring(0, 15),
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case VP_SITE_DISPATCH:
      let new_site_detail = state.site.values;
      let savedSites = state.site.saved_site;
      if (action.payload.editIndex !== null) {
        savedSites[action.payload.editIndex] = new_site_detail;
      } else {
        savedSites = [...state.site.saved_site, new_site_detail];
      }
      return {
        ...state,
        site: {
          ...state.site,
          saved_site: savedSites,
          values: {
            SupplierSite: "",
            procurementBU: "SALIC BU",
            SupplierAddressName: "",
            purchasing: true,
            pay: true,
          },
        },
      };
    case VP_SITE_DELETE:
      let site_detail = state.site.saved_site;
      let site = site_detail.filter((a, index) => index !== action.payload);
      return {
        ...state,
        site: {
          ...state.site,
          saved_site: site,
        },
      };
    case VP_ATTACHMENTS_REQUEST:
      return { ...state };
    case VP_ATTACHMENTS_SUCCESS:
      const fileData = action.payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          saved_attachments: [...state.attachments.saved_attachments, fileData],
        },
      };
    case VP_ATTACHMENTS_DELETE:
      let attachments_detail = state.attachments.saved_attachments;
      let attach = attachments_detail.filter(
        (a, index) => index !== action.payload
      );
      return {
        ...state,
        attachments: {
          ...state.attachments,
          saved_attachments: attach,
        },
      };
    case VP_BANKDETAILS_REQUEST:
      return { ...state };
    case VP_BANKDETAILS_SUCCESS:
      return {
        ...state,
        bankdetails: {
          ...state.bankdetails,
          values: {
            ...state.bankdetails.values,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    case VP_BANKDETAILS_CLEAR:
      return {
        ...state,
        bankdetails: {
          ...state.bankdetails,
          values: {
            country: "Saudi Arabia",
            countryCode: "SA",
            CountryName: "",
            bankName: "",
            branchName: "",
            BranchPartyId: "",
            BankPartyId: "",
            BankPartyNumber: "",
            accountNumber: "",
            iBAN: "",
            currency: "Saudi Riyal",
            currencyCode: "SAR",
            PrimaryOwnerIndicator: false,
            others: "",
            branchothers: "",
          },
        },
      };

    case VP_BANKDETAILS_EDIT:
      return {
        ...state,
        bankdetails: {
          ...state.bankdetails,
          values: {
            country: action.payload.row.country,
            countryCode: action.payload.row.countryCode,
            CountryName: action.payload.row.CountryName,
            bankName: action.payload.row.bankName,
            branchName: action.payload.row.branchName,
            BranchPartyId: action.payload.row.BranchPartyId,
            BankPartyId: action.payload.row.BankPartyId,
            BankPartyNumber: action.payload.row.BankPartyNumber,
            accountNumber: action.payload.row.accountNumber,
            iBAN: action.payload.row.iBAN,
            currency: action.payload.row.currency,
            currencyCode: action.payload.row.currencyCode,
            PrimaryOwnerIndicator: action.payload.row.PrimaryOwnerIndicator,
            others: action.payload.row?.others || "",
            branchothers: action.payload.row?.branchothers || "",
          },
        },
      };

    case VP_BANKDETAILS_DISPATCH:
      let new_bankdetail_detail = state.bankdetails.values;
      let savedBanks = state.bankdetails.saved_bankdetails;
      if (action.payload.editIndex !== null) {
        savedBanks[action.payload.editIndex] = new_bankdetail_detail;
      } else {
        savedBanks = [
          ...state.bankdetails.saved_bankdetails,
          new_bankdetail_detail,
        ];
      }
      let filter_Bankdetails = {
        BankPartyId: new_bankdetail_detail.BankPartyId,
        BankPartyNumber: new_bankdetail_detail.BankPartyNumber,
        BranchPartyId: new_bankdetail_detail.BankPartyId,
        accountNumber: new_bankdetail_detail.accountNumber,
        // bankName:
        //   new_bankdetail_detail.others || new_bankdetail_detail.bankName,
        bankName:
          new_bankdetail_detail.BankPartyId == "0"
            ? new_bankdetail_detail.others
            : new_bankdetail_detail.bankName,
        // branchName:
        //   new_bankdetail_detail.branchothers ||
        //   new_bankdetail_detail.branchName,
        branchName:
          new_bankdetail_detail.BankPartyId == "0"
            ? new_bankdetail_detail.branchothers
            : new_bankdetail_detail.branchName,
        country: new_bankdetail_detail.country,
        CountryName: new_bankdetail_detail.CountryName,
        countryCode: new_bankdetail_detail.countryCode,
        currency: new_bankdetail_detail.currency,
        PrimaryOwnerIndicator: new_bankdetail_detail.PrimaryOwnerIndicator,
        currencyCode: new_bankdetail_detail.currencyCode,
        iBAN: new_bankdetail_detail.iBAN,
      };
      return {
        ...state,
        bankdetails: {
          ...state.bankdetails,
          saved_bankdetails: savedBanks,
          filter_Bankdetails,
          values: {
            country: "Saudi Arabia",
            countryCode: "SA",
            CountryName: "",
            bankName: "",
            branchName: "",
            BranchPartyId: "",
            BankPartyId: "",
            BankPartyNumber: "",
            accountNumber: "",
            iBAN: "",
            currency: "Saudi Riyal",
            currencyCode: "SAR",
            PrimaryOwnerIndicator: false,
            others: "",
            branchothers: "",
          },
        },
      };
    case VP_BANKDETAILS_DELETE:
      let bank_detail = state.bankdetails.saved_bankdetails;
      let detail = bank_detail.filter((a, index) => index !== action.payload);
      return {
        ...state,
        bankdetails: {
          ...state.bankdetails,
          saved_bankdetails: detail,
        },
        values: {},
      };

    case VP_COMPLIANCE_SUCCESS:
      return {
        ...state,
        compliance: {
          ...state.compliance,
          [action.payload.name]: action.payload.value,
        },
      };
    case VP_OTHERS_SUCCESS:
      return {
        ...state,
        others: {
          ...state.others,
          [action.payload.name]: action.payload.value,
        },
      };
    //   case GET_DETAILS_BY_ID_REQUEST:
    //         return { userloading: true}

    case GET_DETAILS_BY_ID_SUCCESS:
      let { level1, level2, level3, workFlowStatus } = action.payload;
      return {
        ...state,
        generalinfo: {
          ...state.generalinfo,
          vendorName: level1?.Supplier || "",
          email: level1?.email || "",
          contactNumber: level1?.contactNumber || "",
          profileImage: level1?.logoUrl || "",
          procurement: level1?.procurement || "SALIC BU",
          BusinessRelationshipCode: level1?.BusinessRelationshipCode || "",
          TaxOrganizationTypeCode: level1?.TaxOrganizationTypeCode || "",
          BusinessRelationship: level1?.BusinessRelationship || "",
          TaxOrganizationType: level1?.TaxOrganizationType || "",
          SupplierTypeCode: level1?.SupplierTypeCode || "",
          SupplierType: level1?.SupplierType || "",
          taxPayerIdcrNum: level1?.taxPayerIdcrNum || "",
          taxRegistrationNum: level1?.taxRegistrationNum || "",
          taxRegistratioCountry: level1?.taxRegistratioCountry || "",
          taxRegistratioCountryCode: level1?.taxRegistratioCountryCode || "",
          generalInformation: level1?.generalInformation || "",
          type_of_organization: level1?.type_of_organization || "",
          type_of_organization_code: level1?.type_of_organization_code || "",
          other_type: level1?.other_type || "",
          productAndServices: level1?.productAndServices || "",
          other_SupplierType: level1?.other_SupplierType || "",
        },
        contacts: {
          ...state.contacts,
          saved_contacts: level1?.contacts || [],
        },
        address: {
          ...state.address,
          saved_address: level1?.address || [],
        },
        site: {
          ...state.site,
          saved_site: level1?.supplierSites || [],
        },
        compliance: {
          ...state.compliance,
          resources: level3?.compliance?.resources || "",
          qualityManagement: level3?.compliance?.qualityManagement,
          subContractors: level3?.compliance?.subContractors,
          QMSystem: level3?.compliance?.QMSystem || "",
          healthAndSafety: level3?.compliance?.healthAndSafety || "",
          environmentAndSustainability:
            level3?.compliance?.environmentAndSustainability || "",
          staffNumber: level3?.compliance?.staffNumber || "",
        },
        others: {
          ...state.others,
          financialIssues: level3?.others?.financialIssues || "",
          insuranceAndWarrenties: level3?.others?.insuranceAndWarrenties || "",
          experienceOrReference: level3?.others?.experienceOrReference || "",
          disputes: level3?.others?.disputes || "",
        },
        attachments: {
          ...state.attachments,
          saved_attachments: level2?.attachments || [],
        },
        bankdetails: {
          ...state.bankdetails,
          BankAccountId: level3?.bankDetails?.BankAccountId || "",
          PaymentInstrumentAssignmentId:
            level3?.bankDetails?.PaymentInstrumentAssignmentId || "",
          saved_bankdetails: level3?.bankDetails || [],
        },
        workflow: {
          workFlowStatus,
        },
        supplierStatus: { status: level1.supplierStatus },
      };
    case VP_GETCOUNTRY_SUCCESS:
      let countryList = action.payload;
      let removed_country = ["Israel", "Iran, Islamic Republic of"];
      let filter_country = countryList.filter(
        (con) => !removed_country.includes(con.TerritoryShortName)
      );
      return { ...state, countryList: filter_country };

    case VP_CITY_SUCCESS:
      let cities = action.payload;
      let addedfield;
      // add cityCode Feild to array
      if (cities?.length > 0) {
        addedfield = cities?.map((obj) => ({ ...obj, cityCode: obj?.name }));
      }
      return { ...state, city: addedfield };

    case VP_GETBANK_SUCCESS:
      return { ...state, BranchList: [], bankList: action.payload };

    case VP_BRANCHDETAILS_SUCCESS:
      return { ...state, BranchList: action.payload };

    case VP_GETCURRENCY_SUCCESS:
      return { ...state, currencyList: action.payload };

    case SUPPLIER_TYPE_SUCCESS:
      return { ...state, supplierList: action.payload };

    case ORGANIZATION_TYPE_SUCCESS:
      return { ...state, organizationList: action.payload };

    case ATTACHMENT_TYPE_SUCCESS:
      return { ...state, attachmentList: action.payload };

    case VP_CLEAR_ALL_STATE:
      return initialState;

    case VP_GENERAL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
}
const returnState = {
  isError: false,
  inProcess: false,
  isSuccess: false,
  isEdit: false,
  generalSuccess: false,
  lastStep: false,
  errorMessage: "",
  successMessage: "",
  generalInfo: {},
  attachments: {},
  bankDetails: {},
  compliance: {},
  others: {},
  VpToken: null,
};
export function SubmitVendorRegister(state = returnState, action) {
  switch (action.type) {
    case VP_GENERAL_SUBMIT_REQUEST:
      return {
        ...state,
        inProcess: true,
        isError: false,
        isSuccess: false,
        generalSuccess: false,
      };
    case VP_TOKEN:
      return {
        ...state,
        VpToken: action.payload,
      };
    case VP_GENERAL_SUBMIT_SUCCESS:
      return {
        ...state,
        inProcess: false,
        isSuccess: true,
        isEdit: true,
        generalSuccess: true,
        loading: false,
        generalInfo: action.payload,
      };
    case VP_GENERAL_SUBMIT_FAIL:
      return {
        ...state,
        inProcess: false,
        isError: true,
        errorMessage: action.payload,
      };
    case VP_ATTACHMENTS_SUBMIT_REQUEST:
      return {
        ...state,
        inProcess: true,
        isError: false,
        isSuccess: false,
      };
    case VP_ATTACHMENTS_SUBMIT_SUCCESS:
      return {
        ...state,
        inProcess: false,
        isSuccess: true,
        loading: false,
        attachments: action.payload,
      };
    case VP_ATTACHMENTS_SUBMIT_FAIL:
      return {
        ...state,
        inProcess: false,
        isError: true,
        errorMessage: action.payload,
      };
    case VP_BANK_DETAILS_SUBMIT_REQUEST:
      return {
        ...state,
        inProcess: true,
        isError: false,
        isSuccess: false,
        lastStep: false,
      };
    case VP_BANK_DETAILS_SUBMIT_SUCCESS:
      return {
        ...state,
        inProcess: false,
        isSuccess: true,
        lastStep: true,
        loading: false,
        bankDetails: action.payload,
      };
    case VP_BANK_DETAILS_SUBMIT_FAIL:
      return {
        ...state,
        inProcess: false,
        isError: true,
        errorMessage: action.payload,
      };
    case VP_COMPLIANCE_SUBMIT_REQUEST:
      return {
        ...state,
        inProcess: true,
        isError: false,
        isSuccess: false,
        generalSuccess: false,
      };
    case VP_COMPLIANCE_SUBMIT_SUCCESS:
      return {
        ...state,
        inProcess: false,
        isSuccess: true,
        generalSuccess: false,
        loading: false,
        compliance: action.payload,
      };
    case VP_COMPLIANCE_SUBMIT_FAIL:
      return {
        ...state,
        inProcess: false,
        isError: true,
        errorMessage: action.payload,
      };
    case VP_OTHERS_SUBMIT_REQUEST:
      return {
        ...state,
        inProcess: true,
        isError: false,
        isSuccess: false,
      };
    case VP_OTHERS_SUBMIT_SUCCESS:
      return {
        ...state,
        inProcess: false,
        isSuccess: true,
        loading: false,
        others: action.payload,
      };
    case VP_OTHERS_SUBMIT_FAIL:
      return {
        ...state,
        inProcess: false,
        isError: true,
        errorMessage: action.payload,
      };
    case VP_CLEAR_ALL_STATE:
      return returnState;
    default:
      return state;
  }
}
