import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import SupplierPagination from "./SupplierPaginationList";
import SyncSupplier from "views/Components/SupplierView/Childs/SyncSupplier";

// @material-ui/core components
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  MenuItem,
  IconButton,
  Typography,
  Fade,
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import { formatDateTime } from "Functions";
import { addZeroes } from "Functions";
import { indexOf } from "lodash";
import { msgAlert } from "Functions";
import Filters from "views/Components/SupplierView/Childs/Filters";
import SupplierList from "actions/SupplierListAction";
import clearAllState from "actions/VendorProfile";
import { Autocomplete } from "@mui/material";
import { SupplierFilter } from "actions/SupplierListAction";
import { saveAs } from "file-saver";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SupplierListView() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const [supplier, setSupplier] = React.useState([]);
  const [decoded, setDecoded] = React.useState(null);
  const [view, setView] = React.useState("grid");
  const [open, setOpen] = React.useState(false);
  const [isFilterBox, setIsFilterBox] = React.useState(false);
  const [newdata, setnewdata] = React.useState();
  const [classicModal, setClassicModal] = React.useState(false);
  const [supplierStatus, setSupplierStatus] = React.useState();
  const [filterStatus, setFilterStatus] = React.useState();
  const [dates, setDates] = React.useState({
    from: "",
    to: "",
  });
  const [vendorLoading, setVendorLoading] = React.useState(false);

  const [formState, setFormState] = React.useState({
    selectedPurchaseOrder: "",
    poInputValue: "",
    supplierInputValue: "",
    vendors: [],
    selectedVendor: "",
    newData: "",
    values: {
      status: "",
      comments: "",
    },
    errors: {
      status: "",
      reviewComments: "",
    },
  });
  const dispatch = useDispatch();
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const supplierData = useSelector((state) => state.suppliers.supplierList);
  let userDetail = jwt.decode(Token);

  React.useEffect(() => {
    userDetail = jwt.decode(Token);
    setDecoded(userDetail);
    Supplierr();
  }, []);

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  const status = getParameterByName("pending");

  const Supplierr = (compId) => {
    setIsLoading(true);
    setSupplier();
    setIsLoading(false);
  };
  const syncSupplier = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_LDOCS_API_URL}/vendor/synchronousSuppliers`,
        {
          headers: {
            cooljwt: Token,
          },
        }
      )
      .then((res) => {
        msgAlert(
          "Sync is in progress. It may take some time, you can continue using system."
        );
      });
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 190,
    bgcolor: "#fff",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  React.useEffect(() => {
    if (status) {
      setFilterStatus("PENDING");
      let dispatch_payload = {
        Status: "PENDING",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: dates.from,
        endDate: dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
    _getSuppliers();
  }, []);

  const handleFilter = (event) => {
    if (event.target.name === "from" || event.target.name === "to") {
      if (!event || !event.target || !event.target.name) {
        // Handle the case where event or event.target is null
        return;
      }
      const { name, value } = event.target;
      setDates((dates) => ({
        ...dates,
        [name]: value,
      }));
      let dispatch_payload = {
        Status: filterStatus || "ALL",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: name === "from" ? value : dates.from,
        endDate: name === "to" ? value : dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
      return;
    }
    if (event.target.value == "ALL") {
      setFilterStatus("ALL");
      let dispatch_payload = {
        Status: "ALL",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: dates.from,
        endDate: dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
    if (event.target.value == "APPROVED") {
      setFilterStatus("APPROVED");
      let dispatch_payload = {
        Status: "APPROVED",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: dates.from,
        endDate: dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
    if (event.target.value == "INCOMPLETE") {
      setFilterStatus("INCOMPLETE");
      let dispatch_payload = {
        Status: "INCOMPLETE",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: dates.from,
        endDate: dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
    if (event.target.value == "PENDING") {
      setFilterStatus("PENDING");
      let dispatch_payload = {
        Status: "PENDING",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: dates.from,
        endDate: dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
    if (event.target.value == "APPROVED AND EXPORTED") {
      setFilterStatus("APPROVED AND EXPORTED");
      let dispatch_payload = {
        Status: "APPROVED AND EXPORTED",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: dates.from,
        endDate: dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
    if (event.target.value == "CORRECTION REQUIRED") {
      setFilterStatus("CORRECTION REQUIRED");
      let dispatch_payload = {
        Status: "CORRECTION REQUIRED",
        page: 0,
        RowsPerPage: 10,
        token: Token,
        checkStatus: false,
        startDate: dates.from,
        endDate: dates.to,
      };
      dispatch(SupplierList(dispatch_payload));
    }
  };

  const refresh = () => {
    let dispatch_payload = {
      Status: "ALL",
      page: 0,
      RowsPerPage: 10,
      token: Token,
    };
    dispatch(SupplierList(dispatch_payload));
  };

  const clearFilter = () => {
    setFormState((formState) => ({
      ...formState,
      selectedVendor: "",
    }));
    setDates({
      from: "",
      to: "",
    });
    setFilterStatus("");
    let dispatch_payload = {
      Status: "ALL",
      page: 0,
      RowsPerPage: 10,
      token: Token,
    };
    dispatch(SupplierList(dispatch_payload));
  };

  // const _getSuppliers = async () => {
  //   await axios({
  //     method: "get",
  //     url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendorsAll`,
  //     headers: { cooljwt: Token },
  //   })
  //     .then((res) => {
  //       setFormState((formState) => ({
  //         ...formState,
  //         vendors: res?.data?.result,
  //       }));
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         error.response.status == 401 && dispatch(setIsTokenExpired(true));
  //       }
  //     });
  // };

  const _getSuppliers = async (value) => {
    if (value) {
      setVendorLoading(true);
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendors?params=${value}`,
        headers: { cooljwt: Token },
      })
        .then((res) => {
          setFormState((formState) => ({
            ...formState,
            vendors: res?.data?.result,
          }));
          setVendorLoading(false);
        })
        .catch((error) => {
          setVendorLoading(false);
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
        });
    }
  };

  const handleExport = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/report/exportSuppliers?startDate=${dates.from}&endDate=${dates.to}&supplierStatus=${filterStatus || "ALL"}`,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        saveAs(process.env.REACT_APP_LDOCS_API_URL + "/" + res?.data?.path);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
      });
  };

  return (
    <>
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={view == "grid" ? true : false}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Filter</h4>
                </CardIcon>
                <Button
                  color="info"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={() => handleExport()}
                >
                  Export
                </Button>
                <Button
                  color="info"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={() => setIsFilterBox(true)}
                >
                  Advance Filters
                </Button>
                <Button
                  color="info"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={clearFilter}
                >
                  Clear Filters
                </Button>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    {isFilterBox ? (
                      <Filters
                        isOpen={isFilterBox}
                        SetisOpen={setIsFilterBox}
                        setnewdata={setnewdata}
                      />
                    ) : (
                      ""
                    )}
                    {!isFilterBox ? (
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        label="Status"
                        name="Status"
                        onChange={(event) => {
                          handleFilter(event);
                        }}
                        select
                        value={filterStatus || ""}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Status
                        </MenuItem>
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="APPROVED">Approved</MenuItem>
                        <MenuItem value="CORRECTION REQUIRED">
                          Correction Required
                        </MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="INCOMPLETE">Incomplete</MenuItem>
                        <MenuItem value="APPROVED AND EXPORTED">
                          Approved & Exported
                        </MenuItem>
                      </TextField>
                    ) : (
                      ""
                    )}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <Autocomplete
                      id="filter-demo"
                      options={formState.vendors || []}
                      inputValue={formState.supplierInputValue || ""}
                      onInputChange={(event, newInputValue) => {
                        _getSuppliers(newInputValue);
                        setFormState((formState) => ({
                          ...formState,
                          supplierInputValue: newInputValue,
                        }));
                      }}
                      value={formState.selectedVendor || ""}
                      getOptionLabel={(option) =>
                        option?.level1?.Supplier || ""
                      }
                      onChange={(event, newValue) => {
                        setFormState((formState) => ({
                          ...formState,
                          selectedVendor: newValue,
                        }));
                        if (newValue?.level1?.Supplier) {
                          //Dispatch Payload
                          let dispatch_payload = {
                            page: 0,
                            rowPerPage: 10,
                            token: Token,
                            // newdata: { data: { Supplier: newValue?.level1?.Supplier } }
                            newdata: {
                              data: {
                                "level1.Supplier": newValue?.level1?.Supplier,
                              },
                            },
                          };
                          dispatch(SupplierFilter(dispatch_payload));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Supplier"}
                          style={{ marginTop: "4px" }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {vendorLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <TextField
                      className={classes.textField}
                      fullWidth={true}
                      label="From Date"
                      name="from"
                      onChange={(event) => {
                        handleFilter(event);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      value={dates.from || ""}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <TextField
                      className={classes.textField}
                      fullWidth={true}
                      label="To Date"
                      name="to"
                      onChange={(event) => {
                        handleFilter(event);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      value={dates.to || ""}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Supplier List</h4>
                </CardIcon>
                {decoded?.role?.isAdmin ? (
                  <>
                    <Link to="/admin/vendor_Register">
                      <Button
                        color="danger"
                        round
                        style={{ float: "right" }}
                        className={classes.marginRight}
                        // onClick={syncSupplier}
                      >
                        Add Supplier
                      </Button>
                    </Link>
                    <Button
                      color="danger"
                      round
                      style={{ float: "right" }}
                      className={classes.marginRight}
                      onClick={syncSupplier}
                    >
                      Sync
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  style={{ float: "right" }}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button onClick={refresh} simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <SupplierPagination
                    isFilterEnable={isFilterBox}
                    isStatus={status}
                    newdata={newdata}
                    supplierStatus={supplierStatus}
                    filterStatus={filterStatus}
                    filterDate={dates}
                    setFilterStatus={setFilterStatus}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Animated>
      {classicModal == true ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"sm"}
          open={classicModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setClassicModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <Card>
              <CardHeader>
                <CardIcon color="info">
                  <h4 className={classes.cardTitle} style={{ color: "#fff" }}>
                    Title
                  </h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <FormControlLabel
                  labelPlacement="bottom"
                  control={
                    <Checkbox
                      // checked={formState.values.Active}
                      // onChange={()=>{
                      //   setFormState((formState) => ({
                      //     ...formState,
                      //     values: {
                      //       ...formState.values,
                      //       Active: !formState.values.Active,
                      //     },
                      //   }));
                      // }}
                      name="Active"
                      color="primary"
                    />
                  }
                  label="Active"
                />
                <FormControlLabel
                  labelPlacement="bottom"
                  control={
                    <Checkbox
                      // checked={formState.values.Active}
                      // onChange={()=>{
                      //   setFormState((formState) => ({
                      //     ...formState,
                      //     values: {
                      //       ...formState.values,
                      //       Active: !formState.values.Active,
                      //     },
                      //   }));
                      // }}
                      name="InActive"
                      color="primary"
                    />
                  }
                  label="In-Active"
                />
              </CardBody>
            </Card>
            <Button
              color="info"
              className={classes.registerButton}
              style={{ float: "right" }}
              // onClick={updateTitle}
              round
            >
              Update
            </Button>
            <Button
              color="danger"
              className={classes.registerButton}
              onClick={() => setClassicModal(false)}
              style={{ float: "right" }}
              round
            >
              Close
            </Button>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}
