import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles, LinearProgress, Typography, Box, IconButton, Chip, Tooltip, } from "@material-ui/core";
import Button from 'components/CustomButtons/Button.js'
import Icon from "@material-ui/core/Icon";
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import StorageIcon from '@material-ui/icons/Storage';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MapIcon from '@material-ui/icons/Map';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import CardText from "components/Card/CardText.js";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Language from "@material-ui/icons/Language";
import * as am4core from "@amcharts/amcharts4/core";
import BarChartIcon from '@material-ui/icons/BarChart';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import dateFormat from "dateformat";
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RoomIcon from '@material-ui/icons/Room';
import ViewListIcon from '@material-ui/icons/ViewList';
import { formatDateTime, addZeroes, currentTracking, msgAlert } from "../Functions/Functions";
import PieChartView from "./PieChart";
import LineChart from "./LineChart";
import Calendar from "../../Calendar/Calendar";
import jwt, { decode } from "jsonwebtoken";
import _ from 'lodash';
import { useSelector, useDispatch } from "react-redux";
import { setIsTokenExpired } from "actions";
import Map from "./Map";
import { useHistory } from "react-router-dom";
import { formatDate } from "Functions";
import { filterInvoice, readyToPayInv, paidInv } from "actions";
import TrackingStatus from "./TrackingStatus";

const useStyles = makeStyles(styles);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="80%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function Dashboard() {

  const [openTrackingModal, setOpenTrackingModal] = React.useState(false)
  const [trackingLoading, setTrackingLoading] = React.useState(false)
  const [initialReview, setInitialReview] = React.useState('')
  const [review, setReview] = React.useState('')
  const [approve, setApprove] = React.useState('')
  const [approveandexported, setApproveAndExported] = React.useState('')
  const [canceled, setCanceled] = React.useState('')

  const Token = useSelector(state => state.userReducer.Token) || localStorage.getItem('cooljwt');
  let permissions = useSelector(state => state.userReducer.permissions);
  const history = useHistory();
  const isAr =
    history.location.pathname.substring(history.location.pathname.lastIndexOf("/") + 1) == "ar"
      ? true
      : false;
  let decoded = jwt.decode(Token);
  const classes = useStyles();
  const pendingApprovalIcon = require("assets/img/pendingApproval.png");
  const dispatch = useDispatch();

  const getStats = () => {
    getDashboardData();
    getFilesHistory();
    getCountReviewChart();
    getCountApproveChart();
    getGraphData();
  }


  // React.useEffect(() => {
  //   getStats();
  // }, []);

  React.useEffect(() => {
    getStats();
  }, [isAr]);

  const [statistics, setStatistics] = React.useState({
    totalInvoice: 0,
    openInvoices: 0,
    closedInvoices: 0,
    pendingInvoices: 0,
    pendingSuppliers: 0,
    pendingReceipt: 0,
    totalInvoiceCount: 0,
    InReviewInvoice: 0,
    InApproveInvoice: 0,
    invoicesCount: 0

  });
  const [graphData, setGraphData] = React.useState([]);

  const { ap, ar } = permissions || {}
  let { actionDesk, financeDesk, others, invoiceDesk } = ap || {}

  const isAllRouteDisabled = rts => {
    if (typeof rts == 'object') {
      let all = []
      Object.keys(rts).map(item => {
        if (
          item != 'name' &&
          item != 'enable' &&
          typeof rts[item] == 'object'
        ) {
          all.push(rts[item]?.enable ? true : false)
        }
      })
      return all.every(item => item == false)
    }
  }
  let isInvoiceDeskDisable = isAllRouteDisabled(invoiceDesk);
  let isActionDeskDisable = isAllRouteDisabled(actionDesk);
  let isfinanceDeskDisable = isAllRouteDisabled(financeDesk);



  const getGraphData = async () => {
    decoded = jwt.decode(Token);
    if (!decoded?.isVendor) {
      var data = {
        tenantId: decoded?.tenantId,
        Date: new Date(),
        organizationId: decoded?.isTenant ? null : decoded?.orgDetail?.organizationId,
        isRequester: isInvoiceDeskDisable == false ? true : false,
        isActionDesk: isActionDeskDisable == false ? true : false,

      }
      await axios({
        method: "post",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getBarMapDataTent` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getBarMapDataOrgAR` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getBarMapDataOrg`,
        data: data,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          setGraphData(response.data);
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          setGraphData([]);
          console.log(error);
        });
    }
  }
  const [loadingStats, setLoadingStats] = React.useState(true);
  const getDashboardData = async () => {
    let stats = {
      totalInvoice: 0,
      openInvoices: 0,
      closedInvoices: 0,
      pendingInvoices: 0,
      pendingSuppliers: 0,
      pendingReceipt: 0,
      totalInvoiceCount: 0,
      InReviewInvoice: 0,
      InApproveInvoice: 0,
      invoicesCount: 0


    }
    setLoadingStats(true);
    //Open Invoices
    if (!decoded?.isVendor) {
      await axios({
        method: "get",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/tenantOpenInvoice` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/openInvoiceAR/${decoded?.orgDetail?.organizationId}` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/openInvoice/${decoded?.orgDetail?.organizationId}`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          stats.openInvoices = response.data.openInvoice
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          stats.openInvoices = 0
        });
      //Close Invoices
      await axios({
        method: "get",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/tenantCloseInvoice` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/closeInvoiceAR/${decoded?.orgDetail?.organizationId}` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/closeInvoice/${decoded?.orgDetail?.organizationId}`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          stats.closedInvoices = response.data.totalClosedInvoice
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          stats.closedInvoices = 0;
        });
      //Total Invoices
      await axios({
        method: "get",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/tenantTotalInvoices` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/totalInvoicesAR/${decoded?.orgDetail?.organizationId}` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/totalInvoices/${decoded?.orgDetail?.organizationId}`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          stats.totalInvoice = response.data.totalInvoice;
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          stats.totalInvoice = 0;
        });
      //Pending Invoices
      await axios({
        method: "get",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/tenantPendingInvoice` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/pendingInvoiceAR/${decoded?.orgDetail?.organizationId}` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/pendingInvoice/${decoded?.orgDetail?.organizationId}`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          stats.pendingInvoices = response.data.pendingInvoice;
          setLoadingStats(false);
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          stats.pendingInvoices = 0;
          setLoadingStats(false);
        });
      //Pending AND Approve Invoices
      await axios({
        method: "post",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getAPIFORAPProvalREviewer` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getAPIFORAPProvalREviewer` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getAPIFORAPProvalREviewer`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          stats.totalInvoiceCount = response?.data?.total;
          stats.InReviewInvoice = response?.data?.reviewCount;
          stats.InApproveInvoice = response?.data?.aproveCount;
          stats.invoicesCount = response?.data?.invoicesCount;
          setLoadingStats(false);
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          stats.pendingInvoices = 0;
          setLoadingStats(false);
        });
      //Pending Suppliers
      await axios({
        method: "post",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getApiForSuppliersPending` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getApiForSuppliersPending` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/getApiForSuppliersPending`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          stats.pendingSuppliers = response.data;
          setLoadingStats(false);
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          stats.pendingInvoices = 0;
          setLoadingStats(false);
        });
      //Pending Receipt
      await axios({
        method: "post",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard//getAPIForReceiptDashBoard` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard//getAPIForReceiptDashBoard` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard//getAPIForReceiptDashBoard`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          stats.pendingReceipt = response.data.totalNumOfItems;
          setLoadingStats(false);
        })
        .catch((error) => {
          if (error.response) { error.response.status == 401 && dispatch(setIsTokenExpired(true)) };
          stats.pendingInvoices = 0;
          setLoadingStats(false);
        });
    }
    setStatistics(stats);
  };
  // const [locationDocs, setLocationDocs] = React.useState([]);
  //   const getLocationDocs = () => {
  //     setLoadingStats(true);
  //     axios({
  //       method: "get",
  //       url: `${process.env.REACT_APP_LDOCS_API_URL}/dev/reg/getLocationDocsCount`,
  //       headers: { cooljwt: Token },
  //     })
  //       .then((response) => {
  //         if(_.isEmpty(response.data)){ setLocationDocs([]);}
  //         else {setLocationDocs(response.data);}
  //         setLoadingStats(false);
  //       })
  //       .catch((error) => {
  //         setLocationDocs([]);
  //         setLoadingStats(false);
  //       });
  //   }
  const [fileHistory, setFileHistory] = React.useState([]);
  const [loadingFiles, setLoadingFiles] = React.useState(true);
  const getFilesHistory = () => {
    if (!decoded?.isVendor) {
      let isRequester = isInvoiceDeskDisable == false ? true : false
      setLoadingFiles(true);
      axios({
        method: "get",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/tenantRecentInvoice` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/recentInvoiceAR/${decoded?.orgDetail?.organizationId}` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/recentInvoice/${decoded?.orgDetail?.organizationId}/${isRequester}`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          setFileHistory(response.data);
          setLoadingFiles(false);
        })
        .catch((error) => {
          setFileHistory([]);
          setLoadingFiles(false);
        });
    }
  };

  const [chartOneData, setChartOneData] = React.useState({});
  const [loadingChartOne, setLoadingChartOne] = React.useState(false);
  const getCountReviewChart = () => {
    if (!decoded?.isVendor) {
      setLoadingChartOne(true);
      axios({
        method: "get",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/tenantInvoiceReviewChart` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/invoiceReviewChartAR/${decoded?.orgDetail?.organizationId}` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/invoiceReviewChart/${decoded?.orgDetail?.organizationId}`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          let Pending = response.data.ByCountStatus.find(r => r._id == 'pending');
          Pending.color = am4core.color("#095392");
          let Reviewed = response.data.ByCountStatus.find(r => r._id == 'reviewed');
          Reviewed.color = am4core.color("#095392");
          let Rejected = response.data.ByCountStatus.find(r => r._id == 'rejected');
          Rejected.color = am4core.color("#095392");
          let Total = response.data.totalfilesforreview;
          var check =
          {
            ByCountStatus: [Pending, Reviewed, Rejected],
            totalfilesforreview: Total
          }
          setChartOneData(check);

          setLoadingChartOne(false);
        })
        .catch((error) => {
          setChartOneData([]);
          setLoadingChartOne(false);
        });
    }
  };
  const [chartTwoData, setChartTwoData] = React.useState({});
  const [loadingChartTwo, setLoadingChartTwo] = React.useState(false);

  const getCountApproveChart = () => {
    if (!decoded?.isVendor) {
      setLoadingChartTwo(true);
      axios({
        method: "get",
        url: decoded?.isTenant ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/tenantInvoiceApproveChart` : isAr ? `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/invoiceApproveChartAR/${decoded?.orgDetail?.organizationId}` : `${process.env.REACT_APP_LDOCS_API_URL}/dashboard/invoiceApproveChart/${decoded?.orgDetail?.organizationId}`,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          let Pending = response.data.ByCountStatus.find(r => r._id == 'pending');
          Pending.color = am4core.color("#007f5e");
          let Reviewed = response.data.ByCountStatus.find(r => r._id == 'approved');
          Reviewed.color = am4core.color("#007f5e");
          let Rejected = response.data.ByCountStatus.find(r => r._id == 'rejected');
          Rejected.color = am4core.color("#007f5e");
          let Total = response.data.totalfilesforreview;
          var check =
          {
            ByCountStatus: [Pending, Reviewed, Rejected],
            totalfilesforreview: Total
          }

          setChartTwoData(check);

          setLoadingChartTwo(false);
        })
        .catch((error) => {
          console.log(error);
          setChartTwoData([]);
          setLoadingChartTwo(false);
        });
    }
  };
  const handlePendinInv = () => {
    history.push('/default/invoices/ap')
    dispatch(filterInvoice(1))
  }

  const handleReadyToPayInv = () => {
    history.push('/default/export/ap')
    dispatch(readyToPayInv('paymentInProcessCount'))
  }

  const handlePaidInv = () => {
    history.push('/default/export/ap')
    dispatch(paidInv('totalInvCount'))
  }
  let userDetail = jwt.decode(localStorage.getItem('cooljwt'))
  let isVendor = userDetail?.isVendor

  const _trackingStatus = trackingStatus => {
    if (trackingStatus) {
      setOpenTrackingModal(true)
      // setTrackingLoading(true)
      setInitialReview(trackingStatus?.initialReview)
      setReview(trackingStatus?.underReview)
      setApprove(trackingStatus?.underApprove)
      setApproveAndExported(trackingStatus?.paymentInProcess)
      setCanceled(trackingStatus?.paid)
    } else {
      setOpenTrackingModal(false)
      setTrackingLoading(false)
      msgAlert('Tracking Status not Found')
    }
  }
  const closeTrackingModal = () => {
    setOpenTrackingModal(false)
    setInitialReview('')
    setReview('')
    setApprove('')
    setApproveAndExported('')
    setCanceled('')
  }

  return (
    <div>
      <GridContainer>
        {/* FOR INVOICE DESK  */}
        {!isInvoiceDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  {/* <Store /> */}
                  <InsertDriveFileIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <Link to="/default/invoices/1/ap">
                    Pending Invoices
                  </Link></p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.invoicesCount}</h3>}
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR FINANCE DESK  */}
        {isInvoiceDeskDisable && isActionDeskDisable && !isfinanceDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  {/* <Store /> */}
                  <InsertDriveFileIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {/* <Link to="/default/invoices/1/ap"> */}
                    Pending Invoices
                  {/* </Link> */}
                  </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.invoicesCount}</h3>}
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR ADMIN INVOICES */}
        {decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  {/* <Store /> */}
                  <InsertDriveFileIcon />
                </CardIcon>
                <p className={classes.cardCategory}>

                  Pending Invoices
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.invoicesCount}</h3>}
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR ACTION DESK IN REVIEW  */}
        {isInvoiceDeskDisable && !isActionDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  {/* <Store /> */}
                  <InsertDriveFileIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <Link to="/default/my-requests/ap">
                    In-Review Invoices
                  </Link></p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.InReviewInvoice}</h3>}
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR ACTION DESK IN APPROVE  */}
        {isInvoiceDeskDisable && !isActionDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  {/* <Store /> */}
                  <InsertDriveFileIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <Link to="/default/approvals/ap">
                    In-Approve Invoices
                  </Link></p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.InApproveInvoice}</h3>}
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR ACTION DESK SUPPLIERS   */}
        {isInvoiceDeskDisable && !isActionDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <CenterFocusWeakIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <>
                    <Link to="/default/my-pending/ap">
                      Pending Suppliers
                    </Link>
                  </>
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingSuppliers}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR Requester SUPPLIERS   */}
        {!isInvoiceDeskDisable && !isActionDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <CenterFocusWeakIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <>
                    <Link to={`/default/my-pending/ap`}>
                      Pending Suppliers
                    </Link>
                  </>
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingSuppliers}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}

          {/* FOR Requester SUPPLIERS 2  */}
        {!isInvoiceDeskDisable && isActionDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <CenterFocusWeakIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <>
                    <Link to={`/default/suppliers?pending=${true}`}>
                      Pending Suppliers
                    </Link>
                  </>
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingSuppliers}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}

          {/* FOR FINANCE DESK SUPPLIERS   */}
        {isInvoiceDeskDisable && isActionDeskDisable && !isfinanceDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <CenterFocusWeakIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <>
                    {/* <Link to={`/default/suppliers?pending=${true}`}> */}
                      Pending Suppliers
                    {/* </Link> */}
                  </>
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingSuppliers}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR ADMIN SUPPLIERS   */}
        {decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <CenterFocusWeakIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <>
                    <Link to={`/admin/suppliers?pending=${true}`}>
                      Pending Suppliers
                    </Link>
                  </>
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingSuppliers}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR ADMIN RECEIPTS   */}
        {decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <CenterFocusStrongIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <Link to={`/admin/receipt?status=${true}`}>
                    Pending Receipts
                  </Link>
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingReceipt}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}

        {/* FOR INVOICE DESK RECEIPTS   */}
        {!isInvoiceDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <CenterFocusStrongIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  <Link to={`/default/receipt?status=${true}`}>
                    Pending Receipts
                  </Link>
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingReceipt}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}

          {/* FOR FINANCE DESK RECEIPTS   */}
        {isInvoiceDeskDisable && isActionDeskDisable && !isfinanceDeskDisable && !decoded?.role?.isAdmin ?
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <CenterFocusStrongIcon />
                </CardIcon>
                <p className={classes.cardCategory}>
                  {/* <Link to={`/default/receipt?status=${true}`}> */}
                    Pending Receipts
                  {/* </Link> */}
                </p>
                {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>{statistics.pendingReceipt}</h3>}
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem> : ""}





        {/* <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <img style={{
                  width: '36px',
                  height: '36px',
                  margin: '10px 10px 4px',
                  fontSize: '36px',
                  textAlign: 'center',
                  lineHeight: '56px',
                }} src={pendingApprovalIcon} />

              </CardIcon>
              <p className={classes.cardCategory}>Pending Action</p>
              {loadingStats ? <LinearProgress /> : <h3 className={classes.cardTitle}>
                {statistics.pendingInvoices}
              </h3>}

            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {decoded?.role?.isAdmin ? "" 
              :
              <>
                <Danger>
                  <WatchLaterIcon />
                </Danger>
                <Typography onClick={handlePendinInv} style={{cursor:'pointer', color:'#095392', fontSize:'0.9rem'}}>
                Action pending
                </Typography>
                </>}
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
      <GridContainer>
        {/* <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" icon>
              <CardText color="danger">
                <h4 className={classes.cardTitleWhite}>
                  Review Statistics
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [<LoyaltyIcon />, "FINANCE", "20"],
                      [<LoyaltyIcon />, "HR", "13"],
                      [<LoyaltyIcon />, "MOM", "10"],
                      [<LoyaltyIcon />, "MEMO", "18"],
                      [<LoyaltyIcon />, "REQUEST", "22"],
                      [<LoyaltyIcon />, "REJECTED", "5"],
                    ]}
                  />
                </GridItem>
                      {loadingChartOne ? <LinearProgress  /> : <PieChartView paddingRight={20}  chartData={chartOneData} chatID="2" />}
            </CardBody>
          </Card>
        </GridItem> */}
        {/* <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" icon>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  Approval Statistics
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                    <GridItem xs={12} sm={12} md={12}>
                    {loadingChartTwo ? <LinearProgress  /> : <PieChartView paddingRight={20} chatID="3" chartData={chartTwoData} />}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={6} lg={12}>
          <Card>
            <CardHeader color="danger" icon>
              <CardText color="danger">
                <h4 className={classes.cardTitleWhite}>
                  Invoice Statistics
                </h4>
              </CardText>
            </CardHeader>
            <CardBody style={{ height: '100%' }}>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  {loadingChartTwo ? <LinearProgress /> : <LineChart paddingRight={20} chatID="10" chartData={graphData} />}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                <MapIcon fontSize="large" />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Map />    
                    </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>  */}
      </GridContainer>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                <DateRangeIcon fontSize="large" />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Calendar />    
                    </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem> 
      </GridContainer> */}
      <h3>Last Invoices</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <ViewListIcon fontSize="large" />
                {/* <h4 className={classes.cardTitleWhite}>
                  Most Recent Invoices
                </h4> */}
              </CardIcon>
            </CardHeader>
            <CardBody>
              {/* ,file.isPettyCash == true ? file.isPettyCash : file.isPrePayment == true ? file.isPrePayment : file.isReceipt == true ? file.isReceipt : "no" */}
              {loadingFiles ? <LinearProgress /> :
                <Table
                  hover
                  tableHeaderColor="info"
                  tableHead={["Invoice Number", "Status", "Invoice Type", "Invoice Date", isAr ? "Customer Name" : "Supplier Name", "Amount"]}
                  tableData={typeof fileHistory.recentInvoices !== "undefined" && fileHistory.recentInvoices.length > 0 ? fileHistory.recentInvoices?.map((prop, index) => {
                    return [prop.originalInvoiceId,
                    prop?.trackingStatus?.received?.status == 'inProgress' ? (
                      <Tooltip title='INCOMPLETE'>
                        <Chip
                          variant='outlined'
                          size='small'
                          // avatar={<Avatar>M</Avatar>}
                          label='INCOMPLETE'
                          clickable
                          onClick={() => _trackingStatus(prop?.trackingStatus)}
                          style={{ border: 'orange 1px solid', color: 'orange' }}
                        />
                      </Tooltip>
                    ) : // if Inoice is Not Draft
                      prop?.trackingStatus?.paid?.status == 'partial' ? (
                        <Tooltip title='PARTIALLY PAID'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='PARTIALLY PAID'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            style={{
                              border: 'lightgreen 1px solid',
                              color: 'lightgreen'
                            }}
                          />
                        </Tooltip>
                      ) : prop?.trackingStatus?.paid?.status == 'completed' ? (
                        <Tooltip title='FULLY PAID'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='FULLY PAID'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            style={{ border: 'green 1px solid', color: 'green' }}
                          />
                        </Tooltip>
                      ) : prop?.trackingStatus?.paid?.status == 'Canceled' ? (
                        <Tooltip title='CANCELED'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='CANCELED'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            color='secondary'
                          />
                        </Tooltip>
                      ) : currentTracking(prop?.trackingStatus)?.status == 'readyToPay' ? (
                        <Tooltip title='READY TO PAY'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='READY TO PAY'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            style={{ border: 'orange 1px solid', color: 'orange' }}
                          />
                        </Tooltip>
                      ) : prop?.trackingStatus?.paymentInProcess?.status ==
                        'completed' ? (
                        <Tooltip title='APPROVED AND EXPORTED'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='APPROVED AND EXPORTED'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            style={{ border: 'green 1px solid', color: 'green' }}
                          />
                        </Tooltip>
                      ) : prop?.workFlowStatus == 'correctionRequired' &&
                        prop?.markedAs !== 'rejected' ? (
                        <Tooltip title='Correction Required'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='CORRECTION REQUIRED'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            style={{ border: 'orange 1px solid', color: 'orange' }}
                          />
                        </Tooltip>
                      ) : prop?.trackingStatus?.underApprove?.status == 'inProgress' ? (
                        <Tooltip title='PENDING'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='PENDING'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            color='primary'
                          />
                        </Tooltip>
                      ) : prop?.trackingStatus?.underApprove?.status == 'completed' &&
                        prop?.trackingStatus?.paymentInProcess?.status !==
                        'completed' ? (
                        <Tooltip title='APPROVED'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='APPROVED'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            style={{ border: 'green 1px solid', color: 'green' }}
                          />
                        </Tooltip>
                      ) : prop?.trackingStatus?.underReview?.status == 'inProgress' ? (
                        <Tooltip title='PENDING'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='PENDING'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            color='primary'
                          />
                        </Tooltip>
                      ) : prop?.trackingStatus?.initialReview?.status ==
                        'inProgress' ? (
                        <Tooltip title='PENDING'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='PENDING'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            color='primary'
                          />
                        </Tooltip>
                      ) : currentTracking(prop?.trackingStatus)?.status == 'rejected' ? (
                        <Tooltip title='REJECTED'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='REJECTED'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            color='secondary'
                          />
                        </Tooltip>
                      ) : currentTracking(prop?.trackingStatus)?.status == 'Canceled' ? (
                        <Tooltip title='Canceled'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='Canceled'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            color='secondary'
                          />
                        </Tooltip>
                      ) : (currentTracking(prop?.trackingStatus)?.status == 'correctionRequired' &&
                        currentTracking(prop?.trackingStatus)?.val == 1) ||
                        !isVendor && prop?.workFlowStatus == 'correctionRequired' ? (
                        <Tooltip title='SENT FOR CORRECTION'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='SENT FOR CORRECTION'
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            clickable
                            style={{ border: 'orange 1px solid', color: 'orange' }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title='PENDING'>
                          <Chip
                            variant='outlined'
                            size='small'
                            // avatar={<Avatar>M</Avatar>}
                            label='PENDING'
                            clickable
                            onClick={() => _trackingStatus(prop?.trackingStatus)}
                            color='primary'
                          />
                        </Tooltip>
                      ), prop.isPrePayment == true ? "Pre Payment" : prop.isPettyCash == true ? "Petty Cash" : prop.isReceipt == true ? "Receipt" : "", formatDate(prop.invoiceDate), isAr ? prop.clientName : prop.vendorName, `${prop.FC_currency ? prop.FC_currency.Code : '$'} ${addZeroes(prop.netAmt)} / ${prop.LC_currency ? prop.LC_currency.Code : ""} ${addZeroes(prop.netAmt_bc ? prop.netAmt_bc : 0.00)
                      }`]
                  }) : []}
                />
              }
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitleWhite}>
                  Most Recent Requested Reviews
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
            {loadingFiles ? <LinearProgress  /> : 
              <Table
                hover
                tableHeaderColor="warning"
                tableHead={[
                  "ID",
                  "File Name",
                  "Status",
                  "Date",
                  "Requested By",
                ]}
                tableData={typeof fileHistory.mostrecentrequestedreviews !== "undefined" && fileHistory.mostrecentrequestedreviews.length > 0? fileHistory.mostrecentrequestedreviews.map((file,index)=>{return [index+1,file.fileName,file.status,dateFormat(file.requestedTime, "dd/mm/yyyy"), file.requestedBy]}): []}
              />
              }
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" text>
              <CardText color="success">
                <h4 className={classes.cardTitleWhite}>
                  Most Recent Requested Approvals
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
            {loadingFiles ? <LinearProgress  /> : 
              <Table
                hover
                tableHeaderColor="success"
                tableHead={[
                  "ID",
                  "File Name",
                  "Status",
                  "Date",
                  "Requested By",
                ]}
                tableData={typeof fileHistory.mostrecentrequestedapprovals !== "undefined" && fileHistory.mostrecentrequestedapprovals.length > 0? fileHistory.mostrecentrequestedapprovals.map((file,index)=>{return [index+1,file.fileName,file.status,dateFormat(file.requestedTime, "dd/mm/yyyy"), file.requestedBy]}): []}
              />
              }
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>

      {/* <br />
      <h3>My Pending</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitleWhite}>Pending For Reviews</h4>
              </CardText>
            </CardHeader>
            <CardBody>
            {loadingFiles ? <LinearProgress  /> : 
              <Table
                hover
                tableHeaderColor="warning"
                tableHead={[
                  "ID",
                  "File Name",
                  "Status",
                  "Date",
                  "Reviewed By",
                ]}
                tableData={typeof fileHistory.mypendingreviews !== "undefined" && fileHistory.mypendingreviews.length > 0? fileHistory.mypendingreviews.map((file,index)=>{return [index+1,file.fileName,file.status,dateFormat(file.requestedTime, "dd/mm/yyyy"), file.reviewedBy]}): []}
              />
              }
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" text>
              <CardText color="success">
                <h4 className={classes.cardTitleWhite}>
                  Pending For Approvals
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
            {loadingFiles ? <LinearProgress  /> : 
              <Table
                hover
                tableHeaderColor="success"
                tableHead={[
                  "ID",
                  "File Name",
                  "Status",
                  "Date",
                  "Approved By",
                ]}
                tableData={typeof fileHistory.mypendingapproval !== "undefined" && fileHistory.mypendingapproval.length > 0? fileHistory.mypendingapproval.map((file,index)=>{return [index+1,file.fileName,file.status,dateFormat(file.requestedTime, "dd/mm/yyyy"), file.approvedBy]}): []}
              />
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer> */}
      {openTrackingModal ? (
        <TrackingStatus initialReview={initialReview} review={review} approve={approve} approveandexported={approveandexported} canceled={canceled} closeTrackingModal={closeTrackingModal} trackingLoading={trackingLoading} setOpenTrackingModal={setOpenTrackingModal} openTrackingModal={openTrackingModal} />
      ) : (
        ''
      )}

    </div>
  );
}
