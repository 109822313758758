import React, { useState, useEffect, useRef } from "react";
import { DataGrid, useGridApiRef } from "@material-ui/data-grid";
import Button from "components/CustomButtons/Button.js";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import axios from "axios";
import { errorAlert } from "Functions";
import { successAlert } from "Functions";
import {
  CircularProgress,
  TextField,
  makeStyles,
  DialogContent,
  Dialog,
  Typography,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CardFooter from "components/Card/CardFooter";
import { AirlineSeatReclineNormalTwoTone } from "@material-ui/icons";
import { msgAlert } from "Functions";
import { useHistory } from "react-router-dom";
import {
  TimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AttachmentRounded from "@material-ui/icons/AttachmentRounded";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Animated } from "react-animated-css";
import Iframe from "react-iframe";
import moment from "moment";
import jwt from "jsonwebtoken";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";

const useStyles = makeStyles(styles);
const Styles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
  table: {
    minWidth: "100%",
  },
  itemName: {
    width: 400,
  },
  itemNumber: {
    width: "55%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function PurchaseOrderItems({
  items,
  selectedPurchaseOrder,
  formState,
  myRef,
  setItems,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [selection, setSelection] = useState([]);
  const [rows, setRows] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [transactionDate, setTransactionDate] = useState(true);
  const [file, setFile] = useState(null);
  const [viewFile, setViewFile] = useState(false);
  const [pdf, setPdf] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [tempFileName, setTempFileName] = useState("");
  const [transactionDateValue, setTransactionDateValue] = useState("");
  const [formStateA, setFormStateA] = useState({
    attachments: [],
    values: {
      fileTitle: "",
      fileDescription: "",
    },
    errors: {
      fileTitle: "",
    },
  });
  const localTime = new Date();
  const maxDate = moment(localTime).format("YYYY-MM-DDTHH:mm");

  let fileInput = React.createRef();
  const dispatch = useDispatch();

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const columns = [
    { field: "id", headerName: "Line #", width: 80, sortable: false },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "ordered",
      headerName: "Ordered",
      width: 100,
      sortable: false,
      // flex: 1
    },
    {
      field: "received",
      headerName: "Received",
      width: 100,
      sortable: false,
      // flex: 1,
    },
    {
      field: "invoiced",
      headerName: "Invoiced",
      width: 100,
      sortable: false,
      // flex: 1,
    },
    {
      field: "currency_code",
      headerName: "Currency",
      // flex: 1,
      width: 100,
      sortable: false,
    },
    {
      field: "attachments",
      width: 140,
      sortable: false,
      headerName: "Attachments",
      renderCell: (params) => {
        const { row } = params;
        const { id } = params;
        return (
          <div style={{ paddingTop: "15px" }}>
            {selection.includes(row.id) ? (
              <AddCircleOutlineIcon
                fontSize="small"
                onClick={() => handleAttachmentModal(row, id)}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "quantity",
      width: 155,
      sortable: false,
      // flex: 1,
      headerName: "Quantity/Amount *",
      renderCell: (params) => {
        const { row } = params;
        const { id } = params;
        return (
          <div style={{ paddingTop: "15px" }}>
            <TextField
              fullWidth={true}
              id="quantity"
              name="quantity"
              variant="outlined"
              type="text"
              style={
                selection.includes(row.id)
                  ? { background: "#fff", height: "100%", width: "100%" }
                  : { height: "100%", width: "100%" }
              }
              disabled={!selection.includes(row.id)}
              // onChange_handleCellValueChange()
              onChange={(e) => _handleCellValueChange(e, id, row)}
              value={row?.quantity || ""}
            />
          </div>
        );
      },
    },
    {
      field: "transaction_date",
      width: 200,
      // flex: 1,
      sortable: false,
      headerName: "Transaction Date *",
      renderCell: (params) => {
        const { row } = params;
        const { id } = params;
        const options = {
          maxDate: new Date(),
          altInputClass: "hide",
          dateFormat: "M d Y h:i K",

          minDate: new Date("01-01-2018"),
          enableTime: true,
        };
        return (
          <div style={{ paddingTop: "15px" }}>
            <Flatpickr
              data-enable-time
              options={options}
              name="transaction_date"
              disabled={!selection.includes(row.id)}
              // onChange={e => _handleCellValueChange(e, id, row)}
              onChange={(selectedDates, dateStr) => {
                handleTransactionDate(selectedDates[0], id, row);
              }}
              value={
                id === selection
                  ? moment(row?.transaction_date).format("YYYY-MM-DD hh:mm A")
                  : "" || ""
              }
              style={{
                height: "36px",
                margin: "0px 0 23px 0",
              }}
            />
            {/* <TextField
              fullWidth={true}
              id='transaction_date'
              name='transaction_date'
              type='time'
              variant='outlined'
              step="2"
              disabled={!selection.includes(row.id)}
              inputProps={{
                max: `${maxDate}`
              }}
              style={
                selection.includes(row.id)
                  ? { background: '#fff', height: '100%', width: '100%' }
                  : { height: '100%', width: '100%' }
              }
              onChange={e => _handleCellValueChange(e, id, row)}
              value={row?.transaction_date || ''}
            /> */}
          </div>
        );
      },
    },
  ];

  const handleAttachmentModal = (row, id) => {
    setSelectedRow(row);
    setAttachmentModal(true);
  };
  useEffect(() => {
    if (items.length === 0) {
      setSelection([]);
    }
  }, [items]);

  useEffect(() => {
    if (formStateA.attachments.length > 0) {
      let index = rows.findIndex((row) => row.id === selectedRow?.id);
      let findCurrentAtt = formStateA.attachments.find(
        (row) => row.name === tempFileName
      );
      let rows_new = [...rows];
      debugger;
      let getRowAttchments = rows[index].attachments;
      getRowAttchments.push(findCurrentAtt);
      let object = { ...rows[index], ["attachments"]: getRowAttchments };
      rows_new[index] = object;
      setRows(rows_new);
      // console.log(rows);
    }
  }, [formStateA.attachments]);

  const _validateDate = async (date) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/accountingPeriodStatusLOV`,
      data: {
        date: date,
      },
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        if (res?.data?.items.length > 0) {
          setTransactionDate(true);
        } else {
          setTransactionDate(false);
          msgAlert(
            "GL period is not opened. Please contact finance department."
          );
        }
      })
      .catch((error) => {
        setTransactionDate(false);
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const _handleCellValueChange = (e, id, row) => {
    if (e.target.name == "quantity") {
      let clean = e.target.value.replace(/,/g, "");
      if (typeof row.ordered == "string" || typeof row.ordered == "number") {
        row.ordered =
          typeof row.ordered == "string" ? row.ordered : row.ordered.toString();
        let clean = row.ordered.replace(/,/g, "");
        var valueO = Number(clean);
      }
      if (typeof row.received == "string" || typeof row.received == "number") {
        row.received =
          typeof row.received == "string"
            ? row.received
            : row.received.toString();
        let clean = row.received.replace(/,/g, "");
        var valueR = Number(clean);
      }
      let final_Value = valueO - valueR;
      let entered_Value = parseInt(clean);
      if (entered_Value > final_Value) {
        msgAlert("Input quantity mismatched with ordered");
      } else {
        let index = rows.findIndex((row) => row.id === id);
        let rows_new = [...rows];
        let object = { ...rows[index], [e.target.name]: e.target.value };
        rows_new[index] = object;
        setRows(rows_new);
      }
    }
  };

  const handleTransactionDate = (selectedDate, id, row) => {
    let date = moment(selectedDate).format("YYYY-MM-DDTHH:mm");
    setTransactionDateValue(date);
    _validateDate(moment(date).format("YYYY-MM-DD"));
    // let date = new Date(e.target.value)
    // let newDate = FormatDateTime(new Date());
    // let finalDate = date.setSeconds(newDate);
    let index = rows.findIndex((row) => row.id === id);
    let rows_new = [...rows];
    let object = { ...rows[index], ["transaction_date"]: date };
    rows_new[index] = object;
    setRows(rows_new);
  };

  const FormatDateTime = (date) => {
    let Token = localStorage.getItem("cooljwt");
    let user = jwt.decode(Token);
    var offset;
    if (user?.tenantConfigs) {
      let tenantConfig = user.tenantConfigs;
      let timeStamp = tenantConfig.timeZone;
      offset = timeStamp.offset * 60;
    } else {
      offset = moment().utcOffset();
    }
    var now = new Date(date);
    const someday = moment(now)
      .utcOffset(offset)
      .format("ss");
    return someday;
  };

  const _createReceipt = async () => {
    try {
      let line_items = rows.map((row) => {
        if (selection.includes(row.id)) {
          return row;
        }
      });
      if (selection.length > 0) {
        let selectedItems = [];
        for (let index = 0; index < selection.length; index++) {
          const element = selection[index];
          let findOne = rows.find((x) => x.id === element);
          selectedItems.push(findOne);
        }
        debugger;
        // validation on quantity
        let validate_items = selectedItems.map((row) => {
          if (
            row.quantity !== "" &&
            row.quantity.trim().length > 0 &&
            row.quantity !== "0" &&
            !/^0+$/.test(row.quantity) &&
            row.transaction_date !== ""
          ) {
            return true;
          } else {
            return false;
          }
        });
        if (validate_items.includes(false)) {
          msgAlert("Quantity or Transaction Date is missing");
        } else {
          setCreateLoading(true);
          await axios({
            method: "post", //you can set what request you want to be
            url: `${process.env.REACT_APP_LDOCS_API_URL}/receipt/createReceipt`,
            data: {
              selectedPurchaseOrder,
              rows: line_items,
            },
            headers: {
              cooljwt: Token,
            },
          }).then((res) => {
            setCreateLoading(false);
            successAlert("Receipt Created Successfully");
            history.push(`/default/receipt/${formState.poHeaderId}`);
          });
        }
      } else {
        msgAlert("Please select the line");
      }
    } catch (error) {
      setCreateLoading(false);
      if (error.response) {
        error.response.status == 401 && dispatch(setIsTokenExpired(true));
      }
      errorAlert(error.response.data || "Error In Creating Receipt");
    }
  };

  useEffect(() => {
    if (selection.length === 0) {
      setRows(items);
    }
  }, [items]);

  const handleChange = (event) => {
    event.persist();
    if (event.target.name == "filename") {
      setSelectedFileName(event.target.value);
    } else {
      setFormStateA((formStateA) => ({
        ...formStateA,
        values: {
          ...formStateA.values,
          fileDescription: event.target.value,
        },
      }));
    }
  };
  const handleAttachmentChange = (e) => {
    e.preventDefault();

    if (e.target.files[0].size > 6291456) {
      msgAlert("Maximum File Size 6 MB");
      fileInput.current.value = "";
    }
    // if (
    //   e.target.files[0].type === 'image/png' ||
    //   e.target.files[0].type === 'image/jpeg' ||
    //   e.target.files[0].type === 'application/pdf'
    // ) {
    let files = e.target.files;
    let file = e.target.files[0].name;
    setSelectedFileName(file);
    setTempFileName(file);
    setSelectedFile(files);
    // } else {
    //   msgAlert('File Type Not Supported')
    //   fileInput.current.value = ''
    // }
  };
  const viewFileHandler = (file) => {
    setFile(file);
    setAttachmentModal(false);
    if (
      file?.eAttachmentURL?.split(".").pop() === "pdf" ||
      file.type === "application/pdf"
    ) {
      setPdf(true);
    } else {
      setPdf(false);
    }
    setViewFile(true);
  };

  //Remove Attachment
  const removeAttachment = (fileIndex) => {
    let attachments = formStateA.attachments.filter(
      (a, index) => index !== fileIndex
    );
    let index = rows.findIndex((row) => row.id === selectedRow?.id);
    let rows_new = [...rows];
    let object = { ...rows[index], ["attachments"]: attachments };
    rows_new[index] = object;
    setRows(rows_new);
  };

  const closeViewFile = () => {
    setViewFile(false);
    setAttachmentModal(true);
    setFile(null);
  };

  const getFileDetails = () => {
    let fileTitle;
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;

    if (!Check(selectedFileName)) {
      fileTitle = "success";
    } else {
      fileTitle = "error";
      error = true;
    }
    setFormStateA((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        fileTitle: fileTitle,
      },
    }));
    if (error) {
      return false;
    } else {
      Object.entries(selectedFile).map(async ([key, value]) => {
        // if (value.type == 'image/png' || 'image/jpeg' || 'application/pdf') {
        let reader = new FileReader();
        reader.onloadend = () => {
          setFormStateA((formStateA) => ({
            ...formStateA,
            attachments: [
              ...formStateA.attachments,
              {
                name: value.name,
                rowIndex: selectedRow.id,
                base64: reader.result,
                type: value.type,
                attachmentTitle: selectedFileName,
                attachmentPath: "",
                file: value,
                title: formStateA.values.fileTitle,
                description: formStateA.values.fileDescription,
              },
            ],
          }));
        };
        reader.readAsDataURL(value);
        setTimeout(() => {
          setFormStateA((formStateA) => ({
            ...formStateA,
            values: {
              ...formStateA.values,
              fileTitle: "",
              fileDescription: "",
            },
          }));
        }, 500);
        // }
      });
      setSelectedFileName("");
      fileInput.current.value = "";
    }
  };
  const saveFile = (file) => {
    saveAs(file);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column  " }} ref={myRef}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p>Select Rows to Perform Action</p>
        <div>
          <Button
            color="danger"
            round
            disabled={!transactionDate}
            style={{ float: "right" }}
            onClick={_createReceipt}
          >
            {createLoading ? (
              <CircularProgress color="#fffff" />
            ) : (
              <>
                {" "}
                <p style={{ display: "flex", margin: "0px" }}>
                  Create Receipt <small>({selection.length})</small>
                </p>
              </>
            )}
          </Button>
        </div>
      </div>
      <div style={{ height: 800, width: "100%" }}>
        <DataGrid
          // style={{ overflowX: 'scroll' }}
          disableColumnMenu
          disableColumnFilter
          rows={rows}
          columns={columns}
          hideFooter={true}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection);
          }}
          onCellEditCommit={(params) => _handleCellValueChange(params)}
          isCellEditable={(params) => selection.includes(params.row.id)}
        />
      </div>

      {viewFile ? (
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Animated
            animationIn="bounceInRight"
            animationOut="bounceOutLeft"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={viewFile}
          >
            <Card xs={12} sm={12} md={12} lg={12}>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>
                    {file.attachmentTitle}
                  </h4>
                </CardIcon>
                <Button
                  color="danger"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={() => closeViewFile()}
                >
                  Go Back
                </Button>
              </CardHeader>
              <CardBody>
                {pdf ? (
                  <Iframe
                    url={
                      file?.base64 ||
                      `${process.env.REACT_APP_LDOCS_API_URL}/${file?.eAttachmentURL}`
                    }
                    width="100%"
                    id="myId"
                    className="myClassname"
                    height={window.screen.height}
                  />
                ) : (
                  <img
                    width="100%"
                    src={
                      file?.base64 ||
                      `${process.env.REACT_APP_LDOCS_API_URL}/${file?.eAttachmentURL}`
                    }
                    alt="image"
                  />
                )}
              </CardBody>
            </Card>
          </Animated>
        </GridItem>
      ) : (
        ""
      )}

      {attachmentModal ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={attachmentModal}
          keepMounted
          onClose={() => setAttachmentModal(false)}
          aria-labelledby="selected-modal-slide-title"
          aria-describedby="selected-modal-slide-description"
        >
          <DialogContent id="selected-Select" className={classes.modalBody}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Attachment Details</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <TextField
                      className={classes.textField}
                      error={formStateA.errors.fileTitle === "error"}
                      required
                      helperText={
                        formStateA.errors.fileTitle === "error"
                          ? "Title is required"
                          : null
                      }
                      InputLabelProps={{
                        style: { color: "black" },
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      fullWidth={true}
                      label="File Name"
                      name="filename"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      value={selectedFileName}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <TextField
                      className={classes.textField}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      fullWidth={true}
                      label="File Description"
                      name="fileDescription"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      value={formStateA.values.fileDescription || ""}
                    />
                  </GridItem>
                  <GridItem
                    style={{
                      display: "flex",
                      alignItems: "right",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Button
                      color="danger"
                      size="small"
                      onClick={() => setAttachmentModal(false)}
                      round
                    >
                      Close
                    </Button>
                    <Button
                      color="info"
                      size="small"
                      onClick={getFileDetails}
                      round
                    >
                      Add File
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <Card
                      onClick={() => {
                        fileInput.current.click();
                      }}
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        cursor: "pointer",
                        background: "#f5f5f5",
                        height: "220px",
                        // margin: '5px'
                      }}
                    >
                      <AddCircleOutlineIcon
                        style={{
                          alignSelf: "center",
                          width: 50,
                          height: 125,
                          marginBottom: 10,
                        }}
                        fontSize="large"
                      />
                      <Typography variant="body1" component="h6">
                        Add Attachment
                      </Typography>
                    </Card>
                    <input
                      type="file"
                      name="attachments"
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg"
                      ref={fileInput}
                      onChange={(event) => {
                        handleAttachmentChange(event);
                      }}
                    />
                  </GridItem>
                  {rows
                    ?.find((list) => list.id === selectedRow?.id)
                    .attachments?.map((res, index) => (
                      <>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Card>
                            <CardBody
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                cursor: "pointer",
                                height: "158px",
                              }}
                            >
                              {/* <AttachmentRounded
                                onClick={() => viewFileHandler(res)}
                                style={{
                                  alignSelf: 'center',
                                  width: 50,
                                  height: 70,
                                  marginBottom: 10
                                }}
                                fontSize='large'
                              /> */}
                              <Typography variant="body1" component="h6">
                                {res?.attachmentTitle}
                              </Typography>
                              <sub>{res?.type}</sub>
                            </CardBody>
                            <CardFooter>
                              {/* <IconButton
                                onClick={() => viewFileHandler(res)}
                                style={{
                                  float: 'right',
                                  color: 'orange'
                                }}
                                fontSize='small'
                              >
                                <VisibilityIcon fontSize='small' />
                              </IconButton> */}
                              <IconButton
                                onClick={() =>
                                  saveFile(
                                    res?.base64 ||
                                      `${process.env.REACT_APP_LDOCS_API_URL}/${res?.eAttachmentPath}`
                                  )
                                }
                                style={{
                                  float: "right",
                                  color: "orange",
                                }}
                                fontSize="small"
                              >
                                <GetAppIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => removeAttachment(index)}
                                style={{
                                  float: "right",
                                  color: "red",
                                }}
                                fontSize="small"
                              >
                                <HighlightOffIcon fontSize="small" />
                              </IconButton>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </>
                    ))}
                </GridContainer>
              </CardBody>
            </Card>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
}
