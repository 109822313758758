
import { INVOICE_FILTER_VALUE,SHOW_INVOICE_PAY_VALUE,SHOW_PAID_INVOICE } from "actions/Constant";

const initialState = {
    filter: null,
    loading:false
}

function filterValue (state = initialState, action) {

    switch (action?.type) {
        case INVOICE_FILTER_VALUE:
            return { loading: false, filter: action?.payload?.val }
            case SHOW_INVOICE_PAY_VALUE:
                return { loading: false, filter: action?.payload?.val }
                case SHOW_PAID_INVOICE:
                    return { loading: false, filter: action?.payload?.val }
        default:
            return state
    }
  }

  export default filterValue;

