import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, workflowName, theme) {
  return {
    fontWeight:
      workflowName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  // const [userName, setUserName] = React.useState(props.formState.selectedUser);
  const [userName, setUserName] = React.useState([]);
  const handleChange = (event) => {
    setUserName(event.target.value);
    props.onChange(event.target.value);
  };

  useEffect(() => {
    debugger
    var existWorkflows = [];
    let filterUser;
    if (props?.formState?.selectedUser?.length > 0) {
      props.formState.selectedUser.map((item) => {
        existWorkflows.push(item?.displayName)
      })
      setUserName(existWorkflows)
    } else {
      setUserName([])
    };
  }, [props?.formState?.selectedUser])

  return (
    <div>
      <FormControl className={classes.formControl}>
        {/* <InputLabel id="demo-mutiple-chip-label">Select User(s)</InputLabel> */}
        <Autocomplete
        value={userName}
        onChange={(event, newValue) => {
          if (newValue) {
            setUserName(newValue);
            props.onChange(newValue);
          }
        }}
        multiple
        id="tags-filled"
        options={props?.getAllUsers?.map((option) => option?.level1?.displayName)}
        freeSolo
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
                  'Select User(s)'
            }
          />
        )}
      />
        {/* <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={userName}
          disabled={props.disabled}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Choose Users
          </MenuItem>
          {props?.getAllUsers?.map((name, index) => (
            <MenuItem key={index} value={name?.level1?.displayName} style={getStyles(name, userName, theme)}>
              {name?.level1?.displayName}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>
    </div>
  );
}
