import React from 'react'
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
} from '@material-ui/core'
import {formatDateTime } from '../Functions/Functions'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info';

const TrackingModal = ({ initialReview, review, approve, approveandexported, canceled, showFwdNotes }) => {

  const useStyles = makeStyles()
  const classes = useStyles()
  return (
    <div>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow className={classes.TableRow}>
            <TableCell className={classes.TableID}>Review / Approve</TableCell>
            <TableCell className={classes.TableID}>Assigned To</TableCell>
            <TableCell className={classes.TableID}>Assigned Date</TableCell>
            <TableCell className={classes.TableCell}>Status</TableCell>
            <TableCell className={classes.TableCell}>Action Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ paddingBottom: 5 }}>
          <TableRow className={classes.TableRow}>
            <TableCell className={classes.TableCell}>
              Initial Review
              <br />
              (Requester)
              {initialReview?.isForward || review?.isDelegate ?
                <IconButton onClick={() => showFwdNotes('initialReview')}>
                  < InfoIcon />
                </IconButton>
                : ""}
            </TableCell>
            <TableCell>
              {initialReview?.reviewedBy || '-'}
              <br />
              {initialReview?.assignTo || '-'}
            </TableCell>
            <TableCell>
              {initialReview?.assignDate
                ? formatDateTime(initialReview?.assignDate)
                : '-'}
            </TableCell>
            <TableCell>
              {initialReview?.status == 'completed'
                ? initialReview?.status.toUpperCase()
                : initialReview?.status == 'correctionRequired'
                  ? initialReview?.status.toUpperCase()
                  : initialReview?.status == 'rejected'
                    ? initialReview?.status.toUpperCase()
                    : 'PENDING' || 'PENDING'}
            </TableCell>
            <TableCell>
              {initialReview?.status !== 'pending' && initialReview?.date
                ? formatDateTime(initialReview?.date)
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow className={classes.TableRow}>
            <TableCell className={classes.TableCell}>
              Review
              <br />
              (Accounting Specialist)
              {review?.isForward || review?.isDelegate ?
                <IconButton onClick={() => showFwdNotes('reviewStep')}>
                  < InfoIcon />
                </IconButton>
                : ""}
            </TableCell>
            <TableCell>
              {review?.reviewedBy || '-'}
              <br />
              {review?.assignTo || '-'}
            </TableCell>
            <TableCell>
              {review?.assignDate ? formatDateTime(review?.assignDate) : '-'}
            </TableCell>
            <TableCell>
              {review?.status == 'completed'
                ? review?.status.toUpperCase()
                : review?.status == 'correctionRequired'
                  ? review?.status.toUpperCase()
                  : review?.status == 'rejected'
                    ? review?.status.toUpperCase()
                    : 'PENDING' || 'PENDING'}
            </TableCell>
            <TableCell>
              {review?.status !== 'inProgress' && review?.date
                ? formatDateTime(review?.date)
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow className={classes.TableRow}>
            <TableCell className={classes.TableCell}>
              Approve
              <br />
              (Accounting Manager)
              {approve?.isForward || approve?.isDelegate ?
                <IconButton onClick={() => showFwdNotes('approveStep')}>
                  < InfoIcon />
                </IconButton>
                : ""}
            </TableCell>
            <TableCell>
              {approve?.approvedBy || '-'}
              <br />
              {approve?.assignTo || '-'}
            </TableCell>
            <TableCell>
              {approve?.assignDate ? formatDateTime(approve?.assignDate) : '-'}
            </TableCell>
            <TableCell>
              {approve?.status == 'completed'
                ? approve?.status.toUpperCase()
                : approve?.status == 'correctionRequired'
                  ? approve?.status.toUpperCase()
                  : approve?.status == 'rejected'
                    ? approve?.status.toUpperCase()
                    : 'PENDING' || 'PENDING'}
            </TableCell>
            <TableCell>
              {approve?.status !== 'pending' && approve?.date
                ? formatDateTime(approve?.date)
                : '-'}
            </TableCell>
          </TableRow>
          <TableRow className={classes.TableRow}>
            <TableCell className={classes.TableCell}>
              Approve and Export
              <br />
              (Requester)
            </TableCell>
            <TableCell>
              {approveandexported?.exportedBy || '-'}
              <br />
              {approveandexported?.assignTo || '-'}
            </TableCell>
            <TableCell>
              {approveandexported?.assignDate
                ? formatDateTime(approveandexported?.assignDate)
                : '-'}
            </TableCell>
            <TableCell>
              {approveandexported?.status == 'completed'
                ? approveandexported?.status.toUpperCase()
                : 'PENDING' || 'PENDING'}
            </TableCell>
            <TableCell>
              {approveandexported?.status !== 'pending' &&
                approveandexported?.date
                ? formatDateTime(approveandexported?.date)
                : '-'}
            </TableCell>
          </TableRow>

          <TableRow className={classes.TableRow}>
            <TableCell className={classes.TableCell}>
              Invoice Status
              {/* <br />
                            (Requester) */}
            </TableCell>
            <TableCell>
              {canceled?.paidBy || '-'}
              <br />
              {canceled?.assignTo || '-'}
            </TableCell>
            <TableCell>
              {canceled?.assignDate
                ? formatDateTime(canceled?.assignDate)
                : '-'}
            </TableCell>
            <TableCell>
              {canceled?.status == 'inProgress'
                ? 'PENDING'
                : canceled?.status == 'completed'
                  ? 'COMPLETED'
                  : canceled?.status == 'Canceled'
                    ? 'CANCELED'
                    : '-'}
            </TableCell>
            <TableCell>
              {canceled?.status !== 'pending' && canceled?.date
                ? formatDateTime(canceled?.date)
                : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default TrackingModal
