import axios from "axios";
import {ORGANIZATION_LIST_FAIL,ORGANIZATION_LIST_SUCCESS,ORGANIZATION_LIST_REQUEST } from "./Constant";

const OrganizationList = (Token) => async (dispatch) => {

    try {
        dispatch({ type: ORGANIZATION_LIST_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_LDOCS_API_URL}/organization/getAllOrgBytenant`,
        {
            headers: { cooljwt:Token

        }});

        dispatch({ type: ORGANIZATION_LIST_SUCCESS, payload: data })
    }
    catch (error) {

        dispatch({ type: ORGANIZATION_LIST_FAIL, payload: error.messsage })


    }
}
export default OrganizationList;