import React, { useState, useEffect } from "react";
// @material-ui/icons
import {
  makeStyles,
  CircularProgress,
  TextField,
  MenuItem,
  useForkRef,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
// @material-ui/core components

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Animated } from "react-animated-css";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Swal from 'sweetalert2'
import { successAlert, errorAlert, msgAlert } from "views/LDocs/Functions/Functions";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import { compliance } from "actions/VendorProfile";
import { submitCompliance } from "actions/VendorProfile"

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

export default function Compliance(props) {
  const classes = useStyles();
  const [animateStep, setAnimateStep] = useState(true);
  const Token = useSelector(state => state.userReducer.Token) || localStorage.getItem('cooljwt');
  const vendordata = useSelector(state => state.VendorRegister.compliance)
  const dispatch = useDispatch();
  const { id, isedit } = useParams();
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (isedit === "true") {
      setEdit(true)
    }
    if (isedit === "false") {
      setEdit(false)
    }
  }, [isedit])

  const scroll = () => window.scrollTo(0, 0);

  useEffect(() => {
    scroll()
  }, [])

  function checkInput(value) {
    if (value.includes('<script>')) {
      return false;
    }
    return true;
  }
  
  const handleChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    if (checkInput(value)) {
      dispatch(compliance(name, value))
    } else {
      return;
    }
  };
  const checkHandleChange = (event) => {
    event.persist();
    const { name, checked } = event.target
    let value = checked
    dispatch(compliance(name, value))
  };

  const handleSubmit = () => {
    dispatch(submitCompliance(vendordata))
  }

  return (
    <Animated
      animationIn="bounceInRight"
      animationOut="bounceOutLeft"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >

      <GridContainer justify="center" md={12} xs={12} sm={12}>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label=" Resources and Equipments"
            id="resources"
            name="resources"
            variant="outlined"
            multiline
            // placeholder="..."
            minRows={5}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.resources || ""}
          />
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <FormControlLabel
            control={<Checkbox name="qualityManagement" color="info" />}
            label="Quality Managment System"
            name="qualityManagement"
            disabled={edit}
            checked={vendordata?.qualityManagement}
            onChange={(event) => {
              checkHandleChange(event);
            }}
          />

        </GridItem>
        {vendordata?.qualityManagement == true ?
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginBottom: "10px" }}
          >
            <TextField
              fullWidth={true}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              label="Quality Managment System"
              id="QMSystem"
              name="QMSystem"
              variant="outlined"
              type="text"
              // placeholder="Please Specify"
              disabled={edit}
              onChange={(event) => {
                handleChange(event);
              }}
              value={vendordata?.QMSystem || ""}

            />
          </GridItem> : ""}
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <FormControlLabel
            control={<Checkbox name="subContractors" color="info" />}
            sty
            label="Use of Sub Contractors"
            checked={vendordata?.subContractors}
            name="subContractors"
            disabled={edit}
            onChange={(event) => {
              checkHandleChange(event);
            }}
          />

        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="Health and Safety"
            id="healthAndSafety"
            name="healthAndSafety"
            variant="outlined"
            multiline
            minRows={3}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.healthAndSafety || ""}

          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputLabelProps={{
              style: { color: 'black' },
            }}
            label="Enviroment and Sustainability"
            id="environmentAndSustainability"
            name="environmentAndSustainability"
            variant="outlined"
            multiline
            minRows={3}
            type="text"
            disabled={edit}
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.environmentAndSustainability || ""}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "10px" }}
        >
          <TextField
            fullWidth={true}
            InputProps={{ inputProps: { min: 0 } }}
            InputLabelProps={{
              style: { color: 'black' }
            }}
            label="Staff Number"
            id="staffNumber"
            name="staffNumber"
            variant="outlined"
            disabled={edit}
            type="number"
            onChange={(event) => {
              handleChange(event);
            }}
            value={vendordata?.staffNumber || ""}

          />
        </GridItem>
      </GridContainer>
    </Animated>
  );
}


