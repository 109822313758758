import React, { useEffect } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";


import {
  getUserDataAction,
  getNotification,
  getTasks,
  setDarkMode,
  setIsTokenExpired,
  setToken,
  logoutUserAction
} from "../actions";
import { Switch, Route, Redirect } from "react-router-dom";
import addNotification from "react-push-notification";
import { useHistory } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme, CssBaseline } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import checkLogin from "views/LDocs/Authorization/LoginCheck";
import { checkIsAdminDesk } from "views/LDocs/Authorization/checkAuthority";

import AdRoutes from "routes/Admin";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import Verify from "views/LDocs/Verify/Verify";

import { Notifications } from "react-push-notification";
import { ToastContainer, toast } from "react-toastify";
import { decode } from "jsonwebtoken";
import { tokenExpiredAlert } from "Functions";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import DeviceUUID from "../../src/views/LDocs/Functions/Functions"
import { sessionTimeout } from "Functions";

let uuid = new DeviceUUID().get();

var ps;

const useStyles = makeStyles(styles);

const checkTimeCompare = (dat) => {
  var taskDate = new Date(dat);
  var nowDate = new Date(Date.now());
  if (
    taskDate.getHours() === nowDate.getHours() &&
    taskDate.getFullYear() === nowDate.getFullYear() &&
    taskDate.getMinutes() === nowDate.getMinutes() &&
    taskDate.getMonth() === nowDate.getMonth() &&
    taskDate.getDate() === nowDate.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

export default function Dashboard(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
    const { instance } = useMsal();
    const history = useHistory();
    const notifications = useSelector((state) => state.userReducer.notifications);
  const isApEnable = decode(Token)?.tenantPermissions?.enableAP ? true : false;

  const isArEnable = decode(Token)?.tenantPermissions?.enableAR ? true : false;


  const routes = AdRoutes({isApEnable, isArEnable});

  const tasks = useSelector((state) => state.userReducer.tasks);
  const isTokenExpired = useSelector(
    (state) => state.userReducer.isTokenExpired
  );
  const { ...rest } = props;
  const sweetAlertStyle = makeStyles(styles2);
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [darkmood, setDarkMood] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-1.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo.png"));
  const [darkLogo, setDarkLogo] = React.useState(
    require("assets/img/logo.png")
  );
  const dispatch = useDispatch();
  const notify = (msg) =>
    toast(msg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const handleMarkNotificationRead = (notification_id, status) => {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/notify/updateSysNotify`,
        data: {
          "_id": notification_id,
          "status": status
        },
        headers: {
          "Content-Type": "application/json",
          cooljwt: Token
        },
      })
        .then((response) => {
          fetchData();
        });
    }
  function fetchData() {
    dispatch(getNotification());
    dispatch(getTasks());
  }

  React.useEffect(() => {
    // getUserInfo();
    fetchData();
    setInterval(() => {
      fetchData();
    }, 20000);
    if (checkLogin()) {
      dispatch(getUserDataAction());
    }
  }, []);

  useEffect(() => {
    tasks.map((task, key) => {
      if (checkTimeCompare(task.taskReminderDate)) {
        addNotification({
          title: "Task Reminder",
          subtitle: task.taskDescription,
          message: task.taskDescription,
          theme: "white",
          duration: 15000,
          silent: false,
          native: true, // when using native, your OS will handle theming.
        });
        notify(task.taskDescription);
      }
    });
  }, [tasks]);
  useEffect(() => {
    if (isTokenExpired) {
      tokenExpiredAlert(hideSessionAlert , "Session Expired ! Please Login Again");
    }
  }, [isTokenExpired]);

  const theme = createTheme({
    palette: {
      type: darkmood ? "dark" : "light",
    },
  });

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount

  const logoutUser = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/"
  }).then(()=>{
      localStorage.clear();
      dispatch(setToken(null));
      // dispatch(logoutUserAction());
  });
  }

  useEffect(() => {
    var myElement = document.getElementsByClassName('ps');
    myElement.scrollTop = 0;
    window.scrollTo(0,0);
    mainPanel.current && mainPanel.current.scrollTo(0,0)
  }, [history.location.pathname]);



  useEffect(()=>{
    notifications.filter(not=>not.status !== "viewed").map((notif,index)=>{
      handleMarkNotificationRead(notif._id, "viewed");
      notify(`${notif.notifyMessage}`);
    });
  },[notifications]);


  React.useEffect(() => {
    if (checkLogin() && checkIsAdminDesk()) {
      if (navigator.platform.indexOf("Win") > -1) {
        if(mainPanel.current){
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = "hidden";
      }
    }
      window.addEventListener("resize", resizeFunction);

      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    }
  }, []);
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.png"));
        break;
      default:
        setLogo(require("assets/img/logo.png"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes?.length; i++) {
      if (routes[i]?.collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i]?.views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop?.collapse) {
        return getRoutes(prop?.views);
      }
      if (prop?.layout === "/admin") {
        return (
          <Route
            path={prop?.layout + prop?.path}
            component={prop?.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const darkMoodStatus = () => {
    setDarkMood(!darkmood);
    dispatch(setDarkMode(!darkmood));
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };  



  const hideSessionAlert = () => {
    localStorage.clear();
    dispatch(setIsTokenExpired(false));
  };

  const onIdle = () => {
    sessionTimeout(handleLoggedOut, "Your session is expired. Please re-login to continue.")
  };

  useIdleTimer({
    timeout: 1500000,
    onIdle: onIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500
  });

  const handleLoggedOut = async () => {
    dispatch(logoutUserAction())
    history.push('/auth/login')
    await axios({
      method: "delete", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/user/deleteUserFcm`,
      data: {
        deviceId: uuid
      },
      headers: {
        cooljwt: Token,
      },
    })
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.wrapper}>
        {alert}
        <Notifications />
        {!checkLogin() || !checkIsAdminDesk() ? (
          <Redirect exact from="/" to="/auth/login" />
        ) : (
          <React.Fragment>
            {/* <ToastContainer position="top-right"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover/> */}
            <Sidebar
              routes={routes.filter((route) => route.name !== undefined)}
              logoText={process.env.REACT_APP_LDOCS_FOOTER_COPYRIGHT_LEVEL_1}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              bgColor={bgColor}
              miniActive={miniActive}
              {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                logoutUser={logoutUser}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                isDarkmode={darkmood}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {getRoutes(routes)}
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {getRoutes(routes)}
                  </Switch>
                </div>
              )}
              {getRoute() ? <Footer fluid /> : null}
              {/* <FixedPlugin
                handleImageClick={handleImageClick}
                handleColorClick={handleColorClick}
                handleBgColorClick={handleBgColorClick}
                color={color}
                bgColor={bgColor}
                bgImage={image}
                darkmood={darkmood}
                handleFixedClick={handleFixedClick}
                fixedClasses={fixedClasses}
                sidebarMinimize={sidebarMinimize.bind(this)}
                changeDarkMood={darkMoodStatus.bind(this)}
                miniActive={miniActive}
              /> */}
            </div>
          </React.Fragment>
        )}
      </div>
    </ThemeProvider>
  );
}
