import axios from 'axios'
import { decode } from 'jsonwebtoken'
import {
  LOGS_LIST_REQUEST,
  LOGS_LIST_SUCCESS,
  LOGS_LIST_FAIL
} from './Constant'

const LogsList = ({
  page,
  RowsPerPage,
  Token,
  eventType,
  value
}) => async dispatch => {
  try {
    dispatch({ type: LOGS_LIST_REQUEST })
    let userDetails = decode(Token)
    var bodyFormData = {
      tenantId: userDetails?.tenantId,
      eventType,
    }
    let { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/notify/getEventLogs?page=${page}&items=${RowsPerPage}`,
      data: bodyFormData,
      headers: { 'Content-Type': 'application/json', cooljwt: Token }
    })

    dispatch({ type: LOGS_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({ type: LOGS_LIST_FAIL, payload: error.messsage })
  }
}
export default LogsList
