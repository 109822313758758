import axios from "axios";
import {
  GET_VENDOR_DETAIL_REQUEST,
  GET_VENDOR_DETAIL_SUCCESS,
  GET_VENDOR_DETAIL_FAIL,
  VP_CLEAR_ALL_STATE,
  ORGANIZATION_LIST_REQUEST,
  VP_CITY_SUCCESS,
  VP_CITY_REQUEST,
  VP_CITY_FAIL,
  VP_TOKEN,
} from "./Constant";
import {
  VP_GENERAL_REQUEST,
  VP_GENERAL_SUCCESS,
  VP_GENERAL_FAIL,
} from "./Constant";
import {
  VP_CONTACT_REQUEST,
  VP_CONTACT_SUCCESS,
  VP_CONTACT_EDIT,
  VP_CONTACT_FAIL,
} from "./Constant";
import {
  VP_ADDRESS_REQUEST,
  VP_ADDRESS_SUCCESS,
  VP_ADDRESS_EDIT,
  VP_ADDRESS_CLEAR,
  VP_ADDRESS_ONCHANGE,
  VP_ADDRESS_FAIL,
} from "./Constant";
import { VP_SITE_REQUEST, VP_SITE_SUCCESS, VP_SITE_FAIL } from "./Constant";
import {
  VP_ATTACHMENTS_REQUEST,
  VP_ATTACHMENTS_SUCCESS,
  VP_ATTACHMENTS_FAIL,
} from "./Constant";
import {
  VP_BANKDETAILS_REQUEST,
  VP_BANKDETAILS_SUCCESS,
  VP_BANKDETAILS_EDIT,
  VP_BANKDETAILS_FAIL,
  VP_BANKDETAILS_CLEAR,
} from "./Constant";
import { VP_CONTACT_DISPATCH } from "./Constant";
import { VP_CONTACT_DELETE } from "./Constant";
import { VP_ADDRESS_DISPATCH } from "./Constant";
import { VP_ADDRESS_DELETE } from "./Constant";
import { VP_SITE_DISPATCH } from "./Constant";
import { VP_SITE_DELETE } from "./Constant";
import { VP_ATTACHMENTS_DISPATCH } from "./Constant";
import { VP_ATTACHMENTS_DELETE } from "./Constant";
import { VP_BANKDETAILS_DISPATCH } from "./Constant";
import { VP_BANKDETAILS_DELETE } from "./Constant";
import {
  GET_DETAILS_BY_ID_REQUEST,
  GET_DETAILS_BY_ID_SUCCESS,
  GET_DETAILS_BY_ID_FAIL,
} from "./Constant";
import {
  VP_COMPLIANCE_REQUEST,
  VP_COMPLIANCE_SUCCESS,
  VP_COMPLIANCE_FAIL,
} from "./Constant";
import {
  VP_OTHERS_REQUEST,
  VP_OTHERS_SUCCESS,
  VP_OTHERS_FAIL,
} from "./Constant";
import {
  VP_ATTACHMENTS_SUBMIT_REQUEST,
  VP_ATTACHMENTS_SUBMIT_SUCCESS,
  VP_ATTACHMENTS_SUBMIT_FAIL,
} from "./Constant";
import {
  VP_BANK_DETAILS_SUBMIT_REQUEST,
  VP_BANK_DETAILS_SUBMIT_SUCCESS,
  VP_BANK_DETAILS_SUBMIT_FAIL,
} from "./Constant";
import {
  VP_GETCOUNTRY_REQUEST,
  VP_GETCOUNTRY_SUCCESS,
  VP_GETCOUNTRY_FAIL,
} from "./Constant";
import {
  VP_GETBANK_REQUEST,
  VP_GETBANK_SUCCESS,
  VP_GETBANK_FAIL,
} from "./Constant";
import {
  VP_GETCURRENCY_REQUEST,
  VP_GETCURRENCY_SUCCESS,
  VP_GETCURRENCY_FAIL,
} from "./Constant";
import {
  VP_BRANCHDETAILS_REQUEST,
  VP_BRANCHDETAILS_SUCCESS,
  VP_BRANCHDETAILS_FAIL,
} from "./Constant";
import {
  VP_GENERAL_SUBMIT_REQUEST,
  VP_GENERAL_SUBMIT_SUCCESS,
  VP_GENERAL_SUBMIT_FAIL,
  VP_GENERAL_SUBMIT_CLEAR,
} from "./Constant";
import {
  VP_COMPLIANCE_SUBMIT_REQUEST,
  VP_COMPLIANCE_SUBMIT_SUCCESS,
  VP_COMPLIANCE_SUBMIT_FAIL,
  VP_COMPLIANCE_SUBMIT_CLEAR,
} from "./Constant";
import {
  VP_OTHERS_SUBMIT_CLEAR,
  VP_OTHERS_SUBMIT_FAIL,
  VP_OTHERS_SUBMIT_REQUEST,
  VP_OTHERS_SUBMIT_SUCCESS,
} from "./Constant";
import {
  SUPPLIER_TYPE_REQUEST,
  SUPPLIER_TYPE_SUCCESS,
  SUPPLIER_TYPE_FAIL,
} from "./Constant";
import {
  ORGANIZATION_TYPE_REQUEST,
  ORGANIZATION_TYPE_SUCCESS,
  ORGANIZATION_TYPE_FAIL,
} from "./Constant";
import {
  ATTACHMENT_TYPE_REQUEST,
  ATTACHMENT_TYPE_SUCCESS,
  ATTACHMENT_TYPE_FAIL,
} from "./Constant";
import {
  VP_PROFILEIMG_REQUEST,
  VP_PROFILEIMG_SUCCESS,
  VP_PROFILEIMG_FAIL,
} from "./Constant";

const VendorProfile = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: VP_GENERAL_REQUEST });

    dispatch({ type: VP_GENERAL_SUCCESS, payload: { name, value } });
  } catch (error) {
    dispatch({ type: VP_GENERAL_FAIL, payload: error.messsage });
  }
};
// const VendorProfileImg = () => async (dispatch) => {
//     try {
//         dispatch({ type: VP_PROFILEIMG_REQUEST })

//         dispatch({ type: VP_PROFILEIMG_SUCCESS, payload: })
//     }
//     catch (error) {

//         dispatch({ type: VP_PROFILEIMG_FAIL, payload: error.messsage })
//     }
// }
export const VendorProfileContacts = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: VP_CONTACT_REQUEST });

    dispatch({ type: VP_CONTACT_SUCCESS, payload: { name, value } });
  } catch (error) {
    dispatch({ type: VP_CONTACT_FAIL, payload: error.messsage });
  }
};

export const VendorProfileContactEdit = (row, index) => async (dispatch) => {
  try {
    dispatch({ type: VP_CONTACT_EDIT, payload: { row, index } });
  } catch (error) {
    dispatch({ type: VP_ADDRESS_FAIL, payload: error.messsage });
  }
};

export const VendorContactsSubmit = (editIndex) => async (dispatch) => {
  dispatch({ type: VP_CONTACT_DISPATCH, payload: { editIndex } });
};

export const VendorContactDelete = (fileIndex) => async (dispatch) => {
  dispatch({ type: VP_CONTACT_DELETE, payload: fileIndex });
};

export const VendorProfileSites = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: VP_SITE_REQUEST });

    dispatch({ type: VP_SITE_SUCCESS, payload: { name, value } });
  } catch (error) {
    dispatch({ type: VP_SITE_FAIL, payload: error });
  }
};

export const VendorSitesSubmit = (editIndex) => async (dispatch) => {
  dispatch({ type: VP_SITE_DISPATCH, payload: { editIndex } });
};

export const VendorSitesDelete = (fileIndex) => async (dispatch) => {
  dispatch({ type: VP_SITE_DELETE, payload: fileIndex });
};

export const VendorProfileAttachments = (fileData) => async (dispatch) => {
  try {
    dispatch({ type: VP_ATTACHMENTS_REQUEST });

    dispatch({ type: VP_ATTACHMENTS_SUCCESS, payload: fileData });
  } catch (error) {
    dispatch({ type: VP_ATTACHMENTS_FAIL, payload: error });
  }
};

export const VendorAttachmentsSubmit = () => async (dispatch) => {
  dispatch({ type: VP_ATTACHMENTS_DISPATCH, payload: "" });
};

export const VendorAttachmentsDelete = (fileIndex) => async (dispatch) => {
  dispatch({ type: VP_ATTACHMENTS_DELETE, payload: fileIndex });
};

export const VendorProfileBankDetails = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: VP_BANKDETAILS_REQUEST });

    dispatch({ type: VP_BANKDETAILS_SUCCESS, payload: { name, value } });
  } catch (error) {
    dispatch({ type: VP_BANKDETAILS_FAIL, payload: error.messsage });
  }
};

export const VendorProfileBankDetailsEdit = (row, index) => async (
  dispatch
) => {
  try {
    dispatch({ type: VP_BANKDETAILS_EDIT, payload: { row, index } });
  } catch (error) {}
};
export const VendorProfileBankDetailsClear = () => async (dispatch) => {
  try {
    dispatch({ type: VP_BANKDETAILS_CLEAR, payload: "" });
  } catch (error) {
    dispatch({ type: VP_BANKDETAILS_CLEAR, payload: error.messsage });
  }
};
export const VendorBankDetailsSubmit = (editIndex) => async (dispatch) => {
  dispatch({ type: VP_BANKDETAILS_DISPATCH, payload: { editIndex } });
};

export const VendorBankDetailsDelete = (fileIndex) => async (dispatch) => {
  dispatch({ type: VP_BANKDETAILS_DELETE, payload: fileIndex });
};

export const VendorProfileAddress = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: VP_ADDRESS_REQUEST });

    dispatch({ type: VP_ADDRESS_SUCCESS, payload: { name, value } });
  } catch (error) {
    dispatch({ type: VP_ADDRESS_FAIL, payload: error.messsage });
  }
};

export const VendorProfileAddressClear = () => async (dispatch) => {
  try {
    dispatch({ type: VP_ADDRESS_CLEAR, payload: "" });
  } catch (error) {
    dispatch({ type: VP_ADDRESS_FAIL, payload: error.messsage });
  }
};

export const VendorProfileAddressEdit = (row, index) => async (dispatch) => {
  try {
    dispatch({ type: VP_ADDRESS_EDIT, payload: { row, index } });
  } catch (error) {
    dispatch({ type: VP_ADDRESS_FAIL, payload: error.messsage });
  }
};

export const VendorAddressSubmit = (editIndex) => async (dispatch) => {
  dispatch({ type: VP_ADDRESS_DISPATCH, payload: { editIndex } });
};

export const VendorAddressDelete = (fileIndex) => async (dispatch) => {
  dispatch({ type: VP_ADDRESS_DELETE, payload: fileIndex });
};

export const compliance = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: VP_COMPLIANCE_REQUEST });

    dispatch({ type: VP_COMPLIANCE_SUCCESS, payload: { name, value } });
  } catch (error) {
    dispatch({ type: VP_COMPLIANCE_FAIL, payload: error.messsage });
  }
};
export const others = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: VP_OTHERS_REQUEST });

    dispatch({ type: VP_OTHERS_SUCCESS, payload: { name, value } });
  } catch (error) {
    dispatch({ type: VP_OTHERS_FAIL, payload: error.messsage });
  }
};

export const submitVendorProfile = ({
  generalinfo,
  saved_contacts,
  saved_address,
  saved_site,
  isEdit,
  selfRegister,
  CreatedBy,
  LastUpdatedBy,
  user,
  recaptchaToken,
  vendorToken,
}) => async (dispatch) => {
  let emailLower = generalinfo?.email;
  let email = emailLower.toLowerCase();
  try {
    dispatch({ type: VP_GENERAL_SUBMIT_REQUEST });

    let senddata = {
      ...generalinfo,
      email: email,
      contacts: saved_contacts,
      address: saved_address,
      supplierSites: saved_site,
      isEdit: isEdit,
      selfRegister: selfRegister,
      CreatedBy: CreatedBy,
      LastUpdatedBy: LastUpdatedBy,
      user: user,
      recaptchaToken,
    };

    const { data } = await axios({
      method: "post",
      headers: {
        cooljwt: vendorToken,
      },
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/saveVendorGeneralInfo`,
      data: senddata,
    });
    // destructure data
    let filterdata = data?.level1 || [];
    dispatch({ type: VP_GENERAL_SUBMIT_SUCCESS, payload: filterdata });
    if (!data?.isUpdated) {
      dispatch({ type: VP_TOKEN, payload: data });
    }
  } catch (error) {
    dispatch({
      type: VP_GENERAL_SUBMIT_FAIL,
      payload: error.response?.data || "",
    });
  }
};
export const submitCompliance = (
  compliancedata,
  email,
  updatedBy,
  recaptchaToken,
  token
) => async (dispatch) => {
  let sendData = {
    email: email,
    compliance: compliancedata,
    updatedBy,
    recaptchaToken,
  };
  try {
    dispatch({ type: VP_COMPLIANCE_SUBMIT_REQUEST });
    const { data } = await axios({
      method: "post",
      headers: {
        cooljwt: token,
      },
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/saveCompliance`,
      data: sendData,
    });
    dispatch({ type: VP_COMPLIANCE_SUBMIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VP_COMPLIANCE_SUBMIT_FAIL,
      payload: error.response?.data || "",
    });
  }
};
export const submitOthers = (
  othersdata,
  email,
  updatedBy,
  recaptchaToken,
  token
) => async (dispatch) => {
  let sendData = {
    email: email,
    others: othersdata,
    updatedBy,
    recaptchaToken,
  };
  try {
    dispatch({ type: VP_OTHERS_SUBMIT_REQUEST });
    const { data } = await axios({
      method: "post",
      headers: {
        cooljwt: token,
      },
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/saveOthers`,
      data: sendData,
    });

    dispatch({ type: VP_OTHERS_SUBMIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VP_OTHERS_SUBMIT_FAIL,
      payload: error.response?.data || "",
    });
  }
};
export const submitAttachments = (
  saved_attachments,
  email,
  updatedBy,
  recaptchaToken,
  token
) => async (dispatch) => {
  let sendData = {
    attachments: saved_attachments,
    email: email,
    updatedBy,
    recaptchaToken,
  };
  try {
    dispatch({ type: VP_ATTACHMENTS_SUBMIT_REQUEST });
    const { data } = await axios({
      method: "post",
      headers: {
        cooljwt: token,
      },
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/saveVendorAttachments`,
      data: sendData,
      // headers: { cooljwt: Token }
    });

    dispatch({ type: VP_ATTACHMENTS_SUBMIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: VP_ATTACHMENTS_SUBMIT_FAIL, payload: error?.response?.data });
  }
};
export const submitBankDetails = (
  saved_bankdetails,
  email,
  updatedBy,
  recaptchaToken,
  token
) => async (dispatch) => {
  let sendData = {
    bankDetails: saved_bankdetails,
    email: email,
    updatedBy,
    recaptchaToken,
  };

  try {
    dispatch({ type: VP_BANK_DETAILS_SUBMIT_REQUEST });
    const { data } = await axios({
      method: "post",
      headers: {
        cooljwt: token,
      },
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/saveVendorBankInfo`,
      data: sendData,
    });
    //destructure data
    let filterdata = data?.level3?.bankDetails || [];
    dispatch({ type: VP_BANK_DETAILS_SUBMIT_SUCCESS, payload: filterdata });
  } catch (error) {
    dispatch({ type: VP_BANK_DETAILS_SUBMIT_FAIL, payload: error.messsage });
  }
};
export const getCountry = () => async (dispatch) => {
  try {
    dispatch({ type: VP_GETCOUNTRY_REQUEST });
    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getCountry`,
    });

    // destructure data
    let filteredData = data.items.map((res) => {
      if (res.EnabledFlag == "Y") {
        return {
          TerritoryCode: res.TerritoryCode,
          TerritoryShortName: res.TerritoryShortName,
        };
      }
    });
    dispatch({ type: VP_GETCOUNTRY_SUCCESS, payload: filteredData });
  } catch (error) {
    dispatch({ type: VP_GETCOUNTRY_FAIL, payload: error.messsage });
  }
};

export const getCity = (countryCode) => async (dispatch) => {
  let sendData = {
    countryCode: countryCode,
  };
  try {
    dispatch({ type: VP_CITY_REQUEST });
    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getCitiesByCountry`,
      data: sendData,
    });

    dispatch({ type: VP_CITY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: VP_CITY_FAIL, payload: error.messsage });
  }
};

export const getBankDetails = (selectcountry) => async (dispatch) => {
  try {
    dispatch({ type: VP_GETBANK_REQUEST });
    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/cashBank`,
      data: {
        selectcountry,
      },
    });
    // destructure data
    let filteredData = data.items;
    dispatch({ type: VP_GETBANK_SUCCESS, payload: filteredData });
  } catch (error) {
    dispatch({ type: VP_GETBANK_FAIL, payload: error.messsage });
  }
};
export const getBankBranches = (BankPartyId) => async (dispatch) => {
  try {
    dispatch({ type: VP_BRANCHDETAILS_REQUEST });
    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/bankBranches/${BankPartyId}`,
    });
    // destructure data
    let filteredData = data.items;
    dispatch({ type: VP_BRANCHDETAILS_SUCCESS, payload: filteredData });
    // .then((res) => {
    //     dispatch({ type: VP_GENERAL_SUBMIT_SUCCESS, payload: data})
    //     dispatch({type: VP_GENERAL_SUBMIT_CLEAR})
    // })
  } catch (error) {
    // dispatch({type: VP_GENERAL_SUBMIT_CLEAR})
    dispatch({ type: VP_BRANCHDETAILS_FAIL, payload: error.messsage });
  }
};
export const getCurrency = () => async (dispatch) => {
  try {
    dispatch({ type: VP_GETCURRENCY_REQUEST });
    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getCurrency`,
    });

    // destructure data
    let filteredData = data.items.map((res) => {
      if (res.EnabledFlag == "Y") {
        return {
          CurrencyCode: res.CurrencyCode,
          Name: res.Name,
        };
      }
    });
    dispatch({ type: VP_GETCURRENCY_SUCCESS, payload: filteredData });
    // .then((res) => {
    //     dispatch({ type: VP_GENERAL_SUBMIT_SUCCESS, payload: data})
    //     dispatch({type: VP_GENERAL_SUBMIT_CLEAR})
    // })
  } catch (error) {
    // dispatch({type: VP_GENERAL_SUBMIT_CLEAR})
    dispatch({ type: VP_GETCURRENCY_FAIL, payload: error.messsage });
  }
};
export const clearAllState = () => (dispatch) => {
  dispatch({ type: VP_CLEAR_ALL_STATE });
};
export const GetDetails = (id, Token) => async (dispatch) => {
  try {
    dispatch({ type: GET_DETAILS_BY_ID_REQUEST });
    const { data } = await axios({
      method: "get",
      url: `${
        process.env.REACT_APP_LDOCS_API_URL
      }/vendor/getSupplierById/${id}/${false}`,
      headers: { cooljwt: Token },
    });

    dispatch({ type: GET_DETAILS_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_DETAILS_BY_ID_FAIL, payload: error });
  }
};
export const GetSupplierType = () => async (dispatch) => {
  try {
    dispatch({ type: SUPPLIER_TYPE_REQUEST });
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getSupplierTypes`,
    });

    dispatch({ type: SUPPLIER_TYPE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUPPLIER_TYPE_FAIL, payload: error });
  }
};

export const GetOrganizationType = () => async (dispatch) => {
  try {
    dispatch({ type: ORGANIZATION_TYPE_REQUEST });
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/organizationType`,
    });

    dispatch({ type: ORGANIZATION_TYPE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ORGANIZATION_TYPE_FAIL, payload: error });
  }
};
export const GetAttachmentType = () => async (dispatch) => {
  try {
    dispatch({ type: ATTACHMENT_TYPE_REQUEST });
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getAttachmentDoc`,
    });

    dispatch({ type: ATTACHMENT_TYPE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ATTACHMENT_TYPE_FAIL, payload: error });
  }
};
export default VendorProfile;
