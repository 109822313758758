import axios from "axios";
import { Purchase_Order_LIST_REQUEST, Purchase_Order_LIST_SUCCESS, Purchase_Order_LIST_FAIL, Purchase_Order_SUCCESS_FILTERS } from "./Constant";

const PurchaseOrderList = ({ page, rowPerPage, Token, isReceipt }) => async (dispatch) => {

    try {
        dispatch({ type: Purchase_Order_LIST_REQUEST })
        let url = !isReceipt ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getAllPo?page=${page}&items=${rowPerPage}` :
            `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getAllOpenPo?page=${page}&items=${rowPerPage}`
        const { data } = await axios.get(url,
            {
                headers: {
                    cooljwt: Token
                }
            });

        dispatch({ type: Purchase_Order_LIST_SUCCESS, payload: data })
    }
    catch (error) {

        dispatch({ type: Purchase_Order_LIST_FAIL, payload: error.messsage })
    }
}

export const POFilter = ({ page, rowPerPage, Token, newdata, isReceipt }) => async (dispatch) => {
    dispatch({ type: Purchase_Order_LIST_REQUEST })
    try {
        //Getting Filter Suppliers
        const { data } = await axios({
            method: "post",
            url:  !isReceipt ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/filterPO?page=${page}&items=${rowPerPage}` :
            `${process.env.REACT_APP_LDOCS_API_URL}/invoice/filterOpenPO?page=${page}&items=${rowPerPage}`,
            data: newdata,
            headers: { cooljwt: Token }

        })
        //Destrurcturing Data
        dispatch({ type: Purchase_Order_SUCCESS_FILTERS, payload: data })
    }
    catch (error) {
        console.log(error)
    }
}
export default PurchaseOrderList