import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  MenuItem,
  TablePagination,
  TextField,
  Chip,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useSelector, useDispatch } from "react-redux";
import { setIsTokenExpired } from "actions";
import { msgAlert } from "Functions";
import { errorAlert } from "Functions";
import { Autocomplete } from "@material-ui/lab";
import { Forward, Visibility } from "@material-ui/icons";
import {
  Menu,
  Item,
  Separator,
  animation,
  MenuProvider,
  theme,
  Submenu,
} from "react-contexify";
import { formatDateTime } from "Functions";
import { Link } from "react-router-dom";
import Pending from "assets/img/statuses/Pending.png";
import Success from "assets/img/statuses/Success.png";
import Rejected from "assets/img/statuses/Rejected.png";
import NoStatus from "assets/img/statuses/NoStatus.png";
import Iframe from "react-iframe";
import ReceiveReceipt from "views/LDocs/Receipts/ReceiveReceipt";
import { successAlert } from "views/LDocs/Functions/Functions";
import InfoIcon from "@material-ui/icons/Info";
import TrackingStatus from "views/Pages/Supplier/TrackingStatus";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: "relative",
    maxWidth: 360,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {
    minWidth: "10%",
  },
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "3%",
  },
}));

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function UsersList() {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const dispatch = useDispatch();
  const classes = useStyles();
  const classesList = useStyle();
  const [classicModal, setClassicModal] = useState(false);
  const [animateTable, setAnimateTable] = useState(true);
  const [animateTableView, setAnimateTableView] = useState(true);
  const [pdfModalData, setPdfModalData] = React.useState(false);
  const [animatePdf, setAnimatePdf] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = useState();
  const [userList, setUserList] = useState([]);
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [filters, setFilters] = useState({
    user: "",
    userEmail: "",
    type: "supplier",
  });
  const [taskList, setTaskList] = useState({
    suppliers: [],
    receipts: [],
    invoices: [],
  });
  const [fowardTaskUser, setFowardTaskUser] = useState({
    loading: false,
    id: "",
    forwardUser: "",
    forwardUserEmail: "",
    notes: "",
  });
  // for supplier
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState(false);

  const [initialReview, setInitialReview] = React.useState("");
  const [review, setReview] = React.useState("");
  const [approve, setApprove] = React.useState("");
  const [approveExported, setApproveExported] = React.useState("");
  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);
  //...........
  // for receipt
  const [openTrackingModalRec, setOpenTrackingModalRec] = useState(false);
  const [approveRec, setApproveRec] = React.useState("");
  const [approveExportedRec, setApproveExportedRec] = React.useState("");

  // for supplier
  const _trackingStatus = (selectedSupplier) => {
    setOpenTrackingModal(true);
    setSelectedWorkFlow(selectedSupplier?.workFlowStatus);
    setInitialReview(selectedSupplier?.workFlowStatus?.initialReviewStep);
    setReview(selectedSupplier?.workFlowStatus?.reviewStep);
    setApprove(selectedSupplier?.workFlowStatus?.approveStep);
    setApproveExported(selectedSupplier?.workFlowStatus?.exportStep);
  };
  // for receipt
  const _trackingStatusRec = (selectedReceipt) => {
    let workFlowStatus = selectedReceipt?.workFlowStatus;
    setOpenTrackingModalRec(true);
    setApproveRec(workFlowStatus?.approveStep);
    setApproveExportedRec(workFlowStatus?.exportStep);
  };

  const closeTrackingModal = (type) => {
    if (type === "supplier") {
      setOpenTrackingModal(false);
      setInitialReview("");
      setReview("");
      setApprove("");
      setApproveExported("");
    }
    if (type === "receipt") {
      setOpenTrackingModalRec(false);
      setApproveRec("");
      setApproveExportedRec("");
    }
  };
  const viewFile = (row) => {
    debugger;
    setIsViewing(false);
    setPdfModalData(row);
    setPdfUrl(
      `${process.env.REACT_APP_LDOCS_API_URL}/${row.invoicePath}/${row.invoiceId}.pdf`
    );
    setAnimateTable(false);
    setIsViewing(true);
    setAnimatePdf(true);
  };

  const goBack = () => {
    setPdfUrl();
    setIsViewing(false);
    setAnimateTable(true);
    setAnimatePdf(false);
    setPdfModalData("");
  };

  const forwardTask = (selected) => {
    setFowardTaskUser((forwardTaskUser) => ({
      ...forwardTaskUser,
      ["id"]: selected._id,
    }));
    setClassicModal(true);
  };

  const _getAllUsers = async (search) => {
    setGetUserLoading(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getUser`,
      headers: { cooljwt: Token },
      data: {
        enterData: search,
      },
    })
      .then((response) => {
        setGetUserLoading(false);
        setUserList(response?.data?.value);
      })
      .catch((error) => {
        setGetUserLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        let msg =
          typeof error.response != "undefined"
            ? error.response.data
            : error.message;
        errorAlert(msg);
      });
  };

  const getPendingTask = async (user, type) => {
    //Pending Suppliers
    if (type === "supplier") {
      setIsLoading(true);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getSupplierPendingTasks`,
        data: { user: user?.toLowerCase(), type },
        headers: { cooljwt: Token },
      })
        .then((response) => {
          setTaskList((taskList) => ({
            ...taskList,
            suppliers: response.data?.supplier,
          }));
          tableData("supplier", response.data?.supplier);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error?.response) {
            error?.response?.status == 401 && dispatch(setIsTokenExpired(true));
          }
          setIsLoading(false);
          errorAlert(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data
          );
        });
    }
    //Pending Receipts
    if (type === "receipt") {
      setIsLoading(true);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/receipt/getReceiptPendingTasks`,
        data: { user: user?.toLowerCase(), type },
        headers: { cooljwt: Token },
      })
        .then((response) => {
          setTaskList((taskList) => ({
            ...taskList,
            receipts: response.data.receipt_of,
          }));
          // tableData('receipt', response.data?.receipt);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error?.response) {
            error?.response?.status == 401 && dispatch(setIsTokenExpired(true));
          }
          setIsLoading(false);
          errorAlert(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data
          );
        });
    }
    //Pending Invoices
    if (type === "invoice") {
      setIsLoading(true);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getInvoicePendingTasks`,
        data: { user: user?.toLowerCase(), type },
        headers: { cooljwt: Token },
      })
        .then((response) => {
          setTaskList((taskList) => ({
            ...taskList,
            invoices: response.data?.invoice,
          }));
          tableData("invoice", response.data?.invoice);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error?.response) {
            error?.response?.status == 401 && dispatch(setIsTokenExpired(true));
          }
          setIsLoading(false);
          errorAlert(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data
          );
        });
    }
  };
  const _forwardTaskUser = async () => {
    setFowardTaskUser((forwardTaskUser) => ({
      ...forwardTaskUser,
      loading: true,
    }));
    await axios({
      method: "put",
      url:
        filters.type === "supplier"
          ? `${process.env.REACT_APP_LDOCS_API_URL}/vendor/updateVendorWorkflow/${fowardTaskUser.id}`
          : filters.type === "receipt"
          ? `${process.env.REACT_APP_LDOCS_API_URL}/receipt/updateReceiptWorkflow/${fowardTaskUser.id}`
          : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/updateInvoiceTrackingStatus`,
      data:
        filters.type === "invoice"
          ? {
              filteredUser: filters.user,
              filteredUserEmail: filters.userEmail,
              forwardUser: fowardTaskUser.forwardUser,
              forwardUserEmail: fowardTaskUser.forwardUserEmail,
              originalInvoiceId: fowardTaskUser.id,
              notes: fowardTaskUser.notes,
            }
          : {
              filteredUser: filters.user,
              filteredUserEmail: filters.userEmail,
              forwardUserEmail: fowardTaskUser.forwardUserEmail,
              forwardUser: fowardTaskUser.forwardUser,
              notes: fowardTaskUser.notes,
            },
      headers: { cooljwt: Token },
    })
      .then((response) => {
        getPendingTask(filters.userEmail, filters.type);
        setFowardTaskUser((forwardTaskUser) => ({
          ...forwardTaskUser,
          loading: false,
        }));
        setClassicModal(false);
        successAlert("Task forward to selected user");
      })
      .catch((error) => {
        if (error?.response) {
          error?.response?.status == 401 && dispatch(setIsTokenExpired(true));
        }
        setFowardTaskUser((forwardTaskUser) => ({
          ...forwardTaskUser,
          loading: false,
        }));
        setClassicModal(false);
        errorAlert(
          error?.response?.data
            ? error?.response?.data
            : error?.response?.data?.msg
        );
      });
  };

  const getSteps = (step) => {
    step = {
      ...step,
      forward: step?.forward
        ?.map((step) => ({ ...step, showReason: true }))
        .concat(
          step?.delegate?.map((step) => ({ ...step, showReason: false }))
        ),
    };
    let filteredStep = step?.forward.filter((x) => x !== undefined);
    return {
      ...step,
      forward: filteredStep?.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      ),
    };
  };

  const showFwdNotes = (step) => {
    if (step === "initialReview") {
      let step = getSteps(initialReview);
      setShowFwdData(step);
    } else if (step === "review") {
      let step = getSteps(review);
      setShowFwdData(step);
    } else {
      let step = getSteps(approve);
      setShowFwdData(step);
    }
    setShowFwdDetails(true);
  };

  const tableData = (type, response) => {
    if (type === "supplier") {
      setData(
        response.map((prop, key) => {
          let status =
            prop?.workFlowStatus[prop?.workFlowStatus?.status]?.status;
          return {
            id: prop?._id,
            name: prop.level1?.Supplier,
            status: (
              <MenuProvider
                style={{ paddingTop: 40, paddingBottom: 40 }}
                data={prop}
                id="menu_id"
              >
                <Tooltip title={prop?.level1?.supplierStatus?.toUpperCase()}>
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    // avatar={<Avatar>M</Avatar>}
                    label={prop?.level1?.supplierStatus?.toUpperCase()}
                    clickable
                    onClick={() => _trackingStatus(prop)}
                  />
                </Tooltip>
              </MenuProvider>
            ),
            number: prop?.level1?.SupplierNumber,
            createdBy: (
              <MenuProvider
                style={{ paddingTop: 40, paddingBottom: 40 }}
                data={prop}
                id="menu_id"
              >
                {prop?.level1?.email}
              </MenuProvider>
            ),
            createdDate: (
              <MenuProvider
                style={{ paddingTop: 40, paddingBottom: 40 }}
                data={prop}
                id="menu_id"
              >
                {formatDateTime(prop.level1?.CreationDate)}
              </MenuProvider>
            ),
            actions: (
              <div className="actions-right">
                <Tooltip title="View Supplier Details" aria-label="viewDetails">
                  <Link to={`/admin/vendor_profile/${prop._id}/${true}`}>
                    <IconButton>
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Forward Task" aria-label="viewDetails">
                  <IconButton onClick={() => forwardTask(prop)}>
                    <Forward fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          };
        })
      );
    }
    if (type === "receipt") {
      setData(
        response.map((prop, key) => {
          let status = prop?.workFlowStatus?.status;
          return {
            id: prop?._id,
            receiptNo: prop.ReceiptNumber,
            status: (
              <MenuProvider
                style={{ paddingTop: 40, paddingBottom: 40 }}
                data={prop}
                id="menu_id"
              >
                <Tooltip title={status?.toUpperCase()}>
                  <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    // avatar={<Avatar>M</Avatar>}
                    label={status?.toUpperCase()}
                    clickable
                    onClick={() => _trackingStatusRec(prop)}
                  />
                </Tooltip>
              </MenuProvider>
            ),
            poNumber: prop?.PONumber,
            createdBy: (
              <MenuProvider
                style={{ paddingTop: 40, paddingBottom: 40 }}
                data={prop}
                id="menu_id"
              >
                {prop?.CreatedBy}
              </MenuProvider>
            ),
            vendorName: (
              <MenuProvider
                style={{ paddingTop: 40, paddingBottom: 40 }}
                data={prop}
                id="menu_id"
              >
                {prop?.VendorName}
              </MenuProvider>
            ),
            actions: (
              <div className="actions-right">
                <Tooltip title="Forward Task" aria-label="viewDetails">
                  <IconButton onClick={() => forwardTask(prop)}>
                    <Forward fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          };
        })
      );
    }
    if (type === "invoice") {
      setData(
        response.map((prop, key) => {
          return {
            id: prop._id,
            fileName: prop.originalInvoiceId,
            requestedBy: prop.requestedBy,
            reviewedBy: prop.reviewedBy ? prop.reviewedBy : prop.approvedBy,
            createdDate: formatDateTime(prop?.createdDate),
            status:
              prop.status === "pending" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Pending} alt={prop.status} />
                  </div>
                </div>
              ) : prop.status === "reviewed" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Success} alt={prop.status} />
                  </div>
                </div>
              ) : prop.status === "rejected" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Rejected} alt={prop.status} />
                  </div>
                </div>
              ) : (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={NoStatus} alt={prop.status} />
                  </div>
                </div>
              ),
            version: prop.version,
            actions: (
              <div className="actions-right">
                <Tooltip title="View Invoice" aria-label="viewfile">
                  <Button
                    justIcon
                    round
                    simple
                    icon={VisibilityIcon}
                    onClick={() => viewFile(prop)}
                    color="warning"
                    className="View"
                  >
                    <VisibilityIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Forward Task" aria-label="viewDetails">
                  <IconButton
                    onClick={() =>
                      forwardTask({ _id: prop?.originalInvoiceId })
                    }
                  >
                    <Forward fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          };
        })
      );
    }
  };

  const handleFilter = (event) => {
    event.persist();
    const { name, value } = event.target;
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
    if (filters.user) {
      getPendingTask(filters.userEmail, value);
    }
  };

  return (
    <div>
      {isViewing ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animatePdf}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      Invoice: {pdfModalData.originalInvoiceId}
                    </h4>
                  </CardIcon>
                  <Button
                    color="info"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <Iframe
                    url={pdfUrl}
                    width="100%"
                    id="myId"
                    allow="print 'none'; download 'none'"
                    className="myClassname"
                    height={window.screen.height}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
      <GridContainer justify="center">
        {!isViewing && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Filter</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <Autocomplete
                      id="filter-demo"
                      options={userList}
                      // value={filters.user || ""}
                      variant="standard"
                      getOptionLabel={(option) =>
                        option?.displayName?.toUpperCase()
                      }
                      loading={getUserLoading}
                      onInputChange={(event, newInputValue) => {
                        _getAllUsers(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFilters((filters) => ({
                            ...filters,
                            ["user"]: `${newValue?.surname}, ${newValue?.givenName}`,
                            ["userEmail"]: newValue?.mail,
                          }));
                          getPendingTask(newValue?.mail, filters.type);
                        } else {
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Users"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {getUserLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}

                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          // value={filters.user}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <TextField
                      className={classes.textField}
                      fullWidth={true}
                      label="Type"
                      name="type"
                      onChange={(event) => {
                        handleFilter(event);
                      }}
                      select
                      value={filters.type}
                    >
                      <MenuItem value={"invoice"}>Invoice</MenuItem>
                      <MenuItem value={"receipt"}>Receipt</MenuItem>
                      <MenuItem value={"supplier"}>Supplier</MenuItem>
                    </TextField>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {classicModal && (
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              open={classicModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setClassicModal(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <h4 className={classes.cardTitleText}>Forward Task</h4>
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Autocomplete
                          id="filter-demo"
                          options={userList}
                          //   value={state.values.Department_CostCenter || ""}
                          variant="standard"
                          getOptionLabel={(option) =>
                            option?.displayName?.toUpperCase()
                          }
                          loading={getUserLoading}
                          onInputChange={(event, newInputValue) => {
                            _getAllUsers(newInputValue);
                          }}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setFowardTaskUser((forwardTaskUser) => ({
                                ...forwardTaskUser,
                                ["forwardUser"]: `${newValue?.surname}, ${newValue?.givenName}`,
                                ["forwardUserEmail"]: newValue?.mail,
                              }));
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Users"
                              //   value={state.values.Department_CostCenter}
                            />
                          )}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginBottom: "10px" }}
                      >
                        <TextField
                          fullWidth={true}
                          label="Notes"
                          id="notes"
                          name="notes"
                          onChange={(event) => {
                            event.persist();
                            setFowardTaskUser((forwardTaskUser) => ({
                              ...forwardTaskUser,
                              ["notes"]: event.target.value,
                            }));
                          }}
                          type="text"
                          value={fowardTaskUser.notes}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <span style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      color="primary"
                      className={classes.registerButton}
                      onClick={_forwardTaskUser}
                      round
                    >
                      {fowardTaskUser.loading ? (
                        <CircularProgress color="white" />
                      ) : (
                        " SUBMIT"
                      )}
                    </Button>
                    <Button
                      color="danger"
                      className={classes.registerButton}
                      onClick={() => setClassicModal(false)}
                      round
                    >
                      CLOSE
                    </Button>
                  </span>
                </Card>
              </DialogContent>
            </Dialog>
          </GridItem>
        )}
        {/*FOR SUPPLIER TRACKING STATUS */}
        {openTrackingModal ? (
          <TrackingStatus
            workflow={selectedWorkFlow}
            openTrackingModal={openTrackingModal}
            setOpenTrackingModal={setOpenTrackingModal}
          />
        ) : (
          ""
        )}
        {showFwdDetails ? (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={showFwdDetails}
            // TransitionComponent={Transition}
            keepMounted
            onClose={() => setShowFwdDetails(false)}
            aria-labelledby="tag-modal-slide-title"
            aria-describedby="tag-modal-slide-description"
          >
            <DialogContent
              id="tag-modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <h4 className={classes.cardTitle}>
                        Forward/Deligate Details
                      </h4>
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Foward/Deligate User</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ paddingBottom: 5 }}>
                        {showFwdData &&
                          showFwdData?.forward?.map((fwd, index) => (
                            <>
                              <TableRow
                                className={classes.TableRow}
                                key={index}
                              >
                                <TableCell className={classes.TableCell}>
                                  {formatDateTime(fwd?.date)}
                                </TableCell>
                                <TableCell>{fwd?.noteMsg}</TableCell>
                              </TableRow>
                              {fwd?.showReason && (
                                <TableRow
                                  className={classes.TableRow}
                                  key={index}
                                >
                                  <TableCell colSpan={3}>
                                    <b>Reason:</b> {fwd?.reason}
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
                <Button
                  color="danger"
                  className={classes.registerButton}
                  onClick={() => setShowFwdDetails(false)}
                  round
                  style={{ float: "right", marginLeft: "auto" }}
                >
                  CLOSE
                </Button>
              </GridContainer>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}
        {/*FOR RECEIPT TRACKING STATUS */}
        {openTrackingModalRec ? (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={openTrackingModalRec}
            // TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenTrackingModalRec(false)}
            aria-labelledby="tag-modal-slide-title"
            aria-describedby="tag-modal-slide-description"
          >
            <DialogContent
              id="tag-modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <h4 className={classes.cardTitle}>Tracking Status</h4>
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableID}>
                            Approve
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned To
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned Date
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Status
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Action Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ paddingBottom: 5 }}>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve
                          </TableCell>

                          <TableCell>
                            {approveRec?.approvedBy || "-"}
                            <br />
                            {approveRec?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {approveRec?.assignDate
                              ? formatDateTime(approveRec?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {approveRec?.status?.toUpperCase() || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {approveRec?.status !== "pending" &&
                            approveRec?.date
                              ? formatDateTime(approveRec?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve and Export
                          </TableCell>
                          <TableCell>
                            {approveExportedRec?.exportedBy || "-"}
                            <br />
                            {approveExportedRec?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {approveExportedRec?.assignDate
                              ? formatDateTime(approveExportedRec?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {approveExportedRec?.status?.toUpperCase() ||
                              "PENDING"}
                          </TableCell>
                          <TableCell>
                            {approveExportedRec?.status !== "pending" &&
                            approveExportedRec?.date
                              ? formatDateTime(approveExportedRec?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
                <Button
                  color="danger"
                  className={classes.registerButton}
                  onClick={() => closeTrackingModal("receipt")}
                  round
                  style={{ float: "right", marginLeft: "auto" }}
                >
                  CLOSE
                </Button>
              </GridContainer>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}
      </GridContainer>
      {animateTableView ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateTable}
        >
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>Pending Task List</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {isLoading ? (
                    <CircularProgress disableShrink />
                  ) : (
                    <>
                      {filters.type === "receipt" &&
                      taskList.receipts?.length > 0 ? (
                        <ReceiveReceipt
                          isPendingView={true}
                          receipts={taskList.receipts}
                          forwardTask={forwardTask}
                          // onClick={() => forwardTask(prop)}
                        />
                      ) : filters.type === "receipt" &&
                        taskList.receipts?.length === 0 ? (
                        <>No Record Found</>
                      ) : (
                        <ReactTable
                          data={data}
                          sortable={false}
                          columns={
                            filters.type === "supplier"
                              ? [
                                  {
                                    Header: "Name",
                                    accessor: "name",
                                  },
                                  {
                                    Header: "Status",
                                    accessor: "status",
                                  },
                                  {
                                    Header: "Number",
                                    accessor: "number",
                                  },
                                  {
                                    Header: "Email",
                                    accessor: "createdBy",
                                  },
                                  {
                                    Header: "Created Date",
                                    accessor: "createdDate",
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                  },
                                ]
                              : filters.type === "invoice"
                              ? [
                                  {
                                    Header: "Invoice Number",
                                    accessor: "fileName",
                                  },
                                  {
                                    Header: "Version",
                                    accessor: "version",
                                  },
                                  {
                                    Header: "Requested By",
                                    accessor: "requestedBy",
                                  },
                                  {
                                    Header: "Pending To",
                                    accessor: "reviewedBy",
                                  },
                                  {
                                    Header: "Status",
                                    accessor: "status",
                                  },
                                  {
                                    Header: "Created Date",
                                    accessor: "createdDate",
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                  },
                                ]
                              : []
                          }
                          defaultPageSize={20}
                          showPaginationTop
                          showPaginationBottom
                          className="-striped -highlight"
                        />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
    </div>
  );
}
