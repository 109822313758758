import React, { useEffect, useState } from "react";

// @material-ui/core components
import {
  makeStyles, Checkbox, Typography
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMediaQuery } from 'react-responsive'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../../actions";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import axios from "axios";

import { redirect } from 'react-router-dom';

//Azure
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "azure/authConfig";
import { callMsGraph } from "azure/graph";
import { errorAlert } from "Functions";
import { FormControlLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import leftIMage from 'assets/img/login/left-image.png';
import actionButton from 'assets/img/login/login-salic.png';

const useStyles = makeStyles(styles);



export default function LoginPage(props) {
  const [loginnameError, setloginnameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiErrorMessage, setAPIErrorMessage] = useState("");
  const [logging, setlogging] = useState(false);
  const [loginName, setloginname] = useState("");
  const [password, setPassword] = useState("");
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [isActiveDir, setIsaActiveDir] = useState(true);
  const [activeDirectoryUser, setActiveDirectoryUser] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })



  const handleLoginIn = async (loginName) => {
    // event.preventDefault();
    setAPIErrorMessage("");
    await axios
      .post(`${process.env.REACT_APP_LDOCS_API_URL}/user/userLogin`, {
        email: loginName,
        password: password,
        isActiveDir: isActiveDir
      })
      .then(async (response) => {
        let token = response.headers.cooljwt;
        localStorage.setItem("cooljwt", token);
        dispatch(setToken(token));
        setloginnameError("success");
        setPasswordError("success");
        props.loginSuccess(response);
      })
      .catch((error) => {


        let msg = typeof error.response != "undefined"
          ? error.response.data
          : error.message;
        //Shows Alert
        Swal.fire({
          title: "Error",
          text: msg,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#49b34e",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          //On click OK
          instance.logoutPopup().then(() => {
            //On logout of User
            localStorage.clear();
            dispatch(setToken(null));
            window.location.reload();
            // loginAzure();
            // dispatch(logoutUserAction());
          });
        });
        // history.push('/');
        setlogging(false);
        setloginnameError("error");
        setPasswordError("error");
        setAPIErrorMessage(typeof error.response != "undefined"
          ? error.response.data
          : error.message);
        setTimeout(function () {
          setAPIErrorMessage("");
        }, 2000);
      });
  };

  const loginAzure = () => {
    setlogging(true);
    instance.loginPopup(loginRequest).then(c => {
      callMsGraph(c.accessToken).then(response => {
        let { displayName, surname, mail, givenName, id, userPrincipalName } = response || {};
        handleLoginIn(mail || userPrincipalName)
      });
    }).catch((err) => {
      setlogging(false);
      console.log(err);
    });
  }


  useEffect(() => {
    loginAzure();
  }, []);
 


  return (
    <div >
      {isTabletOrMobile ? 
      <GridContainer justify="center">
        {/* <GridItem xs={12} sm={6} md={6}>
          <img src={leftIMage} width={450} />
        </GridItem> */}
        <GridItem xs={12} sm={12} md={12}>
          <CardFooter className={classes.justifyContentCenter} style={{display:"flex", flexDirection:"column", marginTop:"150px"}}>
          <Typography component="h2" variant="h4" style={{color:"#054b8a", wordSpacing:"6px", paddingBottom:"25px", fontWeight:"600"}}>
              WELCOME TO
            </Typography>
            <Typography component="h2" variant="h4" style={{color:"#054b8a", wordSpacing:"6px", paddingBottom:"25px", fontWeight:"600"}}>
              SALIC INVOICE PORTAL
            </Typography>
            {logging ?
              <CircularProgress style={{ color: "054b8a" }} /> :
                <img src={actionButton} onClick={loginAzure} role="button" width={200} style={{cursor:"pointer"}} />
            }


          </CardFooter>


        </GridItem>
      </GridContainer>
      :
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img src={leftIMage} width={450} />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CardFooter className={classes.justifyContentCenter} style={{display:"flex", flexDirection:"column", marginTop:"150px"}}>
          <Typography component="h2" variant="h4" style={{color:"#054b8a", wordSpacing:"6px", paddingBottom:"25px", fontWeight:"600"}}>
              WELCOME TO
            </Typography>
            <Typography component="h2" variant="h4" style={{color:"#054b8a", wordSpacing:"6px", paddingBottom:"25px", fontWeight:"600"}}>
              SALIC INVOICE PORTAL
            </Typography>
            {logging ?
              <CircularProgress style={{ color: "054b8a" }} /> :
                <img src={actionButton} onClick={loginAzure} role="button" width={200} style={{cursor:"pointer"}} />
            }


          </CardFooter>


        </GridItem>
      </GridContainer>
      }
    </div>
  );
}
