import axios from "axios";
import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAIL,
  SUPPLIER_LIST_SUCCESS_FILTERS,
} from "./Constant";

const SupplierList = ({
  page,
  RowsPerPage,
  token,
  Status,
  checkStatus,
  startDate,
  endDate,
}) => async (dispatch) => {
  try {
    dispatch({ type: SUPPLIER_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getAllSuppliersData?page=${page}&items=${RowsPerPage}&supplierStatus=${Status}&checkStatus=${checkStatus}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          cooljwt: token,
        },
      }
    );
    dispatch({ type: SUPPLIER_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUPPLIER_LIST_FAIL, payload: error.messsage });
  }
};

export const SupplierFilter = ({ page, rowPerPage, token, newdata }) => async (
  dispatch
) => {
  dispatch({ type: SUPPLIER_LIST_REQUEST });
  try {
    //Getting Filter Suppliers
    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/filterSuppliers?page=${page}&items=${rowPerPage}`,
      data: newdata,
      headers: { cooljwt: token },
    });
    //Destrurcturing Data

    dispatch({ type: SUPPLIER_LIST_SUCCESS_FILTERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export default SupplierList;
