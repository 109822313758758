import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  IconButton,
  Typography,
  makeStyles,
  Slide,
  DialogContent,
  TextField,
  Dialog,
  MenuItem,
  FormLabel,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
// core components

import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Animated } from "react-animated-css";
import CardIcon from "components/Card/CardIcon.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AttachmentRounded from "@material-ui/icons/AttachmentRounded";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CardFooter from "components/Card/CardFooter";
import VendorProfile from "actions/VendorProfile";
import { successAlert } from "Functions";
import Iframe from "react-iframe";
import { submitAttachments } from "actions/VendorProfile";
import { VendorProfileAttachments } from "actions/VendorProfile";
import { errorAlert } from "Functions";
import { VendorAttachmentsDelete } from "actions/VendorProfile";
import { useParams, useLocation } from "react-router-dom";
import { msgAlert } from "Functions";
import conflictPdf from "../../../../assets/img/Conflict of Interest & Whistblowing Declaration Form.pdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";
import { formatDateTime } from "Functions";
import { sanitizeString } from "views/LDocs/Functions/Functions";

const useStyles = makeStyles(styles);
const Styles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
  table: {
    minWidth: "100%",
  },
  itemName: {
    width: 400,
  },
  itemNumber: {
    width: "55%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Attachments(props) {
  const { setGetAttachments } = props;

  let fileInput = React.createRef();

  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");

  const userCheck = useSelector((state) => state.VendorRegister.generalinfo);

  const attachments =
    useSelector(
      (state) => state.VendorRegister.attachments?.saved_attachments
    ) || [];

  const attachmentsList =
    useSelector((state) => state.VendorRegister?.attachmentList) || [];

  const { generalinfo } = useSelector((state) => state.VendorRegister);

  const address =
    useSelector((state) => state.VendorRegister.address?.values) || {};

  const [imagePreviewUrl, setImagePreviewUrl] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedFileModel, setSelectedFileModel] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [file, setFile] = useState(null);
  const [viewFile, setViewFile] = useState(false);
  const [mainFile, setMainFile] = useState(true);
  const [inSideKsa, setInsideKsa] = React.useState(false);
  const [pdf, setPdf] = React.useState(false);
  const [formState, setFormState] = useState({
    attachments: [],
    values: {
      fileTitle: "",
      required: "",
    },
  });
  const attachmentList = useSelector(
    (state) => state.VendorRegister.attachmentList
  );
  const saved_address =
    useSelector((state) => state.VendorRegister.address?.saved_address) || [];
  const { id, isedit } = useParams();
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [attchmentData, setAttchmentData] = useState();
  const [required, setRequired] = useState();

  // React.useEffect(() => {
  //   if (saved_address[0]?.country === 'Saudi Arabia') {
  //     const filterData = attachmentsList?.filter(list => list?.insideKsa === true)
  //     setAttchmentData(filterData)
  //   } else {
  //     const filterData = attachmentsList?.filter(
  //       list => list?.outsideKsa === true)
  //     setAttchmentData(filterData)
  //   }
  // }, [saved_address,attachmentsList])

  React.useEffect(() => {
    if (saved_address[0]?.country === "Saudi Arabia") {
      // const filterData = attachmentsList?.filter(
      //   list => list?.insideKsa === true
      // )
      setAttchmentData(attachmentsList);
      setInsideKsa(true);
    } else {
      // const filterData = attachmentsList?.filter(
      //   list => list?.outsideKsa === true
      // )
      setAttchmentData(attachmentsList);
      setInsideKsa(false);
    }
  }, [saved_address, attachmentsList]);

  React.useEffect(() => {
    if (isedit === "true") {
      setEdit(true);
    }
    if (isedit === "false") {
      setEdit(false);
    }
  }, [isedit]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: sanitizeString(event.target.value),
      },
    }));
  };
  const checkhandleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        fileTitle: event.target.name,
        [event.target.name]:
          event.target.checked == true
            ? event.target.name
            : event.target.checked == false
            ? ""
            : "",
      },
    }));
  };

  const getFileDetails = () => {
    Object.entries(selectedFile).map(async ([key, value]) => {
      // if (value.type === 'image/png' || 'image/jpeg' || 'application/pdf') {
      let reader = new FileReader();
      reader.onloadend = () => {
        const fileData = {
          name: value.name,
          base64: reader.result,
          type: value.type,
          attachmentTitle: formState.values.fileTitle,
          attachmentPath: "",
          file: value,
          title: formState.values.fileTitle,
          description: formState.values.fileDescription,
          required: formState.values.required,
        };
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [formState.values.fileTitle]: formState.values.fileTitle,
            fileDescription: "",
          },
        }));
        dispatch(VendorProfileAttachments(fileData));
        setSelectedFileModel(false);
      };
      reader.readAsDataURL(value);
      fileInput.current.value = "";
    });
  };

  function hasDoubleExtension(fileName) {
    const parts = fileName.split(".");
    return parts.length > 2;
  }

  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const handleAttachmentChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    debugger;
    if (e.target.files[0].size > 6291456) {
      msgAlert("Maximum File Size 6 MB.");
      fileInput.current.value = "";
    } else if (file?.size === 0) {
      msgAlert("File is not valid.");
      fileInput.current.value = "";
    } else if (!allowedTypes.includes(file.type)) {
      msgAlert(
        "Files with extension (.pdf, .jpeg, .png, .docx .xlsx, .pptx) are allowed."
      );
      fileInput.current.value = "";
    } else if (hasDoubleExtension(file.name)) {
      msgAlert("File name is not valid.");
      fileInput.current.value = "";
    } else {
      let files = e.target.files;
      setSelectedFileName(file.name);
      setSelectedFile(files);
      setSelectedFileModel(true);
    }
  };
  const _closeFileModel = (e) => {
    setSelectedFileModel(false);
    fileInput.current.value = "";
  };

  const viewFileHandler = (file) => {
    setFile(file);
    if (
      file?.eAttachmentURL?.split(".").pop() === "pdf" ||
      file.type === "application/pdf"
    ) {
      setPdf(true);
    } else {
      setPdf(false);
    }
    setMainFile(false);
    setViewFile(true);
  };

  //Remove Attachment
  const removeAttachment = (fileIndex) => {
    dispatch(VendorAttachmentsDelete(fileIndex));
  };
  const closeViewFile = () => {
    setMainFile(true);
    setViewFile(false);
    setFile(null);
  };

  const saveFile = (file) => {
    saveAs(file);
  };

  return (
    <>
      <GridContainer>
        {mainFile == true && (
          <>
            {attchmentData?.map((res, index) => (
              <GridItem xs={12} sm={3} md={2} lg={3}>
                {!edit ? (
                  <>
                    {inSideKsa ? (
                      <>
                        <div
                          style={
                            location.pathname == "/vendor/vendor_profile"
                              ? { display: "inline-flex", flexWrap: "wrap" }
                              : { display: "inline-flex", flexWrap: "wrap" }
                          }
                        >
                          <Typography
                            style={{
                              color: "black",
                            }}
                          >
                            {res?.attachmentTitle}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{
                              color: "red",
                              margin: "0 0 0 5px",
                            }}
                          >
                            {res.insideKsa ? `*` : ""}
                          </Typography>
                        </div>
                        <div>
                          {location.pathname == "/vendor/vendor_profile" &&
                          res.attachmentTitle ==
                            "Disclosure of Conflict of Interest" ? (
                            <Typography
                              style={{
                                margin: "0 0 -24px 0",
                                fontSize: "0.9rem",
                              }}
                            >
                              <a href={conflictPdf} download>
                                Download Template
                              </a>
                            </Typography>
                          ) : (
                            ""
                          )}
                          {location.pathname == "/supplier/register" &&
                          res.attachmentTitle ==
                            "Disclosure of Conflict of Interest" ? (
                            <Typography
                              style={{
                                margin: "0 0 -24px 0",
                                fontSize: "0.9rem",
                              }}
                            >
                              <a href={conflictPdf} download>
                                Download Template
                              </a>
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={
                            location.pathname == "/vendor/vendor_profile"
                              ? { display: "inline-flex", flexWrap: "wrap" }
                              : { display: "inline-flex", flexWrap: "wrap" }
                          }
                        >
                          <Typography
                            style={{
                              color: "black",
                            }}
                          >
                            {res?.attachmentTitle}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{
                              color: "red",
                              margin: "0 0 0 5px",
                            }}
                          >
                            {res.outsideKsa ? `*` : ""}
                          </Typography>
                        </div>
                        <div>
                          {location.pathname == "/vendor/vendor_profile" &&
                          res.attachmentTitle ==
                            "Disclosure of Conflict of Interest" ? (
                            <Typography
                              style={{
                                margin: "0 0 -24px 0",
                                fontSize: "0.9rem",
                              }}
                            >
                              <a href={conflictPdf} download>
                                Download Template
                              </a>
                            </Typography>
                          ) : (
                            ""
                          )}
                          {location.pathname == "/supplier/register" &&
                          res.attachmentTitle ==
                            "Disclosure of Conflict of Interest" ? (
                            <Typography
                              style={{
                                margin: "0 0 -24px 0",
                                fontSize: "0.9rem",
                              }}
                            >
                              <a href={conflictPdf} download>
                                Download Template
                              </a>
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}

                {attachments?.find(
                  (list) => list?.attachmentTitle == res?.attachmentTitle
                ) ? (
                  attachments?.map(
                    (list, index) =>
                      list?.attachmentTitle == res?.attachmentTitle && (
                        <>
                          {/* {list?.attachmentTitle ==
                            'Disclosure of Conflict of Interest' ? (
                              <Typography style={{margin: "0px 0px -25px 0px"}}>

                              <a
                                href={conflictPdf}
                                download
                              >
                                Download Template
                              </a>
                              </Typography>
                            ) : (
                              ''
                            )} */}
                          <Card>
                            <CardBody
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                cursor: "pointer",
                                height: "158px",
                              }}
                            >
                              {/* <AttachmentRounded
                                onClick={() => viewFileHandler(list)}
                                style={{
                                  alignSelf: 'center',
                                  width: 50,
                                  height: 70,
                                  marginBottom: 10
                                }}
                                fontSize='large'
                              /> */}
                              <Typography variant="body1" component="h6">
                                {list?.attachmentTitle}
                              </Typography>
                            </CardBody>
                            <CardFooter>
                              {/* <IconButton
                                onClick={() => viewFileHandler(list)}
                                style={{
                                  float: 'right',
                                  color: 'orange'
                                }}
                                fontSize='small'
                              >
                                <VisibilityIcon fontSize='small' />
                              </IconButton> */}
                              <IconButton
                                onClick={() =>
                                  saveFile(
                                    list?.base64 ||
                                      `${process.env.REACT_APP_LDOCS_API_URL}/${list?.eAttachmentPath}`
                                  )
                                }
                                style={{
                                  float: "right",
                                  color: "orange",
                                }}
                                fontSize="small"
                              >
                                <GetAppIcon />
                              </IconButton>
                              {!edit ? (
                                <IconButton
                                  onClick={() => removeAttachment(index)}
                                  style={{
                                    float: "right",
                                    color: "red",
                                  }}
                                  fontSize="small"
                                >
                                  <HighlightOffIcon fontSize="small" />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </CardFooter>
                          </Card>
                        </>
                      )
                  )
                ) : !edit ? (
                  <>
                    <Card
                      onClick={() => {
                        fileInput.current.click();
                        setFormState((formState) => ({
                          ...formState,
                          values: {
                            ...formState.values,
                            fileTitle: res?.attachmentTitle,
                            required: true,
                          },
                        }));
                      }}
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        cursor: "pointer",
                        background: "#f5f5f5",
                        height: "220px",
                        // margin: '5px'
                      }}
                    >
                      <AddCircleOutlineIcon
                        style={{
                          alignSelf: "center",
                          width: 50,
                          height: 125,
                          marginBottom: 10,
                        }}
                        fontSize="large"
                      />
                      <Typography variant="body1" component="h6">
                        Add Attachment
                      </Typography>
                    </Card>
                  </>
                ) : (
                  ""
                )}
              </GridItem>
            )) || []}
          </>
        )}
        <GridItem
          style={{
            marginTop: "20px",
            display: "flex",
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <div className="fileinput text-center">
            <input
              type="file"
              name="attachments"
              accept='"image/jpeg",
              "image/png",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-powerpoint",
              "application/vnd.openxmlformats-officedocument.presentationml.presentation"'
              ref={fileInput}
              onChange={handleAttachmentChange}
            />
          </div>
        </GridItem>

        {selectedFileModel ? (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={"md"}
            open={selectedFileModel}
            keepMounted
            onClose={() => setSelectedFileModel(false)}
            aria-labelledby="selected-modal-slide-title"
            aria-describedby="selected-modal-slide-description"
          >
            <DialogContent id="selected-Select" className={classes.modalBody}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>File Details</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <TextField
                        className={classes.textField}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        fullWidth={true}
                        label="File Name"
                        disabled
                        value={selectedFileName}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <TextField
                        className={classes.textField}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        fullWidth={true}
                        label="File Description"
                        name="fileDescription"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        value={formState.values.fileDescription || ""}
                      />
                    </GridItem>
                    <GridItem
                      style={{
                        display: "flex",
                        alignItems: "right",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Button
                        color="danger"
                        size="small"
                        onClick={() => _closeFileModel()}
                        round
                      >
                        Close
                      </Button>
                      <Button
                        color="info"
                        size="small"
                        onClick={getFileDetails}
                        round
                      >
                        Add File
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </DialogContent>
          </Dialog>
        ) : (
          ""
        )}

        {viewFile ? (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Animated
              animationIn="bounceInRight"
              animationOut="bounceOutLeft"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={viewFile}
            >
              <Card xs={12} sm={12} md={12} lg={12}>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      {file.attachmentTitle}
                    </h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => closeViewFile()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  {pdf ? (
                    <Iframe
                      url={
                        file?.base64 ||
                        `${process.env.REACT_APP_LDOCS_API_URL}/${file?.eAttachmentURL}`
                      }
                      width="100%"
                      id="myId"
                      className="myClassname"
                      height={window.screen.height}
                    />
                  ) : (
                    <img
                      width="100%"
                      src={
                        file?.base64 ||
                        `${process.env.REACT_APP_LDOCS_API_URL}/${file?.eAttachmentURL}`
                      }
                      alt="image"
                    />
                  )}
                </CardBody>
              </Card>
            </Animated>
          </GridItem>
        ) : (
          ""
        )}
      </GridContainer>
    </>
  );
}
