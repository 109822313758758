import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GeneralInfo from './GeneralInfo.js'
import Bankdetails from './Bankdetails'
import Certificates from './Certificates'
import Wizard from "./Wizard.js";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);

export default function Tabs({ generalInfo, certificates, bankdetails }) {

  const classes = useStyles();
  let history = useHistory();
  const steps = [
    {
      stepName: "GENERAL INFORMATION",
      stepComponent: GeneralInfo,
      stepId: "about",
    },
    {
      stepName: "CERTIFICATES/ATTACHMENTS",
      stepComponent: Certificates,
      stepId: "account",
    },
    {
      stepName: "BANK DETAILS",
      stepComponent: Bankdetails,
      stepId: "address",
    },
  ];
  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      <Card >
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <h4 className={classes.cardTitleText}>Supplier Details</h4>
          </CardIcon>
          <Button
            color="danger"
            round
            style={{ float: "right" }}
            className={classes.marginRight}
            onClick={() => history.goBack()}

          >
            GO BACK
          </Button>
        </CardHeader>
        <Wizard
          validate
          steps={steps.filter((s) => s.stepName != null || undefined || "")}
          generalInfo={generalInfo}
          certificates={certificates}
          bankdetails={bankdetails}


        />
      </Card>
    </GridItem>
  );
}
