import React from "react";
import {
  CircularProgress,
  LinearProgress,
  TextField,
  makeStyles,
  DialogContent,
  Dialog,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import { addZeroes } from "views/LDocs/Functions/Functions";

const useStyles = makeStyles();

const RecDetailModal = (props) => {
  const classes = useStyles();
  const {
    recDetailModal,
    handleRecDetailModal,
    handleCloseRecDetailModal,
    selectedRecord,
  } = props;
  console.log("selectedRecord", selectedRecord);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        fullWidth={true}
        maxWidth={"md"}
        open={recDetailModal}
        keepMounted
        onClose={handleCloseRecDetailModal}
        aria-labelledby="selected-modal-slide-title"
        aria-describedby="selected-modal-slide-description"
      >
        <DialogContent id="selected-Select" className={classes.modalBody}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <h4 className={classes.cardTitleText}>Receipt Details</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Receipt #</TableCell>
                        <TableCell>PO Line #</TableCell>
                        <TableCell>Received</TableCell>
                        <TableCell>Invoiced</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedRecord?.poLineDetails
                        ?.filter(
                          (x) => x.POLineNumber === selectedRecord?.POLineNumber
                        )
                        .map((row) => (
                          <TableRow key={row?.POLineNumber}>
                            <TableCell>
                              {selectedRecord?.ReceiptNumber}
                            </TableCell>
                            <TableCell>{row?.POLineNumber}</TableCell>
                            <TableCell>{row?.Received}</TableCell>
                            <TableCell>{row.Invoiced}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <GridItem
                  style={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Button
                    color="danger"
                    size="small"
                    onClick={handleCloseRecDetailModal}
                    round
                  >
                    Close
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RecDetailModal;
